import styled from 'styled-components';
import { AnchorLabel, Base } from 'styled/components/base';
import Button from 'styled/components/Button';
import Img from 'styled/components/Img';
import { BasePortableText } from 'styled/components/portableText';
import Theme from 'styled/theme';

type ContentContainerProps = {
  windowHeightIsSmallerThanContentHeight: boolean;
};

export const Container = styled(Base)`
  display: flex;
  height: ${({ theme }) => `calc(100vh - ${theme.sizes.topBarHeightTablet})`};
`;

export const ContentContainer = styled(Base)<ContentContainerProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.text.primary};
  margin: 1rem;

  ${Theme.mediaQueries.sm} {
    max-height: ${({ theme }) =>
      `calc(100vh - ${theme.sizes.topBarHeightTablet} - 2rem)`}; // offset top and bottom margins
    justify-content: ${({ windowHeightIsSmallerThanContentHeight }) =>
      windowHeightIsSmallerThanContentHeight ? 'start' : 'center'};
  }

  ${Theme.mediaQueries.md} {
    min-width: calc(50% - 10rem); // offset left and right margins
    margin-left: 5rem;
    margin-right: 5rem;
  }
`;

export const BackButton = styled(Button).attrs({
  variant: 'underline-blue-xs',
})`
  margin-bottom: 1.5rem;
`;

export const Title = styled.h1`
  ${({ theme }) => theme.typography.variants.primary['md-lg']};
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 1.5rem;

  ${Theme.mediaQueries.sm} {
    ${({ theme }) => theme.typography.variants.primary.xxl};
  }
`;

export const Image = styled(Img)`
  border-radius: 0.5rem;
  object-fit: cover;
  height: 9.375rem;

  ${Theme.mediaQueries.sm} {
    height: 13.9375rem;
  }

  ${Theme.mediaQueries.md} {
    border-radius: unset;
    height: 100%;
    margin-left: auto;
  }
`;

export const TextContainer = styled(BasePortableText)`
  p {
    ${({ theme }) => theme.typography.variants.primary.sm};
    margin-bottom: 1rem;
  }

  ul,
  ol {
    ${({ theme }) => theme.typography.variants.primary.xs};
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  ul > li {
    padding-left: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${Theme.mediaQueries.sm} {
    p {
      ${({ theme }) => theme.typography.variants.primary.md};
    }

    ul,
    ol {
      ${({ theme }) => theme.typography.variants.primary.sm};
      margin-left: 0.75rem;
    }
  }
`;

export const SectionWithTopBorder = styled(Base)`
  border-top: ${({ theme }) => `1px solid ${theme.palette.gray[10]}`};
  padding-top: 2rem;
  margin-top: 2rem;
`;

export const CtaButtonContainer = styled(Base)`
  display: flex;
  margin: 1.5rem 0;

  ${AnchorLabel} {
    ${({ theme }) => theme.typography.variants.primary.xs};
  }

  ${Theme.mediaQueries.sm} {
    ${AnchorLabel} {
      ${({ theme }) => theme.typography.variants.primary.sm};
    }
  }
`;

export const DesktopImageContainer = styled(Base)`
  display: flex;
  min-width: calc(50% - 10rem); // offset left and right margins
`;
