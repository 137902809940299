import styled from 'styled-components';
import { Base, Theme } from 'styled';

type ContainerProps = {
  desktopImageMaxWidth: number | null;
};

export const Container = styled(Base)<ContainerProps>`
  margin-bottom: 2.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14);

  ${Theme.mediaQueries.sm} {
    margin-bottom: 3.5rem;
    max-width: ${({ desktopImageMaxWidth }) =>
      !desktopImageMaxWidth ? '100vw' : `${desktopImageMaxWidth}px`};
  }
`;
