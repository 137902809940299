import getHost from 'utils/getHost';
import Environments from 'constants/Environments';

const isStaging = () => {
  /*
   * Server
   */
  if (typeof window === 'undefined') {
    return (
      process.env.VERCEL !== undefined || process.env.ENVIRONMENT === 'stage'
    );
  }

  /*
   * Browser
   */
  const host = getHost();

  return (
    host.includes(Environments.STAGING.vercelHost) ||
    host === Environments.STAGING.host
  );
};

export default isStaging;
