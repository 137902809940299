import styled from 'styled-components';
import { Base } from 'styled';
import { Props } from './index';

type PaginationContainerProps = Pick<Props, 'activeLinkBgColor'>;

/*
 * For now, the only way to make styled-components compatible with React Paginate
 * is by wrapping it with a container and pass in classNames.
 */

export const PaginationContainer = styled(Base)<PaginationContainerProps>`
  ${({ theme }) => theme.typography.variants.primary.sm};

  > ul.pagination-next-previous-button-container {
    display: flex;
    align-items: center;
  }

  a.pagination-button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.125rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    &:hover {
      cursor: pointer;
    }
  }

  .active {
    background-color: ${({ activeLinkBgColor }) => activeLinkBgColor};
    color: ${({ theme }) => theme.palette.common.white};
  }

  .pagination-next-previous-button {
    &:hover {
      cursor: pointer;
    }
  }

  .pagination-arrow-icon {
    max-width: 0.6875rem;
  }

  .pagination-disabled {
    > .pagination-next-previous-button {
      opacity: 0.4;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  li.break {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  a.pagination-next-previous-button {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
`;
