import React, { FC } from 'react';

import { Container } from './style';
import { Img } from 'styled';
import sanityImgUtil from 'utils/sanityImgUtil';

import { Image } from 'types';

interface Props {
  image: Image;
  desktopImageMaxWidth: number | null;
}

const ArticleContentImg: FC<Props> = ({ image, desktopImageMaxWidth }) => {
  return (
    <Container desktopImageMaxWidth={desktopImageMaxWidth}>
      <Img
        alt={image.alt}
        className="flex fit-cover w100 h100"
        src={sanityImgUtil(image, 1500)}
      />
    </Container>
  );
};

export default ArticleContentImg;
