import React, { FC } from 'react';
import BlockContent from '@sanity/block-content-to-react';

import sanityImgUtil from 'utils/sanityImgUtil';
import getPortableTextAsString from 'utils/getPortableTextAsString';

import { ExternalLink, InternalLink } from 'constants/PortableTextSerializer';

import { Img, LinkOrButton } from 'styled';
import {
  Container,
  Card,
  ImgContainer,
  TextContainer,
  TitleContainer,
  DescriptionContainer,
} from './style';

import { ThumbnailCardsModule as IThumbnailCardsModule } from 'types';

type Props = {
  module: IThumbnailCardsModule;
};

const ThumbnailCardsModule: FC<Props> = ({ module }) => {
  const { cards } = module;

  if (!cards.length) return null;

  return (
    <Container>
      {cards.map((card) => {
        const { id, image, backgroundColor, url, title, description } = card;

        const styledDescription = (
          <DescriptionContainer>
            <BlockContent
              blocks={description}
              serializers={{
                marks: { link: ExternalLink, internalLink: InternalLink },
              }}
            />
          </DescriptionContainer>
        );

        return (
          <Card key={id}>
            <ImgContainer bgColor={backgroundColor}>
              {!!image.id && (
                <Img
                  alt={image.alt}
                  src={sanityImgUtil(image, 240)}
                  dimensions={image.metadata?.dimensions}
                  crop={image.crop}
                  preloadFullWidth={true}
                />
              )}
            </ImgContainer>
            <TextContainer>
              <TitleContainer>
                {url ? (
                  <LinkOrButton
                    ariaLabel={title}
                    label={title}
                    variant="hover-underline-black"
                    to={url}
                    wrap={true}
                  />
                ) : (
                  <span>{title}</span>
                )}
              </TitleContainer>
              {url ? (
                <LinkOrButton
                  ariaLabel={getPortableTextAsString(description)}
                  to={url}
                >
                  {styledDescription}
                </LinkOrButton>
              ) : (
                styledDescription
              )}
            </TextContainer>
          </Card>
        );
      })}
    </Container>
  );
};

export default ThumbnailCardsModule;
