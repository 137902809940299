import { useQuery } from 'react-query';
import { useCallback } from 'react';

import BazaarClient from 'lib/BazaarClient';
import { AffiliateProductsResponse, BlogPost } from 'types';
type Response = {
  query: string;
  blogAffiliateProducts: AffiliateProductsResponse['results'] | null;
};

type Options = {
  blogPost?: BlogPost | null;
};

function useAffiliateProducts({ blogPost }: Options) {
  const queryFunction = useCallback(async () => {
    if (!blogPost)
      return {
        query: '',
        blogAffiliateProducts: null,
      };
    const { categories, frozen, slug } = blogPost;
    const category = categories?.[0] || '';
    const blogSlug = frozen ? `/blog${category}${slug}` : `${category}${slug}`;

    const response = await BazaarClient.getAffiliateProducts(blogSlug);
    return {
      query: slug,
      blogAffiliateProducts: response?.results || null,
    };
  }, [blogPost]);

  return useQuery<Response>({
    queryKey: [`affiliateProducts-${blogPost?.slug}`],
    queryFn: queryFunction,
    refetchOnWindowFocus: false,
  });
}

export default useAffiliateProducts;
