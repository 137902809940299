import { useQuery } from 'react-query';
import BazaarClient from 'lib/BazaarClient';

import type { Option, Question } from 'lib/BazaarClient/schemas/Questions';
import type { BlogPost } from 'types';
import type { IInterviewQuestion } from '@buoy-components/polaris/types';
import { QuestionType } from '@buoy-components/polaris/types';
type Response = {
  query: string;
  questions: IInterviewQuestion[];
};

type Options = {
  blogPost?: BlogPost | null;
};

function sanitizeQuestions(questions: Question[]): IInterviewQuestion[] {
  return questions.map((question: Question) => ({
    id: question.token,
    key: 'uniqueQA',
    media: '',
    media_alttext: '',
    text: question.text,
    choice:
      question.question_type === 'single'
        ? QuestionType.Single
        : QuestionType.MultipleChoice,
    options: question.options.map((o: Option) => ({
      text: o.text,
      subText: o.subtext || '',
      token: o.token,
      type: '',
    })),
    subText: question.subtext || '',
  }));
}

function useTreatmentGuidanceQuestions(options: Options) {
  const fetcher = async () => {
    if (!options?.blogPost)
      return {
        query: '',
        questions: [],
      };
    const { categories, frozen, slug } = options.blogPost;
    const category = categories?.[0] || '';
    const blogSlug = frozen ? `/blog${category}${slug}` : `${category}${slug}`;

    const response = await BazaarClient.getQuestions(blogSlug);
    return {
      query: slug,
      questions: sanitizeQuestions(response),
    };
  };

  return useQuery<Response>({
    queryKey: [`treatment-guidance-questions-${options.blogPost?.slug}`],
    queryFn: fetcher,
    refetchOnWindowFocus: false,
  });
}

export default useTreatmentGuidanceQuestions;
