import React, { useEffect } from 'react';
import Head from 'next/head';
import { connect } from 'react-redux';

import getPortableTextAsString from 'utils/getPortableTextAsString';
import isStaging from 'utils/isStaging';

import { DefaultSeoSettings } from 'constants/Default';
import Environments from 'constants/Environments';
import { RouteMap } from 'constants/Routes';

import {
  Article as IArticle,
  Author as IAuthor,
  BlogPost as IBlogPost,
  DrugPage as IDrugPage,
  GlobalState,
  Image,
  PortableText,
  SeoSettings,
  Quiz as IQuiz,
} from 'types';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useBlogBase from 'hooks/useBlogBase';
import getDefaultArticleUrl from 'utils/getDefaultArticleUrl';

interface PassedProps {
  allowsLargeImagePreviewForRobots?: boolean;
  children?: React.ReactNode;
  contentType?: 'article' | 'website';
  customCanonicalUrl?: string;
  description?: string;
  image?: Image;
  language?: string;
  nofollow?: boolean;
  noindex?: boolean;
  pathname?: string;
  renderCanonicalTag?: boolean;
  title?: string;
}

type Props = PassedProps & ReturnType<typeof mapStateToProps>;

const getCanonicalUrl = (
  pathname = process.browser ? window.location.pathname : '',
) => {
  const origin = `https://${Environments.PRODUCTION.host}`;
  // No trailing slash for home page
  if (pathname === '/') return origin;

  return `${origin}${pathname}`;
};

const getTranslatedPageUrl = (
  slug: string,
  language: string,
  currentUrl: string,
) =>
  currentUrl.replace(
    /\/learn\/.*/,
    `/learn${language === 'en' ? '' : `/${language}`}${slug}`,
  );

const Meta: React.FC<Props> = ({
  allowsLargeImagePreviewForRobots,
  children,
  contentType = 'website',
  customCanonicalUrl,
  description,
  globalSettingsMeta,
  image,
  language,
  nofollow = false,
  noindex,
  pathname,
  renderCanonicalTag = true,
  title,
}) => {
  const metaTitle =
    title || globalSettingsMeta.title || DefaultSeoSettings.title;
  const metaDescription =
    description ||
    globalSettingsMeta.description ||
    DefaultSeoSettings.description;
  const metaImage =
    image && !!image.id
      ? image
      : globalSettingsMeta.image?.id
        ? globalSettingsMeta.image
        : DefaultSeoSettings.image;

  const metaRobots = [
    (noindex || isStaging()) && 'noindex',
    allowsLargeImagePreviewForRobots && 'max-image-preview:large',
    nofollow ? 'nofollow' : noindex || isStaging() ? 'follow' : '',
  ]
    .filter(Boolean)
    .join(', ');

  const currentUrl = customCanonicalUrl
    ? customCanonicalUrl
    : getCanonicalUrl(pathname);

  const featureFlags = useFeatureFlags();

  useEffect(() => {
    document.documentElement.setAttribute('lang', language || 'en');
  }, [language]);

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta key="description" name="description" content={metaDescription} />
      {renderCanonicalTag && (
        <link key="canonical" rel="canonical" href={currentUrl} />
      )}

      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={`${metaImage.src}?w=1200`} />
      <meta property="og:image:alt" content={metaImage.alt} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:type" content={contentType} />
      <meta
        property="og:author"
        content="https://www.facebook.com/buoyhealth"
      />

      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={`${metaImage.src}?w=1200`} />
      <meta name="twitter:image:alt" content={metaImage.alt} />
      <meta name="twitter:site" content="@buoyhealth" />
      <meta name="twitter:card" content="summary_large_image" />

      {children && children}
      {metaRobots && <meta name="robots" content={metaRobots} />}

      <meta name="p:domain_verify" content="e141d1408efbe2843a0ffecafcfba1bf" />
      <meta name="msvalidate.01" content="8DDAE1D23A2E9FECD7C589F097468D66" />
      <meta
        name="google-site-verification"
        content="_2Q2G77at1kpKFhSCk1gvNFmMZGzvN_hqXOjVtj_vEA"
      />
      <meta
        name="facebook-domain-verification"
        content="z6yqxa41ugu35b04c0r3m2orxykde7"
      />
      {featureFlags?.['growth-public-site-ad-sense-enabled'] && (
        <script
          async
          crossOrigin="anonymous"
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4261747337972556"
        ></script>
      )}
    </Head>
  );
};

const Article: React.FC<
  {
    article: IArticle;
  } & ReturnType<typeof mapStateToProps>
> = ({ article, globalSettingsMeta }) => {
  const pathname = getDefaultArticleUrl({
    slug: article.slug,
    language: article.language,
    articleType: article.articleType,
  });
  const currentUrl = getCanonicalUrl(pathname);

  return (
    <Meta
      allowsLargeImagePreviewForRobots
      contentType="article"
      description={
        article.seo.description ||
        getPortableTextAsString(article.content.overview)
      }
      language={article.language}
      globalSettingsMeta={globalSettingsMeta}
      image={article.seo.image.id ? article.seo.image : article.hero.image}
      noindex={article.seo.noindex}
      pathname={pathname}
      renderCanonicalTag={false}
      title={article.seo.title || `${article.title} | Buoy`}
    >
      {!!article.browsePageLinks.length && (
        <meta
          property="article:tag"
          content={article.browsePageLinks.map((tag) => tag.title).join(', ')}
        />
      )}
      <meta
        property="article:published_time"
        content={article.meta.publishedDate}
      />
      <meta
        property="article:modified_time"
        content={article.meta.modifiedDate}
      />
      {article.meta.author && (
        <meta
          property="article:author"
          content={getCanonicalUrl(
            `${RouteMap.AUTHOR.base}${article.meta.author.slug}`,
          )}
        />
      )}

      <link key="canonical" rel="canonical" href={currentUrl} />

      {article.language && (
        <link
          key="current-page-hreflang"
          rel="alternate"
          href={currentUrl}
          hrefLang={article.language}
        />
      )}

      {article.translatedPages?.map((page) => {
        return (
          <link
            key={page.language}
            rel="alternate"
            href={getTranslatedPageUrl(page.slug, page.language, currentUrl)}
            hrefLang={page.language}
          />
        );
      })}
    </Meta>
  );
};

const DrugPage: React.FC<
  {
    drugPage: IDrugPage;
  } & ReturnType<typeof mapStateToProps>
> = ({ drugPage, globalSettingsMeta }) => {
  return (
    <Meta
      title={
        drugPage.seo.title ||
        `${drugPage.name}: Uses, Side Effects, Interactions & Discounts | Buoy`
      }
      description={
        drugPage.seo.description || getPortableTextAsString(drugPage.overview)
      }
      image={drugPage.seo.image}
      pathname={`${RouteMap.DRUG.base}${drugPage.slug}`}
      globalSettingsMeta={globalSettingsMeta}
      contentType="article"
    >
      <meta
        property="article:published_time"
        content={drugPage.publishedDate}
      />
      <meta property="article:modified_time" content={drugPage.modifiedDate} />
      {drugPage.author && (
        <meta
          property="article:author"
          content={getCanonicalUrl(
            `${RouteMap.AUTHOR.base}${drugPage.author.slug}`,
          )}
        />
      )}
    </Meta>
  );
};

const Page: React.FC<
  {
    title?: string;
    description?: string | PortableText;
    image?: Image;
    seo?: SeoSettings;
    pathname: string;
    noindex?: boolean;
  } & ReturnType<typeof mapStateToProps>
> = ({
  title,
  description,
  image,
  seo,
  pathname,
  noindex,
  globalSettingsMeta,
}) => {
  return (
    <Meta
      title={(seo && seo.title) || `${title} | Buoy`}
      description={
        (seo && seo.description) ||
        (description && typeof description === 'string' && description) ||
        (description && getPortableTextAsString(description))
      }
      image={seo && !!seo.image.id ? seo.image : image}
      pathname={pathname}
      noindex={noindex}
      globalSettingsMeta={globalSettingsMeta}
    />
  );
};

const Author: React.FC<
  {
    author: IAuthor;
  } & ReturnType<typeof mapStateToProps>
> = ({ author, globalSettingsMeta }) => {
  return (
    <Meta
      title={author.fullName}
      description={getPortableTextAsString(author.bio)}
      image={author.image}
      pathname={`${RouteMap.AUTHOR.base}${author.slug}`}
      globalSettingsMeta={globalSettingsMeta}
    />
  );
};

const BlogPost: React.FC<
  {
    blogPost: IBlogPost;
  } & ReturnType<typeof mapStateToProps>
> = ({ blogPost, globalSettingsMeta }) => {
  const { blogBase } = useBlogBase();

  return (
    <Meta
      title={blogPost.seo.title || `${blogPost.title} | Buoy`}
      description={blogPost.seo.description}
      image={blogPost.seo.image.id ? blogPost.seo.image : blogPost.heroImage}
      pathname={`${blogBase}${blogPost.categories?.[0] || ''}${blogPost.slug}`}
      globalSettingsMeta={globalSettingsMeta}
      contentType="article"
      allowsLargeImagePreviewForRobots
      customCanonicalUrl={blogPost.seo.canonicalUrl}
    >
      <meta
        property="article:published_time"
        content={blogPost.meta.publishedDate}
      />
      <meta
        property="article:modified_time"
        content={blogPost.meta.updatedDate}
      />
      {blogPost.meta.author && (
        <meta
          property="article:author"
          content={getCanonicalUrl(
            `${RouteMap.AUTHOR.base}${blogPost.meta.author.slug}`,
          )}
        />
      )}
    </Meta>
  );
};

const ProviderListPage: React.FC<
  {
    title: string;
    description: string;
    pathname: string;
    noindex?: boolean;
  } & ReturnType<typeof mapStateToProps>
> = ({ title, description, pathname, noindex, globalSettingsMeta }) => {
  return (
    <Meta
      title={`${title} | Buoy`}
      description={description}
      pathname={pathname}
      noindex={noindex}
      globalSettingsMeta={globalSettingsMeta}
    />
  );
};

const ProviderFilterPage: React.FC<
  {
    title: string;
    description: string;
    pathname: string;
    noindex?: boolean;
  } & ReturnType<typeof mapStateToProps>
> = ({ title, description, pathname, noindex, globalSettingsMeta }) => {
  return (
    <Meta
      title={`${title} | Buoy`}
      description={description}
      pathname={pathname}
      noindex={noindex}
      globalSettingsMeta={globalSettingsMeta}
    />
  );
};

const QuizPage: React.FC<
  {
    quiz: IQuiz;
  } & ReturnType<typeof mapStateToProps>
> = ({ quiz, globalSettingsMeta }) => {
  const pathname = `${RouteMap.QUIZ.base}${quiz.slug}`;
  return (
    <Meta
      title={`${quiz.seo.title} | Buoy`}
      description={quiz.seo.description}
      pathname={pathname}
      noindex={quiz.seo.noindex}
      globalSettingsMeta={globalSettingsMeta}
    />
  );
};

const mapStateToProps = (state: GlobalState) => ({
  globalSettingsMeta: state.globalSettings.seo,
});

const ArticleMeta = connect(mapStateToProps)(Article);
const PageMeta = connect(mapStateToProps)(Page);
const AuthorMeta = connect(mapStateToProps)(Author);
const BlogPostMeta = connect(mapStateToProps)(BlogPost);
const DrugPageMeta = connect(mapStateToProps)(DrugPage);
const ProviderListPageMeta = connect(mapStateToProps)(ProviderListPage);
const ProviderFilterPageMeta = connect(mapStateToProps)(ProviderFilterPage);
const QuizMeta = connect(mapStateToProps)(QuizPage);

export {
  ArticleMeta,
  PageMeta,
  AuthorMeta,
  BlogPostMeta,
  DrugPageMeta,
  ProviderListPageMeta,
  ProviderFilterPageMeta,
  QuizMeta,
};

export default connect(mapStateToProps)(Meta);
