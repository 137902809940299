import styled from 'styled-components';
import { Base } from 'styled/components/base';
import Img from 'styled/components/Img';
import Theme from 'styled/theme';

type ContainerProps = {
  applyPaddingX: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  ${({ theme }) => theme.global.managedMarketplacePageContentMaxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ applyPaddingX }) =>
    applyPaddingX ? '2.5rem 1rem 1.5rem 1rem' : '2.5rem 0 1.5rem 0'};

  ${Theme.mediaQueries.sm} {
    padding-left: ${({ applyPaddingX }) => applyPaddingX && '1.5rem'};
    padding-right: ${({ applyPaddingX }) => applyPaddingX && '1.5rem'};
  }

  ${Theme.mediaQueries.md} {
    padding: ${({ applyPaddingX }) =>
      applyPaddingX ? '5rem' : '5rem 0 5rem 0'};
  }

  ${Theme.mediaQueries.lg} {
    padding-top: 8rem;
  }

  ${Theme.mediaQueries.xl} {
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

export const Header = styled(Base)`
  text-align: center;
  padding-bottom: 1.5rem;
  ${({ theme }) => theme.typography.variants.primary['md-lg']};

  ${Theme.mediaQueries.md} {
    ${({ theme }) => theme.typography.variants.primary.xl};
  }
`;

export const ImagesContainer = styled(Base)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0 2.5rem 0;
  max-width: 77.5rem;
  margin: 0 auto;

  ${Theme.mediaQueries.sm} {
    padding: 1.5rem 0 1rem 0;
  }

  ${Theme.mediaQueries.md} {
    padding: 1rem 0;
  }

  ${Theme.mediaQueries.lg} {
    padding: 0;
  }
`;

export const Image = styled(Img)`
  height: 100%;
  max-width: 15.375rem;
  max-height: 7.33rem;
  margin: 1.5rem;

  ${Theme.mediaQueries.lg} {
    margin: 1.46rem 1.5rem;
  }
`;

export const ListItemsContainer = styled(Base)`
  padding: 2.5rem 3rem 1.5rem 3rem;
  width: 100%;

  ${Theme.mediaQueries.sm} {
    padding: 1rem 3rem;
  }

  ${Theme.mediaQueries.md} {
    padding: 1rem 2.5rem;
  }

  ${Theme.mediaQueries.lg} {
    padding: 3.625rem 0;
  }
`;

export const ListItemsInnerContainer = styled(Base)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  ${Theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  ${Theme.mediaQueries.lg} {
    max-width: unset;
    flex-wrap: nowrap;
  }
`;

export const IconAndText = styled(Base)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;

  &:not(:first-child) {
    margin: 3rem 0 0 0;
  }

  ${Theme.mediaQueries.sm} {
    &:not(:first-child) {
      margin: 1.5rem;
    }
    text-align: left;
    margin: 1.5rem;
  }

  ${Theme.mediaQueries.lg} {
    &:not(:first-child) {
      margin: 0 1.5rem;
    }

    margin: 0 1.5rem;
  }
`;

export const IconContainer = styled(Base)`
  width: 3rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;

  img,
  div {
    width: 3rem;
    height: 3rem;
  }
`;

export const IconText = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.sm};

  ${Theme.mediaQueries.sm} {
    max-width: 12rem;
  }
`;
