import React, { FC } from 'react';

import cx from 'classnames';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import { Button, Img } from 'styled';
import { ModuleHeader } from 'components/modules';

import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';
import useLanguage from 'hooks/useLanguage';

import { FourColumnVideoModule as IFourColumnVideoModule } from 'types';

type Props = {
  module: IFourColumnVideoModule;
  setVideoPopUpIsActive?(
    vimeoId: string,
    youtubeId: string,
    title: string
  ): void;
};

const FourColumnVideoModule: FC<Props> = ({
  module,
  setVideoPopUpIsActive = () => {},
}) => {
  const { contents, moduleHeader, moduleOptions } = module;
  const Language = useLanguage();

  if (!Object.keys(contents).length) return null;

  const renderModuleHeader = moduleHeader.title || moduleHeader.description;
  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color as GeneralModuleColor];

  return (
    <div
      className={cx(
        'FourColumnVideoModule flex flex-col module-content-padding-x pb2_5',
        {
          'border-top-gray-lighter': moduleOptions.showBorderTop,
          'border-bottom-gray-lighter': moduleOptions.showBorderBottom,
          pt6: !renderModuleHeader,
        }
      )}
      style={{
        backgroundColor: colorScheme.background,
        color: colorScheme.text,
      }}
    >
      {renderModuleHeader && (
        <ModuleHeader
          title={moduleHeader.title}
          description={moduleHeader.description}
        />
      )}
      <div className="FourColumnVideoModule__items">
        {contents.map((content) => {
          const {
            id,
            title,
            vimeoId,
            image,
            customPopUpTitle,
            description,
            buttonLabel,
          } = content;
          const handlePlayVideo = () =>
            setVideoPopUpIsActive(vimeoId, '', customPopUpTitle || title);

          return (
            <div className="FourColumnVideoModule__item w100 mb4" key={id}>
              {image.id && (
                <Button
                  ariaLabel={Language.t('Video.playButtonAriaLabel')}
                  containerClassName="self-center w100 mb1_5"
                  onClick={handlePlayVideo}
                >
                  <Img
                    alt={image.alt}
                    className="FourColumnVideoModule__image fit-contain self-center w100"
                    sizes="(max-width: 1024px) 352px, 700px"
                    src={sanityImgUtil(image, 352)}
                    srcSet={sanityImgSrcSetUtil(image, 352, 352, 700)}
                    preloadContainerClassName="w100"
                    dimensions={image.metadata?.dimensions}
                    crop={image.crop}
                  />
                </Button>
              )}
              <div className="text-md mb1_5">
                <span className="link-border-bottom-black-hidden">{title}</span>
              </div>
              {description && (
                <div className="mb1_5" style={{ color: colorScheme.text }}>
                  <span className="text-secondary-sm">{description}</span>
                </div>
              )}
              <Button
                ariaLabel={Language.t('Video.playButtonAriaLabel')}
                className="inline-flex"
                variant="hover-underline-black"
                label={buttonLabel}
                onClick={handlePlayVideo}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FourColumnVideoModule;
