import { CurrentBreakpoint } from 'lib/withBreakpoints';
import { Breakpoints } from 'lib/withBreakpoints';

import { CARD_MARGIN, CARD_WIDTH } from './constants';

const bpArray = Object.keys(Breakpoints);

export const breakPointIsDown = (
  currentBP: CurrentBreakpoint,
  sizeDown: CurrentBreakpoint
) => {
  if (!currentBP || !sizeDown) return false;
  return bpArray.slice(0, bpArray.indexOf(sizeDown)).includes(currentBP);
};

export const breakpointIsUp = (
  currentBP: CurrentBreakpoint,
  sizeUp: CurrentBreakpoint
) => {
  if (!currentBP || !sizeUp) return false;

  return bpArray.slice(bpArray.indexOf(sizeUp)).includes(currentBP);
};

export const getSlideWidth = (bp: CurrentBreakpoint) => {
  if (bp === 'EXTRA_EXTRA_LARGE') return CARD_WIDTH.XXL;
  if (bp === 'LARGE') return CARD_WIDTH.LG;

  return CARD_WIDTH.SM;
};

/* Returns max width of Carousel to center align the cards, depending on the number of cards and their margins */
export const getCarouselMaxWidth = (
  bp: CurrentBreakpoint,
  itemsLength: number
) => {
  const breakpointIsXxlUp = bp === Breakpoints.EXTRA_EXTRA_LARGE.label;
  const breakpointIsXlUp = breakpointIsUp(bp, Breakpoints.EXTRA_LARGE.label);
  const breakpointIsLgUp = breakpointIsUp(bp, Breakpoints.LARGE.label);
  const breakpointIsMdUp = breakpointIsUp(bp, Breakpoints.MEDIUM.label);

  if (breakpointIsXxlUp && itemsLength >= 4) {
    return CARD_WIDTH.XXL * 4 + CARD_MARGIN * 3;
  }

  if (breakpointIsXlUp && itemsLength >= 4) {
    return CARD_WIDTH.LG * 3 + CARD_MARGIN * 2;
  }

  if (breakpointIsXxlUp && itemsLength === 2) {
    return CARD_WIDTH.XXL * itemsLength + CARD_MARGIN;
  }

  if (breakpointIsXxlUp && itemsLength === 3) {
    return CARD_WIDTH.XXL * itemsLength + CARD_MARGIN * 2;
  }

  if (breakpointIsLgUp && itemsLength === 2) {
    return CARD_WIDTH.LG * itemsLength + CARD_MARGIN;
  }

  if (breakpointIsLgUp && itemsLength >= 3) {
    return CARD_WIDTH.LG * 3 + CARD_MARGIN * 2;
  }

  if (breakpointIsMdUp && itemsLength === 2) {
    return CARD_WIDTH.SM * itemsLength + CARD_MARGIN;
  }

  // Default
  return CARD_WIDTH.SM * itemsLength + CARD_MARGIN;
};
