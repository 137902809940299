import styled from 'styled-components';
import { Base, Theme, AccordionListModulePortableText } from 'styled';
import { Color } from 'styled/theme/colors';

type ContainerProps = {
  hasCallout: boolean | undefined;
  applyArticleBodyWidthOffset: boolean | undefined;
  renderModuleHeader: boolean;
  bgColor: Color;
  textIsBlack: boolean;
};

type DescriptionProps = {
  textIsBlack: boolean;
};

type AccordionTitleProps = {
  textIsBlack: boolean;
};

type AccordionToggleProps = {
  textIsBlack: boolean;
};

type ExpandableContentProps = {
  itemIsExpanded: boolean;
};

type ExpandableContentTextContainerProps = {
  itemIsExpanded: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  width: 100%;
  ${({ theme, applyArticleBodyWidthOffset }) =>
    applyArticleBodyWidthOffset && theme.global.articleInnerTextFullWidth};
  ${({ theme }) => theme.global.moduleContentPaddingX};
  ${({ theme, hasCallout }) =>
    hasCallout && theme.global.moduleWithCalloutContentPaddingX};
  padding-top: ${({ renderModuleHeader }) => (renderModuleHeader ? 0 : '6rem')};
  background-color: ${({ hasCallout, bgColor }) =>
    hasCallout ? undefined : bgColor};
  color: ${({ textIsBlack, theme }) =>
    textIsBlack ? theme.palette.text.primary : theme.palette.common.white};
`;

export const AccordionsContainer = styled(Base)`
  display: flex;
  flex-direction: column;
`;

export const AccordionContainer = styled(Base)`
  border-bottom: ${({ theme }) => theme.borders['black-opacity-20']};

  :first-of-type {
    border-top: ${({ theme }) => theme.borders['black-opacity-20']};
  }
`;

export const AccordionInner = styled(Base)`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Arrow = styled(Base)``;

export const Description = styled(Base)<DescriptionProps>`
  ${({ theme }) => theme.typography.variants.primary.sm};
  color: ${({ textIsBlack, theme }) =>
    textIsBlack ? theme.palette.gray[60] : theme.palette.gray[30]};
`;

export const AccordionTitle = styled(Base)<AccordionTitleProps>`
  ${({ theme }) => theme.typography.variants.primary.md};
  ${({ textIsBlack, theme }) =>
    theme.hoverStyles.linkBorderBottomHidden(
      textIsBlack ? theme.palette.text.primary : theme.palette.common.white
    )};
  color: ${({ textIsBlack, theme }) =>
    textIsBlack ? theme.palette.text.primary : theme.palette.common.white};
`;

export const AccordionToggle = styled(Base)<AccordionToggleProps>`
  :hover,
  :focus {
    ${AccordionTitle} {
      ${({ textIsBlack, theme }) =>
        theme.hoverStyles.linkBorderBottom(
          textIsBlack ? theme.palette.text.primary : theme.palette.common.white
        )};
    }
  }
`;

export const AccordionToggleInner = styled(Base)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ExpandableContent = styled(Base)<ExpandableContentProps>`
  display: flex;
  flex-direction: column;
  text-align: left;
  ${({ theme }) => theme.transitions['transition-short']};
  ${({ itemIsExpanded, theme }) =>
    itemIsExpanded ? theme.animations.openShort : theme.animations.closeShort};
  ${({ theme }) => theme.typography.variants.secondary.sm};
  max-width: 43.75rem;
  width: 100% ${Theme.mediaQueries.md} {
    width: 83%;
  }
`;

export const ExpandableContentTextContainer = styled(
  AccordionListModulePortableText
)<ExpandableContentTextContainerProps>`
  ${({ theme }) => theme.transitions['transition-opacity-short']};
  transition-delay: ${({ itemIsExpanded }) =>
    itemIsExpanded ? '100ms' : undefined};
  opacity: ${({ itemIsExpanded }) => (itemIsExpanded ? 1 : 0)};
`;

export const ModuleDescription = styled(AccordionListModulePortableText)`
  ${({ theme }) => theme.typography.variants.secondary.sm};
  margin-top: 1.75rem;

  ${Theme.mediaQueries.md} {
    width: 83%;
  }
`;

export const ButtonContainer = styled(Base)`
  display: inline-block;
`;
