import React, { FC } from 'react';
import CtaModule from './CtaModule';
import { Container } from './style';

import { ContainerProps } from './types';

const CtaContainer: FC<ContainerProps> = ({ module }) => {
  return (
    <Container data-testid="cta-module" className="disabled-ads">
      <CtaModule module={module} />
    </Container>
  );
};

export default CtaContainer;
