import { trackStructuredEvent } from 'analytics';

const category = 'promoted-supplier-cta';

export const WINDOW_PARAM = 'bh_supplier_cta_module_uuid';

export const SUPPLIER_CTA_ANCHOR = 'get-treatment-today';

export const trackModuleView = (moduleId: string) => {
  trackStructuredEvent({
    category,
    label: moduleId,
    action: 'embedded-cta-viewed',
  });
};

export const trackCtaButtonClicked = (
  moduleId: string,
  action: string,
  url?: string
) => {
  trackStructuredEvent({
    category,
    action,
    label: moduleId,
    property: url,
  });
};

export const trackMobileStickyJumpLinkClicked = (moduleId: string) => {
  trackStructuredEvent({
    category,
    label: moduleId,
    action: 'mobile-sticky-jumplink-clicked',
  });
};

export const trackTOCTreatmentLabelClicked = () => {
  trackStructuredEvent({
    category,
    label: window[WINDOW_PARAM],
    action: 'table-of-contents-jumplink-clicked',
  });
};

export const trackCarePlanLinkClicked = () => {
  trackStructuredEvent({
    category,
    label: window[WINDOW_PARAM],
    action: 'care-plan-link-clicked',
  });
};
