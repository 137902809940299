import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import styled from 'styled-components';
import { Svg } from 'styled';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  circleColor?: Color;
  arrowColor?: Color;
  hoverCircleColor?: Color;
};

const LeftArrowInsideCircle: FC<Props> = ({
  className = '',
  circleColor = theme.palette.gray[90],
  arrowColor = theme.palette.common.white,
}) => (
  <Svg
    width="48"
    height="48"
    viewBox="0 0 80 80"
    fill="none"
    stroke="none"
    className={className}
  >
    <g filter="url(#filter0_ddd)">
      <circle r="32" transform="matrix(-1 0 0 1 40 39)" fill={circleColor} />
    </g>
    <path
      d="M22.7071 38.4041C22.7253 37.9592 22.9164 37.445 23.2348 37.1207L33.6261 26.5425C34.3302 25.9362 35.6804 25.686 36.4674 26.3869C37.2426 27.0773 37.2196 28.4162 36.4473 29.1093L29.1409 36.5374H55.8294C56.9054 36.5374 57.7778 37.3731 57.7778 38.4041C57.7778 39.4351 56.9054 40.2709 55.8294 40.2709H29.1409L36.4473 47.699C37.1122 48.3371 37.235 49.7231 36.4674 50.4213C35.7 51.1195 34.3013 50.8965 33.6261 50.2658L23.2348 39.6875C22.8792 39.3255 22.7059 38.9008 22.7071 38.4041Z"
      fill={arrowColor}
    />
    <defs>
      <filter
        id="filter0_ddd"
        x="0"
        y="0"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="2"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow"
          result="effect2_dropShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow"
          result="effect3_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </Svg>
);

export default styled(LeftArrowInsideCircle)`
  circle {
    ${({ theme }) => theme.transitions['transition']};
  }

  :hover {
    circle {
      fill: ${({ theme, hoverCircleColor = theme.palette.gray[70] }) =>
        hoverCircleColor};
    }
  }
`;
