import React, { FC, useState, useRef, useEffect } from 'react';

import { Video } from 'styled';

import { VideoModule as IVideoModule } from 'types';

interface Props {
  module: IVideoModule;
}

const VideoModule: FC<Props> = ({ module }) => {
  const [load, setLoad] = useState(false);
  const videoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setLoad(true);
        observer.disconnect();
      }
    });

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div ref={videoRef} className="VideoModule flex">
      {load ? (
        module.vimeoId ? (
          <Video video={module} isFullWidth showVimeoVideoControl />
        ) : (
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/${module.youtubeId}`}
            title="YouTube video player"
            allowFullScreen
          ></iframe>
        )
      ) : null}
    </div>
  );
};

export default VideoModule;
