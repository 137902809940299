import styled from 'styled-components';
import { Base } from 'styled';

export const Page = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display'].includes(prop) && defaultValidatorFn(prop),
})`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
`;

export const Title = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.lg};
`;

export const Description = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.sm};
`;

export const Link = styled(Base)``;

export const ImageContainer = styled(Base)`
  width: 100%;
`;
