import axios, { AxiosResponse } from 'axios';
import { AffiliateProductsResponse, IPredictor } from 'types';
import {
  AFFILIATE_PRODUCTS_URL,
  PREDICTOR_MAPPING_URL,
  QUESTIONS_URL,
} from 'lib/BazaarClient/constants';
import validateSchema from 'utils/validateSchema';

import { QuestionSchema } from 'lib/BazaarClient/schemas/Questions';
import type { Question } from 'lib/BazaarClient/schemas/Questions';

const BazaarClient: {
  getPredictorsFromSlug(slug: string, category?: string): Promise<IPredictor[]>;
  getAffiliateProducts(slug: string): Promise<AffiliateProductsResponse>;
  getQuestions(slug: string): Promise<Question[]>;
} = {
  async getPredictorsFromSlug(slug, category) {
    const params = new URLSearchParams();
    params.append('slug', slug.substring(1));
    if (category) {
      params.append('category', category);
    }
    const response: AxiosResponse<IPredictor[]> = await axios
      .get(`${PREDICTOR_MAPPING_URL}?${params.toString()}`)
      .catch((err) => {
        return Promise.reject(err);
      });
    return response.data;
  },
  async getAffiliateProducts(slug) {
    const response: AxiosResponse<AffiliateProductsResponse> = await axios
      .get(`${AFFILIATE_PRODUCTS_URL}?buoy_page_url=${slug}`)
      .catch((err) => {
        return Promise.reject(err);
      });
    return response.data;
  },
  async getQuestions(slug) {
    const response = await axios.get(`${QUESTIONS_URL}?buoy_page_url=${slug}`);
    const validatedResponse = validateSchema({
      schema: QuestionSchema.array(),
      data: response.data,
      defaultValue: [],
    });
    return validatedResponse;
  },
};

export default BazaarClient;
