import React, { FC } from 'react';

import BlockContent from '@sanity/block-content-to-react';

import ScrollableAnchorWrapper from 'components/ScrollableAnchorWrapper';
import {
  ArticleCallout,
  ArticleImage,
  ArticleVideo,
  ListItem,
  ExternalLink,
  InternalLink,
  TextSmall,
} from 'constants/PortableTextSerializer';

import { ArticlePortableText } from 'styled';
import { ArticleTable } from 'styled/components/ArticleTable';
import DynamicFeatherIcon from 'components/DynamicFeatherIcon';

import type { ArticleText } from 'types';

interface Props {
  module: ArticleText;
}

const ArticleBodyTextModule: FC<Props> = ({ module }) => {
  const { sideNav, contentTag, title } = module;

  return (
    <ScrollableAnchorWrapper section={sideNav}>
      <ArticlePortableText className="ArticleBodyTextModule mt-[3rem]">
        {contentTag.text && (
          <span className="mb-2 flex items-center">
            {contentTag.icon?.name && (
              <DynamicFeatherIcon
                {...contentTag.icon}
                className="mr-2"
                size={20}
              />
            )}
            <span className="font-primary-main">{contentTag.text}</span>
          </span>
        )}
        {!!title && <h2 className="!mt-0 mb-2">{title}</h2>}
        <BlockContent
          blocks={module.text}
          serializers={{
            types: {
              articleCallout: ArticleCallout,
              articleImage: ArticleImage,
              articleTable: ArticleTable,
              video: ArticleVideo,
              block: TextSmall,
            },
            marks: { link: ExternalLink, internalLink: InternalLink },
            listItem: ListItem,
          }}
        />
      </ArticlePortableText>
    </ScrollableAnchorWrapper>
  );
};

export default ArticleBodyTextModule;
