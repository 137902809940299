import React, { FC } from 'react';

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';

import { ModuleHeader } from 'components/modules';
import { Img, BasePortableText } from 'styled';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';
import {
  ListItem,
  ExternalLink,
  InternalLink,
} from 'constants/PortableTextSerializer';

import { ImageCardsModule as IImageCardsModule } from 'types';

interface Props {
  module: IImageCardsModule;
  applyArticleBodyWidthOffset?: boolean;
}

const ImageCardsModule: FC<Props> = ({
  module,
  applyArticleBodyWidthOffset,
}) => {
  const { title, imageCards, moduleOptions } = module;
  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color as GeneralModuleColor];

  return (
    <div
      className={cx(`ImageCardsModule`, {
        'border-top-gray-lighter': moduleOptions.showBorderTop,
        'border-bottom-gray-lighter': moduleOptions.showBorderBottom,
        'article-inner-text-full-width': applyArticleBodyWidthOffset,
      })}
    >
      {!!title && (
        <div className="ImageCardsModule__header module-content-padding-x">
          <ModuleHeader title={title} />
        </div>
      )}
      <div className="ImageCardsModule__cards flex flex-col flex-wrap sm:flex-row">
        {imageCards.map((card, index) => {
          return (
            <div
              className="ImageCardsModule__card col-12 sm:col-6 text-sm px1_5 pb3 sm:pb4 md:pb6 sm:px2_25"
              key={`${card.id}`}
              style={{
                backgroundColor:
                  index % 2 === 0
                    ? colorScheme.background
                    : colorScheme.darkBackground,
                color: colorScheme.text,
              }}
            >
              <div className="ImageCardsModule__card-image mb1_5">
                <Img
                  alt={card.image.alt}
                  className="w100"
                  sizes="(max-width: 768px) 400px, (max-width: 1024px) 500px, 650px"
                  src={sanityImgUtil(card.image, 400)}
                  srcSet={sanityImgSrcSetUtil(card.image, 400, 500, 650)}
                  preloadContainerClassName="w100"
                  dimensions={card.image.metadata?.dimensions}
                  crop={card.image.crop}
                />
              </div>
              {card.title && <p className="text-md mb1_5">{card.title}</p>}
              <BasePortableText textColor={colorScheme.text}>
                <BlockContent
                  blocks={card.description}
                  serializers={{
                    listItem: ListItem,
                    marks: { link: ExternalLink, internalLink: InternalLink },
                  }}
                />
              </BasePortableText>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageCardsModule;
