import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import RightArrow from 'styled/components/svg/RightArrow';
import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { withImpressionTracking } from 'analytics';
import { trackSupplierClick, trackSupplierViewed } from './analytics';
import useLanguage from 'hooks/useLanguage';

import type { SupplierCards } from 'types';

const SupplierCard = ({
  supplier,
}: {
  supplier: SupplierCards['suppliers'][0];
}) => {
  const language = useLanguage();
  const impressionId = useMemo(() => uuid(), []);
  const TrackingContainer: React.FC<{ children: React.ReactNode }> = useMemo(
    () =>
      withImpressionTracking('div', () => {
        trackSupplierViewed(impressionId, supplier.buttonUrl);
      }),
    [impressionId, supplier.buttonUrl],
  );

  return (
    <TrackingContainer>
      <Card
        key={supplier.buttonUrl}
        className="w-[272px] flex-shrink-0 rounded-xl border-gray-40 shadow-sm"
        variant="outlined"
      >
        <CardActionArea
          href={supplier.buttonUrl}
          target="_blank"
          onClick={() => {
            trackSupplierClick(impressionId, supplier.buttonUrl);
          }}
          className="p-6"
        >
          <CardContent className="p-0">
            <div className="flex-col">
              <span className="font-primary-smallest">
                {supplier.headerIntroText}
              </span>
              <CardHeader
                title={
                  <span className="font-primary-main">{supplier.header}</span>
                }
                disableTypography
                className="mt-1 p-0"
              />
            </div>
            <div className="mt-4">
              {!!supplier?.bulletPoints?.length && (
                <div className="font-secondary-main grid gap-2">
                  {supplier?.bulletPoints?.map((point) => (
                    <p key={point}>{point}</p>
                  ))}
                </div>
              )}
            </div>
          </CardContent>
          <CardActions className="mt-4 p-0">
            <div className="flex w-full items-center justify-between !rounded-[20px] !bg-blue-0 px-4 py-2">
              <span className="font-primary-smaller text-primary-main">
                {language.t('Global.learnMore')}
              </span>
              <RightArrow />
            </div>
          </CardActions>
        </CardActionArea>
      </Card>
    </TrackingContainer>
  );
};

const SupplierCardsModule = ({ module }: { module: SupplierCards }) => {
  return (
    <div className="disabled-ads w100 my1_5 no-scrollbar flex flex-nowrap gap-x-2 overflow-x-scroll py-1">
      {module.suppliers.map((supplier) => (
        <SupplierCard key={supplier.buttonUrl} supplier={supplier} />
      ))}
    </div>
  );
};

export default SupplierCardsModule;
