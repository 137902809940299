import { BaseButton } from '@buoyhealth/common.buoy-button';
import styled, { css } from 'styled-components';
import FloatingActionButton from 'styled/components/Button/components/FloatingActionButton';

export const ImageContainer = styled.div`
  img,
  svg {
    width: 100%;
    height: auto;
  }
`;

export const Container = styled.div<{ addMargin: boolean }>`
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.palette.orange[0]};
  display: flex;
  gap: 1rem;
  flex-direction: column;

  ${({ addMargin }) =>
    addMargin &&
    css`
      margin: 3rem 0;
    `}

  > ${ImageContainer} {
    display: none;
  }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    padding: 2rem 1.5rem 2rem 1rem;

    > ${ImageContainer} {
      display: block;
      max-width: 151px;
    }
  }
`;

export const StyledList = styled.ul`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-self: flex-start;
`;

export const StyledListItem = styled.li`
  display: flex;
  gap: 0.5rem;
  ${({ theme }) => theme.typography.variants.primary.sm};
  font-size: 1rem;

  svg {
    margin-top: -2px;
  }
`;

export const StyledButton = styled(BaseButton)<{ $spaceBetween?: boolean }>`
  background-color: ${({ theme }) => theme.palette.orange[20]};
  color: ${({ theme }) => theme.palette.text.primary};
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  padding: 0.75rem 1.5rem;
  margin-top: 1rem;
  align-self: center;
  flex: none;

  &.Mui-focusVisible {
    box-shadow: ${({ theme }) =>
      `0 0 10px 0 ${theme.palette.orange[50]}, 0 0 5px 0 ${theme.palette.orange[50]}`};

    &:after {
      border-radius: 30px;
    }
  }

  ${({ $spaceBetween }) =>
    $spaceBetween &&
    css`
      > span {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    `}

  &.MuiButtonBase-root:hover {
    background-color: ${({ theme }) => theme.palette.orange[20]};
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  ${ImageContainer} {
    max-width: 135px;

    @media screen and (min-width: 600px) {
      display: none;
    }
  }
`;

export const Header = styled.div`
  ${({ theme }) => theme.typography.variants.primary.md}
  margin-bottom: 0.5rem;
`;

export const Subheader = styled.div`
  ${({ theme }) => theme.typography.variants.secondary.sm};
  font-size: 1rem;
  margin-bottom: 1rem;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1rem;
  }
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media screen and (min-width: 600px) {
    .MuiButtonBase-root {
      min-width: unset;
      width: auto;
      margin: 0;
      align-self: flex-end;
      flex-grow: 0.5;
    }
  }
`;

export const FloatingTreatmentButton = styled(FloatingActionButton)`
  button,
  a {
    background-color: ${({ theme }) => theme.palette.orange[20]};
    color: ${({ theme }) => theme.palette.text.primary};
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2);

    &.MuiButtonBase-root:hover {
      background-color: ${({ theme }) => theme.palette.orange[20]};
    }
  }
`;
