import styled from 'styled-components';
import { Base, Theme, AccordionListModulePortableText } from 'styled';

type ContainerProps = {
  variant: 'with-blue-accents' | 'without-blue-accents';
  applyPaddingX: boolean;
};

type DividerProps = {
  moduleIntersectsWithViewport: boolean;
};

type TitleContainerProps = {
  variant: 'with-blue-accents' | 'without-blue-accents';
};

type AccordionContainerProps = {
  variant: 'with-blue-accents' | 'without-blue-accents';
};

type AccordionTitleProps = {
  variant: 'with-blue-accents' | 'without-blue-accents';
};

type AccordionToggleProps = {
  itemIsExpanded: boolean;
};

type ExpandableContentProps = {
  itemIsExpanded: boolean;
};

type ExpandableContentTextContainerProps = {
  itemIsExpanded: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  ${({ theme }) => theme.global.managedMarketplacePageContentMaxWidth};
  margin: ${({ variant }) =>
    variant === 'with-blue-accents' ? '0 auto' : '1rem auto 0'};
  padding-top: ${({ variant }) => variant === 'with-blue-accents' && '5rem'};
  padding-bottom: ${({ variant }) =>
    variant === 'with-blue-accents' ? '6.5rem' : '1rem'};
  padding-left: ${({ applyPaddingX }) => (applyPaddingX ? '1.5rem' : '0rem')};
  padding-right: ${({ applyPaddingX }) => (applyPaddingX ? '1.5rem' : '0rem')};

  ${Theme.mediaQueries.md} {
    padding-left: ${({ variant, applyPaddingX }) =>
      variant === 'with-blue-accents' && applyPaddingX && '6.5rem'};
    padding-right: ${({ variant, applyPaddingX }) =>
      variant === 'with-blue-accents' && applyPaddingX && '6.5rem'};
  }
`;

export const DividerContainer = styled(Base)`
  width: 6.25rem;
`;

export const Divider = styled(Base)<DividerProps>`
  width: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.blue[80]};
  margin-bottom: 1.5rem;
  ${({ moduleIntersectsWithViewport, theme }) =>
    moduleIntersectsWithViewport && theme.animations.growInWidth};
  animation-delay: 300ms;
`;

export const TitleContainer = styled(Base)<TitleContainerProps>`
  ${({ theme, variant }) =>
    variant === 'with-blue-accents'
      ? theme.typography.variants.primary.lg
      : theme.typography.variants.primary.md};

  ${Theme.mediaQueries.md} {
    ${({ theme, variant }) =>
      variant === 'with-blue-accents' && theme.typography.variants.primary.xl};
  }
`;

export const AccordionsContainer = styled(Base)`
  display: flex;
  flex-direction: column;
`;

export const AccordionContainer = styled(Base)<AccordionContainerProps>`
  border-bottom: ${({ theme, variant }) =>
    theme.borders[
      variant === 'with-blue-accents' ? 'black-opacity-20' : 'gray-10'
    ]};
  overflow-y: hidden;
`;

export const AccordionInner = styled(Base)`
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-y: hidden;
`;

export const Arrow = styled(Base)`
  margin-left: 1.6875rem;
  min-width: 1.176875rem;
  width: 1.176875rem;
  margin-top: 0.5rem;
`;

export const AccordionTitle = styled.span<AccordionTitleProps>`
  ${({ theme, variant }) =>
    variant === 'with-blue-accents'
      ? theme.typography.variants.primary.md
      : theme.typography.variants.primary.sm};
  ${({ theme }) =>
    theme.hoverStyles.linkBorderBottomHidden(theme.palette.text.primary)};
`;

export const AccordionToggle = styled(Base)<AccordionToggleProps>`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 3rem;
  padding-bottom: ${({ itemIsExpanded }) =>
    itemIsExpanded ? '1.5rem' : '2.25rem'};

  :hover,
  :focus {
    ${AccordionTitle} {
      ${({ theme }) =>
        theme.hoverStyles.linkBorderBottom(theme.palette.text.primary)};
    }
  }
`;

export const AccordionToggleInner = styled(Base)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ExpandableContent = styled(Base)<ExpandableContentProps>`
  display: flex;
  flex-direction: column;
  text-align: left;
  ${({ theme }) => theme.transitions['transition-short']};
  ${({ itemIsExpanded, theme }) =>
    itemIsExpanded ? theme.animations.openShort : theme.animations.closeShort};
  ${({ theme }) => theme.typography.variants.secondary.sm};
  width: 100%;
`;

export const ExpandableContentTextContainer = styled(
  AccordionListModulePortableText
)<ExpandableContentTextContainerProps>`
  ${({ theme }) => theme.transitions['transition-opacity-short']};
  transition-delay: ${({ itemIsExpanded }) =>
    itemIsExpanded ? '100ms' : undefined};
  opacity: ${({ itemIsExpanded }) => (itemIsExpanded ? 1 : 0)};

  && {
    p:last-child {
      padding-bottom: 2.25rem;
    }

    p:not(:last-child) {
      margin-bottom: unset;
    }
  }
`;
