import React from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import styled from 'styled-components';
import { GDPR_COUNTRIES } from 'constants/CountryCodes';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { Base } from '../base';

const Container = styled(Base)`
  a {
    color: ${({ theme }) => theme.palette.blue[50]};
    text-decoration: underline;
  }
`;

const CookieDeclaration = () => {
  const featureFlags = useFeatureFlags();
  const { asPath } = useRouter();

  const cookiebotConfig =
    featureFlags?.['growth-public-site-cookiebot-configuration'] || {};

  const cookiebotId =
    cookiebotConfig?.['data-cbid'] || '20a05637-e6a2-4482-bddf-ea400787b4f3';

  return (
    <Container>
      <div id="CookieDeclaration">
        <Script
          async
          data-culture={
            asPath.toLowerCase().includes('cookies-es') ? 'ES' : 'EN'
          }
          data-georegions={`{'region':'${GDPR_COUNTRIES.join(', ')}','cbid':'7111f24d-4152-4a21-a63e-ea4069a93544'}`}
          src={`https://consent.cookiebot.com/${cookiebotId}/cd.js`}
          type="text/javascript"
          {...cookiebotConfig}
        />
      </div>
    </Container>
  );
};

export default CookieDeclaration;
