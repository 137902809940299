import React, { FC } from 'react';
import { Svg } from 'styled';

type Props = {
  className?: string;
};

const Telemedicine: FC<Props> = ({ className = '' }) => (
  <Svg width="100%" height="100%" viewBox="0 0 800 850" className={className}>
    <desc>
      Illustration of a healthcare provider asking questions on a smart phone.
    </desc>
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#f3ab96"
      d="m712.02,323.39c-1.48-9.19-10.46-17.26-19.62-15.63,11.01-3.32,18.45-15.68,16.22-26.96-2.23-11.28-14.98-15.94-27.21-10.4,7.7-4.13,11.36-24.46.34-31.27-7.43-4.6-16.17-1.27-21.73,5.47-5.56,6.74-6.56,16.38-4.54,24.89-3.85-5.38-12.83-5.08-17.36-.27-4.54,4.82-4.89,12.55-2.17,18.59,2.71,6.03,7.96,10.57,13.58,14.07-10.51-4.24-24,2.86-26.44,13.93-2.45,11.07,6.78,23.19,18.1,23.78-7.95-5.37-24.71,1.57-26.17,18.17-1.72,19.55,21.31,34.4,40.69,36.78,32.39,3.98,49.74-13.65,49.74-28.35,0-14.27-9.76-20.19-24.04-19.49,24.46,3.2,32.1-14.1,30.62-23.29Z"
    />
    <path
      className="cls-7"
      strokeWidth="0px"
      fill="#9cc7c9"
      d="m733.2,309.23c6.5-.15,15.77-12.41,10.76-20.63-3.38-5.55-10.3-6.29-16.26-3.68-5.96,2.6-9.87,8.66-11.39,14.99-.75-4.87-6.8-7.69-11.43-6.02-4.63,1.67-7.47,6.69-7.7,11.6-.23,4.92,1.73,9.7,4.28,13.91-5.55-6.35-16.89-6.18-22.24.34-4.24,5.16-3.82,13.22.16,18.44-5.53-2.66-13.96-3.08-20.25,4.05-5.45,6.17-.28,17,4.88,23.67-1.28-.03-17.86-5.47-22.07,8.52-3.52,11.72,6.49,28.24,16.86,34.61,8.81,5.41,26.27,9.76,33.85.7,2.03-2.43,3.09-5.56,3.51-8.7.46-3.41.21-6.86-.6-10.19,10.37.66,18.27-3.89,21.35-9.95,3.89-7.68,1.68-13.87-4.47-18.25,12.55,5.6,21.36-2.07,23.23-7.93,2.11-6.6-1.13-14.98-7.76-16.98,8.42,1.5,17.52-4.19,19.84-12.43,2.32-8.24-4.58-15.62-14.56-16.07Z"
    />
    <polygon
      className="cls-4"
      strokeWidth="0px"
      fill="#1c2e57"
      points="693.91 524.76 610.59 524.76 590.59 384.83 713.91 384.83 693.91 524.76"
    />
    <rect
      className="cls-7"
      strokeWidth="0px"
      fill="#9cc7c9"
      x="185.02"
      y="123.11"
      width="444.85"
      height="591.17"
      rx="16.61"
      ry="16.61"
    />
    <rect
      className="cls-8"
      strokeWidth="0px"
      fill="#648881"
      x="226.36"
      y="161.34"
      width="362.16"
      height="474.13"
    />
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#f3ab96"
      d="m502.53,462.08l-1.76-7.42c-.34-4.08-1.16-7.64-2.56-10.78l-39.77-167.54h-134.5s1.65,23.7,0,34.72c-1.65,11.02-22.05,14.33-24.8,28.66-2.76,14.33,7.17,25.35,7.17,25.35v35.27c0,35.27,25.49,45.75,25.49,45.75l.96,23.15,7.61-.32c10.9,8.36,30.72,14.27,43.96,16.06,27.37,3.7,55.5,1.68,82.06-5.88,7.07-2.01,22.24-9.62,29.84-16.76l6.3-.27Z"
    />
    <path
      className="cls-4"
      strokeWidth="0px"
      fill="#1c2e57"
      d="m340.79,231.31c-13.87.78-27.97,8.38-33.4,21.16-5.43,12.78.66,30.16,13.94,34.22,9.94,3.04,20.66-1.44,31-.34,5.48.58,10.69,2.72,16.13,3.57,5.29.83,10.68.44,16.02.04,6.53-.48,13.06-.97,19.58-1.45,8.79-.65,19.11-.66,24.52,6.29,4.51,5.8,3.55,14.16,1,21.05-2.55,6.89-6.47,13.4-7.34,20.69-1.18,9.89,3.42,19.57,3.84,29.53.53,12.42-5.47,24.83-2.88,37,3.07,14.4,17.75,23.97,32.36,25.84s30.02,1.01,44.74.46c27.08-1.02,34.29-15.01,36.93-24.06,7.72-26.46-5.51-39.84-5.51-49.05,0-19.84,7.62-24.51,6.06-35.27-2.01-13.84-11.44-22.33-14.5-35.97-2.14-9.53,1.48-19.93-1.97-29.08-4.01-10.63-16.2-15.8-27.49-17.07-11.29-1.27-22.91.16-33.91-2.67-12.75-3.28-24.31-12.17-37.45-11.52-13.24.65-25.04,9.26-38.02,11.5-14.13,2.43-29.16-5.67-43.64-4.86Z"
    />
    <g>
      <path
        className="cls-3"
        strokeWidth="0px"
        fill="#f8edeb"
        d="m346.46,336.31c0-11.06,10.71-20.16,24.44-21.25.9-.07,1.82-.12,2.76-.12,15.01,0,27.18,9.57,27.18,21.36,0,11.8-12.16,21.36-27.18,21.36-.94,0-1.86-.05-2.78-.12-13.7-1.08-24.42-10.19-24.42-21.25Z"
      />
      <path
        className="cls-4"
        strokeWidth="0px"
        fill="#1c2e57"
        d="m346.46,336.31c0-11.06,10.71-20.16,24.44-21.25,4.99,5.4,8.09,12.92,8.09,21.25,0,8.32-3.1,15.84-8.12,21.25-13.7-1.08-24.42-10.19-24.42-21.25Z"
      />
    </g>
    <rect
      className="cls-6"
      strokeWidth="0px"
      fill="#d3ecee"
      x="337.42"
      y="194.47"
      width="167.86"
      height="59.62"
    />
    <circle
      className="cls-8"
      strokeWidth="0px"
      fill="#648881"
      cx="407.44"
      cy="675.51"
      r="18.16"
    />
    <path
      className="cls-1"
      strokeWidth="0px"
      fill="#f7ded7"
      d="m272.09,243.96l-3.53-86.5c.42-3.03.65-6.12.65-9.26v-15.59c0-37.22-30.17-67.39-67.39-67.39h-82.65c-37.22,0-67.39,30.17-67.39,67.39v15.59c0,37.22,30.17,67.39,67.39,67.39h82.65c6.27,0,12.33-.87,18.09-2.47l52.18,30.86Z"
    />
    <path
      className="cls-5"
      strokeWidth="0px"
      fill="#143987"
      d="m179.46,105.06c4.12,4.01,6.19,9.25,6.19,15.73,0,3.93-.79,7.29-2.37,10.06-1.58,2.77-3.72,5.44-6.42,7.98l-2.2,1.97c-2.77,2.47-4.84,4.65-6.19,6.54-1.35,1.89-2.03,4.41-2.03,7.58v2.31h-10.99v-3.35c0-2.93.46-5.53,1.39-7.81.93-2.27,2.04-4.18,3.35-5.73,1.31-1.54,3.04-3.35,5.2-5.44l2.77-2.66c1.93-2,3.39-3.87,4.4-5.61,1-1.73,1.5-3.72,1.5-5.96,0-3.16-.97-5.73-2.89-7.69-1.93-1.97-4.78-2.95-8.56-2.95s-6.98,1.21-9.37,3.64c-2.39,2.43-3.58,5.61-3.58,9.54h-11.45c0-7.56,2.21-13.47,6.65-17.75,4.43-4.28,10.35-6.42,17.75-6.42s12.7,2.01,16.83,6.01Zm-11.62,76.68h-13.76v-13.76h13.76v13.76Z"
    />
    <path
      className="cls-6"
      strokeWidth="0px"
      fill="#d3ecee"
      d="m500.77,454.68l-158.2,2.43c-1.15,0-2.28.02-3.4.05l-4.8.07-.1.15c-59.71,3.84-83.7,47.1-97.21,68.29.34-3.04.75-6.06,1.22-9.08-.65.38-1.29.6-1.91.63-6.31.29-44.39-41.05-76.02-72.59,0,0-34.09,101.74,11.12,155.18,17.88,21.13,45.17,35.65,66.26,35.65h350.8v-161.66c-20.63-11.72-48.75-19.13-87.75-19.13Z"
    />
    <g>
      <polygon
        className="cls-3"
        strokeWidth="0px"
        fill="#f8edeb"
        points="633.28 784.78 531.18 784.78 574.35 653.51 676.44 653.51 633.28 784.78"
      />
      <polygon
        className="cls-2"
        strokeWidth="0px"
        fill="#f3ab96"
        points="531.18 699.35 574.35 653.51 676.44 653.51 633.28 699.35 531.18 699.35"
      />
      <polygon
        className="cls-1"
        strokeWidth="0px"
        fill="#f7ded7"
        points="633.28 699.35 531.18 699.35 574.35 568.09 676.44 568.09 633.28 699.35"
      />
      <polygon
        className="cls-2"
        strokeWidth="0px"
        fill="#f3ab96"
        points="531.18 613.93 574.11 568.09 676.44 568.09 633.28 613.93 531.18 613.93"
      />
      <polygon
        className="cls-3"
        strokeWidth="0px"
        fill="#f8edeb"
        points="633.28 613.93 531.18 613.93 550.45 484.32 652.54 484.32 633.28 613.93"
      />
      <g>
        <path
          className="cls-4"
          strokeWidth="0px"
          fill="#1c2e57"
          d="m637.86,720.65h-69.84c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h69.84c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z"
        />
        <path
          className="cls-4"
          strokeWidth="0px"
          fill="#1c2e57"
          d="m633.01,734.96h-69.84c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h69.84c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z"
        />
        <path
          className="cls-4"
          strokeWidth="0px"
          fill="#1c2e57"
          d="m628.17,749.27h-69.84c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h69.84c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z"
        />
        <path
          className="cls-4"
          strokeWidth="0px"
          fill="#1c2e57"
          d="m623.32,763.58h-69.84c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h69.84c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z"
        />
        <path
          className="cls-4"
          strokeWidth="0px"
          fill="#1c2e57"
          d="m637.65,634.79h-69.84c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h69.84c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z"
        />
        <path
          className="cls-4"
          strokeWidth="0px"
          fill="#1c2e57"
          d="m632.8,649.1h-69.84c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h69.84c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z"
        />
        <path
          className="cls-4"
          strokeWidth="0px"
          fill="#1c2e57"
          d="m627.95,663.41h-69.84c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h69.84c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z"
        />
        <path
          className="cls-4"
          strokeWidth="0px"
          fill="#1c2e57"
          d="m623.1,677.72h-69.84c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5h69.84c.83,0,1.5.67,1.5,1.5s-.67,1.5-1.5,1.5Z"
        />
      </g>
      <g>
        <path
          className="cls-7"
          strokeWidth="0px"
          fill="#9cc7c9"
          d="m623.28,535.83l-7.86,12.44c-4.44,7.02-13.63,10.16-20.53,7.01h0c-6.9-3.15-8.9-11.39-4.46-18.41l7.86-12.44,24.99,11.39Z"
        />
        <path
          className="cls-4"
          strokeWidth="0px"
          fill="#1c2e57"
          d="m631.14,523.4l-7.86,12.44-24.99-11.39,7.86-12.44c4.44-7.02,13.63-10.16,20.53-7.01h0c6.9,3.15,8.9,11.39,4.46,18.41Z"
        />
      </g>
      <g>
        <path
          className="cls-7"
          strokeWidth="0px"
          fill="#9cc7c9"
          d="m586.05,562.28l4.93,11.98c2.78,6.77-1.22,15.22-8.94,18.88h0c-7.72,3.66-16.23,1.14-19.01-5.62l-4.93-11.98,27.95-13.26Z"
        />
        <path
          className="cls-4"
          strokeWidth="0px"
          fill="#1c2e57"
          d="m581.13,550.29l4.93,11.98-27.95,13.26-4.93-11.98c-2.78-6.77,1.22-15.22,8.94-18.88h0c7.72-3.66,16.23-1.14,19.01,5.62Z"
        />
      </g>
    </g>
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#f3ab96"
      d="m254.72,461.76c-16.6-23.08-41.74-37.72-75.24-37.38-8.93.09-17.24,1.87-24.68,5.09-12.92-6.06-27.58-8.19-37.58,2.06-7.77,7.96-9.76,25.79.12,31.41-10.11-.09-20.53,7.91-21.59,18.18-1.23,11.93,10.32,23.08,22.03,22.59-10.29,2.81-16.33,16.48-12.51,26.97,1.94,5.31,5.61,9.62,10.19,12.89.03.02.05.05.07.07,13.46,11.32,28.82,19.08,39.26,23.14,21.19,8.25,49.19,10.29,67.16,3.21,20.27-7.99,29.18-15.94,37.32-32.71,12.01-24.78,8.38-57.54-4.56-75.52Z"
    />
    <rect
      className="cls-3"
      strokeWidth="0px"
      fill="#f8edeb"
      x="133.39"
      y="357.76"
      width="105.17"
      height="166.45"
    />
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#f3ab96"
      d="m146.44,455.57c-6.66,4.14-14.24,7.17-21.94,5.69,7.92,2.43,23.16.68,30.3,8.44,5.53,6.01,4.88,20.27-9.01,25.9-7.18,2.91-15.16,3.49-22.9,3.25,12.13-.52,37.91-1.76,37.92,17.02,0,10.14-8.53,17.49-14.72,20.37-4.16,1.93-8.4,3.88-12.96,4.4s-9.55-.64-12.57-4.09c-4.96-5.66-2.75-14.28-2.12-21.78.91-10.72-2.05-21.38-2.25-32.13-.26-13.85-4.09-51.03,10.07-56.89,16.92-7,27.92,1.07,29.29,10.89.95,6.84-2.25,14.66-9.11,18.93Z"
    />
    <rect
      className="cls-4"
      strokeWidth="0px"
      fill="#1c2e57"
      x="141.35"
      y="334.32"
      width="89.24"
      height="23.44"
    />
    <g>
      <path
        className="cls-7"
        strokeWidth="0px"
        fill="#9cc7c9"
        d="m160.34,436.65v-21.3c0-14.44,11.71-26.15,26.15-26.15h0c17.4,2.61,28.33,13.63,26.15,26.15v21.3s-52.3,0-52.3,0Z"
      />
      <path
        className="cls-4"
        strokeWidth="0px"
        fill="#1c2e57"
        d="m160.34,457.93v-21.29h52.3v21.29c0,14.44-11.71,26.15-26.15,26.15h0c-14.44,0-26.15-11.71-26.15-26.15Z"
      />
    </g>
    <path
      className="cls-2"
      strokeWidth="0px"
      fill="#f3ab96"
      d="m197.91,458.07c-5.01,1.3-10.02,3.37-13.51,7.2s-5.07,9.73-2.7,14.34c2.25,4.36,7.21,6.46,11.78,8.22,12.3,4.74,40.29,36.39,21.76,36.39-13.89,0-23.35,27.27-17.64,27.34s51.42-20.55,54.14-21.51c14.43-5.07,5.14-53.59-3.13-62.26-11.53-12.08-35.78-13.59-50.7-9.72Z"
    />{' '}
  </Svg>
);

export default Telemedicine;
