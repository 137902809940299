import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import React, { Fragment, useMemo } from 'react';
import Img from 'styled/components/Img';
import Link from 'styled/components/Link';
import { v4 as uuid } from 'uuid';
import Icon from './Icons';

import { withImpressionTracking } from 'analytics';
import { trackProductClick, trackProductViewed } from './analytics';

import type { ProductCards } from 'types';

const ProductCard = ({ product }: { product: ProductCards['products'][0] }) => {
  const impressionId = useMemo(() => uuid(), []);
  const TrackingContainer: React.FC<{ children: React.ReactNode }> = useMemo(
    () =>
      withImpressionTracking('div', () => {
        trackProductViewed(impressionId, product.url);
      }),
    [impressionId, product.url],
  );

  return (
    <TrackingContainer>
      <Card
        key={product.url}
        className="w-[302px] flex-shrink-0 shadow-sm"
        variant="outlined"
      >
        <CardActionArea
          href={product.url}
          target="_blank"
          onClick={() => {
            trackProductClick(impressionId, product.url);
          }}
        >
          <CardContent>
            <div className="flex gap-2">
              {product.image.src && (
                <div className="mr-2 flex h-[72px] w-[72px] flex-shrink-0 items-center justify-center">
                  <Img
                    alt={product.image.alt}
                    src={product.image.src}
                    className="max-h-[72px] max-w-[72px]"
                  />
                </div>
              )}
              <CardHeader
                title={
                  <Typography
                    variant="h5"
                    className="line-clamp-2 text-ellipsis text-[18px]"
                  >
                    {product.header}
                  </Typography>
                }
                subheader={
                  <div className="mt-1 flex items-center">
                    <Link
                      ariaLabel={product.linkLabel}
                      href={product.url}
                      label={product.linkLabel}
                      variant="hover-underline-blue"
                      className="mr-2 !text-base"
                    />
                    <OpenInNewOutlined
                      color="primary"
                      fontSize="small"
                      className="text-base"
                    />
                  </div>
                }
                disableTypography
                className="p-0"
              />
            </div>
            <div className="mt-2">
              {!!product?.bulletPoints?.length && (
                <div className="grid grid-cols-[24px_1fr] gap-2 font-sans !text-base text-gray-70">
                  {product?.bulletPoints?.map((point, index) => (
                    <Fragment key={point.text}>
                      <Icon key={point.icon} type={point.icon}>
                        {index + 1}
                      </Icon>
                      <p className="bp-flex bp-items-center !bp-text-base">
                        {point.text}
                      </p>
                    </Fragment>
                  ))}
                </div>
              )}
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    </TrackingContainer>
  );
};

const ProductCardsModule = ({ module }: { module: ProductCards }) => {
  return (
    <div className="disabled-ads w100 my1_5 no-scrollbar flex flex-nowrap gap-x-2 overflow-x-scroll py-1">
      {module.products.map((product) => (
        <ProductCard key={product.url} product={product} />
      ))}
    </div>
  );
};

export default ProductCardsModule;
