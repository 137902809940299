export enum Cases {
  TitleCase = 'Title',
  SentenceCase = 'Sentence',
  LowerCase = 'Lower',
  UpperCase = 'Upper',
}

interface FormatTitleCasing {
  title: string;
  casing: Cases;
}

function checkForAllCaps(string: string): boolean {
  for (const char of string) {
    // Ensure we are dealing with letters so we do not return false for something like 'COPD:'
    const isLetter = char.toLowerCase() !== char.toUpperCase();
    if (char === char.toLowerCase() && isLetter) {
      return false;
    }
  }
  return true;
}

function getProperCasing(
  string: string,
  defaultValue: string,
  casing: Cases,
  index: number,
): string {
  const IGNORED_SPECIAL_CHARS = ['(']; // ignore these characters i.e. '(Hello)' -> '(Hello)'
  const ACCEPTED_SPECIAL_CHARS = ['-']; // do not ignore these characters i.e. '(Hello-world)' -> '(hello-world)'
  const containsCharToIgnore = IGNORED_SPECIAL_CHARS.some((char) =>
    string.includes(char),
  );
  const containsCharToAccept = ACCEPTED_SPECIAL_CHARS.some((char) =>
    string.includes(char),
  );
  const isFullyCapitalized = checkForAllCaps(string); // ignore fully capitalized strings like acronyms i.e. 'GERD'
  const shouldLowerCaseFirstChar =
    casing == 'Lower' && !containsCharToIgnore && !isFullyCapitalized;
  const shouldLowerCaseFirstCharSentence =
    casing == 'Sentence' &&
    index !== 0 &&
    !containsCharToIgnore &&
    !isFullyCapitalized;

  if (casing == 'Lower' && containsCharToAccept) {
    return string.toLowerCase();
  }
  if (shouldLowerCaseFirstChar || shouldLowerCaseFirstCharSentence) {
    return string.charAt(0).toLowerCase() + string.substring(1);
  }
  return defaultValue;
}
export function formatTitleCasing(args: FormatTitleCasing): string {
  const { title, casing } = args;
  let formattedTitle = '';
  switch (casing) {
    case 'Title':
      formattedTitle = title
        .split(/(?!\(.*)\s(?![^(]*?\))/g)
        .map((string) =>
          getProperCasing(
            string,
            string.charAt(0).toUpperCase() + string.substring(1),
            casing,
            Infinity,
          ),
        )
        .join(' ');
      break;
    case 'Sentence':
      formattedTitle = title
        .split(/(?!\(.*)\s(?![^(]*?\))/g)
        .map((string, index) =>
          getProperCasing(
            string,
            index === 0
              ? string.charAt(0).toUpperCase() + string.substring(1)
              : string,
            casing,
            index,
          ),
        )
        .join(' ');
      break;
    case 'Lower':
      formattedTitle = title
        .split(/(?!\(.*)\s(?![^(]*?\))/g)
        .map((string) => getProperCasing(string, string, casing, Infinity))
        .join(' ');
      break;
    case 'Upper':
      formattedTitle = title.toUpperCase();
      break;
    default:
      formattedTitle = title;
      break;
  }
  return formattedTitle;
}
