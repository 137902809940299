import React, { FC } from 'react';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import {
  Base,
  LinkOrButton,
  Img,
  CircledNumber,
  BasePortableText,
} from 'styled';
import {
  Container,
  Inner,
  ImageContainer,
  TitleContainer,
  ImageTitleContainer,
  Description,
} from './style';

import useLanguage from 'hooks/useLanguage';
import { Color } from 'styled/theme/colors';
import { Image } from 'types';
import { PortableTextBlock } from '@portabletext/types';
import BlockContent from '@sanity/block-content-to-react';
import {
  ExternalLink,
  InternalLink,
  ListItem,
} from 'constants/PortableTextSerializer';

interface Props {
  title: string;
  description: string;
  image: Image;
  buttonLabel: string;
  buttonAriaLabel: string;
  buttonUrl: string;
  url: string;
  ariaLabel: string;
  contentAlignIsCenter: boolean;
  numberOptions: {
    displayNumber: boolean;
    bgColor: Color;
    color: Color;
    number: number;
  };
  totalNumberOfContents: number;
  content?: PortableTextBlock;
}

const ImageCard: FC<Props> = ({
  title,
  description,
  image,
  buttonLabel,
  buttonAriaLabel,
  buttonUrl,
  url,
  ariaLabel,
  contentAlignIsCenter,
  numberOptions,
  totalNumberOfContents,
  content,
}) => {
  const Language = useLanguage();

  const cardImage = (
    <Img
      alt={image.alt}
      sizes="(max-width: 1024px) 352px, 700px"
      src={sanityImgUtil(image, 352)}
      srcSet={sanityImgSrcSetUtil(image, 352, 352, 700)}
      dimensions={image.metadata?.dimensions}
      crop={image.crop}
      preloadFullWidth={true}
    />
  );
  const linkedElementsAriaLabel =
    ariaLabel ||
    Language.t('Global.pageButtonAriaLabel', {
      title: url,
    });

  return (
    <Container
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems={contentAlignIsCenter ? 'center' : 'flex-start'}
      pb={[4, 4, 0]}
    >
      <Inner
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems={contentAlignIsCenter ? 'center' : 'flex-start'}
        textAlign={contentAlignIsCenter ? 'center' : undefined}
      >
        {numberOptions.displayNumber && (
          <CircledNumber
            mb={1.5}
            circleColor={numberOptions.bgColor}
            numberColor={numberOptions.color}
            number={numberOptions.number}
            ariaLabel={Language.t('Global.listOfPointsAriaLabel', {
              currentNumber: numberOptions.number,
              totalNumber: totalNumberOfContents,
            })}
          />
        )}
        {(!!title || !!image.id) && (
          <ImageTitleContainer>
            {!!title && (
              <TitleContainer>
                {url ? (
                  <LinkOrButton
                    ariaLabel={linkedElementsAriaLabel}
                    label={title}
                    variant="hover-underline-black"
                    to={url}
                    wrap={true}
                  />
                ) : (
                  <span>{title}</span>
                )}
              </TitleContainer>
            )}
            {!!image.id &&
              (url ? (
                <ImageContainer>
                  <LinkOrButton ariaLabel={linkedElementsAriaLabel} to={url}>
                    {cardImage}
                  </LinkOrButton>
                </ImageContainer>
              ) : (
                <ImageContainer>{cardImage}</ImageContainer>
              ))}
          </ImageTitleContainer>
        )}
        {description && <Description mt={1.5}>{description}</Description>}
        {content && (
          <Base mt={1.5}>
            <BasePortableText>
              <BlockContent
                blocks={content}
                serializers={{
                  listItem: ListItem,
                  marks: { link: ExternalLink, internalLink: InternalLink },
                }}
              />
            </BasePortableText>
          </Base>
        )}
        {buttonLabel && buttonUrl && (
          <Base mt={1.5}>
            <LinkOrButton
              ariaLabel={
                buttonAriaLabel ||
                Language.t('Global.pageButtonAriaLabel', {
                  title: buttonUrl,
                })
              }
              variant="hover-underline-black"
              label={buttonLabel}
              to={buttonUrl}
              wrap={true}
            />
          </Base>
        )}
      </Inner>
    </Container>
  );
};

export default ImageCard;
