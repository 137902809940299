import React, { FC, useEffect, useState } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { useSelector } from 'react-redux';

import {
  InformativeIcon,
  FindCareIcon,
  UserSpeechBubbleIcon,
  RxPillIcon,
  StethoscopeIcon,
} from 'styled';
import { MediumHeading, DrugPageButton } from 'styled/components/drugPage';
import { Container, BasicsContainer } from './style';

import { ExternalLink, InternalLink } from 'constants/PortableTextSerializer';

import { GlobalState, Article } from 'types';

type Props = {
  articleSlug: string;
};

const FirstAidBoxModule: FC<Props> = ({ articleSlug }) => {
  const articleFromState = useSelector(
    (state: GlobalState) => state.article[articleSlug]
  );

  const [article, setArticle] = useState<Article | null>(null);

  useEffect(() => {
    setArticle(articleFromState);
  }, [articleFromState]);

  if (!article) return null;

  const {
    firstAidBox: {
      title,
      description,
      backgroundColor,
      thinkSectionTitle,
      thinkSectionDescription,
      checkSectionTitle,
      checkSectionDescription,
      doSectionTitle,
      doSectionDescription,
      urgentSectionTitle,
      urgentSectionDescription,
      supportSectionTitle,
      supportSectionDescription,
      ctaLabel,
      ctaUrl,
    },
  } = article;

  if (
    !thinkSectionDescription &&
    !checkSectionDescription &&
    !doSectionDescription &&
    !urgentSectionDescription &&
    !supportSectionDescription
  )
    return null;

  return (
    <Container backgroundColor={backgroundColor}>
      <BasicsContainer>
        <MediumHeading>{title}</MediumHeading>
        {!!description && (
          <BlockContent
            blocks={description}
            serializers={{
              marks: { link: ExternalLink, internalLink: InternalLink },
            }}
          />
        )}
      </BasicsContainer>
      {!!thinkSectionDescription.length && (
        <>
          <MediumHeading>
            <InformativeIcon />
            <p>{thinkSectionTitle}</p>
          </MediumHeading>
          <BlockContent
            blocks={thinkSectionDescription}
            serializers={{
              marks: { link: ExternalLink, internalLink: InternalLink },
            }}
          />
        </>
      )}
      {!!checkSectionDescription.length && (
        <>
          <MediumHeading>
            <FindCareIcon />
            <p>{checkSectionTitle}</p>
          </MediumHeading>
          <BlockContent
            blocks={checkSectionDescription}
            serializers={{
              marks: { link: ExternalLink, internalLink: InternalLink },
            }}
          />
        </>
      )}
      {!!doSectionDescription.length && (
        <>
          <MediumHeading>
            <UserSpeechBubbleIcon />
            <p>{doSectionTitle}</p>
          </MediumHeading>
          <BlockContent
            blocks={doSectionDescription}
            serializers={{
              marks: { link: ExternalLink, internalLink: InternalLink },
            }}
          />
        </>
      )}
      {!!urgentSectionDescription.length && (
        <>
          <MediumHeading>
            <RxPillIcon />
            <p>{urgentSectionTitle}</p>
          </MediumHeading>
          <BlockContent
            blocks={urgentSectionDescription}
            serializers={{
              marks: { link: ExternalLink, internalLink: InternalLink },
            }}
          />
        </>
      )}
      {!!supportSectionDescription.length && (
        <>
          <MediumHeading>
            <StethoscopeIcon />
            <p>{supportSectionTitle}</p>
          </MediumHeading>
          <BlockContent
            blocks={supportSectionDescription}
            serializers={{
              marks: { link: ExternalLink, internalLink: InternalLink },
            }}
          />
        </>
      )}
      {!!ctaLabel && !!ctaUrl && (
        <DrugPageButton ariaLabel={ctaLabel} label={ctaLabel} to={ctaUrl} />
      )}
    </Container>
  );
};

export default FirstAidBoxModule;
