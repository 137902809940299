import React, { FC } from 'react';
import { Widget as TypeformWidget } from '@typeform/embed-react';
import styled, { css } from 'styled-components';
import { Base } from 'styled';
import ModuleHeader from 'styled/components/modules/ModuleHeader';
import { ArticleHeadingStyles } from 'styled/components/portableText';

export interface ITypeformModule {
  id: string;
  typeformId: string;
  header?: string;
  type: 'typeformModule';
}

interface Props {
  module: ITypeformModule;
  addModulePadding?: boolean;
}

export const Container = styled(Base)<{ addModulePadding?: boolean }>`
  width: 100%;
  padding: 1rem 0 3rem;

  ${({ addModulePadding, theme }) =>
    addModulePadding
      ? css`
          ${theme.global.moduleContentPaddingX};
           {
            padding-top: 3rem;

            ${theme.mediaQueries.md} {
              padding-top: 6rem;
            }
          }
        `
      : css`
          ${ArticleHeadingStyles};

          .ModuleHeader {
            padding-bottom: 2rem;
          }
        `}

  .ModuleHeader {
    padding-top: 0;
  }
`;

const StyledWidget = styled(TypeformWidget)`
  height: 550px;
`;

const TypeformModule: FC<Props> = ({ module, addModulePadding }) => {
  return (
    <Container addModulePadding={addModulePadding}>
      <ModuleHeader title={module.header} />
      <StyledWidget id={module.typeformId} />
    </Container>
  );
};

export default TypeformModule;
