import React, { FC } from 'react';
import { Svg } from 'styled/components/base';

type Props = {
  backgroundColor?: string;
  className?: string;
  color?: string;
};

const DollarWithCircleIcon: FC<Props> = ({
  className = '',
  color = '#196CFF',
  backgroundColor = '#F7F7F7',
}) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="12" cy="12" r="12" fill={backgroundColor} />
    <path
      d="M11.3896 10.815C10.7255 10.605 10.4915 10.2975 10.4915 9.8925V9.87C10.4915 8.835 12.0689 9 12.6198 9.18C13.1707 9.3225 13.7141 9.5925 14.2349 9.945L15.1104 8.43C14.4085 7.935 13.6085 7.5975 12.6802 7.47V6H11.3292V7.4475C9.68396 7.6125 8.58962 8.55 8.58962 9.99V10.0125C8.58962 11.5425 9.51792 12.255 11.3972 12.765C12.4764 13.0125 13.5632 13.1775 13.5481 14.0325V14.0625C13.5481 15.105 11.9179 14.955 11.3972 14.8275C10.6274 14.67 9.9783 14.31 9.26887 13.7775L8.25 15.255C9.11038 15.9 10.2047 16.3425 11.3292 16.485V18H12.6877V16.5075C14.3481 16.3275 15.45 15.39 15.45 13.9575V13.9275C15.4575 11.4525 12.8613 11.28 11.3896 10.815Z"
      fill={color}
    />
  </Svg>
);

export default DollarWithCircleIcon;
