import useLanguage from 'hooks/useLanguage';
import React, { FC } from 'react';

import {
  Link,
  PersonAtHomeLookingAtPrescription,
  PillsSurroundingPillBottle,
  TwoPeopleDiscussingTreatment,
} from 'styled';
import { RoundedBgLinkCardsModule as IRoundedBgLinkCardsModule } from 'types';
import sanityImgUtil from 'utils/sanityImgUtil';
import {
  Card,
  CardTitle,
  Container,
  ImageContainer,
  LinkedCardContainer,
  StyledCardImage,
  TextContainer,
} from './style';

interface Props {
  module: IRoundedBgLinkCardsModule;
}

const TREATMENT_OPTION_CARD_TYPES = {
  home: 'homeTreatmentsCard',
  provider: 'seeProviderCard',
  prescription: 'rxRefillsCard',
  treatment: 'seeTreatmentsCard',
};

const RoundedBgLinkCardsModule: FC<Props> = ({ module }) => {
  const Language = useLanguage();

  const { cards, renderTwoCardsInARowForTablet } = module;
  if (!cards?.length) return null;

  const anyCardHasDescription = cards.some((card) => !!card.description);

  return (
    <Container
      data-testid="rounded-bg-link-cards-module"
      hasDescription={anyCardHasDescription}
      renderTwoCardsInARowForTablet={renderTwoCardsInARowForTablet}
    >
      {cards.map((card) => {
        const { id, type, image, backgroundColor, url, title, description } =
          card;

        const cardHasDefaultImage = Object.values(
          TREATMENT_OPTION_CARD_TYPES
        ).includes(type);

        const renderDefaultImage = () => {
          switch (type) {
            case TREATMENT_OPTION_CARD_TYPES.home:
              return <PersonAtHomeLookingAtPrescription />;
            case TREATMENT_OPTION_CARD_TYPES.provider:
            case TREATMENT_OPTION_CARD_TYPES.treatment:
              return <TwoPeopleDiscussingTreatment />;
            case TREATMENT_OPTION_CARD_TYPES.prescription:
              return <PillsSurroundingPillBottle />;
            default:
              return null;
          }
        };

        const renderLinkAsButton =
          !!url && type === TREATMENT_OPTION_CARD_TYPES.treatment;

        const ItemInner = (
          <>
            <TextContainer hasDescription={!!description}>
              <CardTitle>{title}</CardTitle>
              {description}
              {renderLinkAsButton && (
                <Link
                  variant="primary"
                  label={Language.t(
                    'RoundedBgLinkCardsModule.seeAllTreatmentOptions'
                  )}
                  to={url}
                  ariaLabel={Language.t(
                    'RoundedBgLinkCardsModule.seeAllTreatmentOptions'
                  )}
                />
              )}
            </TextContainer>

            {(!!image.src || cardHasDefaultImage) && (
              <ImageContainer>
                {image.src ? (
                  <StyledCardImage
                    alt={image.alt}
                    src={sanityImgUtil(image, 160)}
                    dimensions={image.metadata?.dimensions}
                    crop={image.crop}
                    preloadFullWidth={true}
                  />
                ) : (
                  renderDefaultImage()
                )}
              </ImageContainer>
            )}
          </>
        );

        return url && !renderLinkAsButton ? (
          <LinkedCardContainer
            as={Link}
            key={id}
            id={type}
            ariaLabel={title}
            to={url}
          >
            <Card backgroundColor={backgroundColor}>{ItemInner}</Card>
          </LinkedCardContainer>
        ) : (
          <Card key={id} backgroundColor={backgroundColor}>
            {ItemInner}
          </Card>
        );
      })}
    </Container>
  );
};

export default RoundedBgLinkCardsModule;
