import type { Language } from 'hooks/useLanguage';

type Args = [
  text: string,
  length: number,
  language: Language,
  trailingString?: string
];

const truncateStringText = (...args: Args) => {
  const [text, length, language, trailingString] = args;

  const trailing =
    trailingString || language.t('Global.truncatedTrailingString');

  if (text.length <= length) {
    return text;
  }

  const truncatedText = text.slice(0, length);
  const lastCharIsSpace = truncatedText.charAt(length - 1) === ' ';

  return lastCharIsSpace
    ? truncatedText.slice(0, length - 1) + trailing
    : truncatedText + trailing;
};

export default truncateStringText;
