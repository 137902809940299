import React from 'react';
import cx from 'classnames';

import { Circle, Number } from './style';
import { Color } from 'styled/theme/colors';
import { BaseProps as BaseStyledSystemProps } from 'styled/components/types';

export interface Props extends BaseStyledSystemProps {
  className?: string;
  number: number;
  circleColor: Color;
  numberColor: Color;
  renderSmallerSizeOnMobile?: boolean;
  ariaLabel: string;
}

const CircledNumber = (props: Props) => {
  const {
    className,
    number,
    circleColor,
    numberColor,
    renderSmallerSizeOnMobile,
    ariaLabel,
    ...baseStyledSystemProps
  } = props;

  return (
    <Circle
      {...baseStyledSystemProps}
      data-testid="circled-number"
      as="span"
      className={cx('CircledNumber', className)}
      circleColor={circleColor}
      renderSmallerSizeOnMobile={renderSmallerSizeOnMobile}
      role="text"
    >
      <Number as="span" aria-label={ariaLabel} numberColor={numberColor}>
        {number}
      </Number>
    </Circle>
  );
};

export default CircledNumber;
