import styled from 'styled-components';
import { Base, Theme } from 'styled';

type PageHeroProps = {
  mobileImageWidth: number;
  desktopImageWidth: number;
  mobileAspectRatio: number;
  desktopAspectRatio: number;
};

type FullImgContainerProps = {
  mobileImageWidth: number;
  desktopImageWidth: number;
};

export const PageHero = styled(Base)<PageHeroProps>`
  min-height: ${({ mobileAspectRatio, mobileImageWidth }) =>
    !!mobileImageWidth &&
    `${Math.round(mobileImageWidth / mobileAspectRatio)}px`};

  ${Theme.mediaQueries.sm} {
    min-height: ${({ desktopAspectRatio, desktopImageWidth }) =>
      !!desktopImageWidth &&
      `${Math.round(desktopImageWidth / desktopAspectRatio)}px`};
  }
`;

export const FullImgContainer = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display'].includes(prop) && defaultValidatorFn(prop),
})<FullImgContainerProps>`
  left: 50%;
  width: ${({ mobileImageWidth }) => `${mobileImageWidth}px`};
  margin-left: ${({ mobileImageWidth }) =>
    `calc(${mobileImageWidth}px / 2 * -1)`};
  opacity: 0.3;

  ${Theme.mediaQueries.sm} {
    width: ${({ desktopImageWidth }) => `${desktopImageWidth}px`};
    margin-left: ${({ desktopImageWidth }) =>
      `calc(${desktopImageWidth}px / 2 * -1)`};
    opacity: 1;
  }
`;
