import styled from 'styled-components';
import { Base } from 'styled';
import { Color } from '../../theme/colors';

type FormMessageProps = {
  color: Color;
  shouldDisplay: boolean;
};

export const SubmitButtonContainer = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display', 'width'].includes(prop) && defaultValidatorFn(prop),
})``;

export const Description = styled(Base)`
  ${({ theme }) => theme.typography.variants.secondary.sm};
`;

export const SubmitButton = styled(Base)`
  display: inline-flex;
  justify-content: center;
`;

export const FormMessageContainer = styled(Base)``;

export const FormMessage = styled(Base)<FormMessageProps>`
  position: absolute;
  ${({ theme }) => theme.transitions.transition};
  ${({ theme }) => theme.typography.variants.primary.sm};
  opacity: ${({ shouldDisplay }) => (shouldDisplay ? 1 : 0)};
  pointer-events: ${({ shouldDisplay }) => (shouldDisplay ? 'none' : 'auto')};
  color: ${({ color }) => color};
`;

export const StyledTextField = styled(Base)``;
export const StyledDropdown = styled(Base)``;
