import styled from 'styled-components';
import { Base, Theme } from 'styled';

type TitleContainerProps = {
  moduleIntersectsWithViewport: boolean;
};

type SliderContainerProps = {
  backgroundImage: string;
};

type SlideProps = {
  isActive: boolean;
};

export const Container = styled(Base)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleContainer = styled(Base)<TitleContainerProps>`
  ${({ theme }) => theme.typography.variants.primary['md-lg']};
  text-align: center;
  margin: 2.5rem 1rem;

  img {
    width: 1.875rem;
    margin-left: 0.5rem;
    opacity: 0;
    transform: translateY(10rem);
    ${({ moduleIntersectsWithViewport, theme }) =>
      moduleIntersectsWithViewport && theme.animations.slideUpWithJump};
    animation-delay: 600ms;
  }

  ${Theme.mediaQueries.md} {
    ${({ theme }) => theme.typography.variants.primary.xl};
    margin: 5rem 2rem 2.5rem;

    img {
      width: 2.75rem;
      margin-left: 1.75rem;
    }
  }
`;

export const SliderContainer = styled(Base)<SliderContainerProps>`
  ${({ theme }) => theme.zIndex('1')};
  color: ${({ theme }) => theme.palette.common.white};
  background-image: ${({ backgroundImage }) => `url("${backgroundImage}")`};
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 20rem;

  img {
    display: none;
  }

  ${Theme.mediaQueries.md} {
    min-height: 24.75rem;

    img {
      display: initial;
    }
  }
`;

export const SlideText = styled.p`
  max-width: 50rem;
  ${({ theme }) => theme.typography.variants.primary['md-lg']};

  ${Theme.mediaQueries['max-sm']} {
    ${({ theme }) => theme.typography.variants.primary.md};
  }

  ${Theme.mediaQueries['max-xs']} {
    ${({ theme }) => theme.typography.variants.primary.sm};
  }
`;

export const SlideLabel = styled.span`
  ${({ theme }) => theme.typography.variants.primary.sm};
`;

export const Slide = styled(Base)<SlideProps>`
  ${({ theme }) => theme.transitions['transition-opacity']};
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  padding: 2.5rem 1rem;

  ${SlideText}, ${SlideLabel} {
    ${({ theme }) => theme.animations.fadeIn};
  }

  ${Theme.mediaQueries.md} {
    padding-top: 6rem;
    padding-bottom: 4.5rem;
  }
`;

export const SlideInfoAndControls = styled(Base)`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
`;

export const SliderControls = styled(Base)`
  display: flex;

  button:first-of-type {
    margin-right: 1.5rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
