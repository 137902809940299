import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import theme from '@buoyhealth/common.buoy-theme';

import cx from 'classnames';
import styled from 'styled-components';
import { format } from 'date-fns';
import get from 'utils/get';
import throttle from 'lodash/throttle';

import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import formatAriaLabelWithTitle from 'utils/formatAriaLabelWithTitle';
import composeSideNavItemsFromArticleContent from 'utils/composeSxDxArticleSideNavContentAnchors';

import { Base, Button, AccordionArrow, Theme } from 'styled';

import useLanguage from 'hooks/useLanguage';
import useScrollDirection, { ScrollDirection } from 'hooks/useScrollDirection';
import {
  mobileTopBarHeight,
  mobileBreadcrumbHeight,
  tabletTopBarHeight,
} from 'constants/Sizing';

import { GlobalSettingsReducer } from 'state/reducers/globalSettings';
import { Article, ArticleGenericPage, ContentAnchor, GlobalState } from 'types';

interface PassedProps {
  article: Article | ArticleGenericPage;
  className?: string;
}

interface StoreProps {
  globalSettings: GlobalSettingsReducer;
  notificationBarIsActive: boolean | null;
  notificationBarHeight: number;
}

type Props = PassedProps & StoreProps & WithRouterProps & WithBreakpointsProps;

interface State {
  contents: (ContentAnchor | null)[];
  hash: string;
  contentMenuIsActive: boolean;
  selectedContent: ContentAnchor | null;
  navShouldScroll: boolean;
}

/*
 * Temporarily applying styles via styled-components and placing the component here instead of a style.ts file
 * since this ArticleNav component is to be removed in upcoming Sx/Dx Article Redesign epic
 */

type ArticleNavContainerProps = Pick<
  Props,
  'notificationBarIsActive' | 'notificationBarHeight'
> & {
  scrollDirectionIsUp: boolean;
};

export const ArticleNavContainer = styled(Base)<ArticleNavContainerProps>`
  ${Theme.mediaQueries.sm} {
    height: ${({
      theme,
      notificationBarIsActive,
      notificationBarHeight,
      scrollDirectionIsUp,
    }) => `calc(100vh - ${theme.sizes.topBarHeightTablet} - ${
      notificationBarIsActive ? notificationBarHeight : 0
    }px - ${scrollDirectionIsUp ? Theme.sizes.tabNavigationHeight : '0px'}
    )`};

    top: ${({
      theme,
      notificationBarIsActive,
      notificationBarHeight,
      scrollDirectionIsUp,
    }) => `calc(${theme.sizes.topBarHeightTablet} + ${
      notificationBarIsActive ? notificationBarHeight : 0
    }px + ${scrollDirectionIsUp ? theme.sizes.tabNavigationHeight : '0px'}
    )`};
  }

  ${Theme.mediaQueries.lg} {
    height: ${({ notificationBarIsActive, notificationBarHeight }) =>
      `calc(100vh - ${
        notificationBarIsActive ? `${notificationBarHeight}px` : '0px'
      })`};
    top: ${({ notificationBarIsActive, notificationBarHeight }) =>
      notificationBarIsActive
        ? `calc(${notificationBarHeight}px + 0px)`
        : `calc(${tabletTopBarHeight}px + 0px)`};
  }
`;

function ArticleGenericPageNav(props: Props) {
  const { article, className, notificationBarIsActive, notificationBarHeight } =
    props;

  const Language = useLanguage();
  const articleNavRef = useRef(null);
  const articleContentRef = useRef(null);
  const scrollDirection = useScrollDirection({
    initialDirection: ScrollDirection.SCROLL_UP,
  });
  const sideNavItems = useMemo(
    () => composeSideNavItemsFromArticleContent(article),
    [article],
  );

  const [state, setState] = useState<State>({
    contentMenuIsActive: false,
    contents: sideNavItems,
    hash: '',
    navShouldScroll: false,
    selectedContent: sideNavItems[0],
  });

  const handleMenuListClick = useCallback((sideNav: ContentAnchor) => {
    setState((s) => ({
      ...s,
      selectedContent: sideNav,
      contentMenuIsActive: false,
    }));
  }, []);

  const setArticleNavScroll = useCallback(() => {
    const articleNav = articleNavRef.current;
    const articleContent = articleContentRef.current;
    const articleContentHeight = get(articleContent, 'clientHeight', 0);
    const articleNavHeight = get(articleNav, 'clientHeight', 0);

    if (articleContentHeight && articleNavHeight) {
      if (articleContentHeight >= articleNavHeight) {
        setState((s) => ({
          ...s,
          navShouldScroll: true,
        }));
      } else {
        setState((s) => ({
          ...s,
          navShouldScroll: false,
        }));
      }
    }
  }, []);

  const toggleContentMenu = useCallback(() => {
    setState((s) => ({
      ...s,
      contentMenuIsActive: !s.contentMenuIsActive,
    }));
  }, []);

  const updateHash = useCallback(() => {
    if (typeof window !== 'undefined') {
      const hashValue = window.location.hash.slice(1);
      const selectedContent: ContentAnchor | null =
        state.contents.find(
          (contentObj) => get(contentObj, 'slug', '') === hashValue,
        ) || null;

      setState((s) => ({
        ...s,
        hash: hashValue,
        selectedContent: selectedContent,
      }));
    }
  }, [state.contents]);

  const throttleResizeHandler = useCallback(
    throttle(setArticleNavScroll, 500),
    [],
  );
  const throttleUpdateHash = useCallback(throttle(updateHash, 250), []);

  useEffect(() => {
    window.addEventListener('hashchange', throttleUpdateHash);
    window.addEventListener('resize', throttleResizeHandler);

    return () => {
      window.removeEventListener('hashchange', throttleUpdateHash);
      window.removeEventListener('resize', throttleResizeHandler);
    };
  }, [throttleUpdateHash, throttleResizeHandler]);

  useEffect(() => {
    updateHash();
    setArticleNavScroll();
  }, [updateHash, setArticleNavScroll]);

  const date = (article as ArticleGenericPage).date;

  const {
    contentMenuIsActive,
    contents,
    hash,
    navShouldScroll,
    selectedContent,
  } = state;

  // IDs for a11y
  const contentMenuToggleId = 'contentMenuToggle';
  const contentMenuContentId = 'contentMenuContent';

  return (
    <>
      <div
        style={{
          top: `calc(${mobileBreadcrumbHeight}px + ${mobileTopBarHeight}px + ${
            notificationBarIsActive ? notificationBarHeight : 0
          }px)`,
        }}
        className={cx(
          `ArticleGenericPageNav__content-menu w100 bg-color-white px1_5 sm:none border-bottom-gray-lighter ArticleGenericPageNav__content-menu--generic-page flex flex-col`,
          className,
        )}
      >
        <Button
          id={contentMenuToggleId}
          ariaLabel={Language.t(
            `ArticleNav.${
              contentMenuIsActive
                ? 'collapseContentsButtonAriaLabel'
                : 'expandContentsButtonAriaLabel'
            }`,
          )}
          onClick={toggleContentMenu}
          className="ArticleGenericPageNav__mobile-content-button py1_25 flex items-center justify-between"
          ariaExpanded={contentMenuIsActive}
          ariaControls={contentMenuContentId}
        >
          <span className="color-gray-100 nowrap text-xs">
            {Language.t('ArticleNav.jumpTo')}
          </span>
          <div className="flex flex-grow justify-start overflow-x-hidden">
            <span
              className={`ArticleGenericPageNav__content-mobile-title color-gray-100 px_5 text-overflow-ellipsis nowrap link-border-bottom-black-hidden overflow-x-hidden text-xs`}
            >
              {get(selectedContent, 'label', '')}
            </span>
          </div>
          <AccordionArrow
            isReverse={contentMenuIsActive}
            color={theme.palette.gray[100]}
          />
        </Button>
        <div
          id={contentMenuContentId}
          className={cx(
            `ArticleGenericPageNav__menu bg-color-white z-1 transition-short sm:none flex flex-col overflow-y-hidden`,
            {
              'ArticleGenericPageNav__menu--active events-all':
                contentMenuIsActive,
              'events-none opacity-0': !contentMenuIsActive,
            },
          )}
          aria-hidden={!contentMenuIsActive}
          aria-labelledby={contentMenuToggleId}
          role="region"
        >
          {contents.map((content) => {
            if (!content) return null;

            const { label, anchor, id } = content;
            const contentLabel = formatAriaLabelWithTitle(label);

            return (
              <Button
                ariaLabel={Language.t('ArticleNav.contentsAriaLabel', {
                  label: contentLabel,
                })}
                containerClassName={`ArticleGenericPageNav__dropdown-link-container ArticleGenericPageNav__dropdown-link-container--black w100`}
                className={`ArticleGenericPageNav__dropdown-link w100 pr1_5 py_75 color-gray-100 inline-flex text-xs`}
                key={id}
                openInCurrentTab={true}
                onClick={() => handleMenuListClick(content)}
                to={`#${anchor}`}
              >
                <span className="ArticleGenericPageNav__dropdown-label text-overflow-ellipsis nowrap overflow-x-hidden">
                  {label}
                </span>
              </Button>
            );
          })}
        </div>
      </div>

      <ArticleNavContainer
        ref={articleNavRef}
        notificationBarIsActive={notificationBarIsActive}
        notificationBarHeight={notificationBarHeight}
        scrollDirectionIsUp={scrollDirection === ScrollDirection.SCROLL_UP}
        className={cx(
          `ArticleGenericPageNav ArticleGenericPageNav--white-bg none pb1_25 sm:block`,
          className,
          {
            'overflow-y-scroll': navShouldScroll,
          },
        )}
      >
        <div
          ref={articleContentRef}
          className="ArticleGenericPageNav__content px1_5 sm:pb2_25 sm:pt2_25 flex"
        >
          <div className="flex flex-grow flex-col sm:justify-between">
            <div className="none mb3 flex-col sm:flex">
              {contents.map((content) => {
                if (!content) return null;

                const { label, anchor, id } = content;

                return (
                  <Button
                    ariaLabel={Language.t('ArticleNav.contentsAriaLabel', {
                      label,
                    })}
                    containerClassName={`ArticleGenericPageNav__content-button ArticleGenericPageNav__underline-button--black w100`}
                    className={cx(
                      `color-gray-100 sm:py_5 sm:px1_5 inline-flex text-xs`,
                      {
                        'ArticleGenericPageNav__content-button--active':
                          hash === anchor,
                      },
                    )}
                    key={id}
                    label={label}
                    openInCurrentTab={true}
                    to={`#${anchor}`}
                    wrap={true}
                  />
                );
              })}
            </div>

            <div className="ArticleGenericPageNav__border w100 sm:none mb1_5 border-color-gray-10 opacity-50" />
            <div className="flex flex-col text-xs">
              <div className="mt1_5 sm:mt0 flex sm:flex-col">
                <div className={`color-gray-100 sm:mt1_5 col-6 sm:col-12`}>
                  {!!date && (
                    <span className="block">
                      {Language.t('ArticleNav.updated', {
                        date: format(new Date(date), 'MMMM d, y'),
                      })}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ArticleNavContainer>
    </>
  );
}

const mapStateToProps = (state: GlobalState): StoreProps => ({
  globalSettings: state.globalSettings,
  notificationBarIsActive: state.applicationUI.notificationBarIsActive,
  notificationBarHeight: state.applicationUI.notificationBarHeight,
});

export default withBreakpoints(
  connect(mapStateToProps)(withRouter(ArticleGenericPageNav)),
);
