import React, {
  Suspense,
  lazy,
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { InterviewSettings } from 'styled/components/Polaris';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import useBreakpointIsMobile from 'hooks/useBreakpointIsMobile';

import type {
  App,
  IInterviewConfig,
  IInterviewQuestion,
  MiniInterviewType,
  PolarisInterviews,
} from '@buoy-components/polaris/types';
import type { LazyExoticComponent } from 'react';
import type { View } from 'styled/components/Polaris/constants';
import PolarisPopUpContext from 'contexts/PolarisPopUpContext';

interface IEmbeddedInterviewProps {
  handleQuestionSubmit: (interviewType: MiniInterviewType) => void;
  interview: {
    interview: MiniInterviewType;
    question: string;
    questions?: IInterviewQuestion[];
  };
  interviewSettings: InterviewSettings;
  isActive: boolean;
  isPopupOpen: boolean;
  onExit: () => void;
  onPopupOpen?: (view: View) => void;
}

const Interview = ({
  handleQuestionSubmit,
  interview,
  interviewSettings,
  isActive,
  isPopupOpen,
  onExit,
}: IEmbeddedInterviewProps) => {
  const [Component, setComponent] = useState<LazyExoticComponent<App> | null>(
    null,
  );
  const [placeholderHeight, setPlaceholderHeight] = useState('auto');
  const [isSkeletonCollapsed, setIsSkeletonCollapsed] = useState(false);
  const [shouldExpand, setShouldExpand] = useState(false);
  const theme = useTheme();
  const isMobile = useBreakpointIsMobile();
  const containerRef = useRef<HTMLDivElement>(null);
  const { eventEmitter, setIsPopupOpen } = useContext(PolarisPopUpContext);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setComponent(
        lazy(
          () =>
            import('polaris/PolarisInterviews') as Promise<{
              default: PolarisInterviews;
            }>,
        ),
      );
    }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        setIsSkeletonCollapsed(isPopupOpen);
      }, theme.transitions.duration.enteringScreen);
    }
  }, [isMobile, isPopupOpen, theme.transitions.duration.enteringScreen]);

  useEffect(() => {
    setShouldExpand(isActive);
  }, [isActive, setShouldExpand]);

  const onQuestionSubmit = useCallback(() => {
    const containerHeight =
      containerRef.current?.getBoundingClientRect().height;

    if (containerHeight) {
      setPlaceholderHeight(`${Math.round(containerHeight).toString()}px`);
    }
    handleQuestionSubmit(interview.interview);
  }, [handleQuestionSubmit, interview.interview]);

  const handleExit = useCallback(() => {
    onExit();
    setShouldExpand(true);
    containerRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [onExit]);

  const currentInterviewSettings = useMemo(() => {
    const currentInterview = interview.interview;
    return interviewSettings.find((i) => i.interview === currentInterview);
  }, [interview.interview, interviewSettings]);

  const interviewConfig = useMemo(
    () =>
      currentInterviewSettings?.configs?.map((c: IInterviewConfig) => {
        return {
          ...c,
          context: {
            ...c.context,
            polarisProps: {
              defaultExpanded: shouldExpand,
              displayPolarisUI: false,
              showProgressBar: true,
              titleQuestion: currentInterviewSettings.question,
              onExit: handleExit,
            },
            onQuestionSubmit: onQuestionSubmit,
          },
        };
      }),
    [
      currentInterviewSettings?.configs,
      currentInterviewSettings?.question,
      handleExit,
      onQuestionSubmit,
      shouldExpand,
    ],
  );

  return (
    <Grid
      className="relative [&_.MuiAccordion-root]:w-full"
      item
      ref={containerRef}
    >
      {isPopupOpen && (
        <Collapse
          in={!isSkeletonCollapsed}
          collapsedSize={isMobile ? placeholderHeight : '72px'}
          sx={{ borderRadius: '8px' }}
        >
          <Box height={placeholderHeight} position="relative">
            {!isMobile && (
              <Typography variant="body1" p="22px 26px" fontSize="18px">
                <strong>{currentInterviewSettings?.question}</strong>
              </Typography>
            )}
            <Skeleton
              height={placeholderHeight}
              variant="rounded"
              animation="wave"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            />
          </Box>
        </Collapse>
      )}
      <Suspense
        fallback={
          <CircularProgress className="mt-8 flex !w-full justify-center" />
        }
      >
        {Component && !isPopupOpen && (
          <Component
            configs={interviewConfig}
            eventEmitter={eventEmitter}
            interviewSettings={interviewSettings}
            isOpen={isPopupOpen}
            parentInterviewId={currentInterviewSettings?.id}
            setIsPopUpOpen={setIsPopupOpen}
          />
        )}
      </Suspense>
    </Grid>
  );
};

export default Interview;
