export function getMedianDescriptionsLength(
  itemsDescription: string[],
): number {
  if (itemsDescription.length === 0) {
    return 0;
  }

  const lengths = itemsDescription.map((description) => description.length);
  lengths.sort((a, b) => a - b);

  const middleIndex = Math.floor(lengths.length / 2);

  if (lengths.length % 2 === 0) {
    return (lengths[middleIndex - 1] + lengths[middleIndex]) / 2;
  } else {
    return lengths[middleIndex];
  }
}
