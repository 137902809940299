import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Color } from 'styled/theme/colors';

interface INumberIconProps extends PropsWithChildren {
  backgroundColor?: Color;
}

const Circle = styled.div`
  ${({ theme }) => theme.typography.variants.primary.md};
  font-size: 1rem;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ theme }) => theme.palette.gray[0]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -2px 0 2px;
  color: ${({ theme }) => theme.palette.orange[40]};
  font-weight: bold;
`;

const NumberIcon: FC<INumberIconProps> = ({ children }) => (
  <Circle>
    <span>{children}</span>
  </Circle>
);

export default NumberIcon;
