import styled from 'styled-components';
import { Button, Theme, BasePortableText } from 'styled';
import { CalloutColorValue } from 'constants/ColorScheme';
import Arrows from 'constants/Arrows';

type MainSectionProps = {
  colorScheme: CalloutColorValue;
};

type SupportingSectionProps = {
  colorScheme: CalloutColorValue;
};

export const Container = styled(Button)`
  display: flex;
  margin: 1.5rem 0;
`;

export const MainSection = styled(BasePortableText)<MainSectionProps>`
  background-color: ${({ colorScheme }) => colorScheme.infoSectionBackground};
  color: ${({ colorScheme }) => colorScheme.infoSectionText};
  ${({ theme }) => theme.typography.variants.primary.md};
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 60%;
  width: 60%;
  padding: 3.375rem 1.5rem;

  ::after {
    content: '${Arrows.RIGHT_ARROW}';
    margin-left: 0.5rem;
  }

  ${Theme.mediaQueries.sm} {
    ${({ theme }) => theme.typography.variants.primary['md-lg']};
    min-width: 65%;
    width: 65%;
    padding: 3.375rem 1.5rem 3.375rem 2.25rem;
  }
`;

export const SupportingSection = styled(
  BasePortableText
)<SupportingSectionProps>`
  background-color: ${({ colorScheme }) =>
    colorScheme.supportingSectionBackground};
  color: ${({ colorScheme }) => colorScheme.supportingSectionText};
  ${Theme.typography.variants.secondary.sm};
  display: flex;
  align-items: center;
  min-width: 40%;
  width: 40%;
  padding: 3.375rem 1.5rem;
  text-align: left;

  ${Theme.mediaQueries.sm} {
    min-width: 35%;
    width: 35%;
  }
`;
