import React, { FC } from 'react';
import cx from 'classnames';

import { Button, LinkOrButton } from 'styled';

import { SplitCtaColor, SplitCtaColorScheme } from 'constants/ColorScheme';
import Arrows from 'constants/Arrows';

import { SplitCtaModule as ISplitCtaModule } from 'types';

interface Props {
  module: ISplitCtaModule;
  applyArticleBodyWidthOffset?: boolean;
}

const SplitCtaModule: FC<Props> = ({ module, applyArticleBodyWidthOffset }) => {
  const { title, description, downloadLink, slug, moduleOptions } = module;

  const colorScheme = SplitCtaColorScheme[moduleOptions.color as SplitCtaColor];

  const hasDownloadLink = !!downloadLink;

  const moduleInner = (
    <div className="SplitCtaModule__container flex flex-row">
      <div
        className={cx(
          'SplitCtaModule__text-container col-9 md:col-10 flex flex-col px1_5 py2_25 md:p2_25',
          {
            'md:py5': !description,
          }
        )}
        style={{
          backgroundColor: colorScheme.background,
          color: colorScheme.text,
        }}
      >
        <p className="SplitCtaModule__title text-md md-up_text-lg">{title}</p>
        {!!description && (
          <p className="SplitCtaModule__description text-secondary-sm mt2_25">
            {description}
          </p>
        )}
      </div>
      <div
        className="SplitCtaModule__arrow-container col-3 md:col-2 flex justify-center items-center transition"
        style={{
          backgroundColor: colorScheme.section,
          color: colorScheme.arrow,
        }}
      >
        <span className="SplitCtaModule__arrow text-md md-up_text-lg transition">
          {hasDownloadLink ? Arrows.DOWN_ARROW : Arrows.RIGHT_ARROW}
        </span>
      </div>
    </div>
  );

  return (
    <div
      className={cx(`SplitCtaModule`, {
        'SplitCtaModule--with-down-arrow': hasDownloadLink,
        'SplitCtaModule--with-right-arrow': !hasDownloadLink,
        'article-inner-text-full-width': applyArticleBodyWidthOffset,
      })}
    >
      {!!downloadLink && (
        <Button
          containerClassName="flex justify-center"
          className="w100"
          ariaLabel={title}
          to={downloadLink}
        >
          {moduleInner}
        </Button>
      )}
      {!downloadLink && (
        <LinkOrButton
          containerClassName="flex justify-center"
          className="w100"
          ariaLabel={title}
          to={slug}
        >
          {moduleInner}
        </LinkOrButton>
      )}
    </div>
  );
};

export default SplitCtaModule;
