import { trackStructuredEvent } from 'analytics';

const category = 'embedded-interview';

export function trackModuleImpression(formId: string) {
  trackStructuredEvent({
    category,
    action: 'module-impression',
    property: formId,
  });
}
