import React, { FC } from 'react';

import BlockContent from '@sanity/block-content-to-react';
import cx from 'classnames';

import { ModuleHeader } from 'components/modules';
import { TextModulePortableText } from 'styled';
import { ArticleTable } from 'styled/components/ArticleTable';

import {
  TextModuleImage as Image,
  ListItem,
  ExternalLink,
  InternalLink,
  BlockRenderer,
} from 'constants/PortableTextSerializer';
import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';
import { Color } from 'styled/theme/colors';
import { Fonts } from 'styled/theme/typography';

import { TextModule as ITextModule } from 'types';

type Props = {
  module: ITextModule;
  hasWrapper?: boolean;
  hasCallout?: boolean;
  calloutVariant?: 'right' | 'left' | 'bottom';
  heroTextColor?: Color;
  /*
   * Module is used in different page types via ModuleSwitch and BlogPostModuleSwitch,
   * this allows us to have custom margins/padding
   */
  className?: string;
};

const TextModule: FC<Props> = ({
  module,
  hasWrapper,
  hasCallout,
  calloutVariant,
  heroTextColor,
  className,
}) => {
  const {
    anchor,
    title,
    titleFontSize,
    contents,
    contentFont,
    contentFontSize,
    contentTitleAlignment,
    contentAlignment,
    contentIsTwoColumnOnDesktop,
    separateTitleAndContentOnDesktop,
    moduleOptions,
    moduleHeader,
    button,
    contentIsFullWidth,
  } = module;
  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color as GeneralModuleColor];
  const titleFontClassName = `text-${titleFontSize}`;
  const contentFontClassName =
    contentFont === Fonts.GT_EESTI_DISPLAY
      ? `text-${contentFontSize}`
      : `text-secondary-${contentFontSize}`;
  const wrapperIsHero = hasWrapper && !moduleOptions.callout.isActive;
  const renderModuleHeader =
    moduleHeader.title || moduleHeader.description || button.label;

  return (
    <div
      // Anchor will be added to the callout wrapper if the module is rendered within one
      id={!hasCallout && !!anchor ? anchor : undefined}
      className={cx('TextModule w100 pb3 flex flex-col', className, {
        'border-top-gray-lighter': moduleOptions.showBorderTop,
        'border-bottom-gray-lighter': moduleOptions.showBorderBottom,
        pt3: !renderModuleHeader,
        'module-content-padding-with-callout': hasCallout,
      })}
      data-testid="text-module"
      style={{
        backgroundColor: !hasWrapper ? colorScheme.background : undefined,
      }}
    >
      {renderModuleHeader && (
        <ModuleHeader
          title={moduleHeader.title}
          description={moduleHeader.description}
          buttonLabel={button.label}
          buttonUrl={button.url}
          buttonVariant={button.variant}
          buttonInlineWithTitle={true}
        />
      )}
      <div
        className={cx(
          'TextModule__container w100 flex flex-col justify-center',
          {
            'sm:col-8 lg:col-6 xl:col-4 mxauto':
              wrapperIsHero && !separateTitleAndContentOnDesktop,
            'items-center': calloutVariant === 'bottom' || !hasWrapper,
          },
        )}
      >
        <div
          className={cx(`w100 TextModule__text-container`, {
            'TextModule__text--max-width': hasCallout && !contentIsFullWidth,
            'TextModule__text--two-col-max-width':
              contentIsTwoColumnOnDesktop && !contentIsFullWidth,
            'TextModule__text--full-width':
              contentIsFullWidth || separateTitleAndContentOnDesktop,
            'flex flex-col md:flex-row': separateTitleAndContentOnDesktop,
          })}
        >
          {!!title && (
            <p
              className={cx('mb4_5 md:mb3', titleFontClassName, {
                'color-gray-100': !hasWrapper,
                'md:col-6 md:mr4': separateTitleAndContentOnDesktop,
                'text-center': contentTitleAlignment === 'center',
              })}
            >
              {title}
            </p>
          )}
          <TextModulePortableText
            textColor={heroTextColor}
            className={cx('TextModule__contents', contentFontClassName, {
              'TextModule__contents--two-column': contentIsTwoColumnOnDesktop,
              'md:col-6': separateTitleAndContentOnDesktop,
              'text-center': contentAlignment === 'center',
            })}
          >
            <BlockContent
              blocks={contents}
              serializers={{
                types: {
                  image: Image,
                  block: BlockRenderer,
                  articleTable: ArticleTable,
                },
                listItem: ListItem,
                marks: { link: ExternalLink, internalLink: InternalLink },
              }}
            />
          </TextModulePortableText>
        </div>
      </div>
    </div>
  );
};

export default TextModule;
