import styled from 'styled-components';
import { Base } from 'styled/components/base';
import Img from 'styled/components/Img';
import { MarketingPortableText } from 'styled/components/portableText';
import Theme from 'styled/theme';
import {
  GeneralModuleColorValue,
  EmphasizeModuleColorValue,
} from 'constants/ColorScheme';

type ContainerProps = {
  showBorderTop: boolean;
  showBorderBottom: boolean;
  colorScheme: GeneralModuleColorValue;
  applyArticleBodyWidthOffset?: boolean;
  desktopImagePosition: 'left' | 'right';
  desktopMaxHeight: number;
};

type ImageContainerProps = {
  imageContainerColor: EmphasizeModuleColorValue;
};

type CardContainerProps = {
  fillImageContainer: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  ${({ theme, applyArticleBodyWidthOffset }) =>
    applyArticleBodyWidthOffset && theme.global.articleInnerTextFullWidth};
  background-color: ${({ colorScheme }) => colorScheme.background};
  color: ${({ colorScheme }) => colorScheme.text};
  border-top: ${({ theme, showBorderTop }) =>
    showBorderTop && theme.borders['gray-10']};
  border-bottom: ${({ theme, showBorderBottom }) =>
    showBorderBottom && theme.borders['gray-10']};
  display: flex;
  flex-direction: column;

  ${Theme.mediaQueries.md} {
    flex-direction: ${({ desktopImagePosition }) =>
      desktopImagePosition === 'left' ? 'row' : 'row-reverse'};
    max-height: ${({ desktopMaxHeight }) =>
      !!desktopMaxHeight && `${desktopMaxHeight}px`};
  }
`;

export const ImageContainer = styled(Base)<ImageContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ imageContainerColor }) =>
    imageContainerColor.background};
  width: 100%;

  ${Theme.mediaQueries.md} {
    width: 50%;
  }
`;

export const TextContainer = styled(MarketingPortableText)`
  ${({ theme }) => theme.typography.variants.secondary.sm};
`;

export const ImageWithFullWidth = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ImageWithMaxWidth = styled(Img)`
  max-width: 21.875rem;
  max-height: 21.875rem;
`;

export const CardContainer = styled(Base)<CardContainerProps>`
  ${({ theme }) => theme.global.moduleContentPaddingX};
  margin-top: ${({ fillImageContainer }) => fillImageContainer && 'auto'};
  margin-bottom: ${({ fillImageContainer }) => fillImageContainer && 'auto'};
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  width: 100%;

  ${Theme.mediaQueries.md} {
    width: 50%;
    padding-top: 5.25rem;
    padding-bottom: 5.25rem;
  }
`;
