import { trackStructuredEvent } from 'analytics';
import { fireOnce } from 'analytics/snowplow';

export const trackProductClick = (impressionId: string, url: string) =>
  trackStructuredEvent({
    action: 'product-card-click',
    category: 'product-cards',
    property: url,
    label: impressionId,
  });

export const trackProductViewed = (impressionId: string, url: string) => {
  const event = {
    action: 'product-card-viewed',
    category: 'product-cards',
    property: url,
    label: impressionId,
  };
  fireOnce(() => {
    trackStructuredEvent(event);
  }, `${event.action}-${event.category}-${event.property}-${event.label}`);
};
