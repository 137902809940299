import React, { FC, useState, useRef, RefObject, MouseEvent } from 'react';
import theme from '@buoyhealth/common.buoy-theme';

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';

import { Img, Link, Button, BasePortableText } from 'styled';
import { ModuleHeader } from 'components/modules';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';
import useLanguage from 'hooks/useLanguage';
import { RouteMap } from 'constants/Routes';
import { ExternalLink, InternalLink } from 'constants/PortableTextSerializer';

import { TeamListModule as ITeamListModule, TeamMember } from 'types';

interface Props {
  module: ITeamListModule;
}

const ImageCard: FC<{ teamMember: TeamMember }> = ({ teamMember }) => (
  <div className="TeamListModule__image-card text-md">
    <Img
      alt={teamMember.image.alt}
      className="w100"
      sizes="(max-width: 768px) 400px, (max-width: 1024px) 500px, 650px"
      src={sanityImgUtil(teamMember.image, 400)}
      srcSet={sanityImgSrcSetUtil(teamMember.image, 400, 500, 650)}
      preloadContainerClassName="w100"
      dimensions={teamMember.image.metadata?.dimensions}
      crop={teamMember.image.crop}
    />
    <div className="TeamListModule__image-card-info text-left m2_25">
      {!!teamMember.fullName && (
        <span className="color-gray-100 mb_5">{teamMember.fullName}</span>
      )}
      {!!teamMember.jobTitle && (
        <span className="color-gray-50 block">{teamMember.jobTitle}</span>
      )}
    </div>
  </div>
);

const BioCard: FC<{
  teamMember: TeamMember;
  bioRef: RefObject<HTMLDivElement>;
}> = ({ teamMember, bioRef }) => (
  <div className="TeamListModule__bio-card bg-color-teal-0 w100 h100 flex flex-col text-left color-gray-100 px1 py1_5 transition-fade-in-short">
    {!!teamMember.fullName && (
      <span className="text-lg mb_5">{teamMember.fullName}</span>
    )}
    {!!teamMember.jobTitle && (
      <span className="text-md">{teamMember.jobTitle}</span>
    )}
    <BasePortableText
      textColor={theme.palette.text.primary}
      className="TeamListModule__bio text-sm text-left overflow-y-scroll mt1_125"
      ref={bioRef}
    >
      <BlockContent
        blocks={teamMember.bio}
        serializers={{
          marks: { link: ExternalLink, internalLink: InternalLink },
        }}
      />
    </BasePortableText>
  </div>
);

const TeamListModule: FC<Props> = ({ module }) => {
  const Language = useLanguage();
  const [showBio, setShowBio] = useState(false);
  const bioRef = useRef(null);

  const { moduleHeader, contents, moduleOptions } = module;
  const headerTitle = moduleHeader.title;
  const headerDescription = moduleHeader.description;
  const anchor = headerTitle.toLowerCase().split(' ').join('-');
  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color as GeneralModuleColor];

  return (
    <div
      id={anchor}
      className={cx(`TeamListModule color-gray-100 flex flex-col`, {
        'border-top-gray-lighter': moduleOptions.showBorderTop,
        'border-bottom-gray-lighter': moduleOptions.showBorderBottom,
      })}
    >
      <div className="TeamListModule__header module-content-padding-x">
        <ModuleHeader title={headerTitle} description={headerDescription} />
      </div>
      <div className="TeamListModule__cards flex flex-row flex-wrap">
        {contents.map((teamMember, index) => {
          const fullName = teamMember.fullName;

          if (teamMember.type === 'author') {
            return (
              <div
                className="TeamListModule__author-card col-6 md:col-4 text-sm md-up_text-md"
                key={`${teamMember.id}`}
                style={{
                  backgroundColor:
                    index % 2 === 0
                      ? colorScheme.background
                      : colorScheme.darkBackground,
                }}
              >
                <Link
                  ariaLabel={Language.t('Global.teamMemberButtonAriaLabel', {
                    name: fullName,
                  })}
                  to={`${RouteMap.AUTHOR.base}${teamMember.slug}`}
                  containerClassName="TeamListModule__card-image"
                >
                  <Img
                    alt={teamMember.image.alt}
                    className="w100"
                    sizes="(max-width: 768px) 400px, (max-width: 1024px) 500px, 650px"
                    src={sanityImgUtil(teamMember.image, 400)}
                    srcSet={sanityImgSrcSetUtil(
                      teamMember.image,
                      400,
                      500,
                      650
                    )}
                    preloadContainerClassName="w100"
                    dimensions={teamMember.image.metadata?.dimensions}
                    crop={teamMember.image.crop}
                  />
                </Link>
                <div className="TeamListModule__author-card-info m2_25">
                  {!!fullName && (
                    <Link
                      ariaLabel={Language.t(
                        'Global.teamMemberButtonAriaLabel',
                        {
                          name: fullName,
                        }
                      )}
                      to={`${RouteMap.AUTHOR.base}${teamMember.slug}`}
                      containerClassName="TeamListModule__title-button inline-flex"
                    >
                      <span
                        className="TeamListModule__title mb_5 link-border-bottom-black-hidden"
                        style={{
                          color: colorScheme.text,
                        }}
                      >
                        {fullName}
                      </span>
                    </Link>
                  )}
                  {!!teamMember.jobTitle && (
                    <span className="color-gray-50 block">
                      {teamMember.jobTitle}
                    </span>
                  )}
                </div>
              </div>
            );
          }

          return (
            <div
              className="TeamListModule__card relative col-12 sm:col-6 md:col-4"
              key={`${teamMember.id}`}
              style={{
                backgroundColor:
                  index % 2 === 0
                    ? colorScheme.background
                    : colorScheme.darkBackground,
              }}
            >
              {teamMember.bio?.length ? (
                <>
                  <Button
                    containerClassName="w100"
                    key={`image-card-${teamMember.id}`}
                    type="button"
                    ariaLabel={Language.t('TeamListModule.bioCardShowBioAlt', {
                      name: fullName,
                    })}
                    aria-hidden={showBio}
                    tabIndex={showBio ? -1 : undefined}
                    onClick={() => setShowBio(true)}
                  >
                    <ImageCard teamMember={teamMember} />
                  </Button>
                  {showBio && (
                    <Button
                      containerClassName="absolute t0 r0 l0 b0 w100 h100"
                      key={`bio-card-${teamMember.id}`}
                      type="button"
                      ariaLabel={Language.t(
                        'TeamListModule.bioCardHideBioAlt',
                        { name: fullName }
                      )}
                      onClick={(e: MouseEvent<HTMLElement>) => {
                        // Ignore user clicks within bio to allow them to copy text
                        if (
                          (e.target as HTMLElement).parentNode ===
                          bioRef.current
                        )
                          return;

                        setShowBio(false);
                      }}
                    >
                      <BioCard teamMember={teamMember} bioRef={bioRef} />
                    </Button>
                  )}
                </>
              ) : (
                <ImageCard teamMember={teamMember} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TeamListModule;
