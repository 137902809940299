import React, { useMemo } from 'react';
import Script from 'next/script';
import styled from 'styled-components';
import { IGoogleFormModule } from 'types';

interface IGoogleFormModuleProps {
  module: IGoogleFormModule;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`;

const TrustedFormScript = () => {
  const trustedFormSrc = useMemo(
    () =>
      `https://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=${new Date().getTime()}${Math.random()}`,
    []
  );

  return (
    <>
      <Script src={trustedFormSrc} strategy="afterInteractive" />
      <noscript>
        <img src="https://api.trustedform.com/ns.gif" />
      </noscript>
    </>
  );
};

const GoogleFormModule = ({ module }: IGoogleFormModuleProps) => {
  const sanitizedURL = module.url.replace('?usp=sf_link', '');

  return (
    <Container>
      {module.trustedFormEnabled && <TrustedFormScript />}

      <iframe
        src={`${sanitizedURL}?embedded=true`}
        width={module.width || 640}
        height={module.height || 2830}
      >
        Loading…
      </iframe>
    </Container>
  );
};

export default GoogleFormModule;
