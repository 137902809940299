import styled from 'styled-components';
import { Base } from 'styled';

type ContainerProps = {
  showBorderTop: boolean;
  showBorderBottom: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  ${({ theme }) => theme.global.blogPostContentPaddingX};
  color: ${({ theme }) => theme.palette.text.primary};
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: ${({ theme, showBorderTop }) =>
    showBorderTop && theme.borders['gray-10']};
  border-bottom: ${({ theme, showBorderBottom }) =>
    showBorderBottom && theme.borders['gray-10']};
`;

export const HighlightContainer = styled(Base)`
  background-color: ${({ theme }) => theme.palette.rose[0]};
  border-radius: 0.25rem;
  padding: 1rem;

  :not(:last-of-type) {
    margin-bottom: 2rem;
  }

  && {
    ul,
    ol {
      margin-left: 0;
    }
  }
`;

export const HighlightTitleContainer = styled.p`
  ${({ theme }) => theme.typography.variants.primary.sm};
  color: ${({ theme }) => theme.palette.red[70]};
  display: flex;
  align-items: center;
  margin-top: 0rem;
  margin-bottom: 1rem;

  img {
    margin-right: 0.5rem;
  }
`;
