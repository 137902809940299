import styled from 'styled-components';
import { Base, Theme } from 'styled';
import { Props } from './index';

type CircleProps = Pick<Props, 'circleColor' | 'renderSmallerSizeOnMobile'>;
type NumberProps = Pick<Props, 'numberColor'>;

export const Circle = styled(Base)<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.typography.variants.primary.md};
  border-radius: 50%;
  background-color: ${({ circleColor }) => circleColor};
  width: ${({ theme, renderSmallerSizeOnMobile }) =>
    renderSmallerSizeOnMobile ? '2.5rem' : theme.sizes.circledNumber};
  height: ${({ theme, renderSmallerSizeOnMobile }) =>
    renderSmallerSizeOnMobile ? '2.5rem' : theme.sizes.circledNumber};

  ${Theme.mediaQueries.md} {
    width: ${({ theme }) => theme.sizes.circledNumber};
    height: ${({ theme }) => theme.sizes.circledNumber};
  }
`;

export const Number = styled(Base)<NumberProps>`
  color: ${({ numberColor }) => numberColor};
`;
