import NumberIcon from 'styled/components/SupplierPromoModal/components/NumberIcon';
import CheckmarkIcon from 'styled/components/SupplierPromoModal/components/CheckmarkIcon';
import LockInsideFilledCircle from 'styled/components/svg/LockIconInsideFilledCircle';
import DollarIcon from '../../svg/DollarWithCircleIcon';
import NoVideoIcon from '../../svg/NoVideoIcon';
import PillBottleIcon from '../../svg/PillBottleIcon';
import VideoIcon from 'styled/components/svg/VideoIcon';

export const ICONS = [
  { name: 'cost', component: DollarIcon },
  { name: 'noVideo', component: NoVideoIcon },
  { name: 'rxBottle', component: PillBottleIcon },
  { name: 'checkmark', component: CheckmarkIcon },
  { name: 'lock', component: LockInsideFilledCircle },
  { name: 'number', component: NumberIcon },
  { name: 'video', component: VideoIcon },
];
