import React, { FC, FocusEvent } from 'react';
import Select, { components as SelectComponents } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import cx from 'classnames';
import {
  ErrorMessage as ErrorMessageWithIcon,
  DropdownIndicatorIcon,
  ClearIndicatorIcon,
} from 'styled';
import {
  Label,
  Container,
  MessageContainer,
  ErrorMessageAboveField,
} from './style';

import { DropdownOption } from 'types';
import { Color } from 'styled/theme/colors';

export interface Props {
  id?: string;
  name: string;
  label?: string;
  className?: string;
  color?: Color;
  ariaLabel: string;
  onChange:
    | ((value: DropdownOption) => any)
    | ((values: DropdownOption[]) => any);
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  options: DropdownOption[];
  placeholder?: string;
  value?: DropdownOption | DropdownOption[] | null;
  showLabel: boolean;
  errorMessage?: string;
  showError: boolean;
  variant?: 'with-bottom-border' | 'with-all-borders';
  canCreateOptions?: boolean;
  canSelectMultiple?: boolean;
  menuMessage?: string;
}

const Dropdown: FC<Props> = ({
  id,
  ariaLabel,
  className = '',
  color,
  label,
  name,
  options,
  placeholder,
  value,
  showLabel = false,
  errorMessage,
  showError = false,
  onChange,
  onBlur,
  variant = 'with-bottom-border',
  canCreateOptions = false,
  canSelectMultiple = false,
  menuMessage,
}) => {
  const handleOnChange = (option: any) => onChange(option);

  const _id = id || name;

  const DropdownIndicator = (props: any) => {
    return (
      <SelectComponents.DropdownIndicator {...props}>
        <DropdownIndicatorIcon />
      </SelectComponents.DropdownIndicator>
    );
  };

  const ClearIndicator = (props: any) => {
    return (
      <SelectComponents.ClearIndicator {...props}>
        <ClearIndicatorIcon size="large" />
      </SelectComponents.ClearIndicator>
    );
  };

  const MultiValueRemove = (props: any) => {
    return (
      <SelectComponents.MultiValueRemove {...props}>
        <ClearIndicatorIcon size="small" />
      </SelectComponents.MultiValueRemove>
    );
  };

  return (
    <Container
      data-testid="dropdown"
      className={cx('Dropdown', className)}
      showError={showError}
      color={color}
      variant={variant}
      menuMessage={menuMessage}
    >
      <MessageContainer>
        {label && (
          <Label htmlFor={_id} showLabel={showLabel} variant={variant}>
            {label}
          </Label>
        )}
        {variant === 'with-bottom-border' && (
          <ErrorMessageAboveField
            role="alert"
            aria-hidden={!showError}
            showError={showError}
          >
            {errorMessage}
          </ErrorMessageAboveField>
        )}
      </MessageContainer>
      {canCreateOptions ? (
        <CreatableSelect
          classNamePrefix="Select"
          name={name}
          options={options}
          isMulti={canSelectMultiple}
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={onBlur}
          aria-label={ariaLabel}
          value={value}
          components={
            variant === 'with-all-borders'
              ? { DropdownIndicator, ClearIndicator, MultiValueRemove }
              : undefined
          }
        />
      ) : (
        <Select
          classNamePrefix="Select"
          name={name}
          options={options}
          isClearable={false}
          isSearchable={false}
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={onBlur}
          aria-label={ariaLabel}
          value={value}
          components={
            variant === 'with-all-borders' ? { DropdownIndicator } : undefined
          }
        />
      )}
      {errorMessage && variant === 'with-all-borders' && (
        <ErrorMessageWithIcon
          aria-hidden={!showError}
          showError={showError}
          message={errorMessage}
          marginTop={1.5}
        />
      )}
    </Container>
  );
};

export default Dropdown;
