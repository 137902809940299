import styled from 'styled-components';
import { Base, Theme } from 'styled';

export const Image = styled(Base)`
  object-fit: cover;
  width: ${({ theme }) =>
    `calc(100% + ${theme.sizes.carouselMobilePadding} * 2)`};
  height: ${({ theme }) =>
    `calc(100% + ${theme.sizes.carouselMobilePadding} * 2)`};
  margin: ${({ theme }) => `calc(${theme.sizes.carouselMobilePadding} * -1)`};

  ${Theme.mediaQueries.md} {
    width: ${({ theme }) =>
      `calc(100% + ${theme.sizes.carouselDesktopPadding} * 2)`};
    height: ${({ theme }) =>
      `calc(100% + ${theme.sizes.carouselDesktopPadding} * 2)`};
    margin: ${({ theme }) =>
      `calc(${theme.sizes.carouselDesktopPadding} * -1)`};
  }
`;
