import { z } from 'zod';

export const OptionSchema = z.object({
  subtext: z.string().nullable(),
  text: z.string(),
  token: z.string().uuid(),
});
export const QuestionInterviewType = z.enum(['tg']);
export const QuestionType = z.enum(['multiple', 'single']);
export const QuestionSchema = z.object({
  interview_type: QuestionInterviewType,
  options: z.array(OptionSchema),
  page: z.string(),
  question_type: QuestionType,
  subtext: z.string().nullable(),
  text: z.string(),
  token: z.string().uuid(),
});

export type Question = z.infer<typeof QuestionSchema>;
export type Option = z.infer<typeof OptionSchema>;
