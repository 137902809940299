import React from 'react';
import { RouteMap } from 'constants/Routes';
import { LinkedItemContainer, ItemsContainer } from './style';
import useLanguage from 'hooks/useLanguage';
import usePage from 'hooks/usePage';
import { IDxListItem } from 'types';
import ItemInner from './ItemInner';
type Props = {
  items: IDxListItem[];
};

const CtaList = ({ items }: Props) => {
  const { article } = usePage() || {};
  const Language = useLanguage();
  const { language } = article || {};

  return (
    <ItemsContainer data-testid="sx-cta-list">
      {items.map((item, index) => {
        const { title, slug } = item;
        const dxUrl = `${RouteMap.ARTICLE.base}${
          language === 'en' ? '' : `/${language}`
        }${slug}`;

        return slug ? (
          <LinkedItemContainer
            key={`${item.id}-${index}`}
            ariaLabel={Language.t('Global.readMoreAriaLabel', {
              topic: title,
            })}
            to={dxUrl}
          >
            <ItemInner item={item} />
          </LinkedItemContainer>
        ) : (
          <ItemInner key={`${item.id}-${index}`} item={item} />
        );
      })}
    </ItemsContainer>
  );
};

export default CtaList;
