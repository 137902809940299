import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IKatalysModule } from 'types';
import { trackModuleView } from './analytics';
import useRefInView from 'hooks/useRefInView';

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 850px;
  height: auto;
  overflow: hidden;

  &::after {
    display: inline-block;
    content: '';
    padding-bottom: 58.82%;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 660px) {
    width: 300px;
    height: 600px;
    margin: 0 auto;

    &::after {
      padding-bottom: 0%;
      overflow: hidden;
    }
  }
`;
interface KatalysModuleProps {
  module: IKatalysModule;
}
const KatalysModule = ({ module }: KatalysModuleProps) => {
  const katalysUrl = `${module.url}?utm_source=${module.utmParams.source}&utm_medium=${module.utmParams.medium}`;
  const katalysContainerRef = useRef<HTMLDivElement | null>(null);
  const containerInView = useRefInView(katalysContainerRef);

  useEffect(() => {
    if (containerInView) {
      trackModuleView(katalysUrl);
    }
  }, [containerInView]);

  return (
    <Container data-testid="katalys-module" ref={katalysContainerRef}>
      <iframe
        allow="payment"
        data-testid={module.id}
        src={katalysUrl}
        style={{
          margin: 'auto',
          border: 'none',
          width: '100%',
          height: '100%',
        }}
      ></iframe>
    </Container>
  );
};

export default KatalysModule;
