import React, { FC } from 'react';
import { useTheme } from 'styled-components';

import BlockContent from '@sanity/block-content-to-react';
import cx from 'classnames';

import { BasePortableText, LinkOrButton } from 'styled';
import { ModuleHeader } from 'components/modules';

import {
  ExternalLink,
  InternalLink,
  ListItem,
} from 'constants/PortableTextSerializer';

import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';

import { JobPostingsModule as IJobPostingsModule } from 'types';

interface PassedProps {
  module: IJobPostingsModule;
  hasWrapper?: boolean;
  hasCallout?: boolean;
}

type Props = PassedProps;

const JobPostingsModule: FC<Props> = ({ hasCallout, hasWrapper, module }) => {
  const { palette } = useTheme();
  const { moduleHeader, button, bottomDescription } = module;

  const headerTitle = moduleHeader.title;
  const headerDescription = moduleHeader.description;
  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color as GeneralModuleColor];
  const renderModuleHeader = headerTitle || headerDescription;

  const BAMBOO_HR_WIDGET =
    '<div id="BambooHR" data-domain="buoyhealth.bamboohr.com" data-version="1.0.0" data-departmentId=""></div><script src="https://buoyhealth.bamboohr.com/js/embed.js" type="text/javascript" async defer></script>';

  return (
    <div
      className={cx(
        'JobPostingsModule w100 module-content-padding-x flex-1 pb6',
        {
          pt6: !renderModuleHeader,
          'module-content-padding-with-callout': hasCallout,
        }
      )}
      style={{
        backgroundColor: !hasWrapper ? colorScheme.background : undefined,
        color: !hasWrapper ? colorScheme.text : undefined,
      }}
    >
      {renderModuleHeader && (
        <div className="JobPostingsModule__header">
          <ModuleHeader title={headerTitle} description={headerDescription} />
        </div>
      )}
      <div className="JobPostingsModule__items flex flex-col">
        <div dangerouslySetInnerHTML={{ __html: BAMBOO_HR_WIDGET }}></div>
      </div>
      {!!bottomDescription.length && (
        <BasePortableText className="JobPostingsModule__bottom-description md:col-10 text-secondary-sm mt1_75">
          <BlockContent
            blocks={bottomDescription}
            serializers={{
              listItem: ListItem,
              marks: { link: ExternalLink, internalLink: InternalLink },
            }}
          />
        </BasePortableText>
      )}
      {!!button.label && !!button.url && (
        <LinkOrButton
          ariaLabel={button.label}
          containerClassName="inline-block mt1_75"
          label={button.label}
          to={button.url}
          variant={`underline-${
            colorScheme.text === palette.text.primary ? 'black' : 'white'
          }`}
        />
      )}
    </div>
  );
};

export default JobPostingsModule;
