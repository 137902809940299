import { trackStructuredEvent } from 'analytics';

const category = 'promoted-supplier-modal';

export const trackModalSeen = (uuid: string) => {
  trackStructuredEvent({
    category,
    label: uuid,
    action: 'modal-viewed',
  });
};

export const trackNextPageClicked = (uuid: string) => {
  trackStructuredEvent({
    category,
    label: uuid,
    action: 'next-page-clicked',
  });
};

export const trackModalExited = (uuid: string) => {
  trackStructuredEvent({
    category,
    label: uuid,
    action: 'modal-exited',
  });
};

export const trackModalFinalLinkClicked = (uuid: string, url: string) => {
  trackStructuredEvent({
    category,
    label: uuid,
    action: 'final-cta-clicked',
    property: url,
  });
};
