import { useState, useEffect, MutableRefObject } from 'react';

import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useBreakpoint from 'hooks/useBreakpoint';

import type { Args } from 'hooks/useIntersectionObserver';

const useRefInView = (
  ref: MutableRefObject<HTMLElement | null>,
  options?: Partial<Args>,
): boolean => {
  const [refIsInView, setRefIsInView] = useState(false);

  const currentBreakpoint = useBreakpoint();
  const isMobile =
    currentBreakpoint && ['EXTRA_SMALL', 'SMALL'].includes(currentBreakpoint);

  const entry = useIntersectionObserver(ref, {
    threshold: isMobile ? 0.2 : 0.5,
    freezeOnceVisible: true,
    // Theme.sizes.topBarHeightMobile = 59px, theme.sizes.topBarHeightTablet = 84px
    rootMargin: isMobile ? '59px' : '84px',
    ...options,
  });

  useEffect(() => {
    setRefIsInView(!!entry?.isIntersecting);
  }, [entry]);

  return refIsInView;
};

export default useRefInView;
