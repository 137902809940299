import styled, { css } from 'styled-components';
import { Base } from 'styled';
import { Props } from './index';

type ContainerProps = Pick<Props, 'showError' | 'color' | 'variant' | 'value'>;

type LabelProps = Pick<Props, 'variant' | 'showLabelAsPlaceholder'>;

type ErrorMessageProps = Pick<Props, 'showError'>;

type InputContainerProps = Pick<Props, 'showIcon'>;

const getBottomBorderContainerStyles = (
  color: ContainerProps['color'],
  showError: ContainerProps['showError']
) => css`
  input,
  textarea {
    padding: 0.625rem 0.75rem;
    ${({ theme }) => theme.typography.variants.primary.xs};
    border: none;
    border-radius: 0;
    border-bottom: 1px solid
      ${({ theme }) =>
        showError
          ? theme.palette.status.error
          : color || theme.palette.gray[50]};

    :focus {
      border-bottom-color: ${({ theme }) => theme.palette.blue[80]};
    }
  }

  /* Overwrite label color if there is error */
  label {
    color: ${({ theme }) => showError && theme.palette.status.error};
  }
`;

const getAllBordersContainerStyles = (
  value: ContainerProps['value'],
  color: ContainerProps['color'],
  showError: ContainerProps['showError']
) => css`
  margin: 2rem 0rem;

  input,
  textarea {
    ${({ theme }) => theme.transitions['transition-short']};
    ${({ theme }) => theme.typography.variants.primary.sm};
    border: 1px solid
      ${({ theme }) =>
        showError
          ? theme.palette.status.error
          : color || theme.palette.gray[50]};
    border-radius: 0.25rem;
    caret-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.text.primary};

    :focus {
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
    }
  }

  input {
    padding: ${value ? '2.0625rem 1rem 1rem' : '1rem'};

    :focus {
      padding: 2.0625rem 1rem 1rem;
    }
  }

  textarea {
    padding: 2.0625rem 1rem 1rem;
  }
`;

export const Container = styled(Base)<ContainerProps>`
  position: relative;
  ${({ theme }) => theme.typography.variants.primary.xs};
  color: ${({ color }) => color || 'unset'};
  ${({ variant, color, showError }) =>
    variant === 'with-bottom-border' &&
    getBottomBorderContainerStyles(color, showError)};
  ${({ variant, color, showError, value }) =>
    variant === 'with-all-borders' &&
    getAllBordersContainerStyles(value, color, showError)};

  input,
  textarea {
    width: 100%;
    background-color: transparent;
    background-clip: padding-box;
  }

  /* Removes autofill background color */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  ${({ color }) =>
    color &&
    `
    input,
    textarea {
      color: ${color};
    }

    input:-webkit-autofill {
      color: ${color} !important;
      -webkit-text-fill-color: ${color} !important;
    }
  `}
`;

const getBottomBorderLabelStyles = (
  showLabelAsPlaceholder: LabelProps['showLabelAsPlaceholder']
) => css`
  top: ${!showLabelAsPlaceholder ? '0.675rem' : '0rem'};
  margin-top: ${showLabelAsPlaceholder ? '-1rem' : 'unset'};
  margin-left: ${showLabelAsPlaceholder ? '0rem' : '0.75rem'};
  color: ${({ theme }) =>
    showLabelAsPlaceholder
      ? theme.palette.text.primary
      : theme.palette.gray[60]};
`;

const getAllBordersLabelStyles = (
  showLabelAsPlaceholder: LabelProps['showLabelAsPlaceholder']
) => css`
  ${({ theme }) =>
    showLabelAsPlaceholder
      ? theme.typography.variants.primary.sm
      : theme.typography.variants.primary.xs};
  margin-top: ${showLabelAsPlaceholder ? '1rem' : '0.75rem'};
  margin-left: 1rem;
  color: ${({ theme }) => theme.palette.gray[90]};
`;

export const Label = styled.label<LabelProps>`
  ${({ theme }) => theme.typography.variants.primary.xs};
  ${({ theme }) => theme.transitions['transition-short']};
  position: absolute;
  ${({ variant, showLabelAsPlaceholder }) =>
    variant === 'with-bottom-border' &&
    getBottomBorderLabelStyles(showLabelAsPlaceholder)};
  ${({ variant, showLabelAsPlaceholder }) =>
    variant === 'with-all-borders' &&
    getAllBordersLabelStyles(showLabelAsPlaceholder)};
`;

export const ErrorMessageWithinField = styled.p<ErrorMessageProps>`
  ${({ theme }) => theme.typography.variants.primary.xs};
  ${({ theme }) => theme.transitions['transition-short']};
  color: ${({ theme }) => theme.palette.status.error};
  opacity: ${({ showError }) => (showError ? 1 : 0)};
  position: absolute;
  top: -1rem;
  right: 0;
`;

export const InputContainer = styled(Base)<InputContainerProps>`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;

  input {
    margin-right: ${({ showIcon }) => showIcon && '2rem'};
  }
`;
