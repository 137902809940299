import React, { FC } from 'react';
import { Svg } from 'styled';

const InformativeIcon: FC = () => (
  <Svg width="24px" height="24px" viewBox="0 0 24 24" fill="transparent">
    <desc>Informative Icon.</desc>
    <path
      d="M10.5424 10.5322V6.25981C10.5299 3.91128 8.6316 2 6.2712 2C3.92329 2 2.01249 3.89878 2 6.25981V9.3454C2 10.0075 2.53703 10.5447 3.19894 10.5447H10.5424V10.5322Z"
      fill="#FF5D5C"
    />
    <path
      d="M13.4646 13.4553H17.7359C20.0838 13.4678 21.9946 15.3666 21.9946 17.7277C21.9946 20.0762 20.0963 21.9875 17.7359 22H14.6511C13.9892 22 13.4521 21.4628 13.4521 20.8008V13.4553H13.4646Z"
      fill="#F9AE0F"
    />
    <path
      d="M5.44679 21.9001C8.44413 22.4748 11.0418 19.8764 10.4673 16.8782C10.1426 15.2168 8.78132 13.8426 7.1203 13.5303C4.12296 12.9557 1.52526 15.5541 2.09975 18.5522C2.41198 20.2262 3.77328 21.5878 5.44679 21.9001Z"
      fill="#9CC7C9"
    />
    <path
      d="M16.8989 10.4573C19.8963 11.0319 22.494 8.43352 21.9195 5.43539C21.6073 3.77392 20.2335 2.39975 18.5724 2.08745C15.5751 1.5128 12.9774 4.11121 13.5519 7.10934C13.8641 8.77081 15.2254 10.1325 16.8989 10.4573Z"
      fill="#196CFF"
    />
  </Svg>
);

export default InformativeIcon;
