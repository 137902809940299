import React, { FC } from 'react';

import BlockContent from '@sanity/block-content-to-react';

import ScrollableAnchorWrapper from 'components/ScrollableAnchorWrapper';
import { ArticleHighlightedTextModulePortableText } from 'styled';

import {
  ListItem,
  ExternalLink,
  InternalLink,
} from 'constants/PortableTextSerializer';

import { HighlightedText } from 'types';

interface Props {
  module: HighlightedText;
}

const HighlightedTextModule: FC<Props> = ({ module }) => {
  const { sideNav } = module;

  return (
    <ScrollableAnchorWrapper section={sideNav}>
      <ArticleHighlightedTextModulePortableText className="disabled-ads HighlightedTextModule border-top-gray-lighter border-bottom-gray-lighter pt3 pb2_75 sm:pb3_75 mb1_5 md:col-11">
        <BlockContent
          className="text-center"
          blocks={module.text}
          serializers={{
            listItem: ListItem,
            marks: { link: ExternalLink, internalLink: InternalLink },
          }}
        />
      </ArticleHighlightedTextModulePortableText>
    </ScrollableAnchorWrapper>
  );
};

export default HighlightedTextModule;
