import React, { FC } from 'react';
import { Svg } from 'styled/components/base';

type Props = {
  backgroundColor?: string;
  className?: string;
  color?: string;
};

const NoVideoIcon: FC<Props> = ({
  className = '',
  color = '#E29727',
  backgroundColor = '#F7F7F7',
}) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <circle cx="12" cy="12" r="12" fill={backgroundColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9217 17.6008C18.1956 17.8356 18.608 17.8039 18.8427 17.53C19.0775 17.256 19.0458 16.8437 18.7719 16.6089L6.57835 6.15727C6.30444 5.92249 5.89205 5.95421 5.65727 6.22812C5.42249 6.50204 5.45421 6.91442 5.72812 7.1492L7.46949 8.6418C7.43265 8.67064 7.39739 8.70181 7.36397 8.73524C7.14618 8.95302 7.02383 9.2484 7.02383 9.55639V15.3628C7.02383 15.6708 7.14618 15.9662 7.36397 16.184C7.58175 16.4018 7.87713 16.5241 8.18512 16.5241H15.1529C15.4608 16.5241 15.7562 16.4018 15.974 16.184C16.0196 16.1384 16.061 16.0894 16.098 16.0376L17.9217 17.6008ZM18.6367 9.55639V14.8831L11.0674 8.3951H15.1529C15.4608 8.3951 15.7562 8.51745 15.974 8.73524C16.1918 8.95302 16.3141 9.2484 16.3141 9.55639V11.4917L18.6367 9.55639Z"
      fill={color}
    />
  </Svg>
);

export default NoVideoIcon;
