import React, { useState, useEffect, useRef } from 'react';
import { AnimationItem } from 'lottie-web/build/player/lottie_light';
import { Lottie as ILottie } from 'types';

type Props = {
  lottie: ILottie | null;
  loop?: boolean;
  autoplay?: boolean;
  playingState?: 'PLAY' | 'STOP';
};

const Lottie = (props: Props) => {
  const { lottie, loop = true, autoplay = true, playingState } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<AnimationItem | null>(null);

  useEffect(() => {
    const runAsync = async () => {
      if (lottie && containerRef && containerRef.current) {
        const LottiePlayer = (
          await import('lottie-web/build/player/lottie_light')
        ).default;
        setAnimation(
          LottiePlayer.loadAnimation({
            container: containerRef.current,
            path: lottie.url,
            renderer: 'svg',
            loop,
            autoplay,
          }),
        );
      }
    };
    runAsync();
  }, []);

  useEffect(() => {
    if (!animation || !playingState) return;

    if (playingState === 'PLAY') {
      animation.play();
    } else if (playingState === 'STOP') {
      animation.stop();
    }
  }, [playingState, animation]);

  if (!lottie) return null;

  return (
    <div
      id={lottie.id}
      ref={containerRef}
      role={!lottie.alt ? 'presentation' : undefined}
      aria-label={lottie.alt || undefined}
    />
  );
};

export default Lottie;
