import React, { FC } from 'react';

import BlockContent from '@sanity/block-content-to-react';
import cx from 'classnames';

import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';
import { Blue, ListItem } from 'constants/PortableTextSerializer';

import { QuoteModule as IQuoteModule } from 'types';
import { FontSizes } from 'styled/theme/typography';
import { useTheme } from 'styled-components';

interface Props {
  module: IQuoteModule;
  applyArticleBodyWidthOffset?: boolean;
}

const QuoteModule: FC<Props> = ({ module, applyArticleBodyWidthOffset }) => {
  const { palette } = useTheme();
  const { quote, quoteDesktopFontSize, attribution, moduleOptions } = module;
  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color as GeneralModuleColor];

  return (
    <div
      className={cx(
        'QuoteModule module-content-padding-x py3 flex flex-col items-center',
        {
          'border-top-gray-lighter': moduleOptions.showBorderTop,
          'border-bottom-gray-lighter': moduleOptions.showBorderBottom,
          'article-inner-text-full-width': applyArticleBodyWidthOffset,
        }
      )}
      style={{
        backgroundColor: colorScheme.background,
      }}
    >
      <div className="QuoteModule__container">
        <div
          className={cx('QuoteModule__quote text-lg text-center', {
            'md-up_text-xl': quoteDesktopFontSize === FontSizes.xl,
            'color-gray-100': colorScheme.text === palette.text.primary,
            'color-white': colorScheme.text === palette.common.white,
          })}
        >
          <BlockContent
            blocks={quote}
            serializers={{ marks: { blue: Blue }, listItem: ListItem }} // TODO fix me?
          />
        </div>
        {attribution && (
          <div className="mt3 text-center">
            <span
              className={cx('color text-md', {
                'color-gray-60': colorScheme.text === palette.common.black,
                'color-white': colorScheme.text === palette.common.white,
              })}
            >
              {attribution}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuoteModule;
