import styled from 'styled-components';
import { Base, Theme, Button } from 'styled';
import { Container as FractionBarContainer } from 'styled/components/FractionBar/style';

type DrugPageSectionProps = {
  bottomBorderColor?: 'gray-10' | 'teal-50';
};

export const Body = styled(Base)`
  ${({ theme }) => theme.typography.variants.secondary.sm};
`;

export const DisclaimerLabel = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.xs};
`;

export const DisclaimerBody = styled(Base)`
  ${({ theme }) => theme.typography.variants.secondary.xs};
`;

export const DrugPageContentPadding = styled(Base)`
  padding-left: ${({ theme }) => theme.sizes.drugPageContentPaddingMobile};
  padding-right: ${({ theme }) => theme.sizes.drugPageContentPaddingMobile};

  ${Theme.mediaQueries.sm} {
    padding-left: ${({ theme }) => theme.sizes.drugPageContentPaddingTablet};
    padding-right: ${({ theme }) => theme.sizes.drugPageContentPaddingTablet};
  }
`;

export const DrugPageSection = styled(Base)<DrugPageSectionProps>`
  ${({ theme }) => theme.global.drugPageInnerMaxWidth};
  color: ${({ theme }) => theme.palette.text.primary};
  border-bottom: ${({ theme, bottomBorderColor }) =>
    bottomBorderColor ? theme.borders[bottomBorderColor] : undefined};
`;

export const LargeHeading = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.md};
  margin-top: 3rem;
  margin-bottom: 1.5rem;

  ${Theme.mediaQueries.md} {
    ${({ theme }) => theme.typography.variants.primary.lg};
  }
`;

export const MediumHeading = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.md};
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const SmallHeading = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.sm};
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

export const DrugPageButton = styled(Button).attrs({
  variant: 'primary-lg',
  containerClassName: 'w100 sm:wauto',
  wrap: true,
})`
  padding: 1rem 1.5rem;
  width: 100%;
  text-align: center;

  ${Theme.mediaQueries.sm} {
    width: auto;
  }
`;

export const SideEffectsDataContainer = styled(Base)`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  ${Theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;

    ${FractionBarContainer} {
      width: calc((100% - 4.5rem) / 2);

      :nth-child(odd) {
        margin-right: 4.5rem;
      }
    }
  }
`;
