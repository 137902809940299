/*
 * Ensures adding a period at end of aria label
 * only if it doesn't end with a period or question mark
 */

const formatAriaLabelWithTitle = (ariaLabel: string): string => {
  const stringWithoutWhiteSpace = ariaLabel.trim();
  const lastChar = stringWithoutWhiteSpace.charAt(
    stringWithoutWhiteSpace.length - 1
  );

  if (['?', '.'].includes(lastChar)) {
    return stringWithoutWhiteSpace;
  }

  return `${stringWithoutWhiteSpace}.`;
};

export default formatAriaLabelWithTitle;
