import styled from 'styled-components';
import { Base, Theme, BasePortableText } from 'styled';

type ImgContainerProps = {
  bgColor: string;
};

export const Container = styled(Base)`
  margin: 0 auto;
  padding: 1rem 1rem 2.5rem 1rem;
  display: grid;
  row-gap: 2.5rem;
  column-gap: 1.5rem;
  grid-template-columns: 1fr;

  ${Theme.mediaQueries.sm} {
    padding: 2.5rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }

  ${Theme.mediaQueries.lg} {
    padding: 2.5rem 0;
    max-width: 75rem;
  }
`;

export const Card = styled(Base)`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${Theme.mediaQueries.lg} {
    flex-direction: row;
  }
`;

export const ImgContainer = styled(Base)<ImgContainerProps>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.sizes.cardThumbnailImageHeight};
  margin-bottom: 1.5rem;

  * {
    height: ${({ theme }) => theme.sizes.cardThumbnailImageHeight};
  }

  img {
    margin: 0 auto;
  }

  ${Theme.mediaQueries.lg} {
    width: ${({ theme }) => theme.sizes.cardThumbnailImageWidthDesktop};
    margin-bottom: 0rem;
  }
`;

export const TextContainer = styled(Base)`
  margin: 0 1rem;

  ${Theme.mediaQueries.lg} {
    flex: 1;
  }
`;

export const TitleContainer = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.sm};
  display: inline-flex;
  margin-bottom: 0.5rem;
`;

export const DescriptionContainer = styled(BasePortableText)`
  ${({ theme }) => theme.typography.variants.primary.xs};
`;
