import styled from 'styled-components';
import { Base, Theme, BasePortableText } from 'styled';
import { Color } from 'styled/theme/colors';

type QuoteContainerProps = {
  hasDescription: boolean;
};

type QuoteProps = {
  bgColor: Color;
  textColor: Color;
};

export const QuoteContainer = styled(Base)<QuoteContainerProps>`
  display: ${({ hasDescription }) => (hasDescription ? 'block' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

export const Quote = styled(Base)<QuoteProps>`
  color: ${({ bgColor, textColor, theme }) =>
    bgColor === theme.palette.common.white
      ? theme.palette.primary.main
      : textColor};
  ${({ theme }) => theme.typography.variants.secondary.md};

  span {
    position: relative;

    :before {
      content: '“';
      position: absolute;
      left: -0.8rem;

      ${Theme.mediaQueries.md} {
        left: -1.25rem;
      }
    }

    :after {
      content: '”';
      position: absolute;
      right: -0.8rem;

      ${Theme.mediaQueries.md} {
        right: -1.25rem;
      }
    }
  }
`;

export const Attribution = styled(BasePortableText)`
  ${({ theme }) => theme.typography.variants.primary.sm};
  margin-top: 1.5rem;
`;
