// https://usehooks-typescript.com/react-hook/use-intersection-observer

import { RefObject, useEffect, useState } from 'react';

// IntersectionObserverInit are from the native IntersectionObserver API
export interface Args extends IntersectionObserverInit {
  // Only catch the first appearance
  freezeOnceVisible?: boolean;
}

function useIntersectionObserver(
  elementRef: RefObject<Element>,
  {
    /*
     * Threshold can be any ratio from 0 to 1
     * 1 = the element is 100% in the viewport
     * 0 = the element is 100% out of the viewport
     */
    threshold = 0,
    /*
     * Root container is used as the viewport when calculating the intersection ratio.
     * It must be an ancestor to the element being observed.
     * Defaults to null = watching for intersection relative to the root element
     */
    root = null,
    /*
     * Using the CSS margin syntax, rootMargin specifies an invisible box around
     * the root by which the threshold is calculated
     */
    rootMargin = '0%',
    freezeOnceVisible = false,
  }: Args
): IntersectionObserverEntry | undefined {
  // An entry contains data about an intersection between the observed target element and the root container
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM ref of the element we are observing
    const hasIOSupport =
      typeof window !== 'undefined' && !!window.IntersectionObserver;

    /*
     * Only construct the IntersectionObserver if:
     * there is IO Support, observer is not frozen, and there is a node to be observed
     */
    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };

    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    // Clean up the useEffect when it is no longer needed
    return () => observer.disconnect();

    // Re-run the callback to create new IO if any of these dependencies are updated
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
}

export default useIntersectionObserver;
