import React, { FC } from 'react';
import cx from 'classnames';
import { useTheme } from 'styled-components';

import { Button, LinkOrButton } from 'styled';

import {
  EmphasizeModuleColor,
  EmphasizeModuleColorScheme,
} from 'constants/ColorScheme';
import Arrows from 'constants/Arrows';

import { SingleBlockCtaModule as ISingleBlockCtaModule } from 'types';

interface Props {
  module: ISingleBlockCtaModule;
  hasWrapper?: boolean;
  wrapperColor?: EmphasizeModuleColor;
  applyArticleBodyWidthOffset?: boolean;
}

const SingleBlockCtaModule: FC<Props> = ({
  module,
  hasWrapper,
  wrapperColor,
  applyArticleBodyWidthOffset,
}) => {
  const { palette } = useTheme();
  const {
    text,
    link,
    slug,
    variant,
    size,
    moduleOptions,
    gtmTrackerEventName,
  } = module;

  const wrapperColorScheme = wrapperColor
    ? EmphasizeModuleColorScheme[wrapperColor]
    : null;
  const colorScheme =
    EmphasizeModuleColorScheme[moduleOptions.color as EmphasizeModuleColor];

  const textColor = hasWrapper ? wrapperColorScheme?.text : colorScheme.text;

  const moduleInner = (
    <div className="SingleBlockCtaModule__inner">
      <div
        className={cx(
          'SingleBlockCtaModule__text-container text-md relative flex items-center justify-center text-center',
          {
            'py6 md-up_text-xl': size === 'large',
            'py3 md-up_text-lg': size === 'medium',
            'px1_5 md:px3':
              variant === 'with-arrow' || variant === 'underlined',
          },
        )}
      >
        <p>
          {/* This has to be span so underlined variant will wrap */}
          <span
            className={cx('SingleBlockCtaModule__text', {
              [`link-border-bottom-${
                textColor === palette.text.primary ? 'black' : 'white'
              }-hidden`]: variant === 'underlined',
            })}
            style={{
              color: textColor,
            }}
          >
            {text}
          </span>
        </p>
        {variant === 'with-arrow' && (
          <span
            className="SingleBlockCtaModule__arrow ml_75 transition"
            style={{
              color: textColor,
            }}
          >
            {Arrows.RIGHT_ARROW}
          </span>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={cx(`SingleBlockCtaModule SingleBlockCtaModule--${variant}`, {
        'article-inner-text-full-width': applyArticleBodyWidthOffset,
      })}
      style={{
        backgroundColor:
          hasWrapper && wrapperColorScheme
            ? wrapperColorScheme.background
            : colorScheme.background,
      }}
    >
      {!!slug && (
        <LinkOrButton
          ariaLabel={text}
          to={slug}
          containerClassName="flex justify-center"
          gtmTrackerEventName={gtmTrackerEventName}
        >
          {moduleInner}
        </LinkOrButton>
      )}
      {!!link && !slug && (
        <Button
          ariaLabel={text}
          to={link}
          containerClassName="flex justify-center"
          gtmTrackerEventName={gtmTrackerEventName}
        >
          {moduleInner}
        </Button>
      )}
    </div>
  );
};

export default SingleBlockCtaModule;
