import React, { Component, RefObject, createRef } from 'react';
import cx from 'classnames';
import theme from '@buoyhealth/common.buoy-theme';

import getDefaultArticleUrl from 'utils/getDefaultArticleUrl';

import { ModuleHeader } from 'components/modules';
import { ImageCard, Pagination, ArticleCardTile } from 'styled';

import {
  EmphasizeModuleColor,
  EmphasizeModuleColorScheme,
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';
import { RouteMap } from 'constants/Routes';

import {
  ThreeColumnInfoModule as IThreeColumnInfoModule,
  ThreeColumnInfoModuleContent,
  ThreeColumnModuleArticleLink,
  ThreeColumnModuleBlogPostLink,
} from 'types';
import { getMedianDescriptionsLength } from 'utils/getMedianDescriptionsLength';

interface Props {
  module: IThreeColumnInfoModule;
  hasWrapper?: boolean;
  wrapperColor?: EmphasizeModuleColor;
}

interface State {
  activeTab: string;
  prevActiveTab: string;
  paginationInfo: PaginationInfo;
}

interface PaginationInfo {
  [tab: string]: {
    currentPaginationNumber: number;
    totalPaginationCount: number;
    currentPageContents: ThreeColumnInfoModuleContent[];
  };
}

class ThreeColumnInfoModule extends Component<Props, State> {
  containerRef: RefObject<HTMLDivElement> = createRef();

  constructor(props: Props) {
    super(props);

    const {
      module: { tabs },
    } = props;

    const selectedTabs = tabs.length > 0 ? tabs : ['default'];
    const paginationInfo = selectedTabs.reduce(
      (sanitizedPaginationInfo: PaginationInfo, tab: string) => {
        sanitizedPaginationInfo[tab] = {
          currentPaginationNumber: 1,
          totalPaginationCount: Math.ceil(
            props.module.contents[tab].length / props.module.maxItemsPerPage,
          ),
          currentPageContents: this.getCurrentPageStories(tab, 1),
        };

        return sanitizedPaginationInfo;
      },
      {},
    );

    this.state = {
      activeTab: selectedTabs[0],
      prevActiveTab: '',
      paginationInfo,
    };
  }

  handleTabClick = (clickedTab: string) => {
    this.setState((state) => ({
      activeTab: clickedTab,
      prevActiveTab: state.activeTab,
    }));
  };

  getCurrentPageStories = (
    tab: string,
    currentPaginationNumber: number,
  ): ThreeColumnInfoModuleContent[] => {
    const {
      module: { maxItemsPerPage, contents },
    } = this.props;

    return contents[tab].slice(
      currentPaginationNumber * maxItemsPerPage - maxItemsPerPage,
      currentPaginationNumber * maxItemsPerPage,
    );
  };

  handlePageChange = (tab: string, pageNumber: number) => {
    this.setState((state: State) => ({
      paginationInfo: {
        ...state.paginationInfo,
        [tab]: {
          ...state.paginationInfo[tab],
          currentPaginationNumber: pageNumber + 1,
          currentPageContents: this.getCurrentPageStories(tab, pageNumber + 1),
        },
      },
    }));

    if (this.containerRef && this.containerRef.current) {
      // Scroll to top of container
      window.scrollTo({
        // Theme.sizes.topBarHeightTablet = 5.25rem = 84px
        top:
          window.pageYOffset +
          this.containerRef.current.getBoundingClientRect().top -
          84,
        behavior: 'smooth',
      });
    }
  };

  render() {
    const {
      hasWrapper,
      wrapperColor,
      module,
      module: {
        anchor,
        button,
        contentAlign,
        contents,
        isTwoColumnOnMobile,
        moduleHeader,
        numberOptions,
        tabs,
        maxItemsPerPage,
      },
    } = this.props;

    if (!Object.keys(contents).length) return null;

    const { paginationInfo } = this.state;
    const { activeTab, prevActiveTab } = this.state;
    const contentKeys = tabs.length > 0 ? tabs : ['default'];
    const headerTitle = moduleHeader.title;
    const headerDescription = moduleHeader.description;
    const headerTabs = module.tabs;
    const contentAlignIsCenter = contentAlign === 'center';
    const wrapperColorScheme =
      hasWrapper && wrapperColor
        ? EmphasizeModuleColorScheme[wrapperColor]
        : null;
    const colorScheme =
      GeneralModuleColorScheme[
        module.moduleOptions.color as GeneralModuleColor
      ];
    const renderModuleHeader =
      headerTitle || headerDescription || !!headerTabs.length || button.label;

    const allArticleDescriptions: string[] = [];

    function isArticleOrBlogLink(
      item: ThreeColumnInfoModuleContent | null,
    ): item is ThreeColumnModuleArticleLink | ThreeColumnModuleBlogPostLink {
      return (
        item !== null && (item.type === 'article' || item.type === 'blogPost')
      );
    }

    contentKeys.forEach((key) => {
      const currentPageContents = paginationInfo[key].currentPageContents;
      const articlesAndBlogs = currentPageContents.filter(isArticleOrBlogLink);

      const descriptions = articlesAndBlogs.map((item) => {
        if (item.type === 'article') {
          return item.article?.moduleDescription || '';
        } else if (item.type === 'blogPost') {
          return item.description || '';
        }
        return '';
      });

      allArticleDescriptions.push(...descriptions);
    });

    const medianArticleDescriptionsLength = getMedianDescriptionsLength(
      allArticleDescriptions,
    );

    return (
      <div
        id={anchor ? anchor : undefined}
        data-testid="three-col-info-module"
        className={cx('ThreeColumnInfoModule pb2_5', {
          pt6: !renderModuleHeader,
        })}
        ref={this.containerRef}
        style={{
          backgroundColor: !wrapperColorScheme
            ? colorScheme.background
            : undefined,
          color: !wrapperColorScheme ? colorScheme.text : undefined,
        }}
      >
        <div className="ThreeColumnInfoModule__inner">
          {renderModuleHeader && (
            <ModuleHeader
              textIsCentered={contentAlignIsCenter}
              title={headerTitle}
              description={headerDescription}
              tabs={headerTabs}
              activeTab={activeTab}
              onTabClick={this.handleTabClick}
              buttonLabel={button.label}
              buttonUrl={button.url}
              buttonVariant={button.variant}
            />
          )}
          <div className="pb2 md:pb3_5 relative">
            {contentKeys.map((key) => (
              <div
                className={cx('transition-short', {
                  't0 l0 w100 absolute': key !== activeTab,
                  'events-none hidden opacity-0':
                    key !== activeTab && prevActiveTab,
                  'transition-fade-out-short events-none':
                    key !== activeTab && !prevActiveTab,
                })}
                aria-hidden={key !== activeTab}
                key={key}
              >
                <div
                  className={cx(
                    'ThreeColumnInfoModule__info-container transition-short flex flex-col flex-wrap sm:flex-row',
                    {
                      'ThreeColumnInfoModule__info-container--two-column':
                        isTwoColumnOnMobile,
                      'ThreeColumnInfoModule__info-container--manual-content':
                        paginationInfo[key].currentPageContents[0]?.type ===
                        'manualContent',
                    },
                  )}
                  key={key}
                >
                  {paginationInfo[key].currentPageContents.map(
                    (item, i, contents) => {
                      if (!item) return null;

                      if (item.type === 'article') {
                        const { id, article } = item;

                        return (
                          <ArticleCardTile
                            key={id}
                            title={article.moduleTitle || article.title}
                            image={
                              article.moduleImage.id
                                ? article.moduleImage
                                : article.heroImage
                            }
                            imageBgColor={article.heroColor}
                            description={article.moduleDescription}
                            url={getDefaultArticleUrl({
                              slug: article.slug,
                              language: article.language,
                              articleType: article.articleType,
                            })}
                            numberOfCardTilesAtMediumBreakpoint={3}
                            medianDescriptionsLength={
                              medianArticleDescriptionsLength
                            }
                          />
                        );
                      }

                      if (item.type === 'blogPost') {
                        const {
                          id,
                          blogPost,
                          description,
                          image,
                          imageBackgroundColor,
                        } = item;

                        return (
                          <ArticleCardTile
                            key={id}
                            title={blogPost.title}
                            image={image}
                            imageBgColor={imageBackgroundColor}
                            description={description}
                            url={`${RouteMap.BLOG.base}${blogPost.slug}`}
                            numberOfCardTilesAtMediumBreakpoint={3}
                            medianDescriptionsLength={
                              medianArticleDescriptionsLength
                            }
                          />
                        );
                      }

                      return (
                        <ImageCard
                          key={item.id}
                          title={item.title}
                          description={item.description}
                          image={item.image}
                          buttonLabel={item.button.label}
                          buttonAriaLabel={item.button.ariaLabel}
                          buttonUrl={item.button.url}
                          url={item.url}
                          ariaLabel={item.ariaLabel}
                          contentAlignIsCenter={contentAlignIsCenter}
                          numberOptions={{
                            ...numberOptions,
                            number: i + 1,
                          }}
                          totalNumberOfContents={contents.length}
                          content={item.content}
                        />
                      );
                    },
                  )}
                </div>
                {contents[key].length > maxItemsPerPage && (
                  <div className="w100 mt3 flex justify-center">
                    <Pagination
                      pageCount={paginationInfo[key].totalPaginationCount}
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={0}
                      onPageChange={(currentPage: { selected: number }) =>
                        this.handlePageChange(key, currentPage.selected)
                      }
                      activeLinkBgColor={theme.palette.blue[80]}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ThreeColumnInfoModule;
