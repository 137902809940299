import React from 'react';
import { ISupplierCtaModule } from 'styled/components/modules/SupplierCtaModule/types';
import {
  Header,
  ProgressIndicator,
  ProgressIndicators,
} from '../SupplierPromoModal.style';
import { SupplierCTAImage } from '../../modules/SupplierCtaModule/SupplierCtaImage';

interface IImageHeaderProps {
  page: number;
  config: ISupplierCtaModule['modalConfig'];
}

const ImageHeader = ({ page, config }: IImageHeaderProps) => (
  <Header>
    <SupplierCTAImage image={config.image} />
    {config.pages && config.pages?.length > 1 && (
      <ProgressIndicators>
        {[...Array(config.pages?.length).keys()].map((i) => (
          <ProgressIndicator key={i} $active={page === i} />
        ))}
      </ProgressIndicators>
    )}
  </Header>
);

export default ImageHeader;
