import React from 'react';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import useBreakpointIsMobile from 'hooks/useBreakpointIsMobile';
import SwipeableMobileDrawer from 'styled/components/SwipeableMobileDrawer';
import { CloseIconThin } from '@buoyhealth/common.buoy-icons';

const drawerWidth = 390;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 0 0;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const IframeContainer = styled.div`
  padding-top: 3.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledSwipeableMobileDrawer = styled(SwipeableMobileDrawer)`
  svg {
    fill: ${({ theme }) => theme.palette.gray[0]};
  }

  .SwipeableDrawer__Puller {
    background-color: ${({ theme }) => theme.palette.gray[0]};
  }

  .SwipeableDrawer__Nav {
    padding-bottom: 0;
  }
`;

interface ISupplierDrawerProps {
  open: boolean;
  handleDrawerClose: () => void;
  iframeUrl: string;
}

const noop = () => {};

const SupplierDrawer = ({
  open,
  handleDrawerClose,
  iframeUrl,
}: ISupplierDrawerProps) => {
  const isMobile = useBreakpointIsMobile();

  if (isMobile) {
    return (
      <StyledSwipeableMobileDrawer
        open={open}
        onClose={handleDrawerClose}
        onOpen={noop}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            // This seems excessive but it's necessary to make the drawer always appear above ads
            zIndex: 10000000000,
            minHeight: '90%',
          },
        }}
      >
        <IframeContainer>
          <iframe src={iframeUrl} width="100%" style={{ flexGrow: 1 }} />
        </IframeContainer>
      </StyledSwipeableMobileDrawer>
    );
  }
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
      elevation={2}
      PaperProps={{
        elevation: 1,
      }}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <CloseIconThin size={24} color="white" />
        </IconButton>
      </DrawerHeader>
      <iframe src={iframeUrl} width="100%" height="100%" />
    </Drawer>
  );
};

export default SupplierDrawer;
