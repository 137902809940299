import React from 'react';
import { Container, ModuleTitle } from './style';
import { DxListModule as IDxListModule } from 'types';
import CtaList from './components/CtaList';
import { DxListFeatureFlag } from './constants';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useLanguage from 'hooks/useLanguage';

type Props = {
  module: IDxListModule;
};

const DxListModule = ({ module }: Props) => {
  const { moduleTitle, items } = module;
  const featureFlags = useFeatureFlags();
  const Language = useLanguage();

  if (!items.length || !featureFlags[DxListFeatureFlag.SHOW_DX_LIST]) {
    return null;
  }

  const title =
    moduleTitle || `${items.length} ${Language.t('DxListModule.title')}`;

  return (
    <Container data-testid="dx-list-module" id="common-causes-list">
      <ModuleTitle>{title}</ModuleTitle>
      <CtaList items={items} />
    </Container>
  );
};

export default DxListModule;
