import { refreshLinkClickTracking } from '@snowplow/browser-plugin-link-click-tracking';
import { PrimaryButton } from '@buoyhealth/common.buoy-button';
import Card from '@mui/material/Card';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import BlockContent from '@sanity/block-content-to-react';
import {
  ExternalLink,
  InternalLink,
  ListItem,
  SansSerif,
} from 'constants/PortableTextSerializer';
import React, { FC, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Img from 'styled/components/Img';
import { Base } from 'styled/components/base';
import { ArticlePortableText } from 'styled/components/portableText';

import { IFeaturedLinkModule } from 'types';
import sanityImgUtil from 'utils/sanityImgUtil';
import { trackModuleView, trackOutboundClick } from './analytics';
import { withImpressionTracking } from 'analytics';
import { BetaChip } from '@buoyhealth/common.buoy-chip';

interface Props {
  module: IFeaturedLinkModule;
  /** Index of this module amongst all the featured link modules on the page */
  moduleIndex: number;
}

const Container = styled(Card)`
  border: 4px solid ${({ theme }) => theme.palette.primary.main};
  padding: 2rem 1.5rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  margin-top: 1rem;

  h3 {
    margin-top: 0;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-basis: 70%;
    margin-top: 0;
  }
`;

const ImageContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    max-width: 100%;
    max-height: 180px;
  }

  padding: 0 0 1rem;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 2rem 0 0;
    flex-basis: 30%;
  }
`;

const BlockContentContainer = styled.div`
  > div > *:first-child {
    margin-top: 0;
  }
`;

const FeaturedLinkModule: FC<Props> = ({ module, moduleIndex }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      refreshLinkClickTracking();
    }
  }, []);

  const TrackingContainer: React.FC<{ children: React.ReactNode }> = useMemo(
    () =>
      withImpressionTracking('div', () => {
        trackModuleView(moduleIndex + 1, module.buttonUrl);
      }),
    [module.buttonUrl, moduleIndex],
  );

  const handleClick = () => {
    trackOutboundClick(module.buttonUrl);
  };

  const showDescription = !module.productSummary && !module.productName;

  return (
    <div className="disabled-ads w100 my2_5 px1 md:px0">
      <TrackingContainer>
        {module.showTopDivider && (
          <hr className="border-gray-100 text-gray-100" />
        )}
        <ArticlePortableText>
          {module.moduleHeader && (
            <h3 className="!my-6">{module.moduleHeader}</h3>
          )}
        </ArticlePortableText>
        <Container
          elevation={0}
          className={`relative ${module.editorsChoice ? '!pt-14' : ''}`}
        >
          {module.editorsChoice && (
            <BetaChip
              label="Editor's Choice"
              className="absolute left-3 top-3 !bg-blue-60 !text-xs !font-normal"
            />
          )}
          <ImageContainer
            onClick={handleClick}
            href={module.buttonUrl}
            target="_blank"
          >
            <Base
              as={Img}
              alt={module.image.alt}
              src={sanityImgUtil(module.image, 300)}
            />
          </ImageContainer>
          <TextContainer>
            <ArticlePortableText>
              <BlockContentContainer>
                {showDescription ? (
                  <BlockContent
                    blocks={module.description}
                    serializers={{
                      listItem: ListItem,
                      marks: {
                        link: ExternalLink,
                        internalLink: InternalLink,
                        sansSerif: SansSerif,
                      },
                    }}
                  />
                ) : (
                  <>
                    <h4 className="!mb-2 !mt-0">{module.productName}</h4>
                    <p>{module.productSummary}</p>
                  </>
                )}
              </BlockContentContainer>
            </ArticlePortableText>
            <PrimaryButton
              href={module.buttonUrl}
              onClick={handleClick}
              sx={{ marginTop: '1rem', width: { xs: '100%', md: 'auto' } }}
              target="_blank"
              withBuoyTheme
            >
              <span className="flex items-center">
                {module.buttonText}
                <OpenInNewIcon
                  color="inherit"
                  fontSize="small"
                  className="ml-2"
                />
              </span>
            </PrimaryButton>
          </TextContainer>
        </Container>
      </TrackingContainer>
    </div>
  );
};

export default FeaturedLinkModule;
