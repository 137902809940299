import styled from 'styled-components';
import { Base } from 'styled/components/base';
import Theme from 'styled/theme';

export const Container = styled(Base)`
  max-width: 100%;
  margin: 1rem 0rem;

  ${Theme.mediaQueries.lg} {
    margin: 3rem 0rem;
  }
`;

export const ModuleTitle = styled.h2`
  ${({ theme }) => theme.typography.variants.primary.lg};
  margin: 3rem 0 1.5rem;
  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 4rem;
  }
`;

export const ItemTitleContainer = styled(Base)`
  display: inline;
  flex: 1;
`;

export const ItemTitle = styled.span`
  ${({ theme }) => theme.typography.variants.primary.sm};
`;
