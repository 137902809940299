import styled from 'styled-components';
import { Button, ArticlePortableText } from 'styled';

type ShowDataToggleProps = {
  showData: boolean;
};

type DataContainerProps = {
  showData: boolean;
};

export const ShowDataToggle = styled(Button)<ShowDataToggleProps>`
  margin-bottom: ${({ showData }) => showData && '1rem'};
`;

export const DataContainer = styled(ArticlePortableText)<DataContainerProps>`
  color: initial;
  ${({ theme }) => theme.transitions['transition-short']};
  ${({ showData, theme }) =>
    showData ? theme.animations.openShort : theme.animations.closeShort};
  opacity: ${({ showData }) => (showData ? 1 : 0)};

  p:last-child {
    padding-bottom: 0;
  }
`;
