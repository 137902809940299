import React, { FC } from 'react';

import { ArticlePortableText } from 'styled';
import ArticleBodySwitch from 'components/ArticleBodyModuleSwitch';
import ArticleGenericPageNav from 'components/ArticleGenericPageNav';

import { PageMainContentId } from 'constants/Global';

import { ArticleGenericPage as IArticleGenericPage } from 'types';
import { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
import CookieDeclaration from 'styled/components/CookieDeclaration';

interface Props {
  page: IArticleGenericPage;
}

const ArticleGenericPage: FC<Props> = ({ page }) => {
  const { palette } = useTheme();
  const { asPath } = useRouter();

  return (
    <div className="ArticleGenericPage sm:flex">
      <ArticleGenericPageNav article={page} />
      <div
        id={PageMainContentId}
        className={`relative ${page.disableAds ? 'disabled-ads' : ''}`}
      >
        <ArticlePortableText
          textColor={palette.text.primary}
          className="ArticleGenericPage__content mt4 md:mt6 mb1_5 px1_5"
        >
          <ArticleBodySwitch modules={page.bodyModules} />
          {asPath.includes('/cookies') && <CookieDeclaration />}
        </ArticlePortableText>
      </div>
    </div>
  );
};

export default ArticleGenericPage;
