import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import Divider from '@mui/material/Divider';
import { TextInput } from '@buoyhealth/common.buoy-input';
import { Base } from '../base';
import { Widget as TypeformWidget } from '@typeform/embed-react';

export const MobileDivider = styled.div<{ top?: boolean }>`
  height: 8px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.gray[0]};
  border-top: ${({ theme }) => `1px solid ${theme.palette.gray[10]}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.gray[10]}`};

  // Break out of parent padding
  left: 50%;
  margin: 0 -50vw;
  max-width: 100vw;
  position: relative;
  right: 50%;

  ${({ theme }) => theme.mediaQueries.md} {
    display: none;
  }

  &:after {
    content: '';
    border-bottom: ${({ theme, top }) =>
      top && `5px solid ${theme.palette.primary.main}`};
    left: 0;
    right: 0;
    position: absolute;
    top: 7px;
  }
`;

export const StyledAccordion = styled(Accordion)`
  background-color: ${({ theme }) => theme.palette.gray[0]};
  border-radius: 8px;

  &:first-of-type,
  &:last-of-type {
    border-radius: 8px;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    .MuiAccordionSummary-root {
      padding-right: 1.5rem;
      padding-left: 2rem;
    }
  }

  .MuiAccordionSummary-content {
    margin: 1.5rem 0.5rem 1.5rem 0;
    ${(props) => props.theme.typography.variants.primary.sm};
  }

  .BuoyUserFeedback__HelpfulQuestion {
    .MuiTypography-body1 {
      ${(props) => props.theme.typography.variants.primary['xs']};
    }
  }
`;

export const AnswerContainer = styled.div`
  padding: 1rem;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-right: 1.5rem;
    padding-left: 2rem;
  }
`;

export const AccordionWrapper = styled(Base)`
  letter-spacing: normal;

  p {
    ${({ theme }) => theme.typography.variants.secondary.sm};
  }

  &:not(:first-of-type) {
    margin-top: 0.5rem;

    ${({ theme }) => theme.mediaQueries.md} {
      margin-top: 1rem;
    }
  }
`;

export const StyledTextInput = styled(TextInput)`
  .MuiInputBase-root {
    padding: 0 14px 0 0;
    border-radius: 8px;
    letter-spacing: normal;
    background-color: ${({ theme }) => theme.palette.common.white};

    &.Mui-focused {
      box-shadow: ${({ theme }) =>
        `0 0 10px 0 ${theme.palette.primary.main}, 0 0 5px 0 ${theme.palette.primary.main}`};

      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.palette.blue[30]};
      }

      textarea {
        box-shadow: none;
      }
    }
  }

  input::placeholder {
    letter-spacing: normal;
  }

  .MuiIconButton-root {
    width: 38px;
  }

  textarea::placeholder {
    letter-spacing: normal;
  }
`;

export const Paragraph = styled.p`
  ${(props) => props.theme.typography.variants.secondary.sm};
`;

export const StyledH2 = styled.h2`
  margin-bottom: 1.5rem !important;
`;

export const BodyText = styled.p`
  ${(props) =>
    // there's a ts error here because this is using the bit buoy theme, not the one that lives in this repo
    // @ts-ignore
    props.theme.typography.variants.secondary.main};

  margin-top: 1rem;
`;

export const Header = styled.h3`
  padding-right: 30px;
  ${(props) =>
    // there's a ts error here because this is using the bit buoy theme, not the one that lives in this repo
    // @ts-ignore
    props.theme.typography.variants.primary.large};
`;

export const StyledTypeformWidget = styled(TypeformWidget)`
  width: 100%;
  height: 40rem;
  margin-top: 1.5rem;
`;

export const StyledDivider = styled(Divider)`
  margin: 8px 0 20px;
  border-color: ${({ theme }) => theme.palette.gray[40]};
`;

export const InterviewContainer = styled.div`
  margin-bottom: 16px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.palette.gray[20]};

  .MuiPaper-root {
    border-radius: 0;
    padding-left: 16px;
    padding-right: 16px;

    ${({ theme }) => theme.mediaQueries.md} {
      padding-right: 1.5rem;
      padding-left: 2rem;
    }
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;

    .MuiPaper-root {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  // Override styles applied from sanity content formatting
  p {
    ${({ theme }) => theme.typography.variants.primary['xs-sm']};
    letter-spacing: normal;

    strong,
    b {
      font-weight: bold;
    }
  }

  .BuoyInterviews__DifferentialCard {
    .MuiPaper-root {
      padding: 0;
    }
  }

  .BuoyInterviews__PeekDifferential {
    p {
      margin: 16px 0;
    }
    ol {
      li {
        list-style-type: decimal;
      }
      margin: 16px 0;
      padding-inline-start: 40px;
    }

    .BuoyUserFeedback__HelpfulQuestion {
      margin-bottom: 16px;
      width: 100%;
    }

    > div:last-of-type {
      max-width: none;
    }
  }

  .MuiDrawer-root p {
    ${({ theme }) => theme.typography.variants.secondary['xs-sm']};
  }

  .BuoyInterviews__TypewriterResults {
    p {
      margin-top: 10px;
      ${({ theme }) => theme.typography.variants.secondary.sm};
    }
  }
`;
