import React from 'react';
import { InternalLink } from 'constants/PortableTextSerializer';

import type { PortableTextMarkDefinition } from '@portabletext/types';

interface CtaModuleInternalLinkMarkDefinition
  extends PortableTextMarkDefinition {
  children?: string[];
  mark?: {
    link?: string;
    rel?: string;
    _key?: string;
    gtmTrackerEventName?: string;
    _type?: string;
  };
  markKey?: string;
}

const CtaModuleInternalLink = (props: CtaModuleInternalLinkMarkDefinition) => {
  return (
    <InternalLink
      {...(props as any)}
      polarisTrackingLocation="cta-module-link-click"
    />
  );
};

export default CtaModuleInternalLink;
