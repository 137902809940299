import React from 'react';
import { ItemContainer } from './style';
import { IDxListItem } from 'types';
import ItemImage from '../ItemImage';
import { ItemTitleContainer, ItemTitle } from '../../style';

type Props = {
  item: IDxListItem;
  imageBorderRadius?: string;
};

const ItemInnerVersionA = ({ item, imageBorderRadius = '50%' }: Props) => {
  return (
    <ItemContainer>
      <ItemImage item={item} borderRadius={imageBorderRadius} />
      <ItemTitleContainer>
        <ItemTitle>{item.title}</ItemTitle>
      </ItemTitleContainer>
    </ItemContainer>
  );
};

export default ItemInnerVersionA;
