import styled from 'styled-components';
import { Base } from 'styled/components/base';
import Link from 'styled/components/Link';
import Theme from 'styled/theme';
import Arrows from 'constants/Arrows';
import { ItemTitle } from '../../style';

export const ItemsContainer = styled(Base)`
  overflow-x: scroll;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  column-gap: 1.5rem;
`;

export const ItemContainer = styled(Base)`
  height: 100%;
  width: 70vw;
  padding: 1rem;
  display: flex;
  align-items: center;

  ${Theme.mediaQueries.sm} {
    width: 100%;
  }
`;

export const LinkedItemContainer = styled(Link)`
  ${ItemContainer} {
    ::after {
      ${({ theme }) => theme.typography.variants.primary.sm};
      color: ${({ theme }) => theme.palette.primary.main};
      content: '${Arrows.RIGHT_ARROW}';
    }
  }

  ${ItemTitle} {
    ${({ theme }) =>
      theme.hoverStyles.linkBorderBottom(theme.palette.text.primary)};
    white-space: normal;
  }
`;
