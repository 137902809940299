import React, { ElementType, useEffect, useRef, useState } from 'react';
import useRefInView from 'hooks/useRefInView';

/**
 * Decorates the wrapped component with a tracking callback. The tracking
 * callback will be fired once and only once when the component comes into view.
 */
function withImpressionTracking<WrappedComponentProps>(
  WrappedComponent: ElementType,
  trackingCallback: () => void,
) {
  const Component = (props: WrappedComponentProps) => {
    const [hasViewed, setHasViewed] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const isInViewport = useRefInView(containerRef);

    useEffect(() => {
      if (!hasViewed && isInViewport) {
        setHasViewed(true);
        trackingCallback();
      }
    }, [hasViewed, isInViewport]);

    return <WrappedComponent {...props} ref={containerRef} />;
  };

  Component.displayName = 'withImpressionTracking';

  return Component;
}

export default withImpressionTracking;
