import { css, styled } from '@mui/material/styles';
import theme from '@buoyhealth/common.buoy-theme';
import { Theme } from 'styled';

export const ProgressIndicators = styled('div')`
  margin-top: 1rem;

  ${Theme.mediaQueries.sm} {
    margin-top: 3rem;
  }
`;

export const Header = styled('div')`
  padding: 1.5rem;
  background-color: ${theme.palette.orange[0]};

  ${Theme.mediaQueries.sm} {
    padding: 2rem;
  }

  > div {
    display: flex;
    justify-content: center;
    svg,
    img {
      height: 233px;
      width: auto;

      ${Theme.mediaQueries.sm} {
        height: 180px;
        margin-top: 2rem;
      }
    }
  }
`;

export const Slides = styled('div')`
  display: grid;
  grid-template-columns: 1fr;

  > div {
    grid-row-start: 1;
    grid-column-start: 1;
  }
`;

export const ProgressIndicator = styled('span')<{ $active: boolean }>`
  background-color: ${theme.palette.orange[20]};
  height: 6px;
  width: 16px;
  margin: 0 4px;
  border-radius: 6px;
  transition: all 300ms ease;

  ${({ $active }) =>
    $active &&
    css`
      animation: grow;
      animation-direction: normal;
      animation-duration: 300ms;
      background-color: ${theme.palette.orange[40]};
      animation-fill-mode: forwards;
      width: 58px;
    `}

  @keyframes grow {
    0% {
      width: 16px;
    }
    75% {
      width: 60px;
    }
    100% {
      width: 58px;
    }
  }
`;
