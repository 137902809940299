import React, { FC } from 'react';
import BlockContent from '@sanity/block-content-to-react';

import { ItemContainer } from '../style';
import ItemDescription from '../ItemDescription';
import { QuoteContainer, Quote, Attribution } from './style';

import { Color } from 'styled/theme/colors';

import { QuoteItem as IQuoteItem } from 'types';

interface Props {
  item: IQuoteItem;
  bgColor: Color;
  textColor: Color;
}

const QuoteItem: FC<Props> = ({ item, bgColor, textColor }) => {
  return (
    <ItemContainer>
      <QuoteContainer
        hasDescription={!!item.description || !!item.button.label}
      >
        {item.quote && (
          <Quote bgColor={bgColor} textColor={textColor}>
            <span>{item.quote}</span>
          </Quote>
        )}
        {item.attribution && (
          <Attribution>
            <BlockContent blocks={item.attribution} />
          </Attribution>
        )}
      </QuoteContainer>
      <ItemDescription
        description={item.description}
        button={item.button}
        textColor={textColor}
        bgColor={bgColor}
      />
    </ItemContainer>
  );
};

export default QuoteItem;
