import styled from 'styled-components';
import { Base } from 'styled/components/base';
import Img from 'styled/components/Img';

interface ItemImageContainerProps {
  imageBgColor: string;
  borderRadius: string;
  marginRight: string;
}

export const ItemImageContainer = styled(Base)<ItemImageContainerProps>`
  background-color: ${({ imageBgColor }) => imageBgColor};
  height: 3.75rem;
  width: 3.75rem;
  border-radius: ${({ borderRadius }) => borderRadius};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ marginRight }) => marginRight};
`;

export const ItemImage = styled(Img)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
