import { trackStructuredEvent } from 'analytics';
import { fireOnce } from 'analytics/snowplow';

export const trackSupplierClick = (impressionId: string, url: string) =>
  trackStructuredEvent({
    action: 'supplier-card-click',
    category: 'supplier-cards',
    property: url,
    label: impressionId,
  });

export const trackSupplierViewed = (impressionId: string, url: string) => {
  const event = {
    action: 'supplier-card-viewed',
    category: 'supplier-cards',
    property: url,
    label: impressionId,
  };
  fireOnce(() => {
    trackStructuredEvent(event);
  }, `${event.action}-${event.category}-${event.property}-${event.label}`);
};
