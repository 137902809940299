import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Instafeed from 'instafeed.js';

import sanitizeInstagramFeedImageItem from 'state/sanitizers/sanitizeInstagramFeedImageItem';

import { Img, Button } from 'styled';

import useLanguage from 'hooks/useLanguage';

import { GlobalSettingsReducer } from 'state/reducers/globalSettings';
import {
  InstagramFeedModule as IInstagramFeedModule,
  InstagramFeedImageItem,
  GlobalState,
} from 'types';

interface StoreProps {
  globalSettings: GlobalSettingsReducer;
}

interface PassedProps {
  module: IInstagramFeedModule;
}

type Props = StoreProps & PassedProps;

interface State {
  instagramFeed: InstagramFeedImageItem[];
}

function InstagramFeedModule(props: Props) {
  const { globalSettings, module } = props;

  const { title, buttonLabel, buttonUrl } = module;

  const Language = useLanguage();

  const [state, setState] = useState<State>({
    instagramFeed: [],
  });

  const initializeInstaFeed = useCallback(() => {
    const feed = new Instafeed({
      limit: 9,
      target: 'instafeed',
      accessToken: globalSettings.instagramToken,
      error: (err: any) => console.error(err),
      transform: (feedImageItem: unknown) => {
        setState((s) => ({
          ...s,
          instagramFeed: s.instagramFeed.concat([
            sanitizeInstagramFeedImageItem(feedImageItem),
          ]),
        }));
      },
    });

    feed.run();
  }, [globalSettings]);

  useEffect(() => {
    initializeInstaFeed();
  }, [initializeInstaFeed]);

  const { instagramFeed } = state;

  return (
    <div className="InstagramFeedModule">
      <div className="module-content-padding-x mt6 flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="text-lg md-up_text-xl">
          <span>{title}</span>
        </div>
        {buttonLabel && buttonUrl && (
          <Button
            ariaLabel={buttonLabel}
            containerClassName="mt1_5 md:mt0"
            className="inline-flex"
            label={buttonLabel}
            to={buttonUrl}
            variant="hover-underline-black"
          />
        )}
      </div>
      <div className="mt3 md:3_5 flex flex-wrap w100 overflow-y-hidden">
        <div id="instafeed" />
        {instagramFeed.map((imageItem) => (
          <Button
            key={imageItem.id}
            ariaLabel={Language.t('Global.pageButtonAriaLabel', {
              title: imageItem.link,
            })}
            containerClassName="col-4 aspect-square"
            className="w100 h100"
            to={imageItem.link}
          >
            <Img
              alt={Language.t('InstagramModule.instagramImageAlt')}
              className="w100 h100 fit-cover"
              sizes="(max-width: 768px) 256px, (max-width: 1024px) 341px, 663px"
              src={imageItem.src}
            />
          </Button>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state: GlobalState): StoreProps => ({
  globalSettings: state.globalSettings,
});

export default connect(mapStateToProps)(InstagramFeedModule);
