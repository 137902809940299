import React, { useEffect, useMemo } from 'react';
import { logExposure } from 'analytics';
import useFeatureFlags from 'hooks/useFeatureFlags';

enum AD_SETTINGS {
  DISABLED = 'all-disabled',
  ENABLED = 'all-enabled',
  UNIVERSAL_PLAYER_DISABLED = 'universal-player-disabled',
}

/**
 * Disable all ad types (or specific types) on an entire page.
 */
const DisablePageAds = ({ disabled }: { disabled: boolean }) => {
  const featureFlags = useFeatureFlags();

  const isUniversalPlayerEnabled = useMemo(
    () => !featureFlags?.['growth-public-site-disable-universal-player-ads'],
    [featureFlags],
  );

  useEffect(() => {
    logExposure({
      testName: 'mediavine-ads-enabled',
      testVariant: disabled
        ? AD_SETTINGS.DISABLED
        : isUniversalPlayerEnabled
          ? AD_SETTINGS.ENABLED
          : AD_SETTINGS.UNIVERSAL_PLAYER_DISABLED,
    });
  }, [disabled, isUniversalPlayerEnabled]);

  if (disabled) {
    return <div id="mediavine-settings" data-blocklist-all="1"></div>;
  }

  if (!isUniversalPlayerEnabled) {
    <div
      id="mediavine-settings"
      data-blocklist-universal-player-desktop="1"
      data-blocklist-universal-player-mobile="1"
    />;
  }

  return null;
};

export default DisablePageAds;
