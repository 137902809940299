import React, { FC, useState, useEffect, useRef } from 'react';

import useInterval from 'hooks/useInterval';
import useRefInView from 'hooks/useRefInView';
import sanityImgUtil from 'utils/sanityImgUtil';

import {
  Img,
  Button,
  LeftArrowInsideCircle,
  RightArrowInsideCircle,
} from 'styled';
import {
  Container,
  TitleContainer,
  SliderContainer,
  Slide,
  SlideText,
  SlideLabel,
  SlideInfoAndControls,
  SliderControls,
} from './style';

import { SliderModule as ISliderModule } from 'types';
import useLanguage from 'hooks/useLanguage';
import { useTheme } from 'styled-components';

type Props = {
  module: ISliderModule;
};

const SliderModule: FC<Props> = ({ module }) => {
  const Language = useLanguage();
  const { palette } = useTheme();

  const { title, titleIcon, image, backgroundImage, slides } = module;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const moduleIntersectsWithViewport = useRefInView(containerRef);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [autoplayIsActive, setAutoplayIsActive] = useState(false);

  useEffect(() => {
    setAutoplayIsActive(true);
  }, []);

  const resumeAutoplayAfterDelay = () =>
    setTimeout(() => {
      setAutoplayIsActive(true);
    }, 3000);

  const hasReachedEndOfSlidesArray = activeSlideIndex === slides.length - 1;

  useInterval(
    () => {
      setActiveSlideIndex(
        hasReachedEndOfSlidesArray ? 0 : activeSlideIndex + 1
      );
    },
    autoplayIsActive ? 6000 : null
  );

  const setPreviousSlideActive = () => {
    clearTimeout(resumeAutoplayAfterDelay());
    setAutoplayIsActive(false);
    setActiveSlideIndex(
      activeSlideIndex === 0 ? slides.length - 1 : activeSlideIndex - 1
    );
    resumeAutoplayAfterDelay();
  };

  const setNextSlideActive = () => {
    clearTimeout(resumeAutoplayAfterDelay());
    setAutoplayIsActive(false);
    setActiveSlideIndex(hasReachedEndOfSlidesArray ? 0 : activeSlideIndex + 1);
    resumeAutoplayAfterDelay();
  };

  if (!slides.length) return null;

  return (
    <Container ref={containerRef} data-testid="slider-module">
      <TitleContainer
        moduleIntersectsWithViewport={moduleIntersectsWithViewport}
      >
        {title}
        <Img alt={titleIcon.alt} src={sanityImgUtil(titleIcon, 88)} />
      </TitleContainer>
      <SliderContainer backgroundImage={backgroundImage.src}>
        <Img alt={image.alt} src={sanityImgUtil(image, 480)} />
        {slides.map((slide, index) => (
          <Slide
            key={slide.id}
            isActive={index === activeSlideIndex}
            aria-hidden={index !== activeSlideIndex}
          >
            <SlideText>{slide.text}</SlideText>
            <SlideInfoAndControls>
              <SlideLabel>{slide.label}</SlideLabel>
              <SliderControls>
                <Button
                  tabIndex={index === activeSlideIndex ? 0 : -1}
                  ariaLabel={Language.t('SliderModule.previousButtonAriaLabel')}
                  onClick={setPreviousSlideActive}
                >
                  <LeftArrowInsideCircle
                    circleColor={palette.common.white}
                    arrowColor={palette.primary.main}
                    hoverCircleColor={palette.gray[30]}
                  />
                </Button>
                <Button
                  tabIndex={index === activeSlideIndex ? 0 : -1}
                  ariaLabel={Language.t('SliderModule.nextButtonAriaLabel')}
                  onClick={setNextSlideActive}
                >
                  <RightArrowInsideCircle
                    circleColor={palette.common.white}
                    arrowColor={palette.primary.main}
                    hoverCircleColor={palette.gray[30]}
                  />
                </Button>
              </SliderControls>
            </SlideInfoAndControls>
          </Slide>
        ))}
      </SliderContainer>
    </Container>
  );
};

export default SliderModule;
