import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  primaryColor?: Color;
  secondaryColor?: Color;
};

const AlertIcon: FC<Props> = ({
  className = '',
  primaryColor = theme.palette.red[50],
  secondaryColor = theme.palette.common.white,
}) => (
  <Svg
    width="10px"
    height="11px"
    viewBox="0 0 10 11"
    className={className}
    fill="transparent"
  >
    <desc>Alert Icon.</desc>
    <path
      d="M4.24648 0.967665L0.0915162 9.27759C-0.192478 9.8394 0.221166 10.5 0.844719 10.5H9.15464C9.78437 10.5 10.1918 9.8394 9.90785 9.27759L5.75288 0.967665C5.44419 0.344112 4.55517 0.344112 4.24648 0.967665Z"
      fill={primaryColor}
    />
    <path
      d="M4.95032 9.62343C4.52432 9.62343 4.17859 9.2777 4.17859 8.8517C4.17859 8.42571 4.52432 8.07998 4.95032 8.07998C5.37631 8.07998 5.72204 8.42571 5.72204 8.8517C5.72204 9.2777 5.37631 9.62343 4.95032 9.62343Z"
      fill={secondaryColor}
    />
    <path
      d="M5.41951 7.45642L5.96281 3.73362C5.96281 3.40641 5.69733 3.13477 5.37012 3.13477H3.93781L4.46258 7.46877L5.41951 7.45642Z"
      fill="white"
    />
  </Svg>
);

export default AlertIcon;
