import React, { ReactNode, FC } from 'react';

import BlockContent from '@sanity/block-content-to-react';
import cx from 'classnames';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import { Img, BasePortableText } from 'styled';

import { CalloutColorScheme } from 'constants/ColorScheme';
import {
  ListItem,
  ExternalLink,
  InternalLink,
  BlockRenderer,
} from 'constants/PortableTextSerializer';

import { Callout } from 'types';

interface Props {
  callout: Callout;
  children: ReactNode;
  anchor?: string;
  applyArticleBodyWidthOffset?: boolean;
}

const CalloutModuleWrapper: FC<Props> = ({
  callout,
  children,
  anchor,
  applyArticleBodyWidthOffset,
}) => {
  const {
    supportingSectionImage,
    supportingSectionText,
    infoSectionImage,
    infoSectionText,
    variant,
  } = callout;
  const colorScheme = CalloutColorScheme[callout.color];
  const variantIsBottom = variant === 'bottom';

  return (
    <div
      // Anchor from text module
      id={anchor ? anchor : undefined}
      className={cx(
        'CalloutModuleWrapper w100 overflow-x-hidden flex flex-col',
        {
          'CalloutModuleWrapper--right md:flex-row': variant === 'right',
          'CalloutModuleWrapper--left md:flex-row-reverse': variant === 'left',
          'CalloutModuleWrapper--bottom': variant === 'bottom',
          'article-inner-text-full-width': applyArticleBodyWidthOffset,
        }
      )}
      style={{
        backgroundColor: colorScheme.background,
      }}
    >
      {children}
      <div
        className={cx('CalloutModuleWrapper__container flex items-stretch', {
          'md:flex-col': !variantIsBottom,
        })}
        style={{
          backgroundColor: colorScheme.supportingSectionBackground,
        }}
      >
        {callout.infoSectionIsActive && (
          <div
            className={cx('CalloutModuleWrapper__info-section flex flex-wrap', {
              'CalloutModuleWrapper__info-section--with-image':
                !!infoSectionImage.id,
              'CalloutModuleWrapper__info-section--wide':
                !supportingSectionText.length,
              'CalloutModuleWrapper__info-section--without-text':
                !infoSectionText.length,
              'justify-between':
                !!infoSectionText.length && !!infoSectionImage.id,
              'justify-end': !infoSectionText.length && !!infoSectionImage.id,
              'md:flex-col-reverse md:justify-start': !variantIsBottom,
            })}
            style={{
              backgroundColor: colorScheme.infoSectionBackground,
            }}
          >
            {!!infoSectionText.length && (
              <BasePortableText
                textColor={colorScheme.infoSectionText}
                className={cx(
                  `CalloutModuleWrapper__text my1 mx1_5 md:my2 md:mx2_25 text-xs`,
                  {
                    'CalloutModuleWrapper__text--wide-space': variantIsBottom,
                    'mt1 md:mt2': infoSectionImage.id && !variantIsBottom,
                  }
                )}
              >
                <BlockContent
                  blocks={infoSectionText}
                  serializers={{
                    listItem: ListItem,
                    marks: { link: ExternalLink, internalLink: InternalLink },
                    types: { block: BlockRenderer },
                  }}
                />
              </BasePortableText>
            )}
            {infoSectionImage.id && (
              <div
                className={cx(
                  'CalloutModuleWrapper__info-section-image-container my1 mx1_5 md:my2 md:mx2_25',
                  {
                    r0: variant !== 'left',
                    l0: variant === 'left',
                  }
                )}
              >
                <Img
                  alt={infoSectionImage.alt}
                  src={sanityImgUtil(infoSectionImage, 400)}
                  preloadContainerClassName="w100"
                  dimensions={infoSectionImage.metadata?.dimensions}
                  crop={infoSectionImage.crop}
                />
              </div>
            )}
          </div>
        )}
        <div
          className={`CalloutModuleWrapper__supporting-section relative flex-grow`}
          style={{
            backgroundColor: colorScheme.supportingSectionBackground,
          }}
        >
          {!!supportingSectionText.length && (
            <BasePortableText
              textColor={colorScheme.supportingSectionText}
              className={cx(
                `CalloutModuleWrapper__text my1 mx1_5 md:my2 md:mx2_25 text-xs`,
                {
                  'CalloutModuleWrapper__supporting-section-text--with-image':
                    !!supportingSectionImage.id,
                }
              )}
            >
              <BlockContent
                blocks={supportingSectionText}
                serializers={{
                  listItem: ListItem,
                  marks: { link: ExternalLink, internalLink: InternalLink },
                  types: { block: BlockRenderer },
                }}
              />
            </BasePortableText>
          )}
          {supportingSectionImage.id && (
            <Img
              alt={supportingSectionImage.alt}
              className={cx(
                'CalloutModuleWrapper__supporting-section-image absolute b0 events-none r0 fit-contain',
                {
                  'CalloutModuleWrapper__supporting-section-image--without-text':
                    !supportingSectionText.length,
                  'CalloutModuleWrapper__supporting-section-image--with-text':
                    !!supportingSectionText.length,
                  'CalloutModuleWrapper__supporting-section-image--left md:l0':
                    variant === 'left',
                }
              )}
              sizes="(max-width: 768px) 400px, (max-width: 1024px) 500px, 650px"
              src={sanityImgUtil(supportingSectionImage, 400)}
              srcSet={sanityImgSrcSetUtil(
                supportingSectionImage,
                400,
                500,
                650
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CalloutModuleWrapper;
