import React, { FC } from 'react';

import sanityImgUtil from 'utils/sanityImgUtil';

import { Img } from 'styled';
import ItemHeader from '../ItemHeader';
import ItemDescription from '../ItemDescription';
import { ItemContainer } from '../style';
import { useTheme } from 'styled-components';

import { Image } from './style';

import { Color } from 'styled/theme/colors';

import { ImageItem as IImageItem } from 'types';

interface Props {
  item: IImageItem;
  bgColor: Color;
  textColor: Color;
}

const ImageItem: FC<Props> = ({ item, bgColor, textColor }) => {
  const { palette } = useTheme();
  return (
    <ItemContainer position="relative">
      <Image
        as={Img}
        position="absolute"
        alt={item.image.alt}
        src={sanityImgUtil(item.image, 500)}
      />
      <ItemContainer position="relative">
        <ItemHeader
          button={item.button}
          subtitle={item.subtitle}
          title={item.title}
          textColor={palette.common.white}
        />
        <ItemDescription
          button={item.button}
          description={item.description}
          textColor={textColor}
          bgColor={bgColor}
        />
      </ItemContainer>
    </ItemContainer>
  );
};

export default ImageItem;
