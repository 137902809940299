import React, { useCallback, useState } from 'react';
import theme from '@buoyhealth/common.buoy-theme';

import BlockContent from '@sanity/block-content-to-react';

import { ModuleHeader } from 'components/modules';
import { LinkOrButton, Button, AccordionArrow } from 'styled';
import {
  Container,
  AccordionsContainer,
  AccordionContainer,
  AccordionInner,
  AccordionToggle,
  AccordionToggleInner,
  AccordionTitle,
  Arrow,
  Description,
  ExpandableContent,
  ExpandableContentTextContainer,
  ModuleDescription,
  ButtonContainer,
} from './style';

import useLanguage from 'hooks/useLanguage';
import {
  ListItem,
  ExternalLink,
  InternalLink,
  BlockRenderer,
} from 'constants/PortableTextSerializer';

import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';

import { AccordionListModule as IAccordionListModule } from 'types';
import { Color } from 'styled/theme/colors';

interface Props {
  module: IAccordionListModule;
  hasCallout?: boolean;
  heroTextColor?: Color;
  applyArticleBodyWidthOffset?: boolean;
}

interface State {
  selectedItem: string | null;
}

function AccordionListModule(props: Props) {
  const { module, hasCallout, heroTextColor, applyArticleBodyWidthOffset } =
    props;

  const Language = useLanguage();
  const [state, setState] = useState<State>({
    selectedItem: null,
  });

  const toggleSelectedItem = useCallback((id: string) => {
    setState((s) => ({
      ...s,
      selectedItem: s.selectedItem === id ? null : id,
    }));
  }, []);

  const { selectedItem } = state;

  const { moduleHeader, listItems, button, bottomDescription } = module;
  const headerTitle = moduleHeader.title;
  const headerDescription = moduleHeader.description;
  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color as GeneralModuleColor];
  const textIsBlack = heroTextColor
    ? heroTextColor === theme.palette.text.primary
    : colorScheme.text === theme.palette.text.primary;
  const renderModuleHeader = !!headerTitle || !!headerDescription;

  return (
    <Container
      hasCallout={hasCallout}
      applyArticleBodyWidthOffset={applyArticleBodyWidthOffset}
      bgColor={colorScheme.background}
      textIsBlack={textIsBlack}
      renderModuleHeader={renderModuleHeader}
      pb={6}
      flex={1}
    >
      {renderModuleHeader && (
        <div>
          <ModuleHeader title={headerTitle} description={headerDescription} />
        </div>
      )}
      <AccordionsContainer>
        {listItems.map((item) => {
          const itemIsExpanded = selectedItem === item.id;
          const handleToggleListItem = () => toggleSelectedItem(item.id);
          // IDs for a11y
          const toggleId = `toggle-${item.id}`;
          const contentId = `content-${item.id}`;

          return (
            <AccordionContainer key={item.id} overflowY="hidden">
              <AccordionInner overflowY="hidden">
                <AccordionToggle
                  as={Button}
                  id={toggleId}
                  textIsBlack={textIsBlack}
                  display="flex"
                  flexDirection="column"
                  textAlign="left"
                  py={1.5}
                  ariaLabel={Language.t(
                    `Global.${
                      itemIsExpanded
                        ? 'collapseButtonAriaLabel'
                        : 'expandButtonAriaLabel'
                    }`,
                    {
                      section: `"${item.button.label}"`,
                    }
                  )}
                  ariaExpanded={itemIsExpanded}
                  ariaControls={contentId}
                  onClick={handleToggleListItem}
                >
                  <AccordionToggleInner>
                    <p>
                      <AccordionTitle as="span" textIsBlack={textIsBlack}>
                        {item.button.label}
                      </AccordionTitle>
                    </p>
                    <Arrow
                      as={AccordionArrow}
                      mt="0.65rem"
                      ml={1.5}
                      isReverse={itemIsExpanded}
                      color={heroTextColor || colorScheme.text}
                    />
                  </AccordionToggleInner>
                  {!!item.button.description && (
                    <Description as="p" textIsBlack={textIsBlack} mt={1}>
                      {item.button.description}
                    </Description>
                  )}
                </AccordionToggle>
                <ExpandableContent
                  id={contentId}
                  itemIsExpanded={itemIsExpanded}
                  mt={itemIsExpanded ? 1.5 : 0}
                  aria-hidden={!itemIsExpanded}
                  aria-labelledby={toggleId}
                  role="region"
                >
                  <ExpandableContentTextContainer
                    itemIsExpanded={itemIsExpanded}
                  >
                    <BlockContent
                      blocks={item.content}
                      serializers={{
                        listItem: ListItem,
                        marks: {
                          link: ExternalLink,
                          internalLink: InternalLink,
                        },
                        types: { block: BlockRenderer },
                      }}
                    />
                  </ExpandableContentTextContainer>
                </ExpandableContent>
              </AccordionInner>
            </AccordionContainer>
          );
        })}
      </AccordionsContainer>
      {!!bottomDescription.length && (
        <ModuleDescription>
          <BlockContent
            blocks={bottomDescription}
            serializers={{
              listItem: ListItem,
              marks: { link: ExternalLink, internalLink: InternalLink },
              types: { block: BlockRenderer },
            }}
          />
        </ModuleDescription>
      )}
      {!!button.label && !!button.url && (
        <ButtonContainer mt={1.75}>
          <LinkOrButton
            ariaLabel={button.label}
            label={button.label}
            to={button.url}
            variant={
              textIsBlack ? 'hover-underline-black' : 'hover-underline-white'
            }
          />
        </ButtonContainer>
      )}
    </Container>
  );
}

export default AccordionListModule;
