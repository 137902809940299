import styled from 'styled-components';
import { Base, DrugPagePortableText } from 'styled';
import { MediumHeading } from 'styled/components/drugPage';

type ContainerProps = {
  backgroundColor: string;
};

export const Container = styled(DrugPagePortableText)<ContainerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 1.5rem 1rem;
  margin: 2.5rem 0;
`;

export const BasicsContainer = styled(Base)`
  border-bottom: ${({ theme }) => theme.borders['teal-50']};
  padding-bottom: 1.5rem;

  ${MediumHeading} {
    margin-top: 0rem;
  }
`;
