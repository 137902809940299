import React, { FC } from 'react';
import { Svg } from 'styled/components/base';

type Props = {
  backgroundColor?: string;
  className?: string;
  color?: string;
};

const VideoIcon: FC<Props> = ({
  className = '',
  color = '#E29727',
  backgroundColor = '#F7F7F7',
}) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <circle cx="12" cy="12" r="12" fill={backgroundColor} />
    <path
      d="M15.6091 7.72402C15.8592 7.97407 15.9997 8.31321 15.9997 8.66683V10.8888L18.6663 8.66683V15.3335L15.9997 13.1115V15.3335C15.9997 15.6871 15.8592 16.0263 15.6091 16.2763C15.3591 16.5264 15.02 16.6668 14.6663 16.6668H6.66634C6.31272 16.6668 5.97358 16.5264 5.72353 16.2763C5.47348 16.0263 5.33301 15.6871 5.33301 15.3335V8.66683C5.33301 8.31321 5.47348 7.97407 5.72353 7.72402C5.97358 7.47397 6.31272 7.3335 6.66634 7.3335H14.6663C15.02 7.3335 15.3591 7.47397 15.6091 7.72402Z"
      fill={color}
    />
  </Svg>
);

export default VideoIcon;
