import styled from 'styled-components';
import { Base, Theme } from 'styled';
import { GeneralModuleColorValue } from 'constants/ColorScheme';
import { ModuleOptions } from 'types';

type ContainerProps = {
  colorScheme: GeneralModuleColorValue;
  moduleOptions: ModuleOptions;
  renderModuleHeader: boolean;
  applyArticleBodyWidthOffset: boolean | undefined;
};

export const Container = styled(Base)<ContainerProps>`
  ${({ theme, applyArticleBodyWidthOffset }) =>
    applyArticleBodyWidthOffset && theme.global.articleInnerTextFullWidth};
  ${({ theme }) => theme.global.moduleContentPaddingX};
  background-color: ${({ colorScheme }) => colorScheme.background};
  color: ${({ colorScheme }) => colorScheme.text};
  border-top: ${({ moduleOptions, theme }) =>
    moduleOptions.showBorderTop ? theme.borders['gray-10'] : 'none'};
  border-bottom: ${({ moduleOptions, theme }) =>
    moduleOptions.showBorderBottom ? theme.borders['gray-10'] : 'none'};
  padding-top: ${({ renderModuleHeader }) => (renderModuleHeader ? 0 : '6rem')};
`;

export const LinksContainer = styled(Base)`
  display: flex;
  flex-direction: column;
`;

export const LinkContainer = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display'].includes(prop) && defaultValidatorFn(prop),
})`
  border-bottom: ${({ theme }) => theme.borders['black-opacity-20']};

  :first-of-type {
    border-top: ${({ theme }) => theme.borders['black-opacity-20']};
  }
`;

export const LinkTextContainer = styled(Base)`
  display: flex;
  flex-direction: column;

  a,
  button {
    align-self: flex-start;

    span {
      ${({ theme }) => theme.typography.variants.primary.md};
    }
  }
`;

export const LinkText = styled(Base)`
  color: ${({ theme }) => theme.palette.gray[60]};
  ${({ theme }) => theme.typography.variants.primary.sm};

  ${Theme.mediaQueries.md} {
    ${({ theme }) => theme.typography.variants.primary.xs};
  }
`;

export const ShowMoreButtonContainer = styled(Base)``;
