import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';

import cx from 'classnames';

import { LinkOrButton } from 'styled';

import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';
import { Color } from 'styled/theme/colors';

import { LinkCardsModule as ILinkCardsModule, LinkCardWithType } from 'types';
import { useTheme } from 'styled-components';

interface Props {
  module: ILinkCardsModule;
  applyArticleBodyWidthOffset?: boolean;
}

const LinkCardsModule: FC<Props> = ({
  module,
  applyArticleBodyWidthOffset,
}) => {
  const { palette } = useTheme();
  const { linkCards, moduleOptions } = module;

  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color as GeneralModuleColor];

  // Hardcoding background color of both halves to be white if 'white' is selected
  const colorSchemeIsWhite = module.moduleOptions.color === 'WHITE';
  const backgroundColor = colorSchemeIsWhite ? 'white' : colorScheme.background;
  const darkBackgroundColor = colorSchemeIsWhite
    ? 'white'
    : colorScheme.darkBackground;

  const titleElement = (title: string) => {
    return (
      title && (
        <p
          className="text-md md-up_text-lg mb3_75"
          style={{ color: colorScheme.text }}
        >
          <span
            className={`link-border-bottom-${
              colorScheme.text === theme.palette.text.primary
                ? 'black'
                : 'white'
            }-hidden`}
          >
            {title}
          </span>
        </p>
      )
    );
  };

  const descriptionElement = (description?: string) => {
    return (
      <div className="mb1_5" style={{ color: colorScheme.text }}>
        <span className="text-secondary-sm">{description}</span>
      </div>
    );
  };

  const getButtonVariant = (card: LinkCardWithType, textColor: Color) => {
    const buttonVariant = card.button.variant;
    if (buttonVariant === 'primary') {
      return buttonVariant;
    }
    if (card.type === 'article') {
      if (textColor === palette.common.white) {
        return 'hover-underline-white';
      } else {
        return 'hover-underline-black';
      }
    }
    return `hover-${buttonVariant}` as
      | 'hover-underline-white'
      | 'hover-underline-black';
  };

  return (
    <div
      className={cx(
        `LinkCardsModule color-gray-100 flex flex-col sm:flex-row`,
        {
          'border-top-gray-lighter': moduleOptions.showBorderTop,
          'border-bottom-gray-lighter': moduleOptions.showBorderBottom,
          'article-inner-text-full-width': applyArticleBodyWidthOffset,
        }
      )}
    >
      {linkCards.map((card, index) => {
        const buttonLabel = card.button.label;
        const buttonAriaLabel = card.button.ariaLabel;
        const url = card.button.url;
        const buttonVariant = getButtonVariant(card, colorScheme.text);

        return (
          <div
            className="LinkCardsModule__card flex-1 module-content-padding-x py3 sm:py4 md:py6"
            key={card.id}
            style={{
              backgroundColor:
                index % 2 === 0 ? darkBackgroundColor : backgroundColor,
              color: colorScheme.text,
            }}
          >
            {!url && titleElement(card.title)}
            {url && (
              <LinkOrButton
                ariaLabel={
                  card.type === 'article'
                    ? (buttonAriaLabel as string)
                    : card.title
                }
                to={url}
                containerClassName="inline-flex"
                className="link-border-bottom-appear-on-hover"
              >
                {titleElement(card.title)}
              </LinkOrButton>
            )}
            {descriptionElement(card.description)}
            {buttonLabel && url && (
              <LinkOrButton
                ariaLabel={buttonAriaLabel || buttonLabel}
                className="inline-flex"
                variant={buttonVariant}
                label={buttonLabel}
                to={url}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LinkCardsModule;
