import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import dynamic from 'next/dynamic';
import type { MiniInterviewType } from '@buoy-components/polaris/types';
import BazaarClient from 'lib/BazaarClient';
import { Cases, formatTitleCasing } from 'utils/formatTitleCasing';
import { Article } from 'types';
import { InterviewContainer } from '../styles';
import { getCommonCauses } from '../utils/getCommonCauses';
import { SYMPTOM_CATEGORY } from '../../constants';

const MiniInterviewComponent = dynamic(() =>
  import('@buoyhealth/common.buoy-interviews').then(
    (module) => module.MiniInterview,
  ),
);

/* 4 types of interviews can be added in Sanity, but only these 3 are built out so far */
const SUPPORTED_INTERVIEWS = ['emergency', 'symptoms', 'treatment'] as const;
type SupportedInterview = (typeof SUPPORTED_INTERVIEWS)[number];

const SxMiniInterview = ({
  article,
  formLocation,
  interviewType,
}: {
  article: Article;
  formLocation: 'form-query-module' | 'form-embedded';
  interviewType: MiniInterviewType;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [predictors, setPredictors] = useState<number[]>();

  const { slug, content } = article;

  const conditionName = formatTitleCasing({
    title: article?.conditionName || article?.title || '',
    casing: Cases.LowerCase,
  });

  const commonCauses = useMemo(() => getCommonCauses(content), [content]);

  useEffect(() => {
    const getPredictor = async (slug: string) => {
      try {
        const predictors = await BazaarClient.getPredictorsFromSlug(
          slug,
          SYMPTOM_CATEGORY,
        );
        if (predictors?.length) {
          setPredictors(predictors.map((p) => p.predictor_id));
        } else {
          throw Error;
        }
      } catch {
        console.error('No predictor found');
      }
    };

    if (slug) {
      getPredictor(slug);
    }
  }, [slug]);

  const handleAutoScroll = useCallback((questionNum: number) => {
    if (questionNum >= 1 && containerRef.current) {
      const { top } = containerRef.current.getBoundingClientRect();
      if (top >= 0) {
        // only auto-scroll up if the top of the container isn't visible
        return;
      }

      const breadcrumbsHeight =
        document.getElementById('breadcrumbs')?.offsetHeight || 0;

      const mobileBACTAHeight =
        document.getElementById('symptomCheckerCTABar')?.offsetHeight || 0;

      const navHeight = document.getElementById('navTopBar')?.offsetHeight || 0;

      const buffer = 12;

      const basePosition = top + window.scrollY;
      const newPosition =
        basePosition -
        navHeight -
        breadcrumbsHeight -
        mobileBACTAHeight -
        buffer;

      window.scrollTo({
        top: newPosition,
        behavior: 'smooth',
      });
    }
  }, []);

  if (predictors && SUPPORTED_INTERVIEWS.some((i) => i === interviewType)) {
    return (
      <InterviewContainer ref={containerRef}>
        <MiniInterviewComponent
          commonCauses={commonCauses}
          complaints={predictors}
          conditionName={conditionName}
          formLocation={formLocation}
          interviewType={interviewType as SupportedInterview}
          onQuestionDisplay={handleAutoScroll}
          slug={slug}
          withBuoyTheme
        />
      </InterviewContainer>
    );
  }

  return null;
};

export default SxMiniInterview;
