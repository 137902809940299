import React, { FC } from 'react';
import { Svg } from 'styled';

const UserSpeechBubbleIcon: FC = () => (
  <Svg width="24px" height="24px" viewBox="0 0 24 24" fill="transparent">
    <desc>User Speech Bubble Icon.</desc>
    <path
      d="M16.3619 0H0V14.55V15.3V19.2375L3.93136 15.3H16.3994C20.5928 15.3 24 11.8875 24 7.65C24 3.45 20.5928 0 16.3619 0Z"
      fill="#F9AE0F"
    />
    <path
      d="M6.32765 9.1125C5.50394 9.1125 4.86743 8.4375 4.86743 7.65C4.86743 6.8625 5.54138 6.1875 6.32765 6.1875C7.11392 6.1875 7.78787 6.8625 7.78787 7.65C7.78787 8.4375 7.11392 9.1125 6.32765 9.1125Z"
      fill="white"
    />
    <path
      d="M12.1685 9.1125C11.3448 9.1125 10.7083 8.4375 10.7083 7.65C10.7083 6.8625 11.3822 6.1875 12.1685 6.1875C12.9547 6.1875 13.6287 6.8625 13.6287 7.65C13.6287 8.4375 12.9547 9.1125 12.1685 9.1125Z"
      fill="white"
    />
    <path
      d="M18.0093 9.1125C17.1856 9.1125 16.5491 8.4375 16.5491 7.65C16.5491 6.8625 17.223 6.1875 18.0093 6.1875C18.7956 6.1875 19.4695 6.8625 19.4695 7.65C19.4695 8.4375 18.7956 9.1125 18.0093 9.1125Z"
      fill="white"
    />
    <path
      d="M21.6787 23.9999V20.7374C21.6787 19.8374 20.9299 19.0874 19.9939 19.0874H11.3823C10.4088 19.0874 9.62256 19.8374 9.62256 20.7749V23.9999H21.6787Z"
      fill="#8CB5FE"
    />
    <path
      d="M19.8067 18.375L15.6132 22.3125L11.3823 18.375H19.8067Z"
      fill="white"
    />
    <path
      d="M20.5555 14.6249C20.5555 17.1374 18.3464 19.1624 15.6132 19.1624C12.8799 19.1624 10.6709 17.1374 10.6709 14.6249C10.6709 12.1124 12.8799 10.0874 15.6132 10.0874C18.3464 10.0874 20.5555 12.1124 20.5555 14.6249Z"
      fill="#064FDA"
    />
    <path
      d="M18.7957 15.075C18.7957 17.7 17.3729 19.7999 15.6132 19.7999C13.8534 19.7999 12.4307 17.7 12.4307 15.075"
      fill="#F3AB96"
    />
    <path
      d="M13.2544 15.825C13.2544 15.825 12.3933 14.8875 12.6554 14.85C12.9174 14.8125 15.2014 13.5375 15.3137 13.3875C15.426 13.2 15.6132 13.125 15.6132 13.125C15.6132 13.125 18.309 15.0375 18.6085 14.9625C18.9081 14.85 18.0844 15.825 18.0844 15.825H13.2544Z"
      fill="#F3AB96"
    />
    <path
      d="M15.0889 12.1875C15.0889 12.1875 15.8003 14.6625 19.3572 14.925"
      stroke="#064FDA"
      strokeWidth="1.372"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8689 14.8875C11.8689 14.8875 14.1154 15.0375 15.4633 13.2"
      stroke="#064FDA"
      strokeWidth="1.372"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default UserSpeechBubbleIcon;
