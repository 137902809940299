import React, { FC, useRef } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { useTheme } from 'styled-components';

import Img from 'styled/components/Img';
import Lottie from 'styled/components/Lottie';
import { BasePortableText } from 'styled/components/portableText';
import {
  Container,
  DividerContainer,
  Divider,
  TitleContainer,
  SymptomCheckerButton,
  SymptomCheckerButtonContainer,
  ImgOuterContainer,
  ImgContainer,
  TextContainer,
  DescriptionContainer,
} from './style';

import sanityImgUtil from 'utils/sanityImgUtil';
import useRefInView from 'hooks/useRefInView';

import { ExternalLink, InternalLink } from 'constants/PortableTextSerializer';
import { AnimationAndTextModule as IAnimationAndTextModule } from 'types';

export interface Props {
  module: IAnimationAndTextModule;
  applyPaddingX?: boolean;
}

const AnimationAndTextModule: FC<Props> = ({
  module,
  applyPaddingX = true,
}) => {
  const { palette } = useTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const moduleIntersectsWithViewport = useRefInView(containerRef);

  const { header, description, button, lottie, image } = module;

  return (
    <Container
      ref={containerRef}
      applyPaddingX={applyPaddingX}
      data-testid="animation-and-text-module"
    >
      {lottie && !image.src && (
        <ImgOuterContainer>
          <ImgContainer>
            <Lottie
              lottie={lottie}
              autoplay={false}
              loop={false}
              playingState={moduleIntersectsWithViewport ? 'PLAY' : 'STOP'}
            />
          </ImgContainer>
        </ImgOuterContainer>
      )}
      {image.src && !lottie && (
        <ImgOuterContainer>
          <ImgContainer>
            <Img
              alt={image.alt}
              src={sanityImgUtil(image, 554)}
              dimensions={image.metadata?.dimensions}
              crop={image.crop}
              preloadContainerClassName="w100 h100 fit-contain"
              className="fit-contain w100"
            />
          </ImgContainer>
        </ImgOuterContainer>
      )}
      <TextContainer>
        <TitleContainer>
          <DividerContainer>
            <Divider
              moduleIntersectsWithViewport={moduleIntersectsWithViewport}
            />
          </DividerContainer>
          {header}
        </TitleContainer>
        <DescriptionContainer>
          <BasePortableText textColor={palette.text.primary}>
            <BlockContent
              blocks={description}
              serializers={{
                marks: { link: ExternalLink, internalLink: InternalLink },
              }}
            />
          </BasePortableText>
        </DescriptionContainer>
        <SymptomCheckerButtonContainer>
          <SymptomCheckerButton
            ariaLabel={button.label}
            label={button.label}
            to={button.url}
            openInCurrentTab={true}
            variant="symptom-checker-blue"
          />
        </SymptomCheckerButtonContainer>
      </TextContainer>
    </Container>
  );
};

export default AnimationAndTextModule;
