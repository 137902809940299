import React, { FC } from 'react';
import { Svg } from 'styled';

type Props = {
  className?: string;
};

const PillsSurroundingPillBottle: FC<Props> = ({ className = '' }) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    fill="transparent"
    className={className}
  >
    <desc>Illustration of pills surrounding a pill bottle.</desc>
    <path
      fill="#DD9581"
      d="M86.1,123.2c-18,20.2-35.9,55.1-32,103.9c2.4,30.4,16.2,88.1,94.1,101.4c56.8,9.7,114.7-10.1,154.4-28.6c59-27.6,66.2-107.2,53.4-154.8c-14.5-54-46.2-93.7-116.4-95.2C171.2,48.4,125.6,78.8,86.1,123.2z"
    />
    <circle fill="#F8C6B9" cx="69.1" cy="217.6" r="30.5" />
    <rect x="38.6" y="213.1" fill="#4C6551" width="61" height="9" />
    <circle fill="#9CC7C9" cx="304.6" cy="71.9" r="30.5" />
    <rect
      x="300.1"
      y="41.4"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 38.3821 236.4274)"
      fill="#4C6551"
      width="9"
      height="61"
    />
    <circle fill="#F8C6B9" cx="261.5" cy="328.1" r="30.5" />
    <rect
      x="257"
      y="297.6"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -155.4202 281.0187)"
      fill="#4C6551"
      width="9"
      height="61"
    />
    <circle fill="#9CC7C9" cx="330.9" cy="231.3" r="30.5" />
    <rect
      x="326.4"
      y="200.8"
      transform="matrix(0.7071 -0.7071 0.7071 0.7071 -66.6054 301.7205)"
      fill="#4C6551"
      width="9"
      height="61"
    />
    <rect x="154.4" y="130.7" fill="#F8EDEB" width="108.3" height="150.6" />
    <polygon
      fill="#D3ECEE"
      points="262.7,123.4 262.7,105.9 249.8,105.9 249.8,92.9 167.3,92.9 167.3,105.9 154.4,105.9 154.4,123.4 143,123.4 143,138.1 274.1,138.1 274.1,123.4"
    />
    <rect x="154.4" y="162.8" fill="#B1DCDE" width="78" height="92.8" />
    <rect x="154.4" y="192.3" fill="#4C6551" width="64.5" height="8.7" />
    <rect x="154.4" y="219.6" fill="#4C6551" width="41" height="8.7" />
    <circle fill="#D3ECEE" cx="91.8" cy="97.5" r="30.5" />
    <rect
      x="61.3"
      y="93"
      transform="matrix(0.7071 -0.7072 0.7072 0.7071 -42.0232 93.4748)"
      fill="#4C6551"
      width="61"
      height="9"
    />
    <circle fill="#9CC7C9" cx="138.3" cy="292.9" r="30.5" />
    <rect x="133.8" y="262.4" fill="#4C6551" width="9" height="61" />
  </Svg>
);

export default PillsSurroundingPillBottle;
