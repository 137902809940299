import React from 'react';
import { useTheme } from 'styled-components';
import { AlertIcon } from 'styled';
import { Container, Message } from './style';
import { Color } from 'styled/theme/colors';

export interface Props {
  message: string;
  showError: boolean;
  marginTop?: number; // rem
  iconColor?: Color;
}

const ErrorMessage = (props: Props) => {
  const { palette } = useTheme();
  const {
    message,
    showError,
    marginTop = 1.75,
    iconColor = palette.red[50],
  } = props;

  return (
    <Container
      data-testid="error-message"
      marginTop={marginTop}
      showError={showError}
    >
      <AlertIcon primaryColor={iconColor} />
      <Message role="alert">{message}</Message>
    </Container>
  );
};

export default ErrorMessage;
