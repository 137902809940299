import { useState, useEffect, MutableRefObject } from 'react';

const getRefElDimensions = (ref: MutableRefObject<HTMLElement | null>) => {
  const height = ref?.current?.clientHeight;
  const width = ref?.current?.clientWidth;

  return {
    height,
    width,
  };
};

const useElementDimensions = (ref: MutableRefObject<HTMLElement | null>) => {
  const [dimensions, setDimensions] = useState(getRefElDimensions(ref));

  useEffect(() => {
    const handleResize = () => setDimensions(getRefElDimensions(ref));

    if (!dimensions.width || !dimensions.height) handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return dimensions;
};

export default useElementDimensions;
