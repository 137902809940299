import { logExposure } from 'analytics';
import { trackStructuredEvent } from 'analytics/snowplow';

const category = 'sx-query-module';

export const AUTO_EXPAND_AB_TEST =
  'growth-public-site-abtest-auto-expand-query-module';

export function trackModuleImpression(formId: string, testVariant: string) {
  logExposure({
    testName: AUTO_EXPAND_AB_TEST,
    testVariant,
  });

  trackStructuredEvent({
    category,
    action: 'module-impression',
    property: formId,
  });
}

export function trackQuestionClick(formId: string, questionText: string) {
  trackStructuredEvent({
    category,
    action: 'question-click',
    property: formId,
    label: questionText,
  });
}

export function trackSearchBarFocus(formId: string) {
  trackStructuredEvent({
    category,
    action: 'search-bar-focus',
    property: formId,
  });
}

export function trackSearchBarSubmit(formId: string, questionText: string) {
  trackStructuredEvent({
    category,
    action: 'search-bar-text-submitted',
    property: formId,
    label: questionText,
  });
}

export function trackSearchBarAnswerDisplayed(
  formId: string,
  answerText: string
) {
  trackStructuredEvent({
    category,
    action: 'search-bar-text-answer-displayed',
    property: formId,
    label: answerText,
  });
}
