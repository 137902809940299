import React, { FC, useRef, useState } from 'react';
import Router from 'next/router';
import BlockContent from '@sanity/block-content-to-react';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import { Base, Link } from 'styled';
import {
  Container,
  ContentContainer,
  BackButton,
  Title,
  Image,
  TextContainer,
  SectionWithTopBorder,
  CtaButtonContainer,
  DesktopImageContainer,
} from './style';

import { ExternalLink, InternalLink } from 'constants/PortableTextSerializer';

import { GoBackModule as IGoBackModule } from 'types';
import { useEffect } from 'react';

type Props = {
  module: IGoBackModule;
} & WithBreakpointsProps;

const GoBackModule: FC<Props> = ({ module, currentBreakpoint }) => {
  const contentInnerRef = useRef<HTMLDivElement>(null);

  const [
    windowHeightIsSmallerThanContentHeight,
    setWindowHeightIsSmallerThanContentHeight,
  ] = useState(true);

  const setLayoutVariable = () => {
    if (process.browser && contentInnerRef && contentInnerRef.current) {
      // Theme.sizes.topBarHeightTablet = 84px
      setWindowHeightIsSmallerThanContentHeight(
        window.innerHeight - 84 < contentInnerRef.current.clientHeight
      );
    }
  };

  useEffect(() => {
    // Set state when component mounts
    setLayoutVariable();

    // When window is resized, set state again
    window.addEventListener('resize', setLayoutVariable);
    return () => window.removeEventListener('resize', setLayoutVariable);
  }, []);

  const {
    moduleTitle,
    moduleDescription,
    mobileImage,
    desktopImage,
    backButtonLabel,
    moreDetails,
    ctaSectionText,
    ctaButtonLabel,
    ctaButtonLink,
    ctaButtonVariant,
  } = module;

  const handleBackButtonClick = () => {
    Router.back();
  };

  const breakpointIsMdDown = ['EXTRA_SMALL', 'SMALL', 'MEDIUM'].includes(
    currentBreakpoint
  );
  const image =
    breakpointIsMdDown && mobileImage.id ? mobileImage : desktopImage;

  return (
    <Container>
      <ContentContainer
        windowHeightIsSmallerThanContentHeight={
          windowHeightIsSmallerThanContentHeight
        }
      >
        <Base ref={contentInnerRef}>
          {!!backButtonLabel && (
            <BackButton
              label={backButtonLabel}
              ariaLabel={backButtonLabel}
              onClick={handleBackButtonClick}
            />
          )}
          {!!moduleTitle && <Title>{moduleTitle}</Title>}
          {!!moduleDescription.length && (
            <TextContainer>
              <BlockContent
                blocks={moduleDescription}
                serializers={{
                  marks: { link: ExternalLink, internalLink: InternalLink },
                }}
              />
            </TextContainer>
          )}
          {breakpointIsMdDown && (
            <Image
              key={image.id}
              alt={image.alt}
              sizes="(max-width: 375px) 686px, (max-width: 768px) 1472px"
              src={sanityImgUtil(image, 686)}
              srcSet={sanityImgSrcSetUtil(image, 686, 1472, 1472)}
            />
          )}
          {!!moreDetails.length && (
            <SectionWithTopBorder>
              <TextContainer>
                <BlockContent
                  blocks={moreDetails}
                  serializers={{
                    marks: { link: ExternalLink, internalLink: InternalLink },
                  }}
                />
              </TextContainer>
            </SectionWithTopBorder>
          )}
          {(!!ctaSectionText.length ||
            (!!ctaButtonLabel && !!ctaButtonLink)) && (
            <SectionWithTopBorder>
              {!!ctaSectionText.length && (
                <TextContainer>
                  <BlockContent
                    blocks={ctaSectionText}
                    serializers={{
                      marks: { link: ExternalLink, internalLink: InternalLink },
                    }}
                  />
                </TextContainer>
              )}
              {!!ctaButtonLabel && !!ctaButtonLink && (
                <CtaButtonContainer>
                  <Link
                    variant={ctaButtonVariant}
                    label={ctaButtonLabel}
                    ariaLabel={ctaButtonLabel}
                    to={ctaButtonLink}
                  />
                </CtaButtonContainer>
              )}
            </SectionWithTopBorder>
          )}
        </Base>
      </ContentContainer>
      {!breakpointIsMdDown && (
        <DesktopImageContainer>
          <Image
            key={image.id}
            alt={image.alt}
            src={sanityImgUtil(image, 1472)}
          />
        </DesktopImageContainer>
      )}
    </Container>
  );
};

export default withBreakpoints(GoBackModule);
