import React from 'react';
import Img from 'styled/components/Img';
import Telemedicine from 'styled/components/svg/Telemedicine';
import sanityImgUtil from 'utils/sanityImgUtil';
import { ImageContainer } from './SupplierCtaModule.style';
import { Image } from 'types';

export const SupplierCTAImage = ({ image }: { image?: Image }) => {
  if (!image?.src) {
    return (
      <ImageContainer>
        <Telemedicine />
      </ImageContainer>
    );
  }

  return (
    <ImageContainer>
      <Img
        alt={image.alt}
        className="flex fit-cover w100 h100"
        src={sanityImgUtil(image, 100)}
      />
    </ImageContainer>
  );
};
