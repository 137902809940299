import theme from '@buoyhealth/common.buoy-theme';
import React, { FC } from 'react';
import CheckmarkInsideFilledCircle from 'styled/components/svg/CheckmarkInsideFilledCircle';
import { Color } from 'styled/theme/colors';

interface ICheckmarkIconProps {
  backgroundColor?: Color;
}

const CheckmarkIcon: FC<ICheckmarkIconProps> = ({
  backgroundColor = theme.palette.gray[0],
}) => (
  <CheckmarkInsideFilledCircle
    circleColor={backgroundColor}
    checkmarkColor={theme.palette.orange[40]}
  />
);

export default CheckmarkIcon;
