import React, { FC } from 'react';

import { Img } from 'styled';
import { Container, Caption } from './style';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import { ImageWithCaptionModule as IImageWithCaptionModule } from 'types';

export interface Props {
  module: IImageWithCaptionModule;
}

const ImageWithCaptionModule: FC<Props> = ({ module }) => {
  const { image, caption } = module;

  const imageWidth = image.metadata?.dimensions?.width || 1440;

  return (
    <Container
      className="disabled-ads"
      showBorderTop={module.moduleOptions.showBorderTop}
      showBorderBottom={module.moduleOptions.showBorderBottom}
    >
      <Img
        alt={image.alt}
        sizes={`(max-width: 768px) ${imageWidth}px, (max-width: 1024px) ${imageWidth}px, ${imageWidth}px`}
        src={sanityImgUtil(image, imageWidth)}
        srcSet={sanityImgSrcSetUtil(image, imageWidth, imageWidth, imageWidth)}
        dimensions={image.metadata?.dimensions}
        crop={image.crop}
        preloadContainerStyle={{
          maxWidth: `${imageWidth}px`,
        }}
      />
      {!!caption && <Caption>{caption}</Caption>}
    </Container>
  );
};

export default ImageWithCaptionModule;
