import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled/components/base';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
  backgroundColor?: Color;
};

const LockInsideFilledCircle: FC<Props> = ({
  className = '',
  color = theme.palette.orange[40],
  backgroundColor = theme.palette.gray[0],
}) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <ellipse cx="12.1424" cy="12" rx="11.6424" ry="12" fill={backgroundColor} />
    <rect
      x="7"
      y="10.7222"
      width="10"
      height="6.11111"
      rx="2"
      fill={color}
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.22266 10.7222V8.49995C9.22266 6.96582 10.4663 5.72217 12.0004 5.72217C13.5346 5.72217 14.7782 6.96582 14.7782 8.49995V10.7222"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default LockInsideFilledCircle;
