import React, { FC } from 'react';
import { Svg } from 'styled';

type Props = {
  className?: string;
};

const PersonAtHomeLookingAtPrescription: FC<Props> = ({ className = '' }) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    fill="transparent"
    className={className}
  >
    <desc>Illustration of a person at home looking at a prescription.</desc>
    <rect x="36.3" y="239.2" fill="#719B9D" width="327.3" height="126.5" />
    <path
      fill="#415546"
      d="M258.9,228.7c-2.7-21.9-8.7-38.6-24.7-47c0.2-11.7,0.2-23.6-0.2-31.5c-1.3-30-1.8-38.3-9.6-48c-15.1-18.7-78-15.4-100.5-13.1c-7.6,0.8-15.3,1.9-22.5,4.6c-7.2,2.7-13.9,7.3-18,13.8c-5.1,8.2-5.6,18.3-6,27.9c-0.4,12.4-1.2,30.8-1.9,47.9c-8.9,5.9-16.1,13.9-19.8,23.9c-3.4,9.3-3.5,19.5-3.6,29.4c-0.1,14.9-1.1,29.8-0.3,44.7c1.2,20.2,8.6,35.2,26.6,35.4c38,0.5,109.3,1.6,147.3-0.4c13.2-0.7,32.5-4.7,33-25.3C259.2,269.9,260.3,240,258.9,228.7z"
    />
    <path
      fill="#F8C6B9"
      d="M156.1,112.8c17.7-1.1,35.6,6,48.1,18.5c12.5,12.5,19.5,30.2,19.7,47.9c0.1,10.1-2.3,20.9-9.6,27.8c-7.2,6.8-17.7,8.6-27.6,9.6c-25.9,2.7-88.9,12.7-94.1-24.8C87.6,154.8,118.8,115.3,156.1,112.8z"
    />
    <rect
      x="180.9"
      y="231.3"
      transform="matrix(-1 -4.480073e-11 4.480073e-11 -1 402.154 534.2338)"
      fill="#F8EDEB"
      width="40.4"
      height="71.7"
    />
    <path
      fill="#F8EDEB"
      d="M221.3,302.6v10.7c0,2.6,2,4.8,4.6,5h0c4,0.4,7.1,3.7,7.1,7.8l0,0c0,4.3-3.5,7.8-7.8,7.8h-32.6c-6.5,0-11.7-5.2-11.7-11.7v0v-6.3v-13.3H221.3z"
    />
    <rect
      x="127"
      y="231.3"
      transform="matrix(-1 -4.480073e-11 4.480073e-11 -1 294.4401 534.2338)"
      fill="#F8EDEB"
      width="40.4"
      height="71.7"
    />
    <path
      fill="#F8EDEB"
      d="M167.4,302.6v10.7c0,2.6,2,4.8,4.6,5h0c4,0.4,7.1,3.7,7.1,7.8l0,0c0,4.3-3.5,7.8-7.8,7.8h-32.6c-6.5,0-11.7-5.2-11.7-11.7v0v-6.3v-13.3H167.4z"
    />
    <path
      fill="#AB7060"
      d="M160,122.2c27.3,0,26.3-31.7,21.9-39.4c-6.6-11.7-35.4-10.5-42.4,2.5C133.6,96.2,135.8,122.2,160,122.2z"
    />
    <path
      fill="#F8C6B9"
      d="M118.1,300.7c0,0,30.4,3.8,73.9,3.1c28.8-0.5,37.6-3.2,37.6-3.2s-0.2-27.1,0-37.3c0.6-36.3-15-54.1-34.3-66.6c-23.8-15.4-62.4-20.3-85.1-2.7c-7.6,5.9-15.8,29.3-7.6,43.9c4.6,8.2,14.8,13.4,16.4,32.8C119.6,278.2,118.1,300.7,118.1,300.7z"
    />
    <path
      fill="#F8EDEB"
      d="M164.8,171.9c-15.4-2.3-42.8-6.5-42.8-6.5v60.3l43,4l29-10.8v-60.3L164.8,171.9z"
    />
    <path
      fill="#AB7060"
      d="M142.9,186.3c2.5,1.6,5,3.4,6.8,5.8s2.7,5.6,1.8,8.4c-0.9,2.8-4.1,5-6.9,4.2c2.1,1.6,2.8,4.8,1.6,7.1s-4.2,3.6-6.7,2.9c2.1,0.7,1.7,4.3-0.2,5.4c-2,1.1-4.4,0.6-6.6,0.1c-6.4-1.6-12.8-3.1-19.2-4.7c-3-0.7-6.3-1.6-8.4-3.9c-3.5-3.7-2.8-9.6-1.5-14.5c1.8-6.6,5.1-21.4,12.9-22.9C123.7,172.9,137.1,182.5,142.9,186.3z"
    />
    <path
      fill="#AB7060"
      d="M185.7,212.5c-3-0.1-6-0.3-8.8-1.5c-2.7-1.2-5.2-3.4-5.8-6.3s0.9-6.4,3.8-7.2c-2.6-0.3-4.9-2.6-5.1-5.3c-0.2-2.6,1.7-5.3,4.3-5.9c-2.2,0.5-3.7-2.8-2.6-4.8s3.5-2.8,5.6-3.5c6.3-2,12.6-3.9,18.9-5.9c3-0.9,6.2-1.9,9.2-1c4.9,1.4,7.3,6.8,8.7,11.7c1.8,6.5,6.6,21,0.7,26.2C209,214,192.6,212.7,185.7,212.5z"
    />
    <path
      fill="#D3ECEE"
      d="M188.8,77.1c-0.1-4.6-2-16.3-13.5-23.4c-0.8-0.5-1.7-1-2.6-1.4c0.7-4.6-1.1-9.4-6.5-12.4c-10.2-5.7-19.2,3.2-19.3,12c-0.5,0.2-1,0.5-1.5,0.7c-5.6,3-11.1,8.3-13.2,16c-2.4,8.6-1.6,24.9,4.8,29.2c-0.7-6,1.6-10.5,1.6-10.5c8.3,2.5,19.5,2.5,25.1,0c5.3-2.4,9.8-6.9,9.8-6.9c0.2,0,0.3,0,0.5,0c5,0.4,11.2,4.3,10.5,16C184.8,96.7,189.8,87,188.8,77.1z"
    />
    <path
      fill="#F8EDEB"
      d="M155.8,97.5c0-2.5-2.4-4.6-5.6-4.8c-0.2,0-0.4,0-0.6,0c-3.4,0-6.2,2.2-6.2,4.9s2.8,4.9,6.2,4.9c0.2,0,0.4,0,0.6,0C153.4,102,155.8,100,155.8,97.5z"
    />
    <path
      fill="#4C3731"
      d="M155.8,97.5c0-2.5-2.4-4.6-5.6-4.8c-1.1,1.2-1.8,2.9-1.8,4.8s0.7,3.6,1.8,4.8C153.4,102,155.8,100,155.8,97.5z"
    />
    <path
      fill="#F8EDEB"
      d="M176.8,97.5c0-2.5-2.4-4.6-5.6-4.8c-0.2,0-0.4,0-0.6,0c-3.4,0-6.2,2.2-6.2,4.9s2.8,4.9,6.2,4.9c0.2,0,0.4,0,0.6,0C174.4,102,176.8,100,176.8,97.5z"
    />
    <path
      fill="#4C3731"
      d="M176.8,97.5c0-2.5-2.4-4.6-5.6-4.8c-1.1,1.2-1.8,2.9-1.8,4.8s0.7,3.6,1.8,4.8C174.4,102,176.8,100,176.8,97.5z"
    />
    <path
      fill="#415546"
      d="M346.2,249.3v-23.7h-75.3v23.7h33.3v62h-14.7c-3.5,0-6.3,2.8-6.3,6.3h50.7c0-3.5-2.8-6.3-6.3-6.3h-14.7v-62H346.2z"
    />
    <path
      fill="#D3ECEE"
      d="M295.2,226.4c-5.8-0.6-20.2,0.2-21.6-20.1c-1.5-20.7-0.6-39.2,0.6-58.3c0.3-4.9,1.2-11.5,5.4-14.1c3-1.8,9.2-1.9,10.4-4.1c2-3.6-0.8-8.3,1.9-10.8c3-2.7,6.6-1.5,15-1.7c9.3-0.2,17.1-0.7,19.1,1c3.2,2.9-0.1,6.9,1.7,10.5c1.4,2.9,7.7,2.4,11,4.8c5.7,4.1,5.7,12.3,5.7,19.3c-0.2,20.4,0.4,37.5-0.4,56.2c-0.1,2.9-0.3,6-1.5,8.7c-2,4.5-8.8,7.4-13.7,8.3C323.3,227.2,301.2,226.9,295.2,226.4z"
    />
    <path
      fill="#415546"
      d="M293.9,153.6L293.9,153.6c-5.4,5.4-5.4,14.2,0,19.6l13.6,13.6c5.4,5.4,14.2,5.4,19.6,0l0,0c5.4-5.4,5.4-14.2,0-19.6l-13.6-13.6C308.1,148.2,299.4,148.2,293.9,153.6z"
    />
    <path
      fill="#F3AB96"
      d="M293.9,153.6L293.9,153.6c-5.4,5.4-5.4,14.2,0,19.6l6.8,6.8l19.6-19.6l-6.8-6.8C308.1,148.2,299.4,148.2,293.9,153.6z"
    />
    <path
      fill="#415546"
      d="M333.9,204.6L333.9,204.6c0-7.6-6.2-13.8-13.8-13.8h-19.2c-7.6,0-13.8,6.2-13.8,13.8v0c0,7.6,6.2,13.8,13.8,13.8h19.2C327.7,218.4,333.9,212.2,333.9,204.6z"
    />
    <path
      fill="#F3AB96"
      d="M333.9,204.6L333.9,204.6c0-7.6-6.2-13.8-13.8-13.8h-9.6v27.7h9.6C327.7,218.4,333.9,212.2,333.9,204.6z"
    />
    <rect x="286.9" y="114.3" fill="#415546" width="44.3" height="11.5" />
  </Svg>
);

export default PersonAtHomeLookingAtPrescription;
