import type { MiniInterviewType } from '@buoy-components/polaris/types';

export const POLARIS_EAGER_LOAD_FLAG = 'growth-public-site-eager-load-polaris';
export const PXOC_QUESTION_COPY_FLAG_DX =
  'growth-public-site-abtest-pxoc-dx-copy';
export const PXOC_QUESTION_COPY_FLAG_SX =
  'growth-public-site-abtest-pxoc-sx-copy';
export const TREATMENT_GUIDANCE_EMBEDDED_INTERVIEW_FLAG =
  'growth-public-site-treatment-guidance-embedded-interview';
export const TXOC_FLAG = 'growth-public-site-txoc-lifeforce';

export const MINIMUM_PRODUCTS_COUNT = 3;
export const PolarisSupportedInterviewTypes = ['dxoc', 'treatmentGuidance'];
/**All interviews required for the interview of type [interviewType] to run
 * e.g. Sx Treatment Guidance requires interviews of type treatment, treatmentGuidance, and symptoms
 **/
export const requiredInterviewsMap: Record<string, MiniInterviewType[]> = {
  dxoc: ['dxoc'],
  symptoms: ['symptoms'],
  emergency: ['emergency'],
  treatment: ['treatment', 'dxoc', 'symptoms'],
  treatmentGuidance: ['treatmentGuidance'],
  txoc: ['txoc'],
  pxoc: ['pxoc'],
};
