import React, { FC } from 'react';

import {
  Container,
  Inner,
  Image,
  ImageContainer,
  TextContainer,
  Title,
  DetailsContainer,
  Detail,
  CtaContainer,
  Cta,
} from './style';

import sanityImgUtil from 'utils/sanityImgUtil';

import { BannerModule as IBannerModule } from 'types';

export interface Props {
  module: IBannerModule;
}

const BannerModule: FC<Props> = ({ module }) => {
  const { title, category, date, buttonLabel, buttonLink, image } = module;

  return (
    <Container data-testid="banner-module">
      <Inner>
        {image.src && (
          <ImageContainer>
            <Image
              alt={image.alt}
              src={sanityImgUtil(image, 500)}
              dimensions={image.metadata?.dimensions}
              crop={image.crop}
            />
          </ImageContainer>
        )}
        <TextContainer>
          <DetailsContainer>
            <Detail>{category}</Detail>
            <Detail>{date}</Detail>
          </DetailsContainer>
          <Title>{title}</Title>
          {buttonLabel && buttonLink && (
            <CtaContainer>
              <Cta
                ariaLabel={buttonLabel}
                label={buttonLabel}
                to={buttonLink}
              />
            </CtaContainer>
          )}
        </TextContainer>
      </Inner>
    </Container>
  );
};

export default BannerModule;
