import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
  size?: 'large' | 'small';
};

const ClearIndicatorIcon: FC<Props> = ({
  className = '',
  color = theme.palette.primary.main,
  size = 'large',
}) => {
  if (size === 'large') {
    return (
      <Svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        fill="transparent"
        className={className}
      >
        <desc>Clear Indicator Icon.</desc>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75723 2.81455L2.81442 3.75736L7.05706 8L2.81442 12.2426L3.75723 13.1854L7.99987 8.94281L12.2425 13.1854L13.1853 12.2426L8.94268 8L13.1853 3.75736L12.2425 2.81455L7.99987 7.05719L3.75723 2.81455Z"
          fill={color}
        />
      </Svg>
    );
  }

  return (
    <Svg
      width="14px"
      height="15px"
      viewBox="0 0 14 15"
      fill="transparent"
      className={className}
    >
      <desc>Clear Indicator Icon.</desc>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.28776 2.96273L2.4628 3.78769L6.17511 7.5L2.4628 11.2123L3.28776 12.0373L7.00007 8.32496L10.7124 12.0373L11.5373 11.2123L7.82503 7.5L11.5373 3.78769L10.7124 2.96273L7.00007 6.67504L3.28776 2.96273Z"
        fill={color}
      />
    </Svg>
  );
};

export default ClearIndicatorIcon;
