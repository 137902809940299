import React, { FC } from 'react';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import { Img } from 'styled';
import {
  Container,
  Header,
  ImagesContainer,
  Image,
  ListItemsContainer,
  ListItemsInnerContainer,
  IconAndText,
  IconContainer,
  IconText,
} from './style';

import {
  ListItemsAndLogosModule as IListItemsAndLogosModule,
  Image as IImage,
  IconAndText as IIconAndText,
} from 'types';

type Props = {
  module: IListItemsAndLogosModule;
  applyPaddingX?: boolean;
};

const ListItemsAndLogosModule: FC<Props> = ({
  module,
  applyPaddingX = true,
}) => {
  const { header, listItems, images } = module;

  return (
    <Container
      applyPaddingX={applyPaddingX}
      data-testid="list-items-and-logos-module"
    >
      {header && <Header>{header}</Header>}
      {!!listItems.length && (
        <ListItemsContainer>
          <ListItemsInnerContainer>
            {listItems.map((listItem: IIconAndText) => (
              <IconAndText key={listItem.id}>
                <IconContainer>
                  <Img
                    alt={listItem.icon.alt}
                    src={sanityImgUtil(listItem.icon, 96)}
                    dimensions={listItem.icon.metadata?.dimensions}
                    crop={listItem.icon.crop}
                    preloadContainerClassName="w100 h100 fit-contain"
                    className="fit-contain w100"
                  />
                </IconContainer>
                <IconText>{listItem.text}</IconText>
              </IconAndText>
            ))}
          </ListItemsInnerContainer>
        </ListItemsContainer>
      )}
      <ImagesContainer>
        {images.map((image: IImage) => (
          <Image
            key={image.id}
            alt={image.alt}
            sizes="(max-width: 1024px) 200px, 400px"
            src={sanityImgUtil(image, 200)}
            srcSet={sanityImgSrcSetUtil(image, 200, 400, 400)}
          />
        ))}
      </ImagesContainer>
    </Container>
  );
};

export default ListItemsAndLogosModule;
