import SwipeableDrawer, {
  SwipeableDrawerProps,
} from '@mui/material/SwipeableDrawer';
import React from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import IconButton from '@mui/material/IconButton';
import { CloseIconThin } from '@buoyhealth/common.buoy-icons';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { StyledBox, Puller, Nav } from './style';

export interface ISwipeableMobileDrawerProps extends SwipeableDrawerProps {
  HeaderComponent?: React.ReactNode;
  showBackButton?: boolean;
  onBackClick?: () => void;
}

const SwipeableMobileDrawer = ({
  onBackClick,
  onClose,
  children,
  className,
  HeaderComponent,
  showBackButton,
  ...rest
}: ISwipeableMobileDrawerProps) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      className={className}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          // This seems excessive but it's necessary to make the drawer always appear above ads
          zIndex: 10000000000,
        },
      }}
      {...rest}
    >
      <StyledBox>
        <Puller className="SwipeableDrawer__Puller" />
        <Nav className="SwipeableDrawer__Nav">
          <IconButton
            sx={{
              color: theme.palette.gray[50],
              visibility: showBackButton ? 'auto' : 'hidden',
            }}
            onClick={onBackClick}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton onClick={onClose}>
            <CloseIconThin color={theme.palette.gray[50]} />
          </IconButton>
        </Nav>
        {HeaderComponent}
      </StyledBox>
      {children}
    </SwipeableDrawer>
  );
};

export default SwipeableMobileDrawer;
