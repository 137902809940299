import cx from 'classnames';

import { Button, LinkOrButton } from 'styled';
import {
  Container,
  TopSection,
  Button as StyledButton,
  Description,
  Tab,
  TabContainer,
  TabsContainer,
  Content,
  ContentContainer,
  TitleContainer,
} from './style';

const defaultProps = {
  textIsCentered: false,
  buttonVariant: 'primary',
};

type Props = {
  className?: string;
  title?: string;
  description?: string;
  tabs?: string[];
  onTabClick?(clickedTab: string): any;
  activeTab?: string;
  buttonLabel?: string;
  buttonVariant?: 'primary' | 'underline-black' | 'underline-white';
  buttonUrl?: string;
  buttonInlineWithTitle?: boolean;
  textIsCentered: boolean;
} & typeof defaultProps;

const ModuleHeader = (props: Props) => {
  const {
    className,
    title,
    textIsCentered,
    description,
    tabs,
    activeTab,
    onTabClick,
    buttonLabel,
    buttonVariant,
    buttonUrl,
    buttonInlineWithTitle,
  } = props;

  if (
    (!tabs || (tabs && tabs.length <= 1)) &&
    !description &&
    !title &&
    !buttonLabel &&
    !buttonUrl
  ) {
    return null;
  }

  const handleOnTabClick = (tab: string) => {
    typeof onTabClick === 'function' && onTabClick(tab);
  };

  const renderTabs = () => {
    if (!tabs || !tabs.length) return null;

    return (
      <TabsContainer>
        {tabs.map((tab) => {
          if (tab === 'default' || !tab) return null;

          const handleClick = () => handleOnTabClick(tab);

          return (
            <TabContainer key={tab} mr={1.5}>
              <Tab
                as={Button}
                isActive={tab === activeTab}
                label={tab}
                ariaLabel={tab}
                onClick={handleClick}
              />
            </TabContainer>
          );
        })}
      </TabsContainer>
    );
  };

  const renderButton = () => {
    return (
      <StyledButton
        as={LinkOrButton}
        ariaLabel={buttonLabel as string}
        label={buttonLabel}
        to={buttonUrl}
        variant={
          buttonVariant === 'primary'
            ? buttonVariant
            : (`hover-${buttonVariant}` as
                | 'hover-underline-white'
                | 'hover-underline-black')
        }
      />
    );
  };

  const renderDescription = () => {
    return <Description>{description as string}</Description>;
  };

  const renderContent = () => {
    if (!description && !(tabs && !!tabs.length) && !buttonLabel) {
      return null;
    }

    const descriptionOrTabsAreAvailable =
      description || (tabs && !!tabs.length);

    return (
      <ContentContainer
        display="flex"
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
        justifyContent={[
          'unset',
          'unset',
          'unset',
          descriptionOrTabsAreAvailable ? 'space-between' : 'flex-end',
        ]}
        mt={1.5}
      >
        {descriptionOrTabsAreAvailable && (
          <Content
            as="span"
            moduleHasButton={!!buttonLabel}
            buttonInlineWithTitle={buttonInlineWithTitle}
            display="flex"
            flex={1}
          >
            {renderTabs() || renderDescription()}
          </Content>
        )}
        {!!buttonLabel &&
          !!buttonUrl &&
          !buttonInlineWithTitle &&
          renderButton()}
      </ContentContainer>
    );
  };

  return (
    <Container
      display="flex"
      flexDirection="column"
      alignItems={textIsCentered ? 'center' : undefined}
      textAlign={textIsCentered ? 'center' : undefined}
      className={cx('ModuleHeader', className)}
    >
      <TopSection
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
        flexWrap="wrap"
      >
        <TitleContainer
          moduleHasButton={!!buttonLabel}
          buttonInlineWithTitle={buttonInlineWithTitle}
        >
          <h2>{title}</h2>
        </TitleContainer>
        {!!buttonLabel &&
          !!buttonUrl &&
          buttonInlineWithTitle &&
          renderButton()}
      </TopSection>
      {renderContent()}
    </Container>
  );
};

ModuleHeader.defaultProps = defaultProps;

export default ModuleHeader;
