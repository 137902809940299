import React, { FC } from 'react';

import formatAriaLabelWithTitle from 'utils/formatAriaLabelWithTitle';

import { LinkOrButton } from 'styled';
import {
  Container,
  SubtitleContainer,
  LinkedTitleContainer,
  LinkedSubtitleContainer,
  Title,
  Subtitle,
} from './style';

import { Color } from 'styled/theme/colors';

import { Button } from 'types';

interface Props {
  subtitle?: string;
  title: string;
  button: Button;
  subTitleSlug?: string;
  textColor: Color;
  isArticleItem?: boolean;
}

const ItemHeader: FC<Props> = ({
  subtitle = '',
  title,
  button,
  subTitleSlug,
  textColor,
  isArticleItem,
}) => {
  return (
    <Container mb={isArticleItem ? 0.5 : 1}>
      <SubtitleContainer
        mb={isArticleItem ? [0.25, 0.25, 0.25, 0.5] : [0.25, 0.25, 0.25, 0.75]}
      >
        {subtitle &&
          (subTitleSlug || button.link ? (
            <LinkedSubtitleContainer
              as={LinkOrButton}
              textColor={textColor}
              ariaLabel={formatAriaLabelWithTitle(subtitle)}
              to={subTitleSlug || button.link}
            >
              <Subtitle
                isArticleItem={isArticleItem}
                as="span"
                textColor={textColor}
              >
                {subtitle}
              </Subtitle>
            </LinkedSubtitleContainer>
          ) : (
            <Subtitle as="span" textColor={textColor}>
              {subtitle}
            </Subtitle>
          ))}
      </SubtitleContainer>
      <div>
        {button.link ? (
          <LinkedTitleContainer
            as={LinkOrButton}
            textColor={textColor}
            ariaLabel={formatAriaLabelWithTitle(button.label)}
            to={button.link}
          >
            <Title textColor={textColor}>{title}</Title>
          </LinkedTitleContainer>
        ) : (
          <Title textColor={textColor}>{title}</Title>
        )}
      </div>
    </Container>
  );
};

export default ItemHeader;
