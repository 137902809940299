import ApiClient from 'lib/SanityClient/ApiClient';

import {
  FetchShowcasePageAction,
  SetActiveVideoAction,
} from 'state/actions/types/showcasePageActionsTypes';

export const fetchShowcasePage = (
  slug: string,
  preview?: string,
): FetchShowcasePageAction => {
  return {
    type: 'FETCH_SHOWCASE_PAGE',
    payload: ApiClient.fetchShowcasePage(slug, preview),
  };
};

export const setActiveVideo = (
  vimeoId: string,
  youtubeId: string,
  title?: string,
): SetActiveVideoAction => {
  const videoId = vimeoId || youtubeId;
  return {
    type: 'SET_ACTIVE_VIDEO',
    payload: videoId ? { vimeoId, title, youtubeId } : null,
  };
};
