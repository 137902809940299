import React, { Component, RefObject, ReactText } from 'react';
import cx from 'classnames';
import { Base, ErrorMessage as ErrorMessageWithIcon } from 'styled';
import {
  Container,
  Label,
  ErrorMessageWithinField,
  InputContainer,
} from './style';
import { Color } from 'styled/theme/colors';

const MIN_ROWS = 5;

export interface Props {
  id?: string;
  name: string;
  label?: string;
  className: string;
  color?: Color;
  ariaLabel: string;
  onBlur: (value: ReactText) => any;
  onFocus: (value: ReactText) => any;
  onChange: (value: ReactText) => any;
  pattern?: string;
  placeholder?: string;
  required: boolean;
  autoComplete?: boolean;
  maxLength?: number;
  type: 'email' | 'text' | 'tel' | 'number' | 'textarea';
  value?: ReactText;
  min?: number;
  max?: number;
  showLabelAsPlaceholder: boolean;
  showIcon: boolean;
  elemRef?: RefObject<HTMLInputElement | HTMLTextAreaElement>;
  errorMessage?: string;
  showError: boolean;
  variant?: 'with-bottom-border' | 'with-all-borders';
}

const defaultProps = {
  className: '',
  onBlur: (value: ReactText) => {},
  onFocus: (value: ReactText) => {},
  onChange: (value: ReactText) => {},
  required: false,
  autoComplete: true,
  type: 'text',
  showLabelAsPlaceholder: true,
  showIcon: false,
  showError: false,
  variant: 'with-bottom-border',
};

class TextField extends Component<Props> {
  static defaultProps = defaultProps;

  render() {
    const {
      id,
      ariaLabel,
      className,
      color,
      label,
      name,
      onBlur,
      onFocus,
      onChange,
      pattern,
      placeholder,
      type,
      required,
      autoComplete,
      value,
      maxLength,
      min,
      max,
      showLabelAsPlaceholder,
      showIcon,
      elemRef,
      errorMessage,
      showError,
      variant,
    } = this.props;

    const _id = id || name;

    // We show label as "placeholder" to avoid the placeholder HTML attribute for a11y purposes

    return (
      <Container
        className={cx('TextField', className)}
        showError={showError}
        color={color}
        variant={variant}
        value={value}
        data-testid="text-field"
      >
        <Base position="relative">
          {label && (
            <Label
              htmlFor={_id}
              variant={variant}
              showLabelAsPlaceholder={showLabelAsPlaceholder}
            >
              {showLabelAsPlaceholder ? placeholder || label : label}
            </Label>
          )}
          {variant === 'with-bottom-border' && (
            <ErrorMessageWithinField
              role="alert"
              aria-hidden={!showError}
              showError={showError}
            >
              {errorMessage}
            </ErrorMessageWithinField>
          )}
        </Base>
        {type === 'textarea' ? (
          <textarea
            aria-label={ariaLabel}
            id={_id}
            name={name}
            onBlur={(e) => onBlur(e.target.value)}
            onFocus={(e) => onFocus(e.target.value)}
            onChange={(e) => onChange(e.target.value)}
            required={required}
            value={value}
            rows={MIN_ROWS}
            ref={elemRef as RefObject<HTMLTextAreaElement>}
          />
        ) : (
          <InputContainer showIcon={showIcon}>
            <input
              aria-label={ariaLabel}
              id={_id}
              name={name}
              onBlur={(e) => onBlur(e.target.value)}
              onFocus={(e) => onFocus(e.target.value)}
              onChange={(e) => onChange(e.target.value)}
              pattern={pattern}
              type={type}
              min={min}
              max={max}
              required={required}
              maxLength={maxLength}
              value={value}
              autoComplete={autoComplete ? 'on' : 'off'}
              ref={elemRef as RefObject<HTMLInputElement>}
            />
          </InputContainer>
        )}
        {errorMessage && variant === 'with-all-borders' && (
          <ErrorMessageWithIcon
            aria-hidden={!showError}
            showError={showError}
            message={errorMessage}
            marginTop={1.25}
          />
        )}
      </Container>
    );
  }
}

export default TextField;
