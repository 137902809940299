import styled, { css } from 'styled-components';
import { Base, Theme } from 'styled';
import { Props } from './index';

type ContainerProps = Pick<
  Props,
  'showError' | 'color' | 'variant' | 'menuMessage'
>;

type LabelProps = Pick<Props, 'showLabel' | 'variant'>;

type ErrorMessageProps = Pick<Props, 'showError'>;

const getBottomBorderContainerStyles = (
  color: ContainerProps['color'],
  showError: ContainerProps['showError']
) => css`
  label {
    color: ${({ theme }) => color || theme.palette.text.primary};
  }

  .Select__control {
    border: none;
    border-bottom: 1px solid
      ${({ theme }) =>
        showError
          ? theme.palette.status.error
          : color || theme.palette.gray[50]};
    border-radius: 0;

    &--is-focused {
      border-bottom: 1px solid
        ${({ theme }) => color || theme.palette.primary.main[80]};
    }
  }

  .Select__value-container {
    padding: 0.625rem 0.75rem;
  }

  .Select__dropdown-indicator {
    color: ${({ theme }) => color || theme.palette.gray[50]};
  }

  .Select__placeholder,
  .Select__single-value {
    ${({ theme }) => theme.typography.variants.primary.xs};
    margin-left: 0;
    margin-right: 0;
  }

  .Select__menu {
    border-radius: 0;
    border: solid 1px ${({ theme }) => color || theme.palette.gray[50]};
  }

  .Select__option {
    ${({ theme }) => theme.typography.variants.primary.xs};
    color: ${({ theme }) => theme.palette.text.primary};

    :focus,
    :active {
      background-color: ${({ theme }) => theme.palette.gray[0]};
    }

    &--is-focused,
    &--is-selected {
      background-color: ${({ theme }) => theme.palette.gray[0]};

      :focus,
      :active {
        background-color: ${({ theme }) => theme.palette.gray[0]};
      }
    }
  }
`;

const dropdownIndicatorWidth = '1.875rem';
const clearIndicatorWidth = '2rem';
const inputLeftPadding = '1rem';

const getAllBordersContainerStyles = (
  color: ContainerProps['color'],
  showError: ContainerProps['showError'],
  menuMessage: ContainerProps['menuMessage']
) => css`
  position: relative;
  margin-top: 4rem;
  margin-bottom: 2.5rem;
  caret-color: ${({ theme }) => theme.palette.primary.main};

  label {
    color: ${({ theme }) => color || theme.palette.text.primary};
  }
  
  .Select__control {
    cursor: pointer;
    border: 1px solid
    ${({ theme }) =>
      showError ? theme.palette.status.error : color || theme.palette.gray[50]};
    border-radius: 0.25rem;
    
    :focus,
    :hover {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }

    &--is-focused {
      cursor: text;
    }

    &--menu-is-open {
      border-radius: 0.25rem 0.25rem 0 0;
      border-color: ${({ theme }) => theme.palette.primary.main};
      
      .Select__placeholder {
        color: ${({ theme }) => theme.palette.primary.main};
      }
      
      .Select__dropdown-indicator {
        transform: scaleY(-1);
        
        svg {
          path {
            stroke: ${({ theme }) => theme.palette.primary.main};
          }
        }
      }
    }
  }

  .Select__value-container {
    margin: 1rem;
    padding: 0;
  }
  
  .Select__placeholder,
  .Select__single-value,
  .Select__option {
    ${({ theme }) => theme.typography.variants.primary.sm};
  }
  
  .Select__placeholder {
    color: ${({ theme }) => color || theme.palette.gray[90]};
  }

  .Select__dropdown-indicator {
    ${({ theme }) => theme.transitions['transition-short']};
  }

  .Select__indicator {
    cursor: pointer;
  }

  .Select__menu {
    border-radius: 0.25rem;
    margin-top: 0rem;
  }
  
  .Select__menu-list {
    padding-top: 0rem;
    padding-bottom: 0rem;
    background-color: ${({ theme }) => theme.palette.gray[0]};

    ::before {
      display: inline-flex;
      color: ${({ theme }) => theme.palette.purple[80]};
      padding: ${!!menuMessage && '0.625rem 1rem'};
      content: ${!!menuMessage && `"${menuMessage}"`};
    }
  }
  
  .Select__value-container,
  .Select__input input,
  .Select__multi-value__label {
      ${({ theme }) => theme.typography.variants.primary.sm};
    }
  }

  /* Override input container */
  .Select__value-container > div:last-of-type {
    margin: 0;
  }

  .Select__multi-value {
    background-color: ${({ theme }) => theme.palette.gray[0]};
    border-radius: 0.25rem;

    &__label {
      padding: 0.5rem;
      white-space: normal;
      overflow-wrap: break-word;
    }

    &__remove {
      cursor: pointer;
      padding: 0rem 0.5rem;
      
      :hover {
        background-color: ${({ theme }) => theme.palette.rose[0]};

        svg {
          path {
            fill: ${({ theme }) => theme.palette.red[70]};
          }
        }
      }
    }
  }

  .Select__option {
    background-color: ${({ theme }) => theme.palette.gray[0]};
    padding: 1rem;
    overflow-wrap: break-word;

    :focus,
    :active {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.common.white};
    }

    :last-of-type {
      border-radius: 0 0 0.25rem 0.25rem;
    }

    &--is-focused,
    &--is-selected {
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: ${({ theme }) => theme.palette.common.white};
      
      :focus,
      :active {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.common.white};
      }
    }
  }

  .Select__multi-value,
  .Select__input input[style] {
    width: auto;
    max-width: ${({ theme }) =>
      `calc(100vw - ${theme.sizes.drugPageContentPaddingMobile} * 2 - ${inputLeftPadding} - ${dropdownIndicatorWidth})`} !important;

    ${Theme.mediaQueries.sm} {
      max-width: ${({ theme }) =>
        `calc(100vw - ${theme.sizes.drugPageNavWidth} - ${theme.sizes.drugPageContentPaddingTablet} * 2 - ${inputLeftPadding} - ${dropdownIndicatorWidth})`} !important;
    }

    @media screen and (min-width: 1240px) {
      max-width: ${({ theme }) =>
        `calc(${theme.sizes.blogPostViewMaxWidth} - ${dropdownIndicatorWidth} - 2rem)`} !important;
    }
  }

  .Select__value-container--has-value .Select__multi-value,
  .Select__value-container--has-value .Select__input input[style] {
    width: auto;
    max-width: ${({ theme }) =>
      `calc(100vw - ${theme.sizes.drugPageContentPaddingMobile} * 2 - ${inputLeftPadding} - ${dropdownIndicatorWidth} - ${clearIndicatorWidth} - 1.5rem)`} !important;

    ${Theme.mediaQueries.sm} {
      max-width: ${({ theme }) =>
        `calc(100vw - ${theme.sizes.drugPageNavWidth} - ${theme.sizes.drugPageContentPaddingTablet} * 2 - ${inputLeftPadding} - ${dropdownIndicatorWidth} - ${clearIndicatorWidth} - 1.5rem)`} !important;
    }

    @media screen and (min-width: 1240px) {
      max-width: ${({ theme }) =>
        `calc(${theme.sizes.blogPostViewMaxWidth} - ${dropdownIndicatorWidth} - ${clearIndicatorWidth} - 1.5rem)`} !important;
    }
  }
`;

export const Label = styled.label<LabelProps>`
  ${({ theme, variant }) =>
    variant === 'with-all-borders'
      ? theme.typography.variants.primary.sm
      : theme.typography.variants.primary.xs};
  ${({ theme }) => theme.transitions['transition-short']};
  opacity: ${({ showLabel }) => (showLabel ? 1 : 0)};
  position: absolute;
  top: ${({ variant }) => (variant === 'with-all-borders' ? '-2rem' : '-1rem')};
  left: 0;
`;

export const ErrorMessageAboveField = styled.p<ErrorMessageProps>`
  opacity: ${({ showError }) => (showError ? 1 : 0)};
  color: ${({ theme }) => theme.palette.status.error};
  position: absolute;
  right: 0;
  margin-top: -1rem;
`;

export const MessageContainer = styled(Base)`
  position: relative;
`;

export const Container = styled(Base)<ContainerProps>`
  ${({ theme }) => theme.typography.variants.primary.xs};
  ${({ variant, color, showError }) =>
    variant === 'with-bottom-border' &&
    getBottomBorderContainerStyles(color, showError)};
  ${({ variant, color, showError, menuMessage }) =>
    variant === 'with-all-borders' &&
    getAllBordersContainerStyles(color, showError, menuMessage)};

  .Select__control {
    background-color: transparent;
  }

  [data-whatintent='mouse'] .Select__control--is-focused,
  [data-whatintent='touch'] .Select__control--is-focused,
  .Select__control--is-focused {
    box-shadow: none;
  }

  .Select__single-value {
    color: ${({ theme, color }) => color || theme.palette.text.primary};
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    box-shadow: none;
  }
`;
