import React, { FC } from 'react';
import BlockContent from '@sanity/block-content-to-react';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import { ArticlePortableText } from 'styled';
import { Container, Title, ImagesContainer, Image } from './style';

import { ExternalLink, InternalLink } from 'constants/PortableTextSerializer';

import { LogosModule as ILogosModule } from 'types';

type Props = {
  module: ILogosModule;
};

const LogosModule: FC<Props> = ({ module }) => {
  const { moduleTitle, moduleDescription, images, renderLogosGrayscale } =
    module;

  if (!images.length) return null;

  return (
    <Container>
      {!!moduleTitle && <Title>{moduleTitle}</Title>}
      {!!moduleDescription.length && (
        <ArticlePortableText>
          <BlockContent
            blocks={moduleDescription}
            serializers={{
              marks: { link: ExternalLink, internalLink: InternalLink },
            }}
          />
        </ArticlePortableText>
      )}
      <ImagesContainer>
        {images.map((image) => (
          <Image
            key={image.id}
            alt={image.alt}
            sizes="(max-width: 1024px) 200px, 400px"
            src={sanityImgUtil(image, 200)}
            srcSet={sanityImgSrcSetUtil(image, 200, 400, 400)}
            renderLogosGrayscale={renderLogosGrayscale}
          />
        ))}
      </ImagesContainer>
    </Container>
  );
};

export default LogosModule;
