import React, { FC, useRef } from 'react';

import useRefInView from 'hooks/useRefInView';

import { CheckmarkInsideFilledCircle, Video } from 'styled';
import {
  Container,
  TextContainer,
  TitleContainer,
  VideoContainer,
  ListItemsContainer,
  ListItemsInnerContainer,
  IconAndText,
  IconText,
} from './style';

import {
  VideoAndListItemsModule as IVideoAndListItemsModule,
  ListItemText as IListItemText,
} from 'types';

type Props = {
  module: IVideoAndListItemsModule;
};

const VideoAndListItemsModule: FC<Props> = ({ module }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const moduleIntersectsWithViewport = useRefInView(containerRef);

  const { header, listItems, video } = module;

  return (
    <Container ref={containerRef} data-testid="video-list-items-module">
      <VideoContainer>
        <Video
          video={video}
          autoPlay
          autoPause={false}
          loop
          isMuted
          isFullWidth
          showCustomVideoControl={false}
          showVimeoVideoControl={false}
          quality="2K"
        />
      </VideoContainer>
      <TextContainer>
        <TitleContainer>{header}</TitleContainer>
        {!!listItems.length && (
          <ListItemsContainer
            moduleIntersectsWithViewport={moduleIntersectsWithViewport}
          >
            <ListItemsInnerContainer>
              {listItems.map((listItem: IListItemText) => (
                <IconAndText key={listItem.id}>
                  <CheckmarkInsideFilledCircle />
                  <IconText>{listItem.text}</IconText>
                </IconAndText>
              ))}
            </ListItemsInnerContainer>
          </ListItemsContainer>
        )}
      </TextContainer>
    </Container>
  );
};

export default VideoAndListItemsModule;
