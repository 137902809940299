import React from 'react';
import Dialog from '@mui/material/Dialog';
import ImageHeader from './ImageHeader';
import Box from '@mui/material/Box';
import { IMobileSupplierCtaModalProps } from './MobileDrawer';
import ModalContent from './ModalContent';
import IconButton from '@mui/material/IconButton';
import { CloseIconThin } from '@buoyhealth/common.buoy-icons';
import theme from 'styled/theme';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

const DesktopSupplierPromoModal = ({
  isOpen,
  onClose,
  page,
  config,
  paginate,
  impressionId,
  triggerIFrameOpen,
}: IMobileSupplierCtaModalProps) => {
  const goToPreviousPage = () => paginate(-1);
  const goToNextPage = () => paginate(1);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <Box display="flex" position="relative">
        <IconButton
          sx={{
            position: 'absolute',
            left: '0.5rem',
            top: '0.5rem',
            color: theme.palette.gray[50],
            visibility: page === 0 ? 'hidden' : 'auto',
          }}
          onClick={goToPreviousPage}
        >
          <ArrowBackIos />
        </IconButton>
        <ImageHeader config={config} page={page} />
        <Box p="1.5rem 1.5rem 2rem" maxWidth="500px">
          <ModalContent
            config={config}
            page={page}
            goToNextPage={goToNextPage}
            impressionId={impressionId}
            buttonProps={{
              sx: {
                minWidth: '268px',
              },
            }}
            triggerIFrameOpen={triggerIFrameOpen}
          />
        </Box>
        <Box p={1} position="absolute" right={1} top={1}>
          <IconButton onClick={onClose} sx={{ padding: '4px' }}>
            <CloseIconThin color={theme.palette.gray[50]} size={30} />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DesktopSupplierPromoModal;
