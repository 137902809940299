import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled/components/base';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  circleColor?: Color;
  checkmarkColor?: Color;
};

const CheckmarkInsideFilledCircle: FC<Props> = ({
  className = '',
  circleColor = theme.palette.primary.main,
  checkmarkColor = theme.palette.common.white,
}) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <desc>Checkmark Inside Circle.</desc>
    <circle cx="12" cy="12" r="12" fill={circleColor} />
    <svg x="3" y="3">
      <path
        fill={checkmarkColor}
        d="M16.5029 4.82579L14.3192 3L7.04678 11.5665L3.9842 9.01716C3.62389 8.71724 3.08344 8.7641 2.78003 9.12026L1.5 10.6292L6.27539 14.6266C6.81585 15.0765 7.85054 15.1702 8.30567 14.6266L16.5029 4.82579Z"
      />
    </svg>
  </Svg>
);

export default CheckmarkInsideFilledCircle;
