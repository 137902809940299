const formatPhoneNumber = (value: string): string => {
  if (value.length) {
    const numberValue = value.replace(/\D/g, '').slice(0, 10);

    let formedPhoneNumber = numberValue;

    if (formedPhoneNumber.length > 6) {
      formedPhoneNumber =
        formedPhoneNumber.slice(0, 6) + '-' + formedPhoneNumber.slice(6);
    }

    if (formedPhoneNumber.length > 3) {
      formedPhoneNumber =
        formedPhoneNumber.slice(0, 3) + ') ' + formedPhoneNumber.slice(3);
    }

    if (formedPhoneNumber.length > 0) {
      formedPhoneNumber = '(' + formedPhoneNumber;
    }

    return formedPhoneNumber;
  }

  return '';
};

export default formatPhoneNumber;
