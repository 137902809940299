import styled from 'styled-components';
import { Base, Theme } from 'styled';
import { Color } from 'styled/theme/colors';

type CarouselProps = {
  isArticleOrAuthorPageCarousel?: boolean;
};

type ContainerProps = {
  shouldCenterAlignCards?: boolean;
  applyArticleBodyWidthOffset: boolean | undefined;
  maxWidth: string | undefined;
  removeMarginBottom?: boolean;
};

type CardProps = {
  textColor: Color;
  bgColor: Color;
};

type ItemContainerProps = {
  isArticleItem?: boolean;
};

type HeaderProps = {
  isArticleOrAuthorPageCarousel?: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  margin-bottom: ${({ removeMarginBottom }) =>
    removeMarginBottom ? '0' : '1.5rem'};

  ${Theme.mediaQueries.sm} {
    margin: ${({ removeMarginBottom }) =>
      removeMarginBottom ? '0 auto' : '0 auto 4rem auto'};

    // Width of cards + inner margin
    max-width: ${({ maxWidth }) => maxWidth};
  }
`;

export const Header = styled(Base)<HeaderProps>`
  ${({ theme, isArticleOrAuthorPageCarousel }) =>
    !isArticleOrAuthorPageCarousel && theme.global.moduleContentPaddingX};
`;

export const Carousel = styled(Base)<CarouselProps>`
  /* Override NukaCarousel */
  .slider-slide {
    pointer-events: all;
    cursor: grab;

    :active {
      cursor: grabbing;
    }
  }

  /* Slider controls have different behavior on the Article and AuthorPages */

  .slider-control-centerright {
    right: ${({ isArticleOrAuthorPageCarousel }) =>
      isArticleOrAuthorPageCarousel && '0 !important'};

    ${Theme.mediaQueries.md} {
      right: ${({ isArticleOrAuthorPageCarousel }) =>
        isArticleOrAuthorPageCarousel && '1.1875rem !important'};
    }

    ${Theme.mediaQueries.lg} {
      // Arrow Icon width (2.4rem) + 1.25rem of padding present in the svg

      right: ${({ isArticleOrAuthorPageCarousel }) =>
        isArticleOrAuthorPageCarousel && '-3.65rem !important'};
    }
  }

  .slider-control-centerleft {
    left: ${({ isArticleOrAuthorPageCarousel }) =>
      isArticleOrAuthorPageCarousel && '0 !important'};

    ${Theme.mediaQueries.md} {
      left: ${({ isArticleOrAuthorPageCarousel }) =>
        isArticleOrAuthorPageCarousel && '1.1875rem !important'};
    }

    ${Theme.mediaQueries.lg} {
      // Arrow Icon width (2.4rem) + 1.25rem of padding present in the svg

      left: ${({ isArticleOrAuthorPageCarousel }) =>
        isArticleOrAuthorPageCarousel && '-3.65rem !important'};
    }
  }
`;

export const CardContainer = styled(Base)`
  display: flex;
  position: relative;
  align-items: stretch;
`;

export const Card = styled(Base)<CardProps>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  width: 21rem;
  min-height: 29rem;
  border-radius: 4px;
  overflow: hidden;

  ${Theme.mediaQueries.md} {
    width: 22.5rem;
    min-height: 30.75rem;
  }

  ${Theme.mediaQueries.xl} {
    width: 18.875rem;
    min-height: 30.75rem;
  }
`;

export const ControlArrow = styled(Base)``;

/*
 * Shared components
 */

export const ItemContainer = styled(Base)<ItemContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: ${({ isArticleItem }) => !isArticleItem && 'space-between'};
`;

export const CopyContainer = styled(Base)`
  overflow: hidden;

  ${Theme.mediaQueries.sm} {
    max-height: 16.5rem;
  }

  ${Theme.mediaQueries.md} {
    max-height: 16rem;
  }

  ${Theme.mediaQueries.xl} {
    max-height: 15rem;
  }
`;

export const ContentHubItemImageContainer = styled(Base)`
  position: absolute;
  bottom: 0;
`;

export const ContentHubItemImage = styled(Base)`
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 9.1875rem;

  ${Theme.mediaQueries.md} {
    max-height: 11.19875rem;
  }
`;

export const ButtonContainer = styled(Base)`
  display: flex;
  align-items: center;

  a {
    padding: 0.75rem 0;
    ${({ theme }) => theme.zIndex('1')};
  }
`;
