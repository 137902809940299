import styled from 'styled-components';
import { Base } from 'styled/components/base';
import Img from 'styled/components/Img';
import Theme from 'styled/theme';

type ImageProps = {
  renderLogosGrayscale: boolean;
};

export const Container = styled(Base)`
  ${({ theme }) => theme.global.moduleContentPaddingX};
  color: ${({ theme }) => theme.palette.text.primary};
  padding-top: 4rem;
  padding-bottom: 3.5rem;

  ${Theme.mediaQueries.md} {
    padding-bottom: 5rem;
  }
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.variants.primary['md-lg']};
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 2.5rem;

  ${Theme.mediaQueries.sm} {
    ${({ theme }) => theme.typography.variants.primary.xl};
  }

  ${Theme.mediaQueries.md} {
    margin-bottom: 4rem;
  }
`;

export const ImagesContainer = styled(Base)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 77.5rem;
  margin: 0 auto;
`;

export const Image = styled(Img)<ImageProps>`
  height: 100%;
  max-width: 6.25rem;
  max-height: 3.125rem;
  margin: 1.5rem;
  filter: ${({ renderLogosGrayscale }) =>
    `grayscale(${renderLogosGrayscale ? '1' : '0'})`};

  ${Theme.mediaQueries.md} {
    max-width: 12.5rem;
    max-height: 6.25rem;
  }
`;
