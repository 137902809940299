import styled from 'styled-components';
import { Base } from 'styled';

type ContainerProps = {
  marginTop: number;
  showError: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  opacity: ${({ showError }) => (showError ? 1 : 0)};
  position: absolute;
  bottom: ${({ marginTop }) => `-${marginTop}rem`};
  left: 0;
  display: flex;
  align-items: center;
`;

export const Message = styled.p`
  ${({ theme }) => theme.typography.variants.primary.xs};
  color: ${({ theme }) => theme.palette.status.error};
  margin-left: 0.5rem;
`;
