import { Component } from 'react';
import * as Sentry from '@sentry/node';

import type { PropsWithChildren, ReactNode } from 'react';

type Props = PropsWithChildren<{
  fallback: ReactNode;
}>;

type State = {
  hasError: boolean;
};

/**
 * Error boundaries are React components that catch JavaScript errors anywhere in their child component tree,
 * log those errors, and display a fallback UI instead of the component tree that crashed.
 */
class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  /**
   * This lifecycle method is invoked after an error has been thrown by a descendant component.
   * It receives the error and returns a value to update state.
   */
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  /**
   * This lifecycle method is invoked after an error has been thrown by a descendant component.
   * It is used to log the error.
   */
  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
