import React, { FC, useRef } from 'react';
import sanityImgUtil from 'utils/sanityImgUtil';
import { VideoCtaModule as ModuleType } from 'types';
import { Button, Img, Video } from 'styled';
import useRefInView from 'hooks/useRefInView';

import {
  Container,
  Divider,
  HeaderContainer,
  Description,
  ButtonContainer,
  VideoContainer,
  DividerContainer,
} from './style';

type Props = {
  module: ModuleType;
};

const VideoCtaModule: FC<Props> = ({ module }) => {
  const { headerText, headerIcon, description, ctaButton, video } = module;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const moduleIntersectsWithViewport = useRefInView(containerRef);

  return (
    <Container ref={containerRef} data-testid="video-cta-module">
      <DividerContainer>
        <Divider moduleIntersectsWithViewport={moduleIntersectsWithViewport} />
      </DividerContainer>
      <HeaderContainer>
        {headerText}
        {!!headerIcon.src && (
          <Img alt={headerIcon.alt} src={sanityImgUtil(headerIcon, 88)} />
        )}
      </HeaderContainer>
      <Description>{description}</Description>
      <ButtonContainer>
        <Button
          ariaLabel={ctaButton.label}
          label={ctaButton.label}
          variant="symptom-checker-blue"
          to={ctaButton.link}
        />
      </ButtonContainer>
      <VideoContainer>
        <Video
          autoPause={false}
          autoPlay
          isFullWidth
          isMuted
          loop
          quality="2K"
          showCustomVideoControl={false}
          showVimeoVideoControl={false}
          video={video}
        />
      </VideoContainer>
    </Container>
  );
};

export default VideoCtaModule;
