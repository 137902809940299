import React, { FC } from 'react';

import cx from 'classnames';

import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';

import { LargeTextModule as ILargeTextModule } from 'types';
import { useTheme } from 'styled-components';

interface Props {
  module: ILargeTextModule;
  applyArticleBodyWidthOffset?: boolean;
}

const LargeTextModule: FC<Props> = ({
  module,
  applyArticleBodyWidthOffset,
}) => {
  const { palette } = useTheme();
  const { text, desktopFontSize, moduleOptions } = module;
  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color as GeneralModuleColor];

  if (!text) return null;

  return (
    <div
      className={cx(
        'LargeTextModule module-content-padding-x flex flex-col items-center py6',
        {
          'border-top-gray-lighter': moduleOptions.showBorderTop,
          'border-bottom-gray-lighter': moduleOptions.showBorderBottom,
          'article-inner-text-full-width': applyArticleBodyWidthOffset,
        }
      )}
      style={{
        backgroundColor: colorScheme.background,
      }}
    >
      <div
        className={cx(
          `LargeTextModule__text text-xl md-up_text-${desktopFontSize} text-center`,
          {
            'color-gray-100': colorScheme.text === palette.text.primary,
            'color-white': colorScheme.text === palette.common.white,
          }
        )}
      >
        {text}
      </div>
    </div>
  );
};

export default LargeTextModule;
