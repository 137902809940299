import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled/components/base';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
};

const SearchIcon: FC<Props> = ({
  className = '',
  color = theme.palette.primary.main,
}) => (
  <Svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.43333 10.616C4.14121 9.70078 4.02932 8.69838 4.14229 7.65522C4.47422 4.59593 6.80686 2.10775 9.71083 1.7223C13.9301 1.16308 17.4786 4.89649 16.9471 9.33571C16.5791 12.4086 14.1894 14.8696 11.2623 15.2001C9.74849 15.3705 8.32556 14.9896 7.15385 14.2249C7.02851 14.1434 6.86927 14.1587 6.76544 14.2679L1 20.3333"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </Svg>
);

export default SearchIcon;
