import styled from 'styled-components';
import { Base, Theme, VimeoPlayerContainer } from 'styled';

type VideoProps = {
  descriptionHeight: number;
};

export const Video = styled(Base)<VideoProps>`
  && {
    width: ${({ theme }) =>
      `calc(100% + ${theme.sizes.carouselMobilePadding} * 2)`};
    height: ${({ theme }) =>
      `calc(100% + ${theme.sizes.carouselMobilePadding} * 2)`};
    margin: ${({ theme }) => `calc(${theme.sizes.carouselMobilePadding} * -1)`};

    ${Theme.mediaQueries.md} {
      width: ${({ theme }) =>
        `calc(100% + ${theme.sizes.carouselDesktopPadding} * 2)`};
      height: ${({ theme }) =>
        `calc(100% + ${theme.sizes.carouselDesktopPadding} * 2)`};
      margin: ${({ theme }) =>
        `calc(${theme.sizes.carouselDesktopPadding} * -1)`};
    }
  }

  padding-bottom: ${({ descriptionHeight }) => `${descriptionHeight}px`};

  ${VimeoPlayerContainer} {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;

    > iframe {
      position: absolute;
      width: 800%;
      height: 100%;
      top: 0;
      left: unset;
      right: unset;
      bottom: unset;
      margin: auto;
    }
  }
`;
