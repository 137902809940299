import React from 'react';
import Img, { Props as ImgProps } from '../Img';

import type { Image } from 'types';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

interface Props extends Partial<ImgProps> {
  mobileImage: Image;
  containerClassName?: string;
  desktopImage: Image;
}

/** A component to conditionally display mobile/desktop images */
const ArtDirectionImage = ({
  desktopImage,
  mobileImage,
  containerClassName,
  ...rest
}: Props) => {
  const hasMobileImage = !!mobileImage?.id;
  const defaultImage = hasMobileImage ? mobileImage : desktopImage;

  const defaultImageSrc = sanityImgUtil(defaultImage, 768);
  const defaultImgSrcSet = sanityImgSrcSetUtil(defaultImage, 768, 1024, 1990);
  const desktopImgSrcSet = sanityImgSrcSetUtil(desktopImage, 768, 1024, 1990);

  return (
    <picture className={containerClassName}>
      {/*   We only need to define an alternate source for desktop
            if we have both mobile and desktop image variants. */}
      {hasMobileImage && (
        <source srcSet={desktopImgSrcSet} media="(min-width: 768px)" />
      )}
      <Img
        useLazyLoadComponent={false}
        alt={mobileImage.alt}
        sizes="(max-width: 768px) 768px, (max-width: 1024px) 1024px, 1990px"
        src={defaultImageSrc}
        srcSet={defaultImgSrcSet}
        {...rest}
      />
    </picture>
  );
};

export default ArtDirectionImage;
