import React, { FC, useRef } from 'react';
import BlockContent from '@sanity/block-content-to-react';

import useRefInView from 'hooks/useRefInView';
import sanityImgUtil from 'utils/sanityImgUtil';

import { Img, Lottie } from 'styled';

import { ThreeColumnAnimationModule as ModuleType } from 'types';

import {
  Container,
  Header,
  DescriptionContainer,
  Divider,
  ItemsContainer,
  Item,
  ItemText,
  ItemTitle,
  ItemDescription,
} from './style';

type Props = {
  module: ModuleType;
  applyPaddingX?: boolean;
};

const ThreeColumnAnimationModule: FC<Props> = ({
  module,
  applyPaddingX = true,
}) => {
  const { header, description, items } = module;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const moduleIntersectsWithViewport = useRefInView(containerRef);

  return (
    <Container
      ref={containerRef}
      applyPaddingX={applyPaddingX}
      data-testid="three-col-animation-module"
    >
      <Header>{header}</Header>
      <DescriptionContainer>
        <BlockContent blocks={description} />
      </DescriptionContainer>
      <ItemsContainer
        moduleIntersectsWithViewport={moduleIntersectsWithViewport}
      >
        {items.map((item) => (
          <Item key={item.id}>
            {item.lottie ? (
              <Lottie
                lottie={item.lottie}
                autoplay={false}
                loop={false}
                playingState={moduleIntersectsWithViewport ? 'PLAY' : 'STOP'}
              />
            ) : item.image ? (
              <Img
                alt={item.image.alt}
                className="w100"
                src={sanityImgUtil(item.image, 500)}
              />
            ) : null}
            <Divider />
            <ItemText>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemDescription>{item.description}</ItemDescription>
            </ItemText>
          </Item>
        ))}
      </ItemsContainer>
    </Container>
  );
};

export default ThreeColumnAnimationModule;
