import Backdrop from '@mui/material/Backdrop';
import Drawer from '@mui/material/Drawer';
import Portal from '@mui/material/Portal';
import PolarisPopUpContext from 'contexts/PolarisPopUpContext';
import useBreakpointIsMobile from 'hooks/useBreakpointIsMobile';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { zIndex } from 'styled/theme/config';
import { View } from './constants';
import styles from './styles.module.scss';

import type { Ref } from 'react';

const DESKTOP_DRAWER_WIDTH = 500;

function PopupContainer(_props: object, ref: Ref<HTMLDivElement>) {
  const { isPopupOpen, setIsPopupOpen } = useContext(PolarisPopUpContext);
  const [scrollTop, setScrollTop] = useState<number>();

  const isMobile = useBreakpointIsMobile();

  const [view, setView] = useState<View>(View.Minimize);

  /**
   * HANDLERS
   */
  const handleOverlayClick = useCallback(() => {
    setView(View.Minimize);
  }, [setView]);

  /**
   * EFFECTS
   */
  useEffect(() => {
    if (isPopupOpen) {
      setView(View.FullScreen);
    } else if (isPopupOpen !== undefined) {
      setView(View.Minimize);
    }
  }, [isPopupOpen]);

  useEffect(() => {
    if (isPopupOpen && isMobile) {
      // overflow 'hidden' doesn't work on iOS, so we need to set position 'fixed' instead.
      // however, this scrolls the page up to the top, so we need to keep track
      // of where the user was, and then go back to that spot when the modal closes
      setScrollTop(window?.scrollY);
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      if (scrollTop) {
        window?.scrollTo(0, scrollTop);
      }
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
    };
  }, [isPopupOpen, isMobile]);

  // Trigger on view change callback.
  useEffect(() => {
    setIsPopupOpen(view === View.FullScreen);
  }, [view]);

  /**
   * RENDER
   */
  return (
    <Portal>
      <div id="Polaris" className={`${styles['polaris']} ${view}`}>
        <Backdrop
          sx={{ zIndex: zIndex('polaris-backdrop').zIndex }}
          open={isPopupOpen}
          onClick={handleOverlayClick}
        ></Backdrop>

        <Drawer
          sx={
            isMobile
              ? {}
              : {
                  width: DESKTOP_DRAWER_WIDTH,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: DESKTOP_DRAWER_WIDTH,
                  },
                }
          }
          variant="persistent"
          anchor={isMobile ? 'bottom' : 'right'}
          open={isPopupOpen}
          PaperProps={{
            elevation: 3,
            sx: {
              border: 0,
              height: '100vh',
              zIndex: zIndex('polaris').zIndex,
            },
          }}
          onClose={handleOverlayClick}
        >
          {/**
          We use a ref to inject the content to prevent unnecessary re-rendering the children
          subtree. Without this, the children will reset it's state each time the popup view
          (preview, open, minimize, etc) changes.
        */}
          <div ref={ref} id="chatbox" />
        </Drawer>
      </div>
    </Portal>
  );
}

export default forwardRef<HTMLDivElement, object>(PopupContainer);
