import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import { Img } from 'styled';
import { PaginationContainer } from './style';
import { Color } from 'styled/theme/colors';
import useLanguage from 'hooks/useLanguage';

export interface Props extends ReactPaginateProps {
  activeLinkBgColor: Color;
}

const Pagination = (props: Props) => {
  const {
    pageCount,
    pageRangeDisplayed,
    marginPagesDisplayed,
    onPageChange,
    activeLinkBgColor,
  } = props;

  const Language = useLanguage();

  return (
    <PaginationContainer
      data-testid="pagination"
      activeLinkBgColor={activeLinkBgColor}
    >
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={marginPagesDisplayed}
        previousLabel={
          <Img
            alt={Language.t('Global.previousPage')}
            src="/images/left-arrow.svg"
          />
        }
        nextLabel={
          <Img
            alt={Language.t('Global.nextPage')}
            src="/images/right-arrow.svg"
          />
        }
        onPageChange={onPageChange}
        pageLinkClassName="pagination-button"
        activeLinkClassName="active"
        containerClassName="pagination-next-previous-button-container"
        previousLinkClassName="pagination-next-previous-button"
        nextLinkClassName="pagination-next-previous-button"
        disabledClassName="pagination-disabled"
        breakClassName="break"
      />
    </PaginationContainer>
  );
};

export default Pagination;
