import React, { useContext, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import dynamic from 'next/dynamic';
import ErrorBoundary from 'components/ErrorBoundary';
import Loading from './Loading';
import PopupContainer from './PopupContainer';
import PolarisPopUpContext from 'contexts/PolarisPopUpContext';

import type {
  App,
  IInterviewConfig,
  MiniInterviewType,
} from '@buoy-components/polaris/types';

export type InterviewSettings = Array<{
  configs: IInterviewConfig[];
  id: string;
  interview: MiniInterviewType;
  question: string;
}>;

type Props = {
  interviewSettings: InterviewSettings;
  handleExitClick: () => void;
};

const PolarisApp = dynamic(() => import('polaris/App') as Promise<App>, {
  loading: () => <Loading />,
  ssr: false,
});

function Polaris(props: Props) {
  const { interviewSettings, handleExitClick } = props;

  const { isPopupOpen, setIsPopupOpen } = useContext(PolarisPopUpContext);
  const placeholderRef = useRef(null);

  // For polaris interviews(e.g TG/SxTG as of now) update configs with additional context specific for Polaris
  const polarisInterviewConfigs = useMemo(
    () =>
      interviewSettings.map((interview) => ({
        ...interview,
        configs: interview.configs.map((c: IInterviewConfig) => {
          return {
            ...c,
            context: {
              ...c.context,
              polarisProps: {
                displayPolarisUI: true,
                showProgressBar: true,
              },
            },
          };
        }),
      })),
    [interviewSettings],
  );

  return (
    <ErrorBoundary fallback={null}>
      <PopupContainer ref={placeholderRef} />

      {/**
        Inject the Polaris runtime app as a portal into the popup placeholder ref.
        This prevents unnecessary re-rendering the Polaris app and resetting it's state each time
        the popup view (preview, open, minimize, etc) changes.
      */}
      {placeholderRef?.current &&
        createPortal(
          <PolarisApp
            // @ts-expect-error prop types will change upon next polaris deploy
            handleExitClick={handleExitClick}
            interviewSettings={polarisInterviewConfigs}
            open={isPopupOpen}
            setIsOpen={setIsPopupOpen}
          />,
          placeholderRef.current,
        )}
    </ErrorBoundary>
  );
}
export default Polaris;
