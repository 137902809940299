import React, { FC } from 'react';
import BlockContent from '@sanity/block-content-to-react';

import { ArticlePortableText, Img } from 'styled';
import {
  Container,
  HighlightContainer,
  HighlightTitleContainer,
} from './style';

import { ExternalLink, InternalLink } from 'constants/PortableTextSerializer';

import { HighlightsModule as IHighlightsModule } from 'types';
import { GeneralModuleColorScheme } from 'constants/ColorScheme';

type Props = {
  module: IHighlightsModule;
};

const HighlightsModule: FC<Props> = ({ module }) => {
  if (!module.contents.length) return null;

  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color] || {};

  return (
    <Container
      showBorderTop={module.moduleOptions.showBorderTop}
      showBorderBottom={module.moduleOptions.showBorderBottom}
    >
      {module.contents.map((content) => (
        <HighlightContainer
          key={content.id}
          style={{
            backgroundColor: colorScheme.background,
            color: colorScheme.text,
          }}
        >
          {!!content.title && (
            <HighlightTitleContainer style={{ color: colorScheme.text }}>
              {!!content.icon.id && (
                <Img
                  preloadFullWidth
                  alt={content.icon.alt}
                  src={content.icon.src}
                />
              )}
              {content.title}
            </HighlightTitleContainer>
          )}
          <ArticlePortableText>
            <BlockContent
              blocks={content.text}
              serializers={{
                marks: { link: ExternalLink, internalLink: InternalLink },
              }}
            />
          </ArticlePortableText>
        </HighlightContainer>
      ))}
    </Container>
  );
};

export default HighlightsModule;
