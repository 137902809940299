import styled from 'styled-components';
import { Base, Theme, TextModulePortableText } from 'styled';

type DividerProps = {
  moduleIntersectsWithViewport: boolean;
};

type TextContentContainerProps = {
  applyAllMargins: boolean;
};

export const Container = styled(Base)`
  max-width: 90rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${Theme.mediaQueries.md} {
    flex-direction: row;
  }
`;

export const DividerContainer = styled(Base)`
  width: 6.25rem;
`;

export const Divider = styled(Base)<DividerProps>`
  width: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.blue[80]};
  margin-bottom: 1.5rem;
  ${({ moduleIntersectsWithViewport, theme }) =>
    moduleIntersectsWithViewport && theme.animations.growInWidth};
  animation-delay: 300ms;
`;

export const TextContentContainer = styled(Base)<TextContentContainerProps>`
  margin: ${({ applyAllMargins }) =>
    applyAllMargins ? '2.5rem 1rem' : '2.5rem 0 2.5rem 0'};

  ${Theme.mediaQueries.sm} {
    margin-left: ${({ applyAllMargins }) => applyAllMargins && '1.5rem'};
    margin-right: ${({ applyAllMargins }) => applyAllMargins && '1.5rem'};
  }

  ${Theme.mediaQueries.md} {
    max-width: 30rem;
    margin: ${({ applyAllMargins }) =>
      applyAllMargins
        ? '6.78125rem 2.5rem 6.78125rem 5rem'
        : '6.78125rem 2.5rem 6.78125rem 0'};
  }

  ${Theme.mediaQueries.lg} {
    max-width: 29.0625rem;
  }
`;

export const TitleContainer = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary['md-lg']};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1.5rem;

  ${Theme.mediaQueries.md} {
    align-items: flex-start;
    text-align: left;
  }
`;

export const TextContainer = styled(TextModulePortableText)`
  ${({ theme }) => theme.typography.variants.secondary.sm};
`;

export const ImagesContainer = styled(Base)`
  width: 100%;
  height: 28.4625rem;
  position: relative;
  margin: 0 auto;
  max-width: 23.4375rem;

  ${Theme.mediaQueries.md} {
    max-width: 26.5rem;
  }

  ${Theme.mediaQueries.lg} {
    max-width: 43.4375rem;
    height: 50.0625rem;
  }

  ${Theme.mediaQueries.lg} {
    max-width: 43.4375rem;
  }
`;

export const BackImageContainer = styled(Base)`
  width: 100%;
  max-width: 15.25rem;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;

  ${Theme.mediaQueries.md} {
    max-width: 17.885rem;
  }

  ${Theme.mediaQueries.lg} {
    max-width: 28.3125rem;
  }
`;

export const FrontImageContainer = styled(Base)`
  width: 100%;
  max-width: 12.2075rem;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 1.45rem;

  ${Theme.mediaQueries.md} {
    max-width: 14.2925rem;
  }

  ${Theme.mediaQueries.lg} {
    max-width: 22.5rem;
    margin-bottom: 0;
  }
`;
