import React, { FC, RefObject } from 'react';

import { LinkOrButton } from 'styled';
import { Container, Description, ButtonContainer } from './style';

import { Color } from 'styled/theme/colors';
import { Button } from 'types';
import { useTheme } from 'styled-components';

interface Props {
  description?: string;
  button?: Button;
  textColor: Color;
  bgColor: Color;
  elemRef?: RefObject<HTMLDivElement>;
  isArticleItem?: boolean;
}

const ItemDescription: FC<Props> = ({
  description,
  button,
  textColor,
  bgColor,
  elemRef,
  isArticleItem,
}) => {
  const { palette } = useTheme();
  if (!description && !button?.label) return null;

  return (
    <Container
      isArticleItem={isArticleItem}
      bgColor={bgColor}
      ref={elemRef}
      mt={isArticleItem ? 0.5 : 1}
    >
      {!!description && <Description>{description}</Description>}
      {button && !!button.label && !!button.link && (
        <ButtonContainer mt={[1.25, 1.25, 1.25, 0.75]}>
          <LinkOrButton
            ariaLabel={button.label}
            to={button.link}
            label={button.label}
            variant={`hover-underline-${
              textColor === palette.text.primary ? 'black' : 'white'
            }`}
          />
        </ButtonContainer>
      )}
    </Container>
  );
};

export default ItemDescription;
