import React, { FC } from 'react';
import { Svg } from 'styled';

type Props = {
  className?: string;
};

const DoctorBesideBedriddenPatient: FC<Props> = ({ className = '' }) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 2000 1400"
    fill="transparent"
    className={className}
  >
    <desc>Illustration of a doctor beside a bedridden patient.</desc>
    <path
      d="M857.311 1127.73C853.083 1135.93 849.658 1144.51 847.083 1153.37C842.093 1170.66 837.842 1185.11 844.528 1193.1C846.913 1195.96 850.276 1197.5 880.331 1198.23C907.922 1198.9 910.606 1197.74 922.54 1199.51C940.342 1202.16 940.412 1205.65 951.957 1205.92C973.401 1206.43 996.112 1194.92 995.703 1186.31C995.623 1184.84 994.785 1181.31 960.908 1167.47C936.66 1157.52 931.551 1157.03 925.105 1150.81C919.028 1144.94 912.133 1134.33 911.035 1113.63L857.311 1127.73Z"
      fill="#503630"
    />
    <path
      d="M763.95 1167.47C763.412 1179.19 762.394 1189.15 761.386 1196.95C759.64 1210.5 758.442 1213.58 758.831 1221.3C759.329 1231.14 761.947 1240.76 766.505 1249.5C771.452 1258.67 778.458 1266.57 786.971 1272.57C794.162 1278.1 802.445 1282.03 811.269 1284.11C816.937 1285.39 825.578 1287.24 835.566 1284.11C840.775 1282.47 848.389 1280.11 850.914 1273.85C853.438 1267.59 849.616 1261.11 841.933 1248.22C835.068 1236.66 832.513 1235.66 825.309 1223.86C820.419 1215.86 816.527 1209.49 813.793 1200.79C810.629 1190.44 809.757 1179.52 811.239 1168.79L763.95 1167.47Z"
      fill="#503630"
    />
    <path
      d="M775.454 955.98C770.704 970.36 766.354 985.75 762.662 1002.12C747.494 1069.54 748.881 1130.21 754.988 1177.72C775.509 1185.28 797.882 1186.18 818.941 1180.29C819.48 1161.47 820.697 1141.78 822.783 1121.29C828.531 1065.12 840.102 1009.71 857.308 955.94L775.454 955.98Z"
      fill="#162D5B"
    />
    <path
      d="M873.944 958.54C869.633 974.54 865.702 991.63 862.429 1009.81C854.876 1051.83 851.672 1094.51 852.869 1137.19C864.269 1141.59 876.449 1143.59 888.654 1143.07C900.858 1142.56 912.827 1139.53 923.817 1134.19C919.807 1073.87 927.631 1013.34 946.847 956.03L873.944 958.54Z"
      fill="#162D5B"
    />
    <path
      d="M815.109 303.57C825.387 329.57 835.266 356.94 844.526 385.57C873.686 475.996 893.416 569.207 903.4 663.71L967.342 656.02C968.579 589.067 963.871 522.137 953.272 456.02C945.652 408.383 934.987 361.286 921.341 315.02L815.109 303.57Z"
      fill="#F6AB0F"
    />
    <path
      d="M843.239 294.6C838 295.23 832.332 296.15 826.275 297.46C819.01 299.058 811.849 301.099 804.831 303.57C804.831 303.57 780.713 312.29 760.068 327.92C714.166 362.71 698.999 425.77 693.561 448.41C684.38 486.64 674.302 528.61 696.734 567.61C704.388 580.685 714.658 592.033 726.899 600.94C726.167 614.62 725.738 628.717 725.612 643.23C725.335 681.78 727.471 720.309 732.008 758.59L891.844 718.86C900.426 699.1 906.86 678.471 911.033 657.33C913.308 645.75 922.449 595.69 908.469 526.59C897.592 472.79 878.533 436.43 857.309 368.94C852.109 352.619 848.261 335.895 845.803 318.94C844.296 308.61 843.648 300.08 843.239 294.6Z"
      fill="#BBD1F9"
    />
    <path
      d="M910.353 307.86C923.295 309.108 935.906 312.681 947.583 318.41C952.882 321.01 966.542 327.88 978.347 341.12C985.801 349.5 991.459 359.27 1001.01 391.41C1012.31 429.48 1011.39 439.41 1020.44 467.67C1020.44 467.67 1028.79 493.76 1039.86 515.53C1048.63 532.74 1074.67 575.77 1147.52 610.44C1141.61 613.754 1136.16 617.843 1131.33 622.6C1121.07 632.873 1113.79 645.755 1110.28 659.86C1064.2 645.689 1022.63 619.681 989.683 584.42C991.16 595.02 992.49 605.837 993.674 616.87C997.296 649.67 999.322 681.1 1000.15 710.96L957.242 682.57C954.808 662.22 951.345 634.75 946.715 602.26C940.908 561.47 937.994 541.07 933.743 518.71C923.206 462.6 909.505 401.91 909.505 401.91C907.28 392.02 903.757 376.58 903.837 355.67C903.937 339.518 906.127 323.447 910.353 307.86V307.86Z"
      fill="#BBD1F9"
    />
    <path
      opacity="0.15"
      d="M989.683 584.43C988.495 576.1 986.639 563.71 984.015 548.74C980.173 526.88 977.16 513.62 975.104 503.31C970.381 479.224 967.406 454.827 966.203 430.31C966.493 453.72 970.384 494.58 993.724 537.39C1005.82 559.715 1021.71 579.755 1040.67 596.61C1063.23 616.305 1089.71 630.963 1118.36 639.61C1115.7 646.37 1113 653.133 1110.28 659.9C1064.2 645.729 1022.63 619.721 989.683 584.46V584.43Z"
      fill="black"
    />
    <path
      opacity="0.15"
      d="M786.692 441.66C780.157 453.675 774.488 466.144 769.729 478.97C761.746 500.25 757.834 511.17 758.982 524.5C760.099 537.42 765.109 546.39 774.139 562.5C779.569 572.258 785.889 581.491 793.019 590.08C787.764 575.073 783.958 559.595 781.653 543.86C776.806 509.783 778.517 475.092 786.692 441.66V441.66Z"
      fill="black"
    />
    <path
      d="M846.401 288.34C840.882 288.34 823.45 289.25 808.702 302.27C803.122 307.213 798.601 313.237 795.41 319.98L839.116 339.45L822.153 364.6C828.539 380.29 835.783 396.6 844.006 413.27C862.02 449.833 882.974 484.865 906.661 518.02C905.044 495.737 901.138 473.68 895.006 452.2C893.36 446.5 874.63 381.27 853.725 341.07C851.42 336.64 844.624 323.94 844.305 306.74C844.187 300.541 844.892 294.353 846.401 288.34V288.34Z"
      fill="#8AB3FC"
    />
    <path
      d="M912.779 301.32C918.154 303.633 923.09 306.861 927.368 310.86C934.405 317.527 939.447 326.029 941.926 335.41L927.118 347.26L952.414 376.76C952.214 383.05 951.795 392.66 950.797 404.34C949.101 424.23 946.806 437.58 942.705 462.75C940.709 474.89 937.626 494.13 933.794 518.75C926.809 480.27 920.023 449.59 915.184 428.75C908.119 398.3 903.349 381.18 903.848 355.75C904.249 337.275 907.255 318.95 912.779 301.32V301.32Z"
      fill="#8AB3FC"
    />
    <path
      d="M821.317 626.61C826.29 629.582 830.684 633.43 834.29 637.97C839.298 644.344 842.636 651.873 843.999 659.87L793.008 670.41L778.43 649.32C785.072 641.597 793.386 635.496 802.737 631.48C808.659 628.952 814.919 627.312 821.317 626.61V626.61Z"
      fill="#F5C3B6"
    />
    <path
      d="M859.353 237.24C858.417 243.272 857.083 249.235 855.362 255.09C851.015 269.593 844.364 283.299 835.664 295.68C841.402 315.55 857.387 329.68 875.578 331.34C892.422 332.86 904.825 323.11 907.839 320.6L917.668 294.02L859.353 237.24Z"
      fill="#EEB7AB"
    />
    <path
      d="M942.734 128.54C933.174 125.69 913.217 119.72 897.401 129.35C877.883 141.23 870.06 173.6 882.044 207.48C871.606 197.36 855.491 198.59 847.858 207.92C839.965 217.56 843.986 232.43 852.069 239.67C855.58 242.713 859.908 244.653 864.512 245.25C870.683 257.789 879.086 269.095 889.308 278.61C899.517 288.06 921.908 308.85 944.52 302.61C952.692 300.34 957.981 295.42 961.284 292.35C964.587 289.28 972.14 281.47 980.163 251.49C984.014 236.985 986.654 222.184 988.056 207.24C990.241 184.89 991.329 173.72 988.056 165.06C978.267 139.15 948.142 130.16 942.734 128.54Z"
      fill="#F5C3B6"
    />
    <path
      d="M861.858 245.11C861.617 257.036 864.361 268.832 869.841 279.42C882.275 303.14 904.387 311.82 910.244 313.91L915.872 298.69C894.325 287.088 877.269 268.597 867.417 246.16L861.858 245.11Z"
      fill="#F0A893"
    />
    <path
      d="M988.846 199.08C991.407 195.727 993.521 192.052 995.132 188.15C998.743 178.002 999.519 167.058 997.377 156.5C993.386 137.11 974.546 118.61 948.233 109.96C916.551 97.96 888.761 112.73 884.241 115.23C880.189 117.48 854.884 132.04 846.542 163.55C842.762 178.165 843.219 193.556 847.859 207.92C850.316 204.853 853.658 202.622 857.428 201.53C859.424 200.98 865.521 199.29 870.65 203.08C873.533 205.355 875.419 208.665 875.909 212.31C875.95 216.443 876.391 220.562 877.226 224.61C878.892 232.68 882.474 242.07 884.211 241.74C884.789 241.63 885.368 240.45 885.528 221.97C885.668 206.27 885.298 201.86 888.591 196.97C891.475 192.67 893.151 193.97 896.484 189.07C900.196 183.62 899.308 180.27 902.182 170.18C904.327 162.65 905.445 158.72 908.319 154.81C913.707 147.46 921.73 144.95 926.729 143.39C941.767 138.68 954.849 142.72 959.599 144.26C966.983 146.66 976.363 149.7 982.829 158.76C989.624 168.28 989.744 179.6 989.814 186.9C989.865 190.981 989.541 195.058 988.846 199.08Z"
      fill="#8E6255"
    />
    <path
      d="M1332.75 486.55L1569.18 489.09C1576.73 489.172 1583.93 492.253 1589.21 497.657C1594.5 503.061 1597.42 510.346 1597.35 517.91C1596.1 644.623 1594.85 771.33 1593.6 898.03C1593.53 904.415 1591 910.527 1586.54 915.081C1582.07 919.636 1576.02 922.277 1569.65 922.45L1428.19 926.11L1260.15 709.18L1332.75 486.55Z"
      fill="#648680"
    />
    <path
      d="M1332.74 486.55L1546.37 488.85C1553.56 488.929 1560.42 491.854 1565.47 496.987C1570.52 502.12 1573.33 509.044 1573.31 516.25C1572.9 638.99 1572.48 761.723 1572.06 884.45C1572.04 891.03 1569.46 897.343 1564.88 902.054C1560.3 906.766 1554.07 909.508 1547.5 909.7L1418.41 913.46L1260.12 709.18L1332.74 486.55Z"
      fill="#71999B"
    />
    <path
      opacity="0.09"
      d="M1476.59 525.08C1489.04 522.83 1514.62 532.3 1525 550.77C1526.92 554.17 1530.16 561.15 1529.28 590.77C1528.14 628.86 1522.36 630.6 1523.58 652.14C1524.92 675.72 1531.94 675.14 1539.24 713.5C1542.32 729.993 1544.23 746.686 1544.94 763.45C1546.73 796.45 1543.42 807.75 1543.51 834.81C1543.66 859.788 1546.53 884.675 1552.07 909.03L1485.13 911.86L1426.75 811.96C1428.52 586.4 1452.53 529.43 1476.59 525.08Z"
      fill="black"
    />
    <path d="M639.218 961.79H609.312V1205.94H639.218V961.79Z" fill="#71999B" />
    <path d="M657.14 961.79H639.219V1205.94H657.14V961.79Z" fill="#648680" />
    <path
      d="M636.334 1268.11C662.219 1268.11 683.203 1247.08 683.203 1221.14C683.203 1195.2 662.219 1174.17 636.334 1174.17C610.449 1174.17 589.465 1195.2 589.465 1221.14C589.465 1247.08 610.449 1268.11 636.334 1268.11Z"
      fill="#648680"
    />
    <path
      d="M627.943 1268.11C653.829 1268.11 674.813 1247.08 674.813 1221.14C674.813 1195.2 653.829 1174.17 627.943 1174.17C602.058 1174.17 581.074 1195.2 581.074 1221.14C581.074 1247.08 602.058 1268.11 627.943 1268.11Z"
      fill="#71999B"
    />
    <path
      d="M627.942 1245.83C641.548 1245.83 652.579 1234.78 652.579 1221.14C652.579 1207.5 641.548 1196.45 627.942 1196.45C614.335 1196.45 603.305 1207.5 603.305 1221.14C603.305 1234.78 614.335 1245.83 627.942 1245.83Z"
      fill="#648680"
    />
    <path d="M1461.25 955.98H1431.34V1200.13H1461.25V955.98Z" fill="#71999B" />
    <path d="M1479.17 955.98H1461.25V1200.13H1479.17V955.98Z" fill="#648680" />
    <path
      d="M1458.37 1262.3C1484.25 1262.3 1505.23 1241.27 1505.23 1215.33C1505.23 1189.39 1484.25 1168.36 1458.37 1168.36C1432.48 1168.36 1411.5 1189.39 1411.5 1215.33C1411.5 1241.27 1432.48 1262.3 1458.37 1262.3Z"
      fill="#648680"
    />
    <path
      d="M1449.97 1262.3C1475.86 1262.3 1496.84 1241.27 1496.84 1215.33C1496.84 1189.39 1475.86 1168.36 1449.97 1168.36C1424.09 1168.36 1403.11 1189.39 1403.11 1215.33C1403.11 1241.27 1424.09 1262.3 1449.97 1262.3Z"
      fill="#71999B"
    />
    <path
      d="M1449.97 1240.02C1463.58 1240.02 1474.61 1228.97 1474.61 1215.33C1474.61 1201.69 1463.58 1190.64 1449.97 1190.64C1436.37 1190.64 1425.34 1201.69 1425.34 1215.33C1425.34 1228.97 1436.37 1240.02 1449.97 1240.02Z"
      fill="#648680"
    />
    <path
      d="M701.523 934.65L1600.92 930.86L1576.97 915.69L701.543 920.75L701.523 934.65Z"
      fill="#71999B"
    />
    <path
      d="M1255.28 633.78C1256.22 614.83 1261.86 583.13 1276.73 550.35C1282.74 537.099 1289.92 524.41 1298.17 512.43C1374 493.753 1453.88 500.866 1525.23 532.65C1542.65 636.722 1525.84 743.648 1477.34 837.31C1449.1 839.433 1420.73 835.555 1394.08 825.93C1311.86 795.77 1251.58 709.12 1255.28 633.78Z"
      fill="#4083FC"
    />
    <path
      opacity="0.15"
      d="M1525.23 532.65C1504.56 564.657 1488.83 599.603 1478.55 636.31C1454.17 723.76 1467.47 798.84 1477.29 837.31C1525.8 743.646 1542.61 636.721 1525.19 532.65H1525.23Z"
      fill="black"
    />
    <path
      d="M1283.03 416.35C1269.06 434.21 1269.76 454.35 1270.42 474.5C1270.96 490.9 1272.52 538.59 1300.03 551.06C1303.43 552.59 1307.07 553.542 1310.78 553.88C1309.53 570.82 1308.27 587.763 1307 604.71C1318.24 609.069 1330.32 610.779 1342.33 609.71C1358.07 608.219 1373 602.06 1385.23 592.02L1387.3 513.65C1392.43 513.315 1397.26 511.084 1400.84 507.39C1404.43 503.696 1406.52 498.801 1406.72 493.65C1406.72 491.44 1406.91 485.08 1401.9 480.55C1396.33 475.55 1388.99 476.93 1388.15 477.11C1389.98 472.86 1400.4 447.67 1388.15 424.03C1373.06 394.94 1333.37 387.03 1306.98 397.84C1297.57 401.893 1289.34 408.259 1283.03 416.35V416.35Z"
      fill="#F5C3B6"
    />
    <path
      d="M1310.78 553.86C1315.65 554.711 1320.62 554.869 1325.53 554.33C1341.69 552.53 1352.7 543.57 1357.3 539.33C1355.41 549.69 1349.71 558.965 1341.34 565.33C1327.37 575.78 1311.95 573.33 1309.35 572.84C1309.84 566.54 1310.32 560.213 1310.78 553.86Z"
      fill="#F0A893"
    />
    <path
      d="M1270.86 447.55C1267.3 441.844 1264.85 435.517 1263.63 428.9C1262.77 424.14 1260.87 412.82 1266.39 400.64C1268.02 397.05 1271.07 390.3 1278.07 385.47C1291.52 376.22 1308.09 381.89 1310.4 382.72C1317.44 376.739 1325.63 372.279 1334.47 369.62C1362.41 361.41 1399.92 371.44 1417.01 400.62C1432.78 427.56 1422.91 456.76 1420.45 463.34C1417.14 472.157 1412.24 480.291 1406.01 487.34C1405.23 484.776 1403.81 482.455 1401.88 480.6C1400.16 478.96 1398.09 477.741 1395.82 477.04C1392.36 476.042 1388.67 476.275 1385.36 477.7C1379.06 480.64 1378.38 487.24 1375.75 487.02C1373.13 486.8 1371.64 479.76 1370.93 475.78C1366.82 452.96 1371.57 443.59 1365.09 433.13C1362.53 429.02 1359.53 426.82 1355.8 424.08C1344.22 415.6 1331.85 414.58 1323.48 414.43C1305.32 414.11 1286.03 413.77 1276.29 427.43C1271.19 434.53 1270.73 442.74 1270.86 447.55Z"
      fill="#323F35"
    />
    <path
      d="M1309.37 572.86C1291.32 568.381 1272.63 567.172 1254.16 569.29C1206.97 574.83 1175.13 601.29 1153.08 619.68C1132.5 636.856 1114.5 656.924 1099.65 679.26L1412.97 812.39C1427.39 790.7 1442.62 759.89 1447.63 721.23C1451.17 693.92 1455.36 661.54 1435.9 631.49C1420.18 607.24 1396.8 596.43 1385.2 592.05C1355.52 614.42 1324.53 614.56 1313.36 601.12C1311.96 599.47 1305.52 591.15 1309.37 572.86Z"
      fill="#F7AC0F"
    />
    <path d="M1600.92 930.85H546.625V970.14H1600.92V930.85Z" fill="#648680" />
    <path
      d="M1535.72 915.15C1490.39 857.996 1437.43 807.358 1378.33 764.66C1378.33 764.66 1315.47 719.26 1250.45 689.5C1041.39 593.77 735.791 636.66 510.526 779.13C471.784 803.621 435.077 831.204 400.762 861.61L701.505 920.74L1535.72 915.15Z"
      fill="#6A9EFC"
    />
    <path
      d="M724.795 648.51H312.213C293.365 648.51 278.086 663.822 278.086 682.71V935.94C278.086 954.828 293.365 970.14 312.213 970.14H724.795C743.643 970.14 758.922 954.828 758.922 935.94V682.71C758.922 663.822 743.643 648.51 724.795 648.51Z"
      fill="#648680"
    />
    <path
      d="M682.664 648.51H314.456C291.183 648.51 272.316 667.417 272.316 690.74V927.91C272.316 951.233 291.183 970.14 314.456 970.14H682.664C705.937 970.14 724.804 951.233 724.804 927.91V690.74C724.804 667.417 705.937 648.51 682.664 648.51Z"
      fill="#71999B"
    />
    <path
      d="M903.839 575.78C903.839 577.264 903.4 578.714 902.577 579.948C901.754 581.181 900.585 582.143 899.217 582.71C897.849 583.277 896.343 583.425 894.891 583.135C893.439 582.845 892.106 582.13 891.06 581.08C890.013 580.03 889.301 578.693 889.014 577.237C888.726 575.782 888.876 574.273 889.444 572.903C890.012 571.533 890.972 570.362 892.204 569.539C893.437 568.716 894.884 568.278 896.365 568.28C897.347 568.28 898.32 568.474 899.227 568.851C900.135 569.228 900.959 569.781 901.653 570.477C902.348 571.174 902.898 572.001 903.273 572.911C903.648 573.821 903.84 574.796 903.839 575.78V575.78Z"
      fill="#8AB3FC"
    />
    <path
      opacity="0.17"
      d="M1173.87 661.43C1185.32 647.183 1200.48 636.388 1217.67 630.24C1205.64 640.291 1195.26 652.188 1186.94 665.49L1173.87 661.43Z"
      fill="black"
    />
    <path
      d="M1245.85 761.99L1226.89 752.09C1222.19 749.656 1218.52 745.6 1216.57 740.67C1214.95 736.519 1213 732.506 1210.74 728.67C1206.88 722.12 1205.66 722.11 1201.69 715.51C1196.64 707.1 1193.4 694.12 1189.59 694.13C1187.25 694.13 1185.43 699.03 1184.84 701.51C1182.5 711.16 1190.14 719.2 1191.01 720.09C1181.22 713.435 1171.78 706.279 1162.72 698.65C1160.01 696.36 1139.77 679.24 1131.46 680.65C1130.92 680.703 1130.39 680.867 1129.91 681.132C1129.44 681.397 1129.02 681.757 1128.69 682.19C1127.02 684.95 1131.49 690.19 1132.02 690.81C1130.65 689.94 1119.05 682.67 1115.27 686.22C1114.72 686.81 1114.38 687.559 1114.28 688.36C1113.28 693.88 1121.94 699.6 1135.91 710.28C1147.17 718.87 1151.73 722.91 1151.54 723.18C1151 723.93 1111.72 694.06 1105 700.45C1104.49 700.983 1104.12 701.637 1103.93 702.35C1102.63 707.24 1110.91 713.29 1115.47 716.71C1131.73 728.85 1144.79 740.08 1144.23 741.01C1143.46 742.29 1116.45 725.01 1111.92 730.32C1111.45 730.974 1111.23 731.777 1111.3 732.58C1111.24 737.48 1117.9 739.22 1133.43 749.38C1141.55 754.7 1146 757.61 1152.87 761.48C1165.38 768.48 1173.83 773.19 1185.73 776.26C1190.28 777.473 1194.91 778.342 1199.58 778.86C1201.09 779.01 1202.55 779.514 1203.82 780.33L1228.2 795.79C1234.09 784.503 1239.97 773.237 1245.85 761.99Z"
      fill="#F5C3B6"
    />
    <path
      d="M1352.55 690.31C1353.16 691.94 1366.06 728.11 1346.22 750.38C1327.61 771.25 1293.97 764.43 1278.78 761.38C1263.61 758.231 1249.27 751.921 1236.69 742.86C1232.13 747.206 1228.07 752.05 1224.59 757.3C1218.35 766.757 1214.04 777.357 1211.91 788.49C1225.5 798.279 1239.97 806.785 1255.13 813.9C1269.79 820.77 1331.38 849.62 1380.78 832.38C1387.45 830.06 1401.12 825.11 1412.98 812.38C1416.08 809.07 1420.54 803.63 1426.95 790.6C1434.93 774.359 1440.79 757.163 1444.41 739.43L1352.55 690.31Z"
      fill="#F7AC0F"
    />
    <path
      opacity="0.1"
      d="M1290.77 709.86L1349.96 745.41C1358.66 724.762 1359.88 701.714 1353.4 680.26C1353.58 683.26 1354.66 705.95 1341.29 713.59C1336.6 716.27 1331.78 716.15 1323.26 715.85C1312.19 715.433 1301.25 713.415 1290.77 709.86Z"
      fill="black"
    />
    <path
      d="M1385.83 483.24C1389.5 480.72 1394.01 481.98 1396.48 483.59C1401.47 486.87 1402.21 494.27 1400.4 499.32C1398.07 505.84 1390.42 511.54 1385.36 509.15C1381.54 507.34 1380.78 501.7 1380.66 500.63C1383.45 500.08 1385.18 497.68 1384.87 495.52C1384.57 493.36 1382.11 492.2 1381.99 492.15C1381.78 490.456 1382.02 488.737 1382.7 487.17C1383.38 485.603 1384.46 484.246 1385.83 483.24V483.24Z"
      fill="#F0A893"
    />
    <path
      d="M868.595 209.78C866.05 206.1 861.36 206.28 860.732 206.31C855.004 206.61 850.803 212.31 849.396 217.03C847.041 224.93 851.571 233.41 857.578 236.9C858.995 237.73 863.765 240.49 867.028 238.17C869.433 236.45 870.271 232.51 868.914 228.49C865.541 228.35 863.396 225.4 863.875 223.03C864.176 222.014 864.8 221.125 865.652 220.499C866.504 219.872 867.538 219.543 868.595 219.56C869.527 218.1 870.023 216.403 870.023 214.67C870.023 212.937 869.527 211.24 868.595 209.78Z"
      fill="#F0A893"
    />
    <path
      d="M1119.98 680.86L1163.01 715.16"
      stroke="#6A9EFC"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1287 472.4C1286.56 475.79 1286.09 479.07 1285.79 482.4C1285.66 484.05 1285.53 485.68 1285.47 487.32C1285.41 488.96 1285.29 490.66 1285.25 492.27C1285.16 493.78 1285.29 495.296 1285.63 496.77C1286.02 498.114 1286.83 499.298 1287.93 500.15C1289.16 501.077 1290.57 501.741 1292.06 502.1C1293.67 502.511 1295.31 502.762 1296.96 502.85C1295.31 503.384 1293.59 503.624 1291.85 503.56C1290.96 503.513 1290.07 503.383 1289.2 503.17C1288.3 502.933 1287.43 502.569 1286.63 502.09C1284.9 501.105 1283.59 499.533 1282.92 497.66C1282.38 495.894 1282.16 494.045 1282.26 492.2C1282.26 490.46 1282.46 488.78 1282.63 487.1C1282.82 485.394 1283.1 483.701 1283.49 482.03C1284.21 478.672 1285.39 475.431 1287 472.4Z"
      fill="#F0A893"
    />
    <path
      d="M1138.15 616.6L1153.12 619.7C1148.67 623.4 1144.08 627.47 1139.41 631.92C1133.05 637.99 1127.37 643.99 1122.35 649.73L1114.18 648.26C1119.28 635.784 1127.56 624.868 1138.19 616.6H1138.15Z"
      fill="#F0A893"
    />
    <path
      d="M1298.75 528.73C1297.9 526.085 1298.12 523.209 1299.38 520.73C1300.1 519.387 1301.12 518.235 1302.37 517.37C1303.61 516.515 1305.01 515.932 1306.48 515.66C1309.31 515.128 1312.24 515.547 1314.81 516.85C1317.26 518.032 1319.27 519.97 1320.54 522.38C1318.38 521.251 1316.09 520.385 1313.73 519.8C1311.61 519.281 1309.4 519.206 1307.25 519.58C1305.23 519.915 1303.39 520.931 1302.02 522.46C1300.53 524.311 1299.42 526.443 1298.75 528.73V528.73Z"
      fill="#DD9887"
    />
    <path
      d="M972.232 213.69C971.175 218.43 970.047 223.09 969.299 227.78C968.479 232.399 968.144 237.091 968.301 241.78C968.301 242.95 968.431 244.08 968.57 245.27C968.77 246.596 968.77 247.944 968.57 249.27C968.343 250.815 967.523 252.211 966.285 253.16C965.079 254 963.632 254.425 962.164 254.37C959.632 254.282 957.139 253.712 954.82 252.69C952.569 251.759 950.402 250.635 948.344 249.33C950.669 250.02 952.954 250.77 955.269 251.28C957.474 251.885 959.76 252.142 962.044 252.04C962.997 251.965 963.907 251.618 964.669 251.04C965.308 250.485 965.709 249.704 965.786 248.86C965.895 247.8 965.862 246.731 965.686 245.68C965.527 244.47 965.387 243.21 965.327 241.97C965.242 239.493 965.342 237.012 965.627 234.55C965.934 232.112 966.401 229.697 967.024 227.32C968.238 222.593 969.985 218.02 972.232 213.69V213.69Z"
      fill="#F0A893"
    />
    <path
      d="M929.004 258.49C931.251 260.352 933.6 262.088 936.039 263.69C938.418 265.217 940.929 266.525 943.543 267.6C946.157 268.647 948.863 269.448 951.625 269.99C954.488 270.5 957.382 270.817 960.287 270.94C957.437 272 954.402 272.47 951.366 272.32C948.282 272.2 945.242 271.54 942.385 270.37C939.536 269.22 936.903 267.59 934.602 265.55C932.313 263.567 930.414 261.172 929.004 258.49Z"
      fill="#F0A893"
    />
    <path
      d="M934.762 275.65C935.979 275.94 937.097 276.24 938.204 276.45C939.295 276.669 940.398 276.816 941.507 276.89C942.614 276.96 943.724 276.96 944.83 276.89C945.958 276.83 947.105 276.67 948.352 276.54C947.44 277.413 946.336 278.06 945.129 278.43C942.68 279.203 940.03 279.025 937.705 277.93C936.564 277.404 935.557 276.624 934.762 275.65V275.65Z"
      fill="#F0A893"
    />
  </Svg>
);

export default DoctorBesideBedriddenPatient;
