import React, { FC } from 'react';

import { LinkOrButton } from 'styled';
import ScrollableAnchorWrapper from 'components/ScrollableAnchorWrapper';

import { ArticleButton } from 'types';

interface Props {
  module: ArticleButton;
}

const ButtonModule: FC<Props> = ({ module }) => {
  const { sideNav, label, link, logoIsActive } = module;

  return (
    <ScrollableAnchorWrapper section={sideNav}>
      <div className="ButtonModule md:col-10 py1_5 flex justify-center">
        <LinkOrButton
          ariaLabel={label}
          label={label}
          to={link}
          variant={logoIsActive ? 'primary-lg-with-logo' : 'primary-lg'}
          wrap={true}
        />
      </div>
    </ScrollableAnchorWrapper>
  );
};

export default ButtonModule;
