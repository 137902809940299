import React from 'react';
import styled from 'styled-components';
import Fab from '@mui/material/Fab';
import Fade from '@mui/material/Fade';
import { FabProps, useTheme } from '@mui/material';

export interface IFloatingActionButtonProps extends FabProps {
  /** whether button should fade in */
  show: boolean;
  className?: string;
}

const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 18px;
  width: 100%;
  text-align: center;
  ${({ theme }) => theme.zIndex('above-ads')};

  button,
  a {
    ${({ theme }) => theme.typography.variants.primary.sm};
    text-transform: none;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    display: none;
  }
`;

/** Floating action button that shows at the bottom of the screen in mobile & tablet views */
const FloatingActionButton: React.FC<IFloatingActionButtonProps> = (props) => {
  const { className, show, ...rest } = props;
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Fade in={show} timeout={transitionDuration}>
      <Container className={className}>
        <Fab color="primary" {...rest}>
          {props.children}
        </Fab>
      </Container>
    </Fade>
  );
};

export default FloatingActionButton;
