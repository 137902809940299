import React, { FC } from 'react';
import { Svg } from 'styled';

const StethoscopeIcon: FC = () => (
  <Svg width="24px" height="24px" viewBox="0 0 24 24" fill="transparent">
    <desc>Stethoscope Icon.</desc>
    <path
      d="M16.1391 23.2393L15.7768 22.9857C15.487 22.8046 8.27832 18.0229 8.27832 13.7484C8.27832 10.7056 10.4518 9.11169 12.589 9.11169C13.9656 9.11169 15.2334 9.76374 16.1391 10.8505C17.0447 9.76374 18.3125 9.11169 19.6891 9.11169C21.8263 9.14792 23.9998 10.7418 23.9998 13.7847C23.9998 18.0592 16.7911 22.8046 16.5013 23.0219L16.1391 23.2393Z"
      fill="#F43531"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.43872 2.57436C3.41083 2.57436 2.57436 3.41083 2.57436 4.43872V7.44536H0V4.43872C0 1.98905 1.98905 0 4.43872 0V2.57436Z"
      fill="#4085FE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.58301 0C12.0327 0 14.0217 1.98905 14.0217 4.43872V7.44536H11.4474V4.43872C11.4474 3.41083 10.6109 2.57436 9.58301 2.57436V0Z"
      fill="#4085FE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.29799 13.3862V15.7046C8.29799 17.4999 9.75699 18.9455 11.5027 18.9455C13.2778 18.9455 14.7073 17.5067 14.7073 15.7046H17.2817C17.2817 18.9015 14.7265 21.5199 11.5027 21.5199C8.32178 21.5199 5.72363 18.9083 5.72363 15.7046V13.3862H8.29799Z"
      fill="#196CFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.57436 7.40918C2.57436 9.89135 4.5753 11.8817 7.01066 11.8817C9.44603 11.8817 11.447 9.89135 11.447 7.40918H14.0213C14.0213 11.3025 10.8784 14.4561 7.01066 14.4561C3.14295 14.4561 0 11.3025 0 7.40918H2.57436Z"
      fill="#4085FE"
    />
    <path
      d="M16.2118 13.0602C15.1613 13.0602 14.3281 13.8933 14.3281 14.9439C14.3281 15.9944 15.1613 16.8275 16.2118 16.8275C17.2623 16.8275 18.0955 15.9944 18.0955 14.9439C18.0955 13.8933 17.2623 13.0602 16.2118 13.0602Z"
      fill="#196CFF"
    />
    <path
      d="M5.56126 1.28718C5.56126 1.90299 5.05412 2.37391 4.47452 2.37391C3.85871 2.37391 3.35156 1.90299 3.35156 1.28718C3.35156 0.671359 3.85871 0.200439 4.4383 0.200439C5.05412 0.200439 5.56126 0.707584 5.56126 1.28718Z"
      fill="#196CFF"
    />
    <path
      d="M10.6696 1.28718C10.6696 1.90299 10.1624 2.37391 9.58283 2.37391C8.96701 2.37391 8.49609 1.86677 8.49609 1.28718C8.49609 0.671359 9.00324 0.200439 9.58283 0.200439C10.1986 0.200439 10.6696 0.707584 10.6696 1.28718Z"
      fill="#196CFF"
    />
  </Svg>
);

export default StethoscopeIcon;
