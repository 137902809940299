import styled from 'styled-components';
import { Base, Theme } from 'styled';
import { Color } from 'styled/theme/colors';

type TitleProps = {
  textColor: Color;
};

type LinkedTitleContainerProps = {
  textColor: Color;
};

type SubtitleProps = {
  textColor: Color;
  isArticleItem?: boolean;
};

type LinkedSubtitleContainerProps = {
  textColor: Color;
};

export const Container = styled(Base)``;

export const Title = styled.span<TitleProps>`
  color: ${({ textColor }) => textColor};
  ${({ theme }) => theme.typography.variants.primary.md};
`;

export const Subtitle = styled(Base)<SubtitleProps>`
  color: ${({ textColor }) => textColor};
  ${({ theme, isArticleItem }) =>
    isArticleItem
      ? theme.typography.variants.primary.xs
      : theme.typography.variants.primary.sm};

  ${Theme.mediaQueries.md} {
    ${({ theme, isArticleItem }) =>
      !isArticleItem && theme.typography.variants.primary.lg};
  }
`;

export const SubtitleContainer = styled(Base)``;

export const LinkedTitleContainer = styled(Base)<LinkedTitleContainerProps>`
  ${Title} {
    ${({ textColor, theme }) =>
      theme.hoverStyles.linkBorderBottomHidden(textColor)};
  }

  :hover,
  :focus {
    ${Title} {
      ${({ textColor, theme }) =>
        theme.hoverStyles.linkBorderBottom(textColor)};
    }
  }
`;

export const LinkedSubtitleContainer = styled(
  Base
)<LinkedSubtitleContainerProps>`
  ${Subtitle} {
    ${({ textColor, theme }) =>
      theme.hoverStyles.linkBorderBottomHidden(textColor)};
  }

  :hover,
  :focus {
    ${Subtitle} {
      ${({ textColor, theme }) =>
        theme.hoverStyles.linkBorderBottom(textColor)};
    }
  }
`;
