import styled from 'styled-components';
import Theme from 'styled/theme';
import { Base } from 'styled/components/base';
import Button from 'styled/components/Button';
import Img from 'styled/components/Img';

export const Container = styled(Base)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
`;

export const Inner = styled(Base)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;

  ${Theme.mediaQueries.sm} {
    padding: 3.5rem;
    flex-direction: row;
  }

  ${Theme.mediaQueries.md} {
    padding: 3.5rem 1rem;
  }

  ${Theme.mediaQueries.lg} {
    max-width: 80rem;
    padding: 3.5rem 6rem;
  }
`;

export const TextContainer = styled(Base)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary['md-lg']};

  ${Theme.mediaQueries.md} {
    ${({ theme }) => theme.typography.variants.primary.lg};
  }
`;

export const DetailsContainer = styled(Base)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.125rem;
`;

export const Detail = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.sm};

  ${Theme.mediaQueries.md} {
    ${({ theme }) => theme.typography.variants.primary['md-lg']};
  }
`;

export const ImageContainer = styled(Base)`
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 1.5rem;

  img,
  div {
    min-width: 15.625rem;
  }

  ${Theme.mediaQueries.sm} {
    margin-bottom: 0rem;
    margin-right: 1.5rem;
  }

  ${Theme.mediaQueries.md} {
    margin-right: 8.25rem;
  }
`;

export const Image = styled(Img)`
  width: 100%;
  object-fit: contain;
`;

export const CtaContainer = styled(Base)`
  margin-top: 1.125rem;
`;

export const Cta = styled(Button).attrs({
  variant: 'underline-white',
})`
  ${({ theme }) => theme.typography.variants.secondary.sm};

  ${Theme.mediaQueries.md} {
    ${({ theme }) => theme.typography.variants.secondary.lg};
  }
`;
