import styled from 'styled-components';
import { Base, Theme } from 'styled';

type IndividualBarFillProps = {
  fill: number;
  totalNumberOfBars: number;
  isFirst?: boolean;
  isLast?: boolean;
};

export const Container = styled(Base)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.625rem;
  width: 100%;
`;

export const Title = styled.p`
  ${({ theme }) => theme.typography.variants.primary.sm};
  margin-bottom: 1.25rem;
`;

export const WholeBar = styled(Base)`
  height: 0.75rem;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

export const IndividualBar = styled(Base)`
  height: 100%;
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.palette.teal[30]};

  :not(:last-of-type) {
    margin-right: 0.125rem;
  }

  :first-of-type {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  :last-of-type {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
`;

export const IndividualBarFill = styled(Base)<IndividualBarFillProps>`
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: ${({ fill, totalNumberOfBars }) =>
    `calc(${fill} / (100/${totalNumberOfBars}) * 100%)`};

  :not(:last-of-type) {
    margin-right: 0.125rem;
  }

  border-top-left-radius: ${({ isFirst }) => (isFirst ? '0.75rem' : 'unset')};
  border-bottom-left-radius: ${({ isFirst }) =>
    isFirst ? '0.75rem' : 'unset'};

  border-top-right-radius: ${({ isLast, fill }) =>
    isLast && fill === 1 ? '0.75rem' : 'unset'};
  border-bottom-right-radius: ${({ isLast, fill }) =>
    isLast && fill === 1 ? '0.75rem' : 'unset'};
`;

export const Description = styled.p`
  ${({ theme }) => theme.typography.variants.secondary.xs};
`;
