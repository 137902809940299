import React, { FC } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { ExternalLink, ListItem } from 'constants/PortableTextSerializer';
import { ArticleContentImg } from 'styled';
import { CtaModulePortableText } from 'styled/components/portableText';
import CtaModuleInternalLink from './CtaModuleInternalLink';

import type { ModuleProps } from './types';

const CtaModule: FC<ModuleProps> = ({ module }) => {
  const { desktopImageMaxWidth } = module;

  return (
    <>
      <CtaModulePortableText as="section">
        <BlockContent
          blocks={module.text}
          serializers={{
            marks: {
              internalLink: CtaModuleInternalLink,
              link: ExternalLink,
              polarisLink: CtaModuleInternalLink,
            },
            listItem: ListItem,
          }}
        />
      </CtaModulePortableText>
      {module?.image?.src && (
        <ArticleContentImg
          image={module.image}
          desktopImageMaxWidth={desktopImageMaxWidth}
        />
      )}
    </>
  );
};

export default CtaModule;
