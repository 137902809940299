import styled from 'styled-components';
import Theme from 'styled/theme';
import { Base } from 'styled';

type DividerProps = {
  moduleIntersectsWithViewport: boolean;
};

export const Container = styled(Base)`
  color: ${({ theme }) => theme.palette.text.primary};
  margin-top: 4rem;
`;

export const HeaderContainer = styled(Base)`
  ${({ theme }) => theme.global.moduleContentPaddingX};
  ${({ theme }) => theme.typography.variants.primary['md-lg']};
  text-align: center;
  margin-top: 1.5rem;

  img {
    width: 1.875rem;
    margin-left: 0.5rem;
  }

  ${Theme.mediaQueries.md} {
    ${({ theme }) => theme.typography.variants.primary.xl};

    img {
      width: 2.75rem;
      margin-left: 1rem;
    }
  }
`;

export const Description = styled(Base)`
  ${({ theme }) => theme.typography.variants.secondary.md};
  ${({ theme }) => theme.global.moduleContentPaddingX};
  margin: 1.5rem auto;
  text-align: center;
  max-width: 80rem;
`;

export const DividerContainer = styled(Base)`
  margin: 0 auto;
  width: 6.25rem;
`;

export const Divider = styled(Base)<DividerProps>`
  width: 0;
  height: 2px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.palette.primary.main};
  ${({ moduleIntersectsWithViewport, theme }) =>
    moduleIntersectsWithViewport && theme.animations.growInWidth};
  animation-delay: 200ms;
`;

export const ButtonContainer = styled(Base)`
  display: flex;
  justify-content: center;
`;

export const VideoContainer = styled(Base)`
  width: 100%;
  height: 100%;
  margin: 3.5rem auto 0;
`;
