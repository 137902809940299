import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
};

const DropdownIndicatorIcon: FC<Props> = ({
  className = '',
  color = theme.palette.gray[100],
}) => (
  <Svg
    width="14px"
    height="8px"
    viewBox="0 0 14 8"
    fill="transparent"
    className={className}
  >
    <desc>Dropdown Indicator Icon.</desc>
    <path d="M13 1L7 7.18341L1 0.999999" stroke={color} />
  </Svg>
);

export default DropdownIndicatorIcon;
