import styled from 'styled-components';
import { Base } from 'styled';

type ContainerProps = {
  showBorderTop: boolean;
  showBorderBottom: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  ${({ theme }) => theme.global.blogPostContentPaddingX};
  margin: 1rem 0;
  border-top: ${({ theme, showBorderTop }) =>
    showBorderTop && theme.borders['gray-10']};
  border-bottom: ${({ theme, showBorderBottom }) =>
    showBorderBottom && theme.borders['gray-10']};
`;

export const Caption = styled.p`
  ${({ theme }) => theme.typography.variants.primary.xs};
  color: ${({ theme }) => theme.palette.gray[90]};
  margin-top: 0.75rem;
`;
