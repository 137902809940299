import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '@buoyhealth/common.buoy-theme';
import { Svg } from 'styled';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
  isReverse?: boolean;
  height?: string;
  width?: string;
};

const AccordionArrow: FC<Props> = ({
  className = '',
  color,
  height,
  width,
}) => (
  <Svg
    width={width || '12px'}
    height={height || '7px'}
    viewBox="0 0 12 7"
    fill="transparent"
    className={className}
  >
    <desc>Arrow Icon.</desc>
    <path d="M1 1L6 6L11 1" stroke={color || theme.palette.gray[100]} />
  </Svg>
);

export default styled(AccordionArrow)`
  ${({ theme }) => theme.transitions['transition-short']};
  transform: ${({ isReverse }) => (isReverse ? 'scaleY(-1)' : 'initial')};
`;
