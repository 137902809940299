import React, { useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import SearchBar from './SearchBar';
import useFeatureFlags from 'hooks/useFeatureFlags';
import Collapse from '@mui/material/Collapse';

const SearchResults = dynamic(() => import('./SearchResults'));

interface IBuoyGPTSearchProps {
  moduleId: string;
  placeholder?: string;
}

const BuoyGPTSearch = ({ moduleId, placeholder }: IBuoyGPTSearchProps) => {
  const featureFlags = useFeatureFlags();
  const [searchString, setSearchString] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resultsAreShowing, setResultsAreShowing] = useState(false);

  const handleSearchBarSubmit = useCallback((searchString: string) => {
    setSearchString(searchString);
    setIsLoading(!!searchString);

    if (!searchString) {
      setResultsAreShowing(false);
    }
  }, []);

  const displaySearchBar =
    featureFlags['growth-public-site-show-free-text-search-sx-query-module'];

  if (!displaySearchBar) {
    return null;
  }

  return (
    <>
      <SearchBar
        moduleId={moduleId}
        handleSubmit={handleSearchBarSubmit}
        isLoading={isLoading}
        placeholder={placeholder}
        resultsAreShowing={resultsAreShowing}
        setResultsAreShowing={setResultsAreShowing}
      />
      <Collapse in={!!searchString}>
        <SearchResults
          key={searchString}
          searchString={searchString}
          moduleId={moduleId}
          setIsLoading={setIsLoading}
          setResultsAreShowing={setResultsAreShowing}
        />
      </Collapse>
    </>
  );
};

export default BuoyGPTSearch;
