import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import styled from 'styled-components';
import { Svg } from 'styled';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  circleColor?: Color;
  arrowColor?: Color;
  hoverCircleColor?: Color;
};

const RightArrowInsideCircle: FC<Props> = ({
  className = '',
  circleColor = theme.palette.gray[90],
  arrowColor = theme.palette.common.white,
}) => (
  <Svg
    width="48"
    height="48"
    viewBox="0 0 80 80"
    fill="none"
    className={className}
  >
    <g filter="url(#filter0_ddd)">
      <circle cx="40" cy="39" r="32" fill={circleColor} />
    </g>
    <path
      d="M57.2929 38.4041C57.2747 37.9592 57.0836 37.445 56.7652 37.1207L46.3739 26.5425C45.6697 25.9362 44.3195 25.686 43.5325 26.3869C42.7573 27.0773 42.7804 28.4162 43.5526 29.1093L50.859 36.5374H24.1705C23.0945 36.5374 22.2222 37.3731 22.2222 38.4041C22.2222 39.4351 23.0945 40.2709 24.1705 40.2709H50.859L43.5526 47.699C42.8878 48.3371 42.7649 49.7231 43.5325 50.4213C44.2999 51.1195 45.6986 50.8965 46.3739 50.2658L56.7652 39.6875C57.1208 39.3255 57.294 38.9008 57.2929 38.4041Z"
      fill={arrowColor}
    />
    <defs>
      <filter
        id="filter0_ddd"
        x="0"
        y="0"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feMorphology
          radius="2"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow"
          result="effect2_dropShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow"
          result="effect3_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </Svg>
);

export default styled(RightArrowInsideCircle)<Props>`
  circle {
    ${({ theme }) => theme.transitions['transition']};
  }

  :hover {
    circle {
      fill: ${({ theme, hoverCircleColor = theme.palette.gray[70] }) =>
        hoverCircleColor};
    }
  }
`;
