import React, { FC } from 'react';

import { ItemContainer } from '../style';
import ItemHeader from '../ItemHeader';
import ItemDescription from '../ItemDescription';

import { Color } from 'styled/theme/colors';

import { TextItem as ITextItem } from 'types';

interface Props {
  item: ITextItem;
  bgColor: Color;
  textColor: Color;
}

const TextItem: FC<Props> = ({ item, bgColor, textColor }) => {
  return (
    <ItemContainer>
      <ItemHeader
        button={item.button}
        subtitle={item.subtitle}
        title={item.title}
        textColor={textColor}
      />
      <ItemDescription
        button={item.button}
        description={item.description}
        textColor={textColor}
        bgColor={bgColor}
      />
    </ItemContainer>
  );
};

export default TextItem;
