import React, { FC, useState, useEffect, useRef } from 'react';

import BlockContent from '@sanity/block-content-to-react';
import get from 'utils/get';
import useRefInView from 'hooks/useRefInView';

import { Button, AccordionArrowThick } from 'styled';
import {
  Container,
  TitleContainer,
  DividerContainer,
  Divider,
  AccordionsContainer,
  AccordionContainer,
  AccordionInner,
  AccordionToggle,
  AccordionToggleInner,
  AccordionTitle,
  Arrow,
  ExpandableContent,
  ExpandableContentTextContainer,
} from './style';

import useLanguage from 'hooks/useLanguage';
import {
  ListItem,
  ExternalLink,
  InternalLink,
  BlockRenderer,
} from 'constants/PortableTextSerializer';

import { AccordionListWithBlueAccentsModule as IAccordionListWithBlueAccentsModule } from 'types';
import { useTheme } from 'styled-components';

interface Props {
  module: IAccordionListWithBlueAccentsModule;
  /*
   * Variant with blue accents is used for Sanity module switches
   *
   * Variant without blue accents is used for Supplier Treatment and DxTx pages
   * and has reduced font sizes for header and question text
   */
  variant?: 'with-blue-accents' | 'without-blue-accents';
  applyPaddingX?: boolean;
}

const AccordionListWithBlueAccentsModule: FC<Props> = ({
  module,
  variant = 'with-blue-accents',
  applyPaddingX = true,
}) => {
  const Language = useLanguage();
  const { palette } = useTheme();
  const { header, listItems } = module;

  const [selectedItem, setSelectedItem] = useState('');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const moduleIntersectsWithViewport = useRefInView(containerRef);

  const toggleSelectedItem = (id: string) => {
    selectedItem === id ? setSelectedItem('') : setSelectedItem(id);
  };

  useEffect(() => {
    /* First item should be expanded */
    const firstListItemId = get(listItems[0], 'id');

    if (firstListItemId) {
      setSelectedItem(firstListItemId);
    }
  }, []);

  return (
    <Container
      ref={containerRef}
      variant={variant}
      applyPaddingX={applyPaddingX}
      data-testid="accordion-list-blue-accents-module"
    >
      <TitleContainer variant={variant}>
        {variant === 'with-blue-accents' && (
          <DividerContainer>
            <Divider
              moduleIntersectsWithViewport={moduleIntersectsWithViewport}
            />
          </DividerContainer>
        )}
        {header}
      </TitleContainer>
      <AccordionsContainer>
        {listItems.map((item) => {
          const itemIsExpanded = selectedItem === item.id;
          const handleToggleListItem = () => toggleSelectedItem(item.id);
          // IDs for a11y
          const toggleId = `toggle-${item.id}`;
          const contentId = `content-${item.id}`;

          return (
            <AccordionContainer key={item.id} variant={variant}>
              <AccordionInner>
                <AccordionToggle
                  itemIsExpanded={itemIsExpanded}
                  as={Button}
                  id={toggleId}
                  ariaLabel={Language.t(
                    `Global.${
                      itemIsExpanded
                        ? 'collapseButtonAriaLabel'
                        : 'expandButtonAriaLabel'
                    }`,
                    {
                      section: `"${item.button.label}"`,
                    },
                  )}
                  ariaExpanded={itemIsExpanded}
                  ariaControls={contentId}
                  onClick={handleToggleListItem}
                >
                  <AccordionToggleInner>
                    <p>
                      <AccordionTitle variant={variant}>
                        {item.button.label}
                      </AccordionTitle>
                    </p>

                    <Arrow
                      as={AccordionArrowThick}
                      isReverse={itemIsExpanded}
                      color={palette.primary.main}
                    />
                  </AccordionToggleInner>
                </AccordionToggle>
                <ExpandableContent
                  id={contentId}
                  itemIsExpanded={itemIsExpanded}
                  aria-hidden={!itemIsExpanded}
                  aria-labelledby={toggleId}
                  role="region"
                >
                  <ExpandableContentTextContainer
                    itemIsExpanded={itemIsExpanded}
                  >
                    <BlockContent
                      blocks={item.content}
                      serializers={{
                        listItem: ListItem,
                        marks: {
                          link: ExternalLink,
                          internalLink: InternalLink,
                        },
                        types: { block: BlockRenderer },
                      }}
                    />
                  </ExpandableContentTextContainer>
                </ExpandableContent>
              </AccordionInner>
            </AccordionContainer>
          );
        })}
      </AccordionsContainer>
    </Container>
  );
};

export default AccordionListWithBlueAccentsModule;
