import styled from 'styled-components';
import { Base, Theme } from 'styled';

type ContainerProps = {
  applyPaddingX: boolean;
};

type ItemsContainerProps = {
  moduleIntersectsWithViewport: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  ${({ theme, applyPaddingX }) =>
    applyPaddingX && theme.global.moduleContentPaddingX};
  color: ${({ theme }) => theme.palette.text.primary};
  padding-top: 4rem;
  padding-bottom: 3.5rem;
`;

export const Header = styled(Base)`
  text-align: center;
  ${({ theme }) => theme.typography.variants.primary['md-lg']};

  ${Theme.mediaQueries.md} {
    ${({ theme }) => theme.typography.variants.primary.xl};
  }
`;

export const DescriptionContainer = styled(Base)`
  ${({ theme }) => theme.typography.variants.secondary.md};
  margin: 2.5rem 0 5rem;
  text-align: center;
`;

export const Divider = styled(Base)`
  height: 2px;
  width: 0;
  margin-bottom: 1rem;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const ItemText = styled(Base)``;

export const ItemsContainer = styled(Base)<ItemsContainerProps>`
  ${({ moduleIntersectsWithViewport, theme }) =>
    moduleIntersectsWithViewport && theme.animations.fadeIn};
  animation-delay: 300ms;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${ItemText} {
    opacity: 0;
    ${({ moduleIntersectsWithViewport, theme }) =>
      moduleIntersectsWithViewport && theme.animations.fadeInUpShortDistance};
    animation-delay: 600ms;
  }

  ${Divider} {
    ${({ moduleIntersectsWithViewport, theme }) =>
      moduleIntersectsWithViewport && theme.animations.growInWidth};
    animation-delay: 1.5s;
  }

  ${Theme.mediaQueries.md} {
    flex-direction: row;
  }
`;

export const Item = styled(Base)`
  width: 15rem;
  margin: 0 1.5rem 3rem;

  ${Theme.mediaQueries.sm} {
    width: 19rem;
  }

  ${Theme.mediaQueries.md} {
    width: 15rem;
    align-self: baseline;
  }

  ${Theme.mediaQueries.lg} {
    width: 20rem;
  }

  ${Theme.mediaQueries.xl} {
    width: 23rem;
  }
`;

export const ItemTitle = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.md};
  text-align: center;
  margin: 1rem 0;

  ${Theme.mediaQueries.md} {
    text-align: left;
  }
`;

export const ItemDescription = styled(Base)`
  ${({ theme }) => theme.typography.variants.secondary.sm};
  text-align: center;

  ${Theme.mediaQueries.md} {
    text-align: left;
  }
`;
