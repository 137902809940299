import React from 'react';

import { withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';

import cx from 'classnames';
import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import { LinkOrButton } from 'styled';
import { PageHero as StyledPageHero, FullImgContainer } from './style';
import ModuleSwitch from 'components/ModuleSwitch';
import ArtDirectionImage from './ArtDirectionImage';

import useLanguage from 'hooks/useLanguage';
import { EmphasizeModuleColorScheme } from 'constants/ColorScheme';
import { RouteMap, RoutesWithoutPageHeroButton } from 'constants/Routes';

import type { PageHero as IPageHero } from 'types';

interface PassedProps {
  hero: IPageHero;
}

type Props = PassedProps & WithBreakpointsProps & WithRouterProps;

function PageHero(props: Props) {
  const { hero, router } = props;

  const Language = useLanguage();

  // mobile image is optional in sanity
  const hasMobileImage = !!hero.mobileImage?.id;
  const defaultImage = hasMobileImage ? hero.mobileImage : hero.desktopImage;
  const hasImage = !!defaultImage.src;

  const {
    variant,
    buttonLink,
    buttonLabel,
    buttonVariant,
    gtmTrackerEventName,
  } = hero;
  // per SR-1364: when all three Hero Button fields are empty, do not render
  const pageHeroButtonUndefined = !buttonLink && !buttonLabel && !buttonVariant;
  const colorScheme = EmphasizeModuleColorScheme[hero.color];
  const variantIsLg = variant.includes('lg');
  const variantIsMd = variant.includes('md');
  const variantIsSm = variant.includes('sm');
  const selectedButtonLabel =
    buttonLabel || Language.t('SymptomChecker.chatAboutSymptoms');
  const selectedButtonLink = buttonLink || RouteMap.SYMPTOM_CHECKER.path;
  const selectedButtonAriaLabel = buttonLabel
    ? Language.t('Global.pageButtonAriaLabel', { title: buttonLabel })
    : Language.t('SymptomChecker.ariaLabel');

  const selectedButtonVariant = buttonVariant
    ? buttonVariant.includes('inline-')
      ? buttonVariant.replace('inline-', '')
      : buttonVariant
    : !!buttonLink && !!buttonLabel
      ? 'hero'
      : 'symptom-checker-white';

  const hidePageHeroButton = RoutesWithoutPageHeroButton.some((basePath) =>
    router.asPath.includes(basePath),
  );

  const isContentHubLanding = router.asPath.includes(
    RouteMap.CONTENT_HUB_LANDING.path,
  );

  const isHeroInline = buttonVariant && buttonVariant.includes('inline-');

  // To-do: Refactor to use styled components entirely

  return (
    <>
      <StyledPageHero
        className={cx('PageHero', {
          'py2_5 sm:pt3 sm:pb1_5': variant === 'content-hub-md',
        })}
        mobileImageWidth={hero.mobileImageWidth}
        desktopImageWidth={hero.desktopImageWidth}
        mobileAspectRatio={
          hero.mobileImage?.metadata?.dimensions.aspectRatio || 1
        }
        desktopAspectRatio={
          hero.desktopImage?.metadata?.dimensions.aspectRatio || 1
        }
        data-testid="page-hero"
        style={{ backgroundColor: colorScheme.background }}
      >
        {variantIsLg && hasImage && (
          <div className="t0 r0 l0 w100 h100 absolute z-0 overflow-x-hidden overflow-y-hidden">
            <FullImgContainer
              position="absolute"
              top={0}
              left={0}
              right={0}
              mx="auto"
              mobileImageWidth={hero.mobileImageWidth}
              desktopImageWidth={hero.desktopImageWidth}
            >
              <ArtDirectionImage
                containerClassName="w100"
                mobileImage={hero.mobileImage}
                desktopImage={hero.desktopImage}
              />
            </FullImgContainer>
          </div>
        )}
        <div
          className={cx(`PageHero__container PageHero__container--${variant}`, {
            'md:px2_25': variantIsLg,
            px1_5: variant !== 'general-md',
          })}
          style={{
            color: colorScheme.text,
          }}
        >
          {!isHeroInline &&
            !hidePageHeroButton &&
            !pageHeroButtonUndefined &&
            (variant === 'general-md' || variant === 'general-sm') && (
              <div className="PageHero__button h100 w100 events-none l0 r0 z-hero-button none absolute flex-col items-center md:flex">
                <LinkOrButton
                  ariaLabel={selectedButtonAriaLabel}
                  containerClassName="sm:sticky z-hero-button t0"
                  className="events-all inline-flex"
                  label={selectedButtonLabel}
                  to={selectedButtonLink}
                  variant={selectedButtonVariant as any}
                  gtmTrackerEventName={gtmTrackerEventName}
                />
              </div>
            )}
          <div
            className={cx(`PageHero__inner-container--${variant}`, {
              'flex flex-col sm:flex-row': variantIsMd,
              'items-start': variant === 'general-md',
              'items-center sm:items-start': variant === 'content-hub-md',
            })}
          >
            <div
              className={cx(
                `PageHero__text-container--${variant} flex flex-col`,
                {
                  'items-center text-center': variant !== 'general-md',
                  'col-12 sm:col-6 px1_5 md:pl5_25 md:pr1_5':
                    variant === 'general-md',
                  'w100 sm:mr1 flex-1 sm:items-start':
                    variant === 'content-hub-md',
                },
              )}
            >
              {hero.description && variant === 'content-hub-sm' && (
                <p className="PageHero__description z-2 mxauto mb_5 relative text-center text-sm">
                  {hero.description}
                </p>
                /* Inline CTA goes here */
              )}
              <h1
                className={cx(`PageHero__title--${variant} z-2 relative`, {
                  'text-xl-xxl md-up-text-xxxl':
                    variantIsLg && !isContentHubLanding,
                  'text-md-lg sm-up_text-xl-xxl':
                    (variantIsLg && isContentHubLanding) ||
                    variant === 'content-hub-md',
                  'md-up_text-xxl text-xl':
                    variant === 'general-md' || variantIsSm,
                })}
              >
                {hero.title}
              </h1>
              {hero.description && variant !== 'content-hub-sm' && (
                <>
                  <p
                    className={cx('PageHero__description z-2 relative', {
                      'text-sm': variant !== 'content-hub-md',
                      mt1_5:
                        (variantIsLg && !isContentHubLanding) || variantIsSm,
                      mt1: variantIsLg && isContentHubLanding,
                      'md-up_text-md mt1_5 md:mt2_5': variant === 'general-md',
                      'text-md sm-up_text-md-lg mt1':
                        variant === 'content-hub-md',
                    })}
                  >
                    {hero.description}
                  </p>
                  {isHeroInline && (
                    <LinkOrButton
                      ariaLabel={selectedButtonAriaLabel}
                      className="events-all inline-flex"
                      containerClassName="sm:sticky inline-flex z-hero-button t0 mt1_5 md:mt2_5"
                      label={selectedButtonLabel}
                      to={selectedButtonLink}
                      variant={selectedButtonVariant as any}
                      gtmTrackerEventName={gtmTrackerEventName}
                    />
                  )}
                </>
              )}
              {!isHeroInline && variant === 'content-hub-md' && (
                <div className="PageHero__button-container--content-hub-md border-top-gray-lighter w100 pt1 my1 sm:mb0 flex flex-col sm:items-start">
                  <p className="PageHero__button-container-text--content-hub-md">
                    {Language.t('SymptomChecker.notSureWhatYouAreLookingFor')}
                  </p>
                  <LinkOrButton
                    ariaLabel={selectedButtonAriaLabel}
                    containerClassName="flex justify-center mt1"
                    label={selectedButtonLabel}
                    to={selectedButtonLink}
                    variant={selectedButtonVariant as any}
                    gtmTrackerEventName={gtmTrackerEventName}
                  />
                </div>
              )}
            </div>
            {variant === 'general-md' && hasImage && (
              <ArtDirectionImage
                containerClassName="col-12 sm:col-6"
                mobileImage={hero.mobileImage}
                desktopImage={hero.desktopImage}
              />
            )}
            {variant === 'content-hub-md' && hasImage && (
              <ArtDirectionImage
                containerClassName="PageHero__img--content-hub-md w100"
                mobileImage={hero.mobileImage}
                desktopImage={hero.desktopImage}
                dimensions={defaultImage.metadata?.dimensions}
                crop={defaultImage.crop}
                className="fit-contain"
              />
            )}
          </div>
          {!isHeroInline && !hidePageHeroButton && variant === 'general-lg' && (
            <>
              <div className="PageHero__button-container--general-lg h100 w100 events-none l0 r0 absolute flex flex-col items-center">
                <LinkOrButton
                  ariaLabel={selectedButtonAriaLabel}
                  containerClassName={`flex justify-center md:sticky z-hero-button ${
                    isContentHubLanding ? 'mt1_5' : 'mt2_25'
                  }`}
                  className="events-all inline-flex"
                  label={selectedButtonLabel}
                  to={selectedButtonLink}
                  variant={selectedButtonVariant as any}
                  gtmTrackerEventName={gtmTrackerEventName}
                />
              </div>
              <div className="PageHero__button-space" />
            </>
          )}
          <div className="PageHero__module-container z-2 relative overflow-x-hidden">
            <ModuleSwitch
              modules={hero.modules}
              hasWrapper={true}
              color={hero.color}
              textColor={colorScheme.text}
            />
          </div>
        </div>
      </StyledPageHero>
    </>
  );
}

export default withBreakpoints(withRouter(PageHero));
