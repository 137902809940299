import React, { Suspense, useMemo } from 'react';
import sanityImgUtil from 'utils/sanityImgUtil';
import { ItemImage as StyledImage, ItemImageContainer } from './style';
import DoctorBesideBedriddenPatient from 'styled/components/svg/DoctorBesideBedriddenPatient';
import HealthCareWorkerSwabbingIndividual from 'styled/components/svg/HealthCareWorkerSwabbingIndividual';
import HealthCareOptions from 'styled/components/svg/HealthCareOptions';
import PersonThinkingWithCrossBandaids from 'styled/components/svg/PersonThinkingWithCrossBandaids';
import { IDxListItem } from 'types';
import { useTheme } from 'styled-components';

const DEFAULT_IMAGE_SET = [
  DoctorBesideBedriddenPatient,
  HealthCareWorkerSwabbingIndividual,
  HealthCareOptions,
  PersonThinkingWithCrossBandaids,
];

type Props = {
  item: IDxListItem;
  borderRadius?: string;
  marginRight?: string;
};

const ItemImage = ({
  item,
  borderRadius = '50%',
  marginRight = '1.5rem',
}: Props) => {
  const { palette } = useTheme();
  const { image, imageBgColor } = item;
  const RandomImageFromSet = useMemo(
    () =>
      DEFAULT_IMAGE_SET[Math.floor(Math.random() * DEFAULT_IMAGE_SET.length)],
    [],
  );

  return (
    <Suspense>
      <ItemImageContainer
        borderRadius={borderRadius}
        imageBgColor={imageBgColor || palette.blue[0]}
        marginRight={marginRight}
      >
        {image.id ? (
          <StyledImage
            src={sanityImgUtil(image, 120)}
            alt={image.alt}
            crop={image.crop}
          />
        ) : (
          <RandomImageFromSet />
        )}
      </ItemImageContainer>
    </Suspense>
  );
};

export default ItemImage;
