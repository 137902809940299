import React, { useMemo } from 'react';
import Script from 'next/script';

const TrustedFormScript = () => {
  const trustedFormSrc = useMemo(
    () =>
      `https://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=${new Date().getTime()}${Math.random()}`,
    []
  );

  return (
    <>
      <form>
        <input
          type="hidden"
          name="xxTrustedFormToken"
          id="xxTrustedFormToken_0"
        />
        <input
          type="hidden"
          name="xxTrustedFormCertUrl"
          id="xxTrustedFormCertUrl_0"
        />
        <input
          type="hidden"
          name="xxTrustedFormPingUrl"
          id="xxTrustedFormPingUrl_0"
        />
      </form>
      <Script src={trustedFormSrc} strategy="afterInteractive" />
      <noscript>
        <img src="https://api.trustedform.com/ns.gif" />
      </noscript>
    </>
  );
};

export default TrustedFormScript;
