import styled from 'styled-components';
import { Base } from 'styled';
import { Color } from 'styled/theme/colors';

type ContainerProps = {
  bgColor: Color;
  isArticleItem?: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  background-color: ${({ bgColor }) => bgColor};
  ${({ theme, isArticleItem }) =>
    !isArticleItem && theme.global.carouselItemDescriptionContainerStyling};
`;

export const Description = styled.span`
  display: block;
  ${({ theme }) => theme.typography.variants.secondary.sm};
`;

export const ButtonContainer = styled(Base)`
  display: inline-flex;
`;
