import styled from 'styled-components';
import { Base, Theme } from 'styled';

export const Container = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display', 'width'].includes(prop) && defaultValidatorFn(prop),
})`
  font-size: 1.125rem;
  *:last-child {
    margin-bottom: 0;
  }
`;

export const Inner = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display', 'width'].includes(prop) && defaultValidatorFn(prop),
})`
  ${Theme.mediaQueries.sm} {
    max-width: 16.5rem;
  }

  ${Theme.mediaQueries.xl} {
    max-width: 22rem;
  }
`;

export const TitleContainer = styled(Base)`
  span {
    ${({ theme }) => theme.typography.variants.primary.md}
  }
`;

export const ImageContainer = styled(Base)`
  width: 100%;
  margin-top: 1.5rem;

  img {
    align-self: center;
    object-fit: contain;
    width: 100%;
    max-width: 100%;
  }
`;

export const ImageTitleContainer = styled(Base)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Description = styled(Base)`
  ${({ theme }) => theme.typography.variants.secondary.sm};
`;
