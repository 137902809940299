import React, { useCallback, useEffect, useState } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import cx from 'classnames';
import validateEmail from 'utils/validateEmail';
import validatePhoneNumber from 'utils/validatePhoneNumber';
import formatphoneNumber from 'utils/formatPhoneNumber';

import { Button, Dropdown, TextField } from 'styled';
import {
  StyledTextField,
  StyledDropdown,
  Description,
  SubmitButtonContainer,
  SubmitButton,
  FormMessageContainer,
  FormMessage,
} from './style';
import usePrevious from 'hooks/usePrevious';
import useLanguage from 'hooks/useLanguage';
import { Color } from 'styled/theme/colors';

import { DropdownOption, InputField, FormValues, Status } from 'types';
import formatDate from 'utils/formatDate';

interface Props {
  inputFields: InputField[];
  className?: string;
  color: Color;
  description?: string;
  termsAgreement?: boolean;
  submitButtonLabel: string;
  handleSubmit: (formValues: FormValues) => any;
  formSubmitStatus: Status;
  title: string;
  errorMsg?: string;
}

interface State {
  formValues: FormValues;
  formValidation: {
    [label: string]: boolean;
  };
  formHasSent: boolean;
  formSubmissionHasFailed: boolean;
}

function Form(props: Props) {
  const {
    inputFields,
    className,
    color,
    description,
    submitButtonLabel,
    title,
    termsAgreement,
    errorMsg,
  } = props;

  const Language = useLanguage();
  const prevProps = usePrevious(props);
  const [state, setState] = useState<State>({
    formValues: {},
    formValidation: {},
    formHasSent: false,
    formSubmissionHasFailed: false,
  });
  const [agreement, setAgreement] = useState(false);
  const [localError, setLocalError] = useState('');

  const handleInputFieldValidation = useCallback(
    (title: string, value: boolean) => {
      setState((s) => ({
        ...s,
        formValidation: { ...s.formValidation, [title]: value },
      }));
    },
    []
  );

  const handleListChange = useCallback((title: string, value: string) => {
    setState((s) => ({
      ...s,
      formValues: { ...s.formValues, [title]: value },
    }));
  }, []);

  const optionAsDropdownOption = useCallback(
    (options: string[]): DropdownOption[] => {
      return options.map((option) => ({ value: option, label: option }));
    },
    []
  );

  const toggleLabel = useCallback(
    (title: string): boolean => {
      return !!state.formValues[title];
    },
    [state]
  );

  const validateInputField = useCallback(
    (currentInputField: InputField, selectedValue?: string): boolean => {
      const { formValues } = state;
      const { type, title, regex } = currentInputField;

      const value = selectedValue ? selectedValue : formValues[title];

      if (regex) {
        const valid = new RegExp(regex).test(value);
        handleInputFieldValidation(title, valid);
        return valid;
      }

      if (currentInputField.isRequired && !value) {
        handleInputFieldValidation(title, false);

        return false;
      }

      if (type === 'email') {
        const emailIsValidated = validateEmail(value);
        handleInputFieldValidation(title, emailIsValidated);

        return emailIsValidated;
      }

      if (type === 'tel') {
        const phoneNumberIsValidated = validatePhoneNumber(value);
        handleInputFieldValidation(title, phoneNumberIsValidated);

        return phoneNumberIsValidated;
      }

      handleInputFieldValidation(title, true);

      return true;
    },
    [handleInputFieldValidation, state]
  );

  const handleFormSubmit = useCallback(() => {
    const { inputFields } = props;
    const { formValues } = state;

    const formIsValidated = Object.values(inputFields).map((inputField) =>
      validateInputField(inputField)
    );

    const termsError =
      'Please agree to the terms above by clicking the checkbox.';
    if (termsAgreement && !agreement) {
      setLocalError(termsError);
      return;
    } else if (localError === termsError) {
      setLocalError('');
    }

    const fieldsError = 'Please check the fields above for errors.';
    if (formIsValidated.filter((value) => !value).length > 0) {
      setLocalError(fieldsError);
      return;
    } else if (localError === fieldsError) {
      setLocalError('');
    }

    props.handleSubmit(formValues);
  }, [props, state, validateInputField, agreement, termsAgreement]);

  const { formValues, formValidation, formHasSent, formSubmissionHasFailed } =
    state;

  const componentTitle = title;

  useEffect(() => {
    if (
      prevProps?.formSubmitStatus === Status.PENDING &&
      props.formSubmitStatus === Status.FULFILLED
    ) {
      setState((s) => ({
        ...s,
        formValues: {},
        formValidation: {},
        formHasSent: true,
        formSubmissionHasFailed: false,
      }));
    }

    if (
      prevProps?.formSubmitStatus === Status.PENDING &&
      props.formSubmitStatus === Status.REJECTED
    ) {
      setState((s) => ({
        ...s,
        formHasSent: false,
        formSubmissionHasFailed: true,
      }));

      setTimeout(
        () =>
          setState((s) => ({
            ...s,
            formSubmissionHasFailed: false,
          })),
        3000
      );
    }
  }, [prevProps, props]);

  return (
    <div className={cx('Form', className)}>
      <div>
        {inputFields.map((inputField) => {
          const { label, title } = inputField;
          const isValidated =
            formValidation[title] !== undefined ? formValidation[title] : true;

          if (inputField.options.length) {
            return (
              <StyledDropdown
                as={Dropdown}
                pt={2}
                key={title}
                name={`${componentTitle}-${title}`}
                color={color}
                ariaLabel={Language.t('Global.dropdownAriaLabel', {
                  title: label,
                })}
                options={optionAsDropdownOption(inputField.options)}
                placeholder={label}
                onChange={(option: DropdownOption | null) => {
                  const selectedValue = option ? option.value : '';
                  handleListChange(title, selectedValue);
                  validateInputField(inputField, selectedValue);
                }}
                showError={!isValidated}
                errorMessage={Language.t('Global.textFieldErrorMessage')}
                value={
                  formValues[title]
                    ? optionAsDropdownOption([formValues[title]])[0]
                    : null
                }
                showLabel={toggleLabel(title)}
                label={label}
              />
            );
          }

          const textType =
            inputField.type === 'date' ? 'text' : inputField.type;

          return (
            <StyledTextField
              as={TextField}
              pt={2}
              key={title}
              name={`${componentTitle}-${title}`}
              ariaLabel={Language.t('Global.textFieldAriaLabel', {
                title: label,
              })}
              color={color}
              errorMessage={Language.t('Global.textFieldErrorMessage')}
              label={label}
              onBlur={() => validateInputField(inputField)}
              onChange={(value: React.Key) => {
                if (inputField.type === 'tel') {
                  return handleListChange(
                    title,
                    formatphoneNumber(value.toString())
                  );
                }
                if (inputField.type === 'date') {
                  return handleListChange(title, formatDate(value.toString()));
                }
                handleListChange(title, value.toString());
              }}
              value={formValues[title] || ''}
              placeholder={label}
              showError={!isValidated}
              showLabelAsPlaceholder={toggleLabel(title)}
              type={textType}
            />
          );
        })}
      </div>
      {description && (
        <Description my={1.75}>
          {description + '  '}
          {termsAgreement && (
            <input
              type="checkbox"
              checked={agreement}
              onChange={() => setAgreement(!agreement)}
            />
          )}
        </Description>
      )}
      <SubmitButtonContainer
        width="100%"
        mt={description ? 1.75 : 2.5}
        display="inline-flex"
        flexDirection={['column', 'column', 'row']}
        justifyContent={['flex-start', 'flex-start', 'space-between']}
        alignItems={['start', 'start', 'center']}
      >
        <SubmitButton
          as={Button}
          ariaLabel={submitButtonLabel}
          variant="primary"
          onClick={handleFormSubmit}
          label={submitButtonLabel}
        />
        <FormMessageContainer
          position="relative"
          width="100%"
          mt={[1, 1, 0]}
          ml={[0, 0, 1]}
          display="flex"
          flexGrow={1}
          justifyContent={['flex-start', 'flex-start', 'flex-end']}
          alignItems={['start', 'start', 'center']}
        >
          <FormMessage
            color={theme.palette.status.success}
            shouldDisplay={formHasSent}
            aria-hidden={!formHasSent}
          >
            {Language.t('Form.formSubmitSuccessMsg')}
          </FormMessage>
          <FormMessage
            color={theme.palette.status.error}
            shouldDisplay={formSubmissionHasFailed}
            aria-hidden={!formSubmissionHasFailed}
          >
            {errorMsg || Language.t('Form.formSubmitFailMsg')}
          </FormMessage>
          <FormMessage
            color={theme.palette.status.error}
            shouldDisplay={localError !== ''}
            aria-hidden={localError === ''}
          >
            {localError}
          </FormMessage>
        </FormMessageContainer>
      </SubmitButtonContainer>
    </div>
  );
}

export default Form;
