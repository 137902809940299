import { NextPage } from 'next';
import { reduxWrapper } from 'store';

import { fetchGenericPage } from 'state/actions/genericPageActions';

import GenericPageView from 'views/GenericPageView';

import { RouteMap } from 'constants/Routes';

import {
  ApplicationContext,
  GenericPage,
  ErrorPage as IErrorPage,
} from 'types';
import { initializeApplication } from 'state/actions/applicationActions';
import { REVALIDATE_TIME } from 'constants/Revalidate';

interface InitialProps {
  page: GenericPage | null;
  errorPage: IErrorPage;
}

type Props = InitialProps;

const HomePage: NextPage<Props> = (props) => {
  return (
    <GenericPageView page={props.page} errorPage={props.errorPage} disableAds />
  );
};

export const getStaticProps = reduxWrapper.getStaticProps(
  async ({ preview = false, previewData, store }) => {
    try {
      // _app.tsx#getInitialProps doesn't fire when building static pages. We have to initialize
      // the app here. TODO: think of a DRY'er way to do this, so we don't forget to when we build
      // more pages statically.
      await store.dispatch(
        initializeApplication({
          query: {},
          req: {},
        } as ApplicationContext) as any,
      );

      const frontPageFromStore =
        store.getState().genericPage[RouteMap.HOME.path];

      if (!preview && frontPageFromStore) {
        return {
          props: {
            page: frontPageFromStore,
          },
        };
      }

      const action = fetchGenericPage(RouteMap.HOME.path, {
        enabled: preview,
        id: previewData?.id,
      });
      store.dispatch(action);

      return action.payload.then((page) => {
        if (!page) {
          return {
            props: {
              article: null,
            },
            notFound: true,
            revalidate: REVALIDATE_TIME,
          };
        }

        return {
          props: {
            page,
            draftMode: preview,
          },
        };
      });
    } catch (e) {
      console.error(e);

      return {
        notFound: true,
      };
    }
  },
);

export default reduxWrapper.withRedux(HomePage);
