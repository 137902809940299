import React, { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ISupplierCtaModule } from 'styled/components/modules/SupplierCtaModule/types';
import useBreakpointIsMobile from 'hooks/useBreakpointIsMobile';
import MobileSupplierPromoModal from './components/MobileDrawer';
import DesktopSupplierPromoModal from './components/DesktopModal';
import { trackModalExited, trackModalSeen } from './analytics';

export interface ISupplierPromoModalProps {
  config: ISupplierCtaModule['modalConfig'];
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  openIFrame?: () => void;
}

const SupplierPromoModal = (props: ISupplierPromoModalProps) => {
  const isMobile = useBreakpointIsMobile();
  const pageCount = props.config.pages?.length;

  const impressionId = useMemo(() => uuid(), []);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (props.isOpen) {
      trackModalSeen(impressionId);
    }
  }, [impressionId, props.isOpen]);

  const handleClose = () => {
    trackModalExited(impressionId);
    props.onClose();
    setPage(0);
  };

  if (!pageCount) {
    return null;
  }

  const handlePagination = (diff: -1 | 1) => {
    const newPage = page + diff;
    if (newPage <= pageCount - 1) {
      setPage(newPage < 0 ? 0 : newPage);
    }
  };

  const triggerIFrameOpen = () => {
    props.onClose();
    props.openIFrame?.();
  };

  if (isMobile) {
    return (
      <MobileSupplierPromoModal
        {...props}
        impressionId={impressionId}
        onClose={handleClose}
        paginate={handlePagination}
        page={page}
        triggerIFrameOpen={triggerIFrameOpen}
      />
    );
  }

  return (
    <DesktopSupplierPromoModal
      {...props}
      impressionId={impressionId}
      onClose={handleClose}
      paginate={handlePagination}
      page={page}
      triggerIFrameOpen={triggerIFrameOpen}
    />
  );
};

export default SupplierPromoModal;
