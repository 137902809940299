import React, { FC } from 'react';

import cx from 'classnames';

import { Img, CircledNumber } from 'styled';

import BlockContent from '@sanity/block-content-to-react';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import {
  Base,
  BasePortableText,
  MarketingPortableText,
  ToggleableDataContainer,
} from 'styled';

import useLanguage from 'hooks/useLanguage';
import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
  SplitCtaColorScheme,
  EmphasizeModuleColorScheme,
} from 'constants/ColorScheme';
import {
  ListItem,
  ExternalLink,
  InternalLink,
} from 'constants/PortableTextSerializer';

import { NumberedListWithImageModule as INumberedListWithImageModule } from 'types';
import { useTheme } from 'styled-components';

type Props = {
  module: INumberedListWithImageModule;
  hasWrapper?: boolean;
  wrapperColor?: GeneralModuleColor;
  applyArticleBodyWidthOffset?: boolean;
  applyBlogPostBodyWidthOffset?: boolean;
};

const NumberedListWithImageModule: FC<Props> = ({
  module,
  hasWrapper,
  wrapperColor,
  applyArticleBodyWidthOffset,
  applyBlogPostBodyWidthOffset,
}) => {
  const Language = useLanguage();
  const { palette } = useTheme();

  const {
    moduleTitle,
    moduleDescription,
    moduleOptions,
    numberOptions,
    contents,
  } = module;

  const colorScheme =
    GeneralModuleColorScheme[moduleOptions.color as GeneralModuleColor];
  const wrapperColorScheme = wrapperColor
    ? GeneralModuleColorScheme[wrapperColor]
    : null;

  const numberBgColor = SplitCtaColorScheme[numberOptions.bgColor].background;

  /**
   *  If there is wrapper component, use wrapper bg color as default.
   *  Otherwise, use module bg color as default.
   * */

  const numberColor = numberOptions.color
    ? EmphasizeModuleColorScheme[numberOptions.color].background
    : hasWrapper && wrapperColorScheme
    ? wrapperColorScheme.background
    : colorScheme.background;

  const moduleHasTitle = !!moduleTitle;

  return (
    <div
      className={cx(
        'NumberedListWithImageModule module-content-padding-x py6 mxauto flex flex-col',
        {
          'article-inner-text-full-width': applyArticleBodyWidthOffset,
          'blog-post-inner-text-full-width': applyBlogPostBodyWidthOffset,
        }
      )}
      style={{
        backgroundColor: !hasWrapper ? colorScheme.background : undefined,
      }}
    >
      {(moduleHasTitle || !!moduleDescription.length) && (
        <div className="NumberedListWithImageModule__title-description flex flex-col mb4_5 md:mb5_5 text-center">
          {moduleHasTitle && (
            <h2 className="NumberedListWithImageModule__title text-md-lg md-up_text-xl mxauto">
              {moduleTitle}
            </h2>
          )}
          {!!moduleDescription.length && (
            <div className="text-sm mt1_5 mxauto">
              <MarketingPortableText>
                <BlockContent
                  blocks={moduleDescription}
                  serializers={{
                    marks: { link: ExternalLink, internalLink: InternalLink },
                  }}
                />
              </MarketingPortableText>
            </div>
          )}
        </div>
      )}
      {!!module.topImage.id && (
        <Img
          alt={module.topImage.alt}
          className="NumberedListWithImageModule__top-image w100"
          sizes="(max-width: 768px) 768px, (max-width: 1024px) 1024px, 1990px"
          src={sanityImgUtil(module.topImage, 768)}
          srcSet={sanityImgSrcSetUtil(module.topImage, 768, 1024, 1990)}
          preloadContainerClassName="w100"
          dimensions={module.topImage.metadata?.dimensions}
          crop={module.topImage.crop}
        />
      )}
      <div
        className={cx('NumberedListWithImageModule__items flex flex-col', {
          'color-gray-100':
            !hasWrapper && colorScheme.text === palette.text.primary,
          'color-white':
            !hasWrapper && colorScheme.text === palette.common.white,
        })}
      >
        {contents.map((listItem, i, contents) => {
          const hasEvenIndex = i % 2 === 0;
          const listItemHasImage = !!listItem.image?.id;

          return (
            <div
              className={cx(
                'NumberedListWithImageModule__item flex flex-col md:flex-row md:items-center md:justify-between mb3 md:mb4_5',
                {
                  'md:flex-row-reverse':
                    (hasEvenIndex &&
                      listItem.desktopImageAlignment !== 'left') ||
                    (!hasEvenIndex &&
                      listItem.desktopImageAlignment === 'right'),
                }
              )}
              key={listItem.id}
            >
              {listItem.image && listItemHasImage && (
                <div
                  className={cx(
                    'NumberedListWithImageModule__item-image-container mxauto mb1_5 md:mb0',
                    {
                      'md:mr1_5':
                        !hasEvenIndex ||
                        listItem.desktopImageAlignment === 'left',
                      'md:ml1_5':
                        hasEvenIndex ||
                        listItem.desktopImageAlignment === 'right',
                    }
                  )}
                >
                  <Img
                    alt={listItem.image.alt}
                    className="w100"
                    src={sanityImgUtil(listItem.image, 400)}
                    preloadContainerClassName="w100"
                    dimensions={listItem.image.metadata?.dimensions}
                    crop={listItem.image.crop}
                  />
                </div>
              )}
              <div className="NumberedListWithImageModule__item-text-container flex flex-row items-start flex-1">
                {numberOptions.displayNumber && (
                  <CircledNumber
                    mr={1.5}
                    circleColor={numberBgColor}
                    numberColor={numberColor}
                    number={i + 1}
                    ariaLabel={Language.t('Global.listOfPointsAriaLabel', {
                      currentNumber: i + 1,
                      totalNumber: contents.length,
                    })}
                    renderSmallerSizeOnMobile={true}
                  />
                )}
                <div className="NumberedListWithImageModule__item-text flex-1">
                  <Base
                    as={moduleHasTitle ? 'h3' : 'h2'}
                    className="NumberedListWithImageModule__item-text__title text-md-lg md-up_text-xl mb1_5"
                  >
                    {listItem.title}
                  </Base>
                  {!!listItem.text.length && (
                    <MarketingPortableText className="NumberedListWithImageModule__item-rich-text text-secondary-sm">
                      <BlockContent
                        blocks={listItem.text}
                        serializers={{
                          listItem: ListItem,
                          marks: {
                            link: ExternalLink,
                            internalLink: InternalLink,
                          },
                        }}
                      />
                    </MarketingPortableText>
                  )}
                  {!!listItem.statisticsList.length && (
                    <div
                      className={cx(
                        `NumberedListWithImageModule__statistics-list`,
                        {
                          'border-top-gray-lighter pt1_5':
                            listItem.displayDividerBetweenStatisticsListAndText,
                        }
                      )}
                    >
                      {listItem.statisticsList.map((statistic) => {
                        if (
                          !statistic.highlightedText ||
                          !statistic.statisticText.length
                        )
                          return null;

                        return (
                          <div
                            key={statistic.highlightedText}
                            className="NumberedListWithImageModule__statistics-list__item mb1_5"
                            style={{
                              color: statistic.textColor,
                            }}
                          >
                            <div
                              className={cx('flex items-end', {
                                mb1: !!statistic.source.length,
                              })}
                            >
                              <span className="NumberedListWithImageModule__statistics-list__item-stat">
                                {statistic.highlightedText}
                              </span>
                              <BasePortableText className="ml1">
                                <BlockContent
                                  blocks={statistic.statisticText}
                                  serializers={{
                                    marks: {
                                      link: ExternalLink,
                                      internalLink: InternalLink,
                                    },
                                  }}
                                />
                              </BasePortableText>
                            </div>
                            {!!statistic.source.length && (
                              <ToggleableDataContainer
                                data={statistic.source}
                                contentName="source"
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NumberedListWithImageModule;
