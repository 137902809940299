import { trackStructuredEvent } from 'analytics';

export const trackOutboundClick = (href: string) => {
  trackStructuredEvent({
    category: 'featured-link-module',
    action: 'product-link-clicked',
    property: href,
  });
};

export const trackModuleView = (moduleIndex: number, href: string) => {
  trackStructuredEvent({
    category: 'featured-link-module',
    action: 'module-viewed',
    property: href,
    value: moduleIndex,
  });
};
