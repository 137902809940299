import React, { FC } from 'react';
import get from 'utils/get';

import CalloutModuleWrapper from 'components/modules/CalloutModuleWrapper';
import EmphasisLinkCardModule from 'components/modules/EmphasisLinkCardModule';
import ImageCardsModule from 'components/modules/ImageCardsModule';
import LargeTextModule from 'components/modules/LargeTextModule';
import LinkCardsModule from 'components/modules/LinkCardsModule';
import NumberedListWithImageModule from 'components/modules/NumberedListWithImageModule';
import QuoteModule from 'components/modules/QuoteModule';
import SingleBlockCtaModule from 'components/modules/SingleBlockCtaModule';
import SplitCtaModule from 'components/modules/SplitCtaModule';

import AccordionListModule from 'styled/components/modules/AccordionListModule';
import AccordionListWithBlueAccentsModule from 'styled/components/modules/AccordionListWithBlueAccentsModule';
import AnimationAndTextModule from 'styled/components/modules/AnimationAndTextModule';
import CarouselModule from 'styled/components/modules/CarouselModule';
import EmbeddedInterview from 'styled/components/modules/EmbeddedInterview';
import GoogleFormModule from 'styled/components/modules/GoogleFormModule';
import InsuranceFormModule from 'styled/components/modules/InsuranceForm';
import HighlightedImagesModule from 'styled/components/modules/HighlightedImagesModule';
import LinkCardWithImageModule from 'styled/components/modules/LinkCardWithImageModule';
import LinksListModule from 'styled/components/modules/LinksListModule';
import ListItemsAndLogosModule from 'styled/components/modules/ListItemsAndLogosModule';
import DxListModule from 'styled/components/modules/DxListModule';
import SliderModule from 'styled/components/modules/SliderModule';
import ThreeColumnAnimationModule from 'styled/components/modules/ThreeColumnAnimationModule';
import VideoAndListItemsModule from 'styled/components/modules/VideoAndListItemsModule';
import VideoCtaModule from 'styled/components/modules/VideoCtaModule';
import ButtonModule from 'components/articleBodyModules/ButtonModule';
import CtaModule from 'components/articleBodyModules/CtaModule';
import ArticleBodyTextModule from 'components/articleBodyModules/TextModule';
import HighlightedTextModule from 'components/articleBodyModules/HighlightedTextModule';
import FeaturedLinkModule from 'styled/components/modules/FeaturedLinkModule';
import TypeformModule from './modules/TypeformModule';

import FirstAidBoxModule from 'styled/components/modules/FirstAidBoxModule';
import TesterRecruitmentCtaModule from 'styled/components/modules/TesterRecruitmentCtaModule';
import RoundedBgLinkCardsModule from 'styled/components/modules/RoundedBgLinkCardsModule';
import SupplierCardsModule from 'styled/components/modules/SupplierCardsModule';
import ProductCardsModule from 'styled/components/modules/ProductCardsModule';

import { ArticleBodyModule, FeatureFlags } from 'types';
import QueryModule from 'styled/components/QueryModule';
import {
  HighlightsModule,
  ImageWithCaptionModule,
  VideoModule,
} from './modules';
import KatalysModule from 'styled/components/modules/KatalysModule';

interface Props {
  modules: ArticleBodyModule[];
  articleSlug?: string;
  featureFlags?: FeatureFlags;
  isLoading?: boolean;
  conditionName?: string;
}

const ArticleBodyModuleSwitch: FC<Props> = ({ modules, articleSlug }) => {
  return (
    <>
      {modules.map((module, index) => {
        if (!module) {
          return null;
        }

        const calloutIsActive = get(
          module,
          'moduleOptions.callout.isActive',
          false,
        );

        /*
         * applyArticleBodyWidthOffset adds offset margins to module containers
         */
        switch (module.type) {
          case 'accordionList':
            if (calloutIsActive) {
              return (
                <CalloutModuleWrapper
                  key={module.id}
                  callout={module.moduleOptions.callout}
                  applyArticleBodyWidthOffset={true}
                >
                  <AccordionListModule module={module} hasCallout />
                </CalloutModuleWrapper>
              );
            }

            return (
              <AccordionListModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'accordionListWithAnimation':
            return (
              <AccordionListWithBlueAccentsModule
                key={module.id}
                module={module}
                applyPaddingX={false}
              />
            );
          case 'animationAndTextModule':
            return (
              <AnimationAndTextModule
                key={module.id}
                module={module}
                applyPaddingX={false}
              />
            );
          case 'articleButton':
            return <ButtonModule key={module.id} module={module} />;
          case 'articleText':
            return <ArticleBodyTextModule key={module.id} module={module} />;
          case 'carousel':
            return (
              <CarouselModule
                isArticleOrAuthorPageCarousel={true}
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'cta':
            return <CtaModule index={index} key={module.id} module={module} />;
          case 'dxList':
            return <DxListModule key={module.id} module={module} />;
          case 'embeddedInterview':
            return <EmbeddedInterview key={module.id} module={module} />;
          case 'emphasisLinkCard':
            if (calloutIsActive) {
              return (
                <CalloutModuleWrapper
                  key={module.id}
                  callout={module.moduleOptions.callout}
                  applyArticleBodyWidthOffset={true}
                >
                  <EmphasisLinkCardModule module={module} hasCallout />
                </CalloutModuleWrapper>
              );
            }

            return (
              <EmphasisLinkCardModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'featuredLink':
            return (
              <FeaturedLinkModule
                key={module.id}
                module={module}
                moduleIndex={modules
                  .filter((m) => m?.type === 'featuredLink')
                  .findIndex((m) => m?.id === module.id)}
              />
            );
          case 'firstAidBox':
            if (!articleSlug) return null;

            return (
              <FirstAidBoxModule key={module.id} articleSlug={articleSlug} />
            );
          case 'googleFormModule':
            return <GoogleFormModule key={module.id} module={module} />;
          case 'insuranceFormModule':
            return <InsuranceFormModule key={module.id} module={module} />;
          case 'highlightedImages':
            return (
              <HighlightedImagesModule
                key={module.id}
                module={module}
                applyAllMargins={false}
              />
            );
          case 'highlightedText':
            return <HighlightedTextModule key={module.id} module={module} />;
          case 'listItemsAndLogosModule':
            return (
              <ListItemsAndLogosModule
                key={module.id}
                module={module}
                applyPaddingX={false}
              />
            );
          case 'imageCards':
            return (
              <ImageCardsModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'katalysModule':
            return <KatalysModule key={module.id} module={module} />;
          case 'largeText':
            return (
              <LargeTextModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'linkCards':
            return (
              <LinkCardsModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'linkCardWithImage':
            return (
              <LinkCardWithImageModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'linksList':
            return (
              <LinksListModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'numberedListWithImage':
            return (
              <NumberedListWithImageModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'queryModule':
            return <QueryModule key={module.id} module={module} />;
          case 'quote':
            return (
              <QuoteModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'roundedBgLinkCardsModule':
            return <RoundedBgLinkCardsModule key={module.id} module={module} />;
          case 'singleBlockCta':
            return (
              <SingleBlockCtaModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'slider':
            return <SliderModule key={module.id} module={module} />;
          case 'splitCta':
            return (
              <SplitCtaModule
                key={module.id}
                module={module}
                applyArticleBodyWidthOffset={true}
              />
            );
          case 'testerRecruitmentCta':
            return (
              <TesterRecruitmentCtaModule key={module.id} module={module} />
            );
          case 'threeColumnAnimationModule':
            return (
              <ThreeColumnAnimationModule
                key={module.id}
                module={module}
                applyPaddingX={false}
              />
            );
          case 'typeformModule':
            return <TypeformModule key={module.id} module={module} />;
          case 'videoModule':
            return <VideoModule key={module.id} module={module} />;
          case 'videoAndListItemsModule':
            return <VideoAndListItemsModule key={module.id} module={module} />;
          case 'videoCtaModule':
            return <VideoCtaModule key={module.id} module={module} />;
          case 'imageWithCaption':
            return <ImageWithCaptionModule key={module.id} module={module} />;
          case 'highlights':
            return <HighlightsModule key={module.id} module={module} />;
          case 'supplierCards':
            return <SupplierCardsModule key={module.id} module={module} />;
          case 'productCards':
            return <ProductCardsModule key={module.id} module={module} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default ArticleBodyModuleSwitch;
