import React, { FC, useState, useEffect } from 'react';
import BlockContent from '@sanity/block-content-to-react';

import { Base } from 'styled';
import { ShowDataToggle, DataContainer } from './style';

import useLanguage from 'hooks/useLanguage';
import { ExternalLink, InternalLink } from 'constants/PortableTextSerializer';

import { PortableText } from 'types';

type Props = {
  data: PortableText;
  contentName: string;
};

const ToggleableDataContainer: FC<Props> = ({ data, contentName }) => {
  const Language = useLanguage();

  // Show data during SSR
  const [showData, setShowData] = useState(true);

  useEffect(() => {
    // Hide data when component mounts for CSR
    setShowData(false);
  }, []);

  return (
    <Base data-testid="toggleable-data-container">
      <ShowDataToggle
        variant="underline-blue-xs"
        ariaLabel={Language.t(
          `Global.${showData ? 'hideContent' : 'showContent'}`,
          { content: contentName }
        )}
        label={Language.t(
          `Global.${showData ? 'hideContent' : 'showContent'}`,
          { content: contentName }
        )}
        onClick={() => setShowData(!showData)}
        showData={showData}
      />
      <DataContainer showData={showData}>
        <BlockContent
          blocks={data}
          serializers={{
            marks: { link: ExternalLink, internalLink: InternalLink },
          }}
        />
      </DataContainer>
    </Base>
  );
};

export default ToggleableDataContainer;
