import React, { FC } from 'react';
import theme from '@buoyhealth/common.buoy-theme';
import BlockContent from '@sanity/block-content-to-react';

import { LinkOrButton } from 'styled';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

import {
  Container,
  ImageContainer,
  TextContainer,
  ImageWithFullWidth,
  ImageWithMaxWidth,
  CardContainer,
} from './style';

import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
  EmphasizeModuleColorScheme,
} from 'constants/ColorScheme';
import useLanguage from 'hooks/useLanguage';
import {
  ListItem,
  ExternalLink,
  InternalLink,
  Blue,
} from 'constants/PortableTextSerializer';

import { LinkCardWithImageModule as ILinkCardWithImageModule } from 'types';

interface Props {
  module: ILinkCardWithImageModule;
  applyArticleBodyWidthOffset?: boolean;
}

const LinkCardWithImageModule: FC<Props> = ({
  module,
  applyArticleBodyWidthOffset,
}) => {
  const {
    anchor,
    linkCard,
    image,
    fillImageContainer,
    imageContainerBackgroundColor,
    desktopImagePosition,
    moduleOptions,
    desktopMaxHeight,
  } = module;

  const Language = useLanguage();

  const colorScheme =
    GeneralModuleColorScheme[moduleOptions.color as GeneralModuleColor];
  const buttonLabel = linkCard.button.label;
  const buttonVariant = linkCard.button.variant;
  const url = linkCard.button.url;
  const gtmTrackerEventName = linkCard.button.gtmTrackerEventName;

  const titleElement = (title: string) => {
    if (!title) return null;

    return (
      <p className="text-md-lg" style={{ color: colorScheme.text }}>
        <span
          className={`link-border-bottom-${
            colorScheme.text === theme.palette.text.primary ? 'black' : 'white'
          }-hidden`}
        >
          {title}
        </span>
      </p>
    );
  };

  return (
    <Container
      id={anchor ? anchor : undefined}
      showBorderTop={moduleOptions.showBorderTop}
      showBorderBottom={moduleOptions.showBorderBottom}
      colorScheme={colorScheme}
      applyArticleBodyWidthOffset={applyArticleBodyWidthOffset}
      desktopImagePosition={desktopImagePosition}
      desktopMaxHeight={desktopMaxHeight}
    >
      <ImageContainer
        imageContainerColor={
          EmphasizeModuleColorScheme[imageContainerBackgroundColor]
        }
      >
        {fillImageContainer ? (
          <ImageWithFullWidth
            alt={image.alt}
            sizes="(max-width: 768px) 600px, (max-width: 1024px) 700px, 1990px"
            src={sanityImgUtil(image, 600)}
            srcSet={sanityImgSrcSetUtil(image, 600, 700, 1990)}
          />
        ) : (
          <ImageWithMaxWidth alt={image.alt} src={sanityImgUtil(image, 700)} />
        )}
      </ImageContainer>
      <CardContainer fillImageContainer={fillImageContainer}>
        {!!linkCard.title && (
          <div className="mb1_5">
            {!url && titleElement(linkCard.title)}
            {!!url && (
              <LinkOrButton
                ariaLabel={Language.t('Global.pageButtonAriaLabel', {
                  title: url,
                })}
                to={url}
                className="link-border-bottom-appear-on-hover"
                containerClassName="inline-flex"
                gtmTrackerEventName={gtmTrackerEventName}
              >
                {titleElement(linkCard.title)}
              </LinkOrButton>
            )}
          </div>
        )}
        {!!linkCard.text?.length && (
          <TextContainer>
            <BlockContent
              blocks={linkCard.text}
              serializers={{
                listItem: ListItem,
                marks: {
                  link: ExternalLink,
                  internalLink: InternalLink,
                  blue: Blue, // TODO fix this?
                },
              }}
            />
          </TextContainer>
        )}
        <div>
          {!!buttonLabel && !!url && (
            <LinkOrButton
              ariaLabel={Language.t('Global.pageButtonAriaLabel', {
                title: url,
              })}
              variant={
                buttonVariant === 'primary'
                  ? buttonVariant
                  : (`hover-${buttonVariant}` as
                      | 'hover-underline-white'
                      | 'hover-underline-black')
              }
              label={buttonLabel}
              to={url}
              gtmTrackerEventName={gtmTrackerEventName}
            />
          )}
        </div>
      </CardContainer>
    </Container>
  );
};

export default LinkCardWithImageModule;
