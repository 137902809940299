import styled from 'styled-components';
import { Base } from 'styled/components/base';
import Button from 'styled/components/Button';
import Theme from 'styled/theme';

type ContainerProps = {
  applyPaddingX: boolean;
};

type DividerProps = {
  moduleIntersectsWithViewport: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  ${({ theme }) => theme.global.managedMarketplacePageContentMaxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: ${({ applyPaddingX }) =>
    applyPaddingX ? '2.5rem 1rem 1.5rem 1rem' : '2.5rem 0 1.5rem 0'};
  align-items: center;

  ${Theme.mediaQueries.sm} {
    padding-left: ${({ applyPaddingX }) => applyPaddingX && '1.5rem'};
    padding-right: ${({ applyPaddingX }) => applyPaddingX && '1.5rem'};
  }

  ${Theme.mediaQueries.md} {
    flex-direction: row;
    padding: ${({ applyPaddingX }) =>
      applyPaddingX ? '6.5rem' : '6.5rem 0 6.5rem 0'};
  }

  ${Theme.mediaQueries.lg} {
    padding-top: 7.21875rem;
    padding-bottom: 7.21875rem;
  }
`;

export const TextContainer = styled(Base)`
  display: flex;
  flex-direction: column;
`;

export const DividerContainer = styled(Base)`
  width: 6.25rem;
`;

export const Divider = styled(Base)<DividerProps>`
  width: 0;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.blue[80]};
  margin-bottom: 1.5rem;
  ${({ moduleIntersectsWithViewport, theme }) =>
    moduleIntersectsWithViewport && theme.animations.growInWidth};
  animation-delay: 300ms;
`;

export const TitleContainer = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary['md-lg']};
`;

export const DescriptionContainer = styled(Base)`
  ${({ theme }) => theme.typography.variants.secondary.sm};
  margin-top: 1.5rem;
`;

export const SymptomCheckerButtonContainer = styled(Base)`
  display: flex;
`;

export const SymptomCheckerButton = styled(Button)`
  display: inline-flex;
  margin-top: 1.5rem;
`;

export const ImgOuterContainer = styled(Base)`
  display: flex;
  align-items: center;
  width: 17.3075rem;
  min-width: 17.3075rem;
  margin-bottom: 2.25rem;

  ${Theme.mediaQueries.md} {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }
`;

export const ImgContainer = styled(Base)`
  width: 100%;
  max-height: 22.5rem;

  img,
  div,
  div > svg {
    max-height: 20.406875rem;
  }
`;
