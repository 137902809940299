import React, { FC } from 'react';
import { Svg } from 'styled';

const RxPillIcon: FC = () => (
  <Svg width="24px" height="24px" viewBox="0 0 24 24" fill="transparent">
    <desc>Rx Pill Icon.</desc>
    <path
      d="M22.6375 12.3374V18.0374C22.6375 21.2999 19.975 23.9624 16.675 23.9624C13.4125 23.9999 10.75 21.3374 10.75 18.0749V14.0249C10.75 13.0874 11.5 12.3374 12.4375 12.3374H13.7125H22.6375Z"
      fill="#0641BD"
    />
    <path
      d="M10.75 12.3375V5.925C10.75 2.6625 13.4125 0 16.7125 0C19.975 0 22.6375 2.6625 22.675 5.925V10.6875C22.675 11.625 21.925 12.3375 21.025 12.3375H19.75H10.75Z"
      fill="#F43531"
    />
    <path
      d="M15.1 16.9499C15.1 20.8499 11.95 23.9999 8.05 23.9999C4.15 23.9999 1 20.8499 1 16.9499C1 13.0499 4.15 9.8999 8.05 9.8999C11.95 9.8999 15.1 13.0874 15.1 16.9499Z"
      fill="#4085FE"
    />
    <path
      d="M5.57502 16.3124V18.1499H4.52502V13.0874H6.47503C7.07503 13.0874 7.52502 13.2374 7.86252 13.5374C8.20002 13.8374 8.35003 14.2124 8.35003 14.6999C8.35003 15.1874 8.20002 15.5624 7.86252 15.8624C7.52502 16.1624 7.07503 16.3124 6.47503 16.3124H5.57502ZM5.57502 15.5249H6.51252C6.81252 15.5249 7.00002 15.4499 7.15002 15.2999C7.30002 15.1499 7.37503 14.9624 7.37503 14.6999C7.37503 14.4749 7.30002 14.2499 7.15002 14.0999C7.00002 13.9499 6.77502 13.8749 6.51252 13.8749H5.57502V15.5249Z"
      fill="white"
    />
    <path
      d="M6.25 15.9751H7.4125L11.2 21.3751H9.9625L6.25 15.9751Z"
      fill="white"
    />
    <path
      d="M6.625 21.3751H7.7875L11.2 15.9751H9.9625L6.625 21.3751Z"
      fill="white"
    />
  </Svg>
);

export default RxPillIcon;
