import Arrows from 'constants/Arrows';
import { Base, Img, Link, Theme } from 'styled';
import styled, { css } from 'styled-components';

type CardProps = {
  backgroundColor: string;
};

type TextContainerProps = {
  hasDescription: boolean;
};

type ContainerProps = {
  hasDescription: boolean;
  renderTwoCardsInARowForTablet: boolean;
};

export const Container = styled(Base)<ContainerProps>`
  display: grid;
  row-gap: 1.5rem;
  margin: 1.5rem 0rem;

  ${({ renderTwoCardsInARowForTablet }) =>
    renderTwoCardsInARowForTablet &&
    css`
      ${Theme.mediaQueries.sm} {
        grid-gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(22.25rem, 1fr));
      }
    `};

  // If any card has a description, we display as a stack on desktop
  ${({ hasDescription }) =>
    hasDescription &&
    css`
      ${Theme.mediaQueries.md} {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    `};
`;

export const Card = styled(Base)<CardProps>`
  display: flex;
  padding: 2rem 1.5rem;
  width: 100%;
  border-radius: 0.5rem;
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${Theme.mediaQueries['max-sm']} {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
`;

export const CardTitle = styled.h2`
  ${({ theme }) => theme.typography.variants.primary.md};
  margin-bottom: 0.5rem;
`;

export const LinkedCardContainer = styled(Link).attrs({
  containerClassName: 'w100',
})`
  ${CardTitle} {
    ${({ theme }) =>
      theme.hoverStyles.linkBorderBottomHidden(theme.palette.text.primary)};
    white-space: normal;

    ::after {
      content: '${Arrows.RIGHT_ARROW}';
      margin-left: 0.5rem;
    }
  }

  :hover {
    ${CardTitle} {
      ${({ theme }) =>
        theme.hoverStyles.linkBorderBottom(theme.palette.text.primary)};
    }
  }
`;

export const TextContainer = styled(Base)<TextContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  ${({ theme }) => theme.typography.variants.secondary['xs-sm']};

  a {
    margin-top: 1.5rem;
  }

  ${({ hasDescription }) =>
    !hasDescription &&
    css`
      ${CardTitle} {
        padding-bottom: 0;
      }
    `};

  ${Theme.mediaQueries['max-sm']} {
    align-items: center;
  }
`;

export const ImageContainer = styled(Base)`
  display: flex;
  height: 6.25rem;
  width: 6.25rem;
  margin-left: 1rem;

  ${Theme.mediaQueries['max-sm']} {
    margin: 0 0 1.25rem;
  }
`;

export const StyledCardImage = styled(Img)`
  width: 100%;
  height: 100%;
`;
