import { FC, useRef } from 'react';
import BlockContent from '@sanity/block-content-to-react';

import { Img } from 'styled';
import {
  Container,
  DividerContainer,
  Divider,
  TextContentContainer,
  TitleContainer,
  TextContainer,
  ImagesContainer,
  BackImageContainer,
  FrontImageContainer,
} from './style';

import { ExternalLink, InternalLink } from 'constants/PortableTextSerializer';

import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import useRefInView from 'hooks/useRefInView';

import { HighlightedImagesModule as IHighlightedImagesModule } from 'types';

export interface Props {
  module: IHighlightedImagesModule;
  // If rendered within articles, certain margins are not necessary
  applyAllMargins?: boolean;
}

const HighlightedImagesModule: FC<Props> = ({
  module,
  applyAllMargins = true,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const moduleIntersectsWithViewport = useRefInView(containerRef);

  const { title, text, backImage, frontImage } = module;

  return (
    <Container ref={containerRef} data-testid="highlighted-images-module">
      <TextContentContainer applyAllMargins={applyAllMargins}>
        <TitleContainer>
          <DividerContainer>
            <Divider
              moduleIntersectsWithViewport={moduleIntersectsWithViewport}
            />
          </DividerContainer>
          {title}
        </TitleContainer>
        <TextContainer>
          <BlockContent
            blocks={text}
            serializers={{
              marks: { link: ExternalLink, internalLink: InternalLink },
            }}
          />
        </TextContainer>
      </TextContentContainer>
      <ImagesContainer>
        <BackImageContainer>
          <Img
            alt={backImage.alt}
            sizes="(max-width: 768px) 488px, (max-width: 1024px) 574px, 906px"
            src={sanityImgUtil(backImage, 488)}
            srcSet={sanityImgSrcSetUtil(backImage, 488, 574, 906)}
            dimensions={backImage.metadata?.dimensions}
            crop={backImage.crop}
          />
        </BackImageContainer>
        <FrontImageContainer>
          <Img
            alt={frontImage.alt}
            sizes="(max-width: 768px) 392px, (max-width: 1024px) 458px, 720px"
            src={sanityImgUtil(frontImage, 392)}
            srcSet={sanityImgSrcSetUtil(frontImage, 392, 458, 720)}
            dimensions={frontImage.metadata?.dimensions}
            crop={frontImage.crop}
          />
        </FrontImageContainer>
      </ImagesContainer>
    </Container>
  );
};

export default HighlightedImagesModule;
