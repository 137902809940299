import React, { useCallback, useMemo, useState } from 'react';
import { ISupplierCtaModule } from 'styled/components/modules/SupplierCtaModule/types';
import { StyledButton } from './SupplierCtaModule.style';
import { useTheme } from 'styled-components';
import {
  SUPPLIER_CTA_ANCHOR,
  WINDOW_PARAM,
  trackCtaButtonClicked,
} from './analytics';
import SupplierPromoModal from '../../SupplierPromoModal';
import SupplierDrawer from './Drawer';
import { Portal } from '@mui/base';

interface ISupplierCtaButton {
  module: ISupplierCtaModule;
  label: string;
  ctaOnly?: boolean;
  scrollToModule?: boolean;
  moduleId: string;
}

const SupplierCtaButton = ({
  module,
  label,
  scrollToModule,
  ctaOnly,
  moduleId,
}: ISupplierCtaButton) => {
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIFrameOpen, setIsIFrameOpen] = useState(false);

  const { ctaButton, modalConfig } = module;
  const ctaButtonLink = !ctaButton.isIFrame && ctaButton.url;
  const buttonOpensNewLink = !!ctaButtonLink && !scrollToModule;

  const iframeUrl = useMemo(() => {
    if (ctaButton.isIFrame && ctaButton.url) {
      return ctaButton.url;
    }
    const lastModalPage = modalConfig?.pages?.at(-1);
    if (lastModalPage?.ctaButton?.isIFrame && lastModalPage?.ctaButton?.url) {
      return lastModalPage.ctaButton.url;
    }
  }, [ctaButton.isIFrame, ctaButton.url, modalConfig?.pages]);

  const onCtaClick = useCallback(() => {
    if (ctaOnly) {
      trackCtaButtonClicked(
        window[WINDOW_PARAM] || '',
        'desktop-sidebar-cta-clicked'
      );
    } else {
      trackCtaButtonClicked(
        moduleId,
        'embedded-cta-clicked',
        buttonOpensNewLink ? ctaButton.url : undefined
      );
    }

    if (scrollToModule) {
      return;
    } else if (ctaButton.url && ctaButton.isIFrame) {
      setIsIFrameOpen(true);
    } else if (!ctaButton.url) {
      setIsModalOpen(true);
    }
  }, [
    buttonOpensNewLink,
    ctaButton.isIFrame,
    ctaButton.url,
    ctaOnly,
    moduleId,
    scrollToModule,
  ]);

  return (
    <>
      <StyledButton
        touchRippleColor={theme.palette.orange[30]}
        fullWidth
        withBuoyTheme
        onClick={onCtaClick}
        showFocusRing
        href={
          scrollToModule
            ? `#${SUPPLIER_CTA_ANCHOR}`
            : ctaButtonLink || undefined
        }
        target={buttonOpensNewLink ? '_blank' : undefined}
      >
        {label}
      </StyledButton>
      {modalConfig && (
        <SupplierPromoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onOpen={() => setIsModalOpen(true)}
          config={modalConfig}
          openIFrame={() => {
            setIsIFrameOpen(true);
          }}
        />
      )}
      {iframeUrl && (
        <Portal>
          <SupplierDrawer
            open={isIFrameOpen}
            handleDrawerClose={() => setIsIFrameOpen(false)}
            iframeUrl={iframeUrl}
          />
        </Portal>
      )}
    </>
  );
};

export default SupplierCtaButton;
