import get from 'utils/get';
import { DefaultReferencesSideNav } from 'constants/Default';
import {
  Article,
  ArticleBodyModule,
  ArticleGenericPage,
  ContentAnchor,
} from 'types';

const composeSxDxArticleSideNavContentAnchors = (
  article: Article | ArticleGenericPage,
) => {
  const content = get(article, 'content');
  const overviewLabel = get(article, 'content.overviewLabel');
  const overviewAnchor = get(article, 'content.overviewSlug');

  const overviewSideNavItem: (ContentAnchor | null)[] =
    overviewAnchor && overviewLabel
      ? [
          {
            label: overviewLabel,
            anchor: overviewAnchor,
            id: overviewAnchor,
          },
        ]
      : [];

  const referencesSideNavItem: (ContentAnchor | null)[] = get(
    content,
    'references.length',
    0,
  )
    ? [DefaultReferencesSideNav]
    : [];

  const bodyModules = content
    ? get(content, 'bodyModules')
    : get(article, 'bodyModules', []);

  return overviewSideNavItem
    .concat(
      bodyModules.map((module: ArticleBodyModule) => {
        if (!module) return null;

        if (
          'sideNav' in module &&
          module.sideNav.label &&
          module.sideNav.anchor
        ) {
          return module.sideNav;
        }

        return null;
      }),
    )
    .concat(referencesSideNavItem);
};

export default composeSxDxArticleSideNavContentAnchors;
