import styled from 'styled-components';
import {
  Base,
  Theme,
  CardTile,
  CardTileTitleContainer,
  CardTileImgContainer,
} from 'styled';
import Link from 'styled/components/Link';
import { Anchor } from 'styled/components/Link/style';

type ContainerProps = {
  cardTileHasImage: boolean;
};

export const Container = styled(CardTile)<ContainerProps>`
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  filter: none;
  box-shadow:
    rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px,
    rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${Anchor} {
    overflow-y: hidden;
  }

  &:hover {
    box-shadow:
      rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px,
      rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }
`;

export const TextContainer = styled(Base)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.25rem;
  flex: 1;
  height: auto;
`;

export const TitleContainer = styled(CardTileTitleContainer)`
  ${Theme.mediaQueries.lg} {
    ${Anchor} {
      ${({ theme }) => theme.typography.variants.primary.md};
    }
  }
`;

export const SimplifiedDescription = styled.p<{ $maxLines: number }>`
  display: flex;
  margin-top: 0.5rem;
  ${({ theme }) => theme.typography.variants.primary.xs};

  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: ${({ $maxLines }) => $maxLines};
  -webkit-box-orient: vertical;
`;

export const ImgContainer = styled(CardTileImgContainer)`
  img {
    border-radius: unset;
  }
`;

export const Cta = styled(Link).attrs({
  variant: 'underline-blue-xs-sm-secondary',
  wrap: true,
  // applies css to button container instead of inner
  style: {
    marginTop: '0.5rem',
    overflowY: 'unset',
  },
})``;

export const ExtendedDescription = styled(Base)`
  margin-top: 1rem;
  ${({ theme }) => theme.typography.variants.secondary.xs};

  border-top: ${({ theme }) => `1px solid ${theme.palette.gray[10]}`};
  padding-top: 1rem;

  p {
    margin-bottom: 0.75rem;
  }
`;
