import React, { FC, useRef } from 'react';
import NukaCarousel, { ControlProps } from 'nuka-carousel';
import cx from 'classnames';

import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

import { ModuleHeader } from 'components/modules';
import ArticleItem from './ArticleItem';
import ImageItem from './ImageItem';
import TextItem from './TextItem';
import VideoItem from './VideoItem';
import QuoteItem from './QuoteItem';
import { Button, RightArrowInsideCircle, LeftArrowInsideCircle } from 'styled';
import {
  Container,
  Header,
  Carousel,
  CardContainer,
  Card,
  ControlArrow,
} from './style';

import { CARD_MARGIN } from './constants';
import { getCarouselMaxWidth, getSlideWidth } from './utils';

import {
  GeneralModuleColor,
  GeneralModuleColorScheme,
} from 'constants/ColorScheme';
import { Color } from 'styled/theme/colors';
import useLanguage from 'hooks/useLanguage';

import { CarouselModule as ICarouselModule, CarouselItem } from 'types';
import useElementDimensions from 'hooks/useElementDimensions';
import { useTheme } from 'styled-components';

type PassedProps = {
  module: ICarouselModule;
  applyArticleBodyWidthOffset?: boolean;
  isArticleOrAuthorPageCarousel?: boolean;
  removeMarginBottom?: boolean;
};

type Props = WithBreakpointsProps & PassedProps;

const renderCarouselItemByType = (
  item: CarouselItem,
  bgColor: Color,
  textColor: Color
) => {
  if (!item) return null;

  switch (item.type) {
    case 'article':
      return (
        <ArticleItem item={item} bgColor={bgColor} textColor={textColor} />
      );
    case 'textItem':
      return <TextItem item={item} bgColor={bgColor} textColor={textColor} />;
    case 'quoteItem':
      return <QuoteItem item={item} bgColor={bgColor} textColor={textColor} />;
    case 'imageItem':
      return <ImageItem item={item} bgColor={bgColor} textColor={textColor} />;
    case 'videoItem':
      return <VideoItem item={item} bgColor={bgColor} textColor={textColor} />;
    default:
      return null;
  }
};

const CarouselModule: FC<Props> = ({
  module,
  currentBreakpoint,
  applyArticleBodyWidthOffset,
  isArticleOrAuthorPageCarousel,
  removeMarginBottom,
}) => {
  const Language = useLanguage();
  const { palette } = useTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { width: carouselContainerWidth } = useElementDimensions(containerRef);
  const carouselItemsLength = module.carouselItems.length;
  const carouselMaxWidth = getCarouselMaxWidth(
    currentBreakpoint,
    carouselItemsLength
  );
  const cardWidth = getSlideWidth(currentBreakpoint);
  const slidesToShow =
    (carouselContainerWidth || carouselMaxWidth) / (cardWidth + CARD_MARGIN);

  const { moduleHeader, moduleOptions } = module;
  const colorScheme =
    GeneralModuleColorScheme[moduleOptions.color as GeneralModuleColor];

  const LeftControls = (props: ControlProps) => {
    return (
      <ControlArrow
        className={cx('none', {
          'md:flex': carouselItemsLength > 3,
        })}
        as={Button}
        ariaLabel={Language.t('CarouselModule.previousButtonAriaLabel')}
        onClick={props.previousSlide}
      >
        <LeftArrowInsideCircle />
      </ControlArrow>
    );
  };

  const RightControls = (props: ControlProps) => {
    return (
      <ControlArrow
        className={cx({
          none: carouselItemsLength < 3,
          'sm:flex': carouselItemsLength > 3,
          'md:none': carouselItemsLength <= 3,
        })}
        as={Button}
        ariaLabel={Language.t('CarouselModule.nextButtonAriaLabel')}
        onClick={props.nextSlide}
      >
        <RightArrowInsideCircle />
      </ControlArrow>
    );
  };

  return (
    <Container
      applyArticleBodyWidthOffset={applyArticleBodyWidthOffset}
      maxWidth={`${carouselMaxWidth}px`}
      removeMarginBottom={removeMarginBottom}
      ref={containerRef}
    >
      <Header
        as={ModuleHeader}
        title={moduleHeader.title}
        description={moduleHeader.description}
        isArticleOrAuthorPageCarousel={isArticleOrAuthorPageCarousel}
      />
      <Carousel
        as={NukaCarousel}
        cellSpacing={CARD_MARGIN}
        defaultControlsConfig={{
          pagingDotsStyle: { display: 'none' },
        }}
        enableKeyboardControls={true}
        swiping={true}
        wrapAround={true}
        renderCenterLeftControls={LeftControls}
        renderCenterRightControls={RightControls}
        slidesToShow={slidesToShow}
        isArticleOrAuthorPageCarousel={isArticleOrAuthorPageCarousel}
      >
        {module.carouselItems.map((item, i) => {
          if (!item) return null;

          const hasEvenIndex = i % 2 === 0;
          const bgColor =
            colorScheme.background === palette.common.white
              ? palette.common.white
              : hasEvenIndex
              ? colorScheme.background
              : colorScheme.darkBackground;
          const textColor = colorScheme.text;

          return (
            <CardContainer key={item.id}>
              <Card
                bgColor={bgColor}
                textColor={textColor}
                p={
                  isArticleOrAuthorPageCarousel
                    ? [1.5, 1.5, 1.5, 1.5]
                    : [1.5, 1.5, 1.5, 2.25]
                }
              >
                {renderCarouselItemByType(item, bgColor, textColor)}
              </Card>
            </CardContainer>
          );
        })}
      </Carousel>
    </Container>
  );
};

export default withBreakpoints(CarouselModule);
