const formatDate = (value: string): string => {
  if (value.length) {
    const numberValue = value.replace(/\D/g, '').slice(0, 8);

    let formedDate = numberValue;

    if (formedDate.length > 2) {
      formedDate = formedDate.slice(0, 2) + '/' + formedDate.slice(2);
    }

    if (formedDate.length > 5) {
      formedDate = formedDate.slice(0, 5) + '/' + formedDate.slice(5);
    }

    return formedDate;
  }

  return '';
};

export default formatDate;
