import React, { FC } from 'react';
import {
  Page,
  Title,
  Description,
  Link as StyledLink,
  ImageContainer,
} from './style';
import useLanguage from 'hooks/useLanguage';
import { Base, Img, Link } from 'styled';
import Meta from 'components/Meta';
import sanityImgUtil from 'utils/sanityImgUtil';
import { ErrorPage as IErrorPage } from 'types';

type Props = {
  errorPage: IErrorPage;
};

const ErrorPage: FC<Props> = ({ errorPage }) => {
  const language = useLanguage();

  const title =
    errorPage.title ||
    language.t('Global.errorPageDefaultTitle') ||
    'Page not found.';

  return (
    <Page
      display="flex"
      flex="1"
      flexDirection={['column', 'column', 'row']}
      justifyItems={['initial', 'initial', 'center']}
      alignItems={['initial', 'initial', 'center']}
      pt={2.5}
      pl={[1.5, 1.5, 5.25]}
      pr={[1.5, 1.5, 'initial']}
    >
      <Meta renderCanonicalTag={false} />
      <Base mr={['initial', 'initial', 4, 6]}>
        <Title as="h1" mb={[1.5, 1.5, 2.5]}>
          {title}
        </Title>
        {!!errorPage.description && (
          <Description mb={1.5}>{errorPage.description}</Description>
        )}
        {errorPage.link && (
          <StyledLink
            as={Link}
            display="inline-flex"
            ariaLabel={errorPage.link.label}
            label={errorPage.link.label}
            to={errorPage.link.url}
            variant="underline-white"
          />
        )}
      </Base>
      {errorPage.image && (
        <ImageContainer
          maxWidth={['unset', 'unset', '50%']}
          alignSelf={['flex-end', 'flex-end', 'unset']}
        >
          <Img
            alt={errorPage.image.alt}
            src={sanityImgUtil(errorPage.image, 600)}
            dimensions={errorPage.image.metadata?.dimensions}
            crop={errorPage.image.crop}
          />
        </ImageContainer>
      )}
    </Page>
  );
};

export default ErrorPage;
