import React, { FC } from 'react';
import { Svg } from 'styled/components/base';

const FindCareIcon: FC = () => (
  <Svg width="24px" height="24px" viewBox="0 0 24 24" fill="transparent">
    <desc>Find Care Icon.</desc>
    <path
      d="M14.8268 23.9999L14.415 23.7349C14.0405 23.5078 5.61621 17.8674 5.61621 12.7949C5.61621 9.19865 8.16223 7.30591 10.6334 7.30591C12.2434 7.30591 13.741 8.063 14.7894 9.35007C15.8377 8.063 17.3354 7.30591 18.9454 7.30591C21.454 7.30591 23.9625 9.19865 23.9625 12.7949C23.9625 17.8674 15.5382 23.4699 15.2012 23.7349L14.8268 23.9999Z"
      fill="#FF5D5C"
    />
    <path
      d="M15.8006 18.5489H13.8162V15.8612H11.1953V13.8549H13.8162V11.1672H15.8006V13.8549H18.459V15.8612H15.8006V18.5489Z"
      fill="white"
    />
    <path
      d="M8.49922 0C5.76599 0 3.55694 2.23344 3.55694 4.99684C3.55694 5.90536 3.78159 6.73817 4.19345 7.45741L0 11.6972L0.973479 12.6814C1.42278 13.1356 2.09672 13.1356 2.54602 12.6814L5.9532 9.23659C6.70203 9.69085 7.56318 9.95583 8.53666 9.95583C11.2699 9.95583 13.4789 7.7224 13.4789 4.95899C13.4415 2.23344 11.2325 0 8.49922 0Z"
      fill="#196CFF"
    />
    <path
      d="M8.4995 7.72234C7.00184 7.72234 5.80371 6.51099 5.80371 4.99679C5.80371 3.4826 7.00184 2.27124 8.4995 2.27124C9.99716 2.27124 11.1953 3.4826 11.1953 4.99679C11.1953 6.51099 9.99716 7.72234 8.4995 7.72234Z"
      fill="white"
    />
  </Svg>
);

export default FindCareIcon;
