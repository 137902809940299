import React, { useState } from 'react';
import { StyledTable } from './styles';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import Button from '@mui/material/Button';
import useLanguage from 'hooks/useLanguage';

export const ArticleTable = (props: any) => {
  const rows = props?.node?.table?.rows || [];
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => setIsExpanded(!isExpanded);
  const language = useLanguage();

  if (!rows.length) return null;

  const isExpandable = rows?.length > 6;

  const colCount = rows[0]?.cells?.length || 0;

  return (
    <div className="mb-4">
      <StyledTable
        className="border border-solid border-gray-30"
        overflowStyles={colCount > 2}
      >
        <table className="w-full border-separate border-spacing-0 border-0">
          <tbody>
            {rows.map((row: any, rowIndex: number) => {
              const cells = row?.cells || [];

              if (!cells.length) return null;

              return (
                <tr key={row?._key || rowIndex}>
                  {cells.map((cell: string, cellIndex: number) => {
                    if (rowIndex > 5 && !isExpanded) {
                      return null;
                    }

                    const props = {
                      key: `${cell}-${cellIndex}`,
                      children: cell,
                    };

                    if (rowIndex === 0)
                      return (
                        <th
                          {...props}
                          className="border-b border-solid border-gray-30 bg-white p-3 text-left !font-bold"
                        />
                      );

                    return <td {...props} className="bg-white p-3 text-left" />;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </StyledTable>
      {isExpandable && (
        <Button
          onClick={toggleExpansion}
          fullWidth
          className="sticky rounded-none border border-t-0 border-solid border-gray-30 normal-case"
        >
          {isExpanded ? (
            <>
              {language.t('ArticleTable.collapseTable')} <ExpandLessRounded />
            </>
          ) : (
            <>
              {language.t('ArticleTable.expandTable')} <ExpandMoreRounded />
            </>
          )}
        </Button>
      )}
    </div>
  );
};
