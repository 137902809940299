import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import theme from '@buoyhealth/common.buoy-theme';

export const StyledBox = styled(Box)(() => ({
  backgroundColor: theme.palette.orange[0],
  position: 'relative',
}));

export const Puller = styled(Box)(() => ({
  width: 58,
  height: 6,
  backgroundColor: theme.palette.gray[50],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 29px)',
}));

export const Nav = styled('div')({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  padding: 10,
  width: '100%',
});
