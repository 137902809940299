import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import BlockContent from '@sanity/block-content-to-react';

import { openTesterRecruitmentPopUp } from 'state/actions/applicationUIActions';

import { Container, MainSection, SupportingSection } from './style';

import { CalloutColor, CalloutColorScheme } from 'constants/ColorScheme';
import { BlockRenderer } from 'constants/PortableTextSerializer';

import { TesterRecruitmentCtaModule as ITesterRecruitmentCtaModule } from 'types';
import useLanguage from 'hooks/useLanguage';

type Props = {
  module: ITesterRecruitmentCtaModule;
} & ReturnType<typeof mapDispatchToProps>;

const TesterRecruitmentCtaModule: FC<Props> = ({ module, actions }) => {
  const Language = useLanguage();
  const { mainCta, supportingCta, moduleOptions } = module;
  const colorScheme = CalloutColorScheme[moduleOptions.color as CalloutColor];

  return (
    <Container
      ariaLabel={Language.t('TesterRecruitment.openPopUpAriaLabel')}
      onClick={actions.openTesterRecruitmentPopUp}
    >
      <MainSection colorScheme={colorScheme}>
        <BlockContent
          blocks={mainCta}
          serializers={{
            types: { block: BlockRenderer },
          }}
        />
      </MainSection>
      <SupportingSection colorScheme={colorScheme}>
        <BlockContent
          blocks={supportingCta}
          serializers={{
            types: { block: BlockRenderer },
          }}
        />
      </SupportingSection>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      openTesterRecruitmentPopUp,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(TesterRecruitmentCtaModule);
