import dynamic from 'next/dynamic';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BlockContent from '@sanity/block-content-to-react';
import { toPlainText } from '@portabletext/react';
import {
  ExternalLink,
  InternalLink,
  BlockRenderer,
} from 'constants/PortableTextSerializer';
import React, { useCallback, useEffect, useState } from 'react';
import {
  AccordionWrapper,
  StyledAccordion,
  StyledTypeformWidget,
  AnswerContainer,
} from '../styles';
import { trackQuestionClick } from '../analytics';
import usePage from 'hooks/usePage';
import SxMiniInterview from './SxMiniInterview';
import { IQueryModuleQuery } from 'types';
import { ArticlePortableText } from 'styled';
import useFeatureFlags from 'hooks/useFeatureFlags';
import type { LDFlagSet } from 'launchdarkly-node-server-sdk';
import type { MiniInterviewType } from '@buoy-components/polaris/types';
import { HelpfulQuestion } from '@buoyhealth/common.buoy-user-feedback';

export const isMiniInterviewFeatureFlagEnabled = (
  interviewType: MiniInterviewType,
  featureFlags: LDFlagSet,
) => {
  switch (interviewType) {
    case 'emergency':
      return featureFlags[
        'growth-public-site-show-emergency-interview'
      ] as boolean;
    case 'symptoms':
      return featureFlags[
        'growth-public-site-show-whats-causing-symptoms-interview'
      ];
    case 'treatment':
      return featureFlags['growth-public-site-show-treatment-interview'];
    default:
      return false;
  }
};

interface IAccordionProps {
  query: IQueryModuleQuery;
  moduleId: string;
  index: number;
  defaultExpanded: boolean;
  isInViewport: boolean;
}

const Accordion = ({
  query,
  moduleId,
  index,
  defaultExpanded,
  isInViewport,
}: IAccordionProps) => {
  const { question, answer, typeFormID, miniInterview } = query;
  const { article } = usePage();
  const featureFlags = useFeatureFlags();

  const showMiniInterview = !!(
    miniInterview &&
    article?.slug &&
    isMiniInterviewFeatureFlagEnabled(miniInterview, featureFlags)
  );

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (isInViewport && defaultExpanded && showMiniInterview) {
      setExpanded(true);
    }
  }, [defaultExpanded, isInViewport, showMiniInterview]);

  const handleAccordionChange = useCallback(
    (e: React.SyntheticEvent, expanded: boolean) => {
      if (expanded) {
        trackQuestionClick(moduleId, question);
      }
      setExpanded(expanded);
    },
    [moduleId, question],
  );

  const showTypeForm = !showMiniInterview && typeFormID;

  if (!showMiniInterview && !answer && !typeFormID) {
    // Nothing to see here
    return null;
  }

  return (
    <AccordionWrapper key={question}>
      <StyledAccordion
        elevation={0}
        disableGutters
        expanded={expanded}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />}
          aria-controls={`queryPanel${index}-content`}
          id={`queryPanel${index}-header`}
        >
          {question}
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {showMiniInterview && expanded && (
              <SxMiniInterview
                article={article}
                formLocation="form-query-module"
                interviewType={miniInterview}
              />
            )}
            {(answer || showTypeForm) && (
              <AnswerContainer>
                <ArticlePortableText>
                  <BlockContent
                    blocks={answer}
                    serializers={{
                      marks: {
                        link: ExternalLink,
                        internalLink: InternalLink,
                      },
                      types: { block: BlockRenderer },
                    }}
                  />
                </ArticlePortableText>
                {answer && (
                  <HelpfulQuestion
                    snowplowProps={{
                      action: 'sx-query-module-static-answer',
                      label: question,
                      property: toPlainText(answer),
                    }}
                    withBuoyTheme
                  />
                )}
                {!showMiniInterview && typeFormID && (
                  <StyledTypeformWidget id={typeFormID} />
                )}
              </AnswerContainer>
            )}
          </div>
        </AccordionDetails>
      </StyledAccordion>
    </AccordionWrapper>
  );
};

export default Accordion;
