import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useMemo, useState } from 'react';
import SearchIconThin from '../../svg/SearchIconThin';
import { trackSearchBarFocus, trackSearchBarSubmit } from '../analytics';
import useLanguage from 'hooks/useLanguage';
import { StyledTextInput } from '../styles';
import { Base } from 'styled/components/base';
import { CloseIconThin } from '@buoyhealth/common.buoy-icons';

const SearchBar = ({
  placeholder,
  moduleId,
  handleSubmit,
  isLoading,
  resultsAreShowing,
  setResultsAreShowing,
}: {
  placeholder?: string;
  moduleId: string;
  handleSubmit: (stringString: string) => void;
  isLoading: boolean;
  resultsAreShowing: boolean;
  setResultsAreShowing: (showing: boolean) => void;
}) => {
  const Language = useLanguage();
  const [searchValue, setSearchValue] = useState('');

  const isQueryTooLong = useMemo(
    () => searchValue.length > 180,
    [searchValue.length]
  );

  const onSearchTextSubmit = useCallback(() => {
    if (resultsAreShowing) {
      return;
    }
    if (searchValue.trim() && !isQueryTooLong) {
      trackSearchBarSubmit(moduleId, searchValue);
      handleSubmit(searchValue);
    }
  }, [handleSubmit, isQueryTooLong, moduleId, resultsAreShowing, searchValue]);

  const onSearchTextFocus = useCallback(() => {
    trackSearchBarFocus(moduleId);
  }, [moduleId]);

  const onSearchTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value.replace('\n', '');
      if (val !== searchValue) {
        setResultsAreShowing(false);
        setSearchValue(val);
      }
    },
    [searchValue, setResultsAreShowing]
  );

  const onSearchKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && !isLoading) {
        onSearchTextSubmit();
      }
    },
    [onSearchTextSubmit, isLoading]
  );

  const onSearchTextClear = useCallback(() => {
    setSearchValue('');
    handleSubmit('');
  }, [handleSubmit]);

  return (
    <StyledTextInput
      placeholder={
        placeholder || Language.t('QueryModule.searchBarPlaceholder')
      }
      withBuoyTheme
      error={isQueryTooLong}
      helperText={
        isQueryTooLong ? Language.t('QueryModule.characterLimit') : null
      }
      onChange={onSearchTextChange}
      onFocus={onSearchTextFocus}
      onKeyDown={onSearchKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading ? (
              <Base
                width="38px"
                height="38px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress color="primary" size={22} />
              </Base>
            ) : (
              <IconButton
                onClick={
                  resultsAreShowing ? onSearchTextClear : onSearchTextSubmit
                }
              >
                {resultsAreShowing ? (
                  <CloseIconThin size={22} />
                ) : (
                  <SearchIconThin />
                )}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      multiline
      value={searchValue}
    />
  );
};

export default SearchBar;
