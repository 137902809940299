import { Theme } from 'styled';
import styled, { css } from 'styled-components';

export const StyledTable = styled.div<{ overflowStyles: boolean }>`
  td,
  th {
    ${Theme.typography.variants.primary.xs};

    &:first-child {
      border-right: ${Theme.borders['gray-30']};
    }
  }

  tr:nth-of-type(2n) {
    td,
    th {
      background-color: ${Theme.palette.gray[0]};
    }
  }

  ${Theme.mediaQueries['max-sm']} {
    td,
    th {
      min-width: 40vw;

      &:first-child {
        position: sticky;
        z-index: 2;
        left: 0;
        mix-blend-mode: unset;
      }
    }

    tr {
      mix-blend-mode: darken;
    }

    position: relative;
    overflow-x: scroll;

    ${({ overflowStyles }) =>
      overflowStyles &&
      css`
        // scrollbar styles
        &::-webkit-scrollbar {
          appearance: none;
          height: 4px;
          border-top: 1px solid ${Theme.palette.gray[20]};
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${Theme.palette.gray[20]};
          border-radius: 6px;
        }

        // scroll gradient to indicate more content
        background-attachment: local, local, scroll, scroll;
        background-image: linear-gradient(90deg, #fff, #fff),
          linear-gradient(90deg, #fff, #fff), linear-gradient(90deg, #fff, #fff),
          linear-gradient(270deg, #00000021, #fff0);
        background-position: 0, 100%, 0, 100%;
        background-repeat: no-repeat;
        background-size:
          20px 100%,
          20px 100%,
          10px 100%,
          15px 100%;
      `}
  }
`;
