import React, { FC, RefObject, createRef, useState, useEffect } from 'react';

import withBreakpoints, {
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';
import useLanguage from 'hooks/useLanguage';
import truncateStringText from 'utils/truncateStringText';
import sanityImgUtil from 'utils/sanityImgUtil';
import getDefaultArticleUrl from 'utils/getDefaultArticleUrl';

import { Img, Button, RightArrow, Link } from 'styled';
import {
  ItemContainer,
  ContentHubItemImage,
  ContentHubItemImageContainer,
  ButtonContainer,
  CopyContainer,
} from '../style';
import ItemHeader from '../ItemHeader';
import ItemDescription from '../ItemDescription';

import { Color } from 'styled/theme/colors';

import { ArticleItem as IArticleItem } from 'types';

interface PassedProps {
  item: IArticleItem;
  bgColor: Color;
  textColor: Color;
}

type Props = WithBreakpointsProps & PassedProps;

const itemHeaderRef: RefObject<HTMLDivElement> = createRef();
const itemDescriptionRef: RefObject<HTMLDivElement> = createRef();
const ITEM_HEADER_MAX_HEIGHT = 70;
const ITEM_DESCRIPTION_MOBILE_CHARACTER_LIMIT = 200;
const ITEM_DESCRIPTION_DESKTOP_CHARACTER_LIMIT = 190;

const ArticleItem: FC<Props> = ({
  item,
  bgColor,
  textColor,
  currentBreakpoint,
}) => {
  const Language = useLanguage();

  const isDxTx = item.linkedArticle;
  let dxTxTitle = item.customTitle || item.conditionName;
  // set item as linked article if it exists since this would be a dxtx page item
  item = item.linkedArticle ? item.linkedArticle : item;
  /**
   * custom dxtx title and condition name could be undefined
   * if so fallback to module title to avoid empty titles and append 'Treatments' to title
   */
  dxTxTitle = `${dxTxTitle || item.moduleTitle} Treatments`;

  const button = {
    label: Language.t('Global.readMore'),
    link: getDefaultArticleUrl({
      slug: item.slug,
      language: item.language,
      articleType: item.articleType,
    }),
  };

  const breakpointIsSmDown = ['EXTRA_SMALL', 'SMALL'].includes(
    currentBreakpoint,
  );

  const [itemDescriptionIsTruncated, setItemDescriptionIsTruncated] =
    useState(false);

  useEffect(() => {
    if (copyContainerIsOverflown() && !itemDescriptionIsTruncated) {
      setItemDescriptionIsTruncated(true);
    }
  }, [itemDescriptionIsTruncated]);

  /* If Item Header height exceeds a certain height and the copy container is likely to overflow into the image, truncate the Item Description text. */
  const copyContainerIsOverflown = () => {
    if (itemHeaderRef && itemHeaderRef.current) {
      return itemHeaderRef.current.clientHeight > ITEM_HEADER_MAX_HEIGHT;
    }

    return false;
  };

  const handleItemDescriptionLength = (text: string) => {
    return itemDescriptionIsTruncated
      ? breakpointIsSmDown
        ? truncateStringText(
            text,
            ITEM_DESCRIPTION_MOBILE_CHARACTER_LIMIT,
            Language,
          )
        : truncateStringText(
            text,
            ITEM_DESCRIPTION_DESKTOP_CHARACTER_LIMIT,
            Language,
          )
      : text;
  };

  return (
    <ItemContainer isArticleItem={true}>
      <CopyContainer>
        <div ref={itemHeaderRef}>
          <ItemHeader
            isArticleItem={true}
            button={button}
            title={isDxTx ? dxTxTitle : item.moduleTitle || item.title}
            textColor={textColor}
          />
        </div>
        <div ref={itemDescriptionRef}>
          <Link
            ariaLabel={Language.t('Global.readMoreAriaLabel', {
              topic: item.moduleTitle || item.title,
            })}
            to={button.link}
          >
            <ItemDescription
              isArticleItem={true}
              description={handleItemDescriptionLength(
                isDxTx
                  ? Language.t('ArticleCarousel.defaultTreatmentDescription', {
                      condition: item.conditionName,
                    })
                  : item.moduleDescription,
              )}
              textColor={textColor}
              bgColor={bgColor}
            />
          </Link>
        </div>
      </CopyContainer>
      <ButtonContainer>
        <Button
          label={button.label}
          ariaLabel={button.label}
          variant="underline-blue-xs"
          to={button.link}
          openInCurrentTab={true}
        />
        <RightArrow className="ml_5" />
      </ButtonContainer>
      {item.heroImage.id && (
        <ContentHubItemImageContainer>
          <ContentHubItemImage
            as={Img}
            alt={item.heroImage.alt}
            src={sanityImgUtil(
              item.moduleImage.id ? item.moduleImage : item.heroImage,
              500,
            )}
          />
        </ContentHubItemImageContainer>
      )}
    </ItemContainer>
  );
};

export default withBreakpoints(ArticleItem);
