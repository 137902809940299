import React, { PropsWithChildren } from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import PillBottleIcon from 'styled/components/svg/PillBottleIcon';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Star from '@mui/icons-material/Star';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

import type { TreatmentSupplierIcon } from '@buoy-components/polaris/types';

type IconProps = PropsWithChildren<{
  type: TreatmentSupplierIcon;
}>;

function Icon(props: IconProps) {
  const { children, type } = props;

  const containerClasses =
    'flex h-6 w-6 text-center items-center justify-center rounded-full bg-gray-0';
  const iconClasses = 'h-4 w-4 fill-orange-30';

  switch (type) {
    case 'checkmark':
      return (
        <div className={containerClasses}>
          <CheckIcon className={iconClasses} />
        </div>
      );
    case 'clock':
      return (
        <div className={containerClasses}>
          <ScheduleIcon className={iconClasses} />
        </div>
      );
    case 'cost':
      return (
        <div className={containerClasses}>
          <AttachMoneyIcon className={iconClasses} />
        </div>
      );
    case 'lock':
      return (
        <div className={containerClasses}>
          <LockIcon className={iconClasses} />
        </div>
      );
    case 'noVideo':
      return (
        <div className={containerClasses}>
          <VideocamOffIcon className={iconClasses} />
        </div>
      );
    case 'number':
      return (
        <div className={containerClasses}>
          <div className="h-4 w-4 !text-xs !text-[#E29727]">{children}</div>
        </div>
      );
    case 'rxBottle':
      return (
        <div className={containerClasses}>
          <PillBottleIcon className="h-6 w-6" backgroundColor="#FFF" />
        </div>
      );
    case 'star':
      return (
        <div className={containerClasses}>
          <Star className={iconClasses} />
        </div>
      );
    case 'video':
      return (
        <div className={containerClasses}>
          <VideocamIcon className={iconClasses} />
        </div>
      );
    default:
      return null;
  }
}

export default Icon;
