import { v4 as uuid } from 'uuid';
import React, { useMemo, useRef } from 'react';
import { IQueryModule } from 'types';
import { Base } from '../base';
import { ArticlePortableText } from '../portableText';
import { MobileDivider, Paragraph, StyledH2 } from './styles';
import { AUTO_EXPAND_AB_TEST, trackModuleImpression } from './analytics';
import Accordion from './components/Accordion';
import { withImpressionTracking } from 'analytics';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useLanguage from 'hooks/useLanguage';
import usePage from 'hooks/usePage';
import { Cases, formatTitleCasing } from 'utils/formatTitleCasing';
import useRefInView from 'hooks/useRefInView';
import ScrollableAnchorWrapper from 'components/ScrollableAnchorWrapper';
import EmbeddedInterview from '../modules/EmbeddedInterview';
import type { MiniInterviewType } from '@buoy-components/polaris/types';
import BuoyGPTSearch from './components/BuoyGPTSearch';

interface IQueryModuleProps {
  module: IQueryModule;
}

const QueryModule = ({ module }: IQueryModuleProps) => {
  const featureFlags = useFeatureFlags();
  const Language = useLanguage();
  const moduleId = useMemo(() => uuid(), []);

  const containerRef = useRef<HTMLDivElement>(null);
  const isInViewport = useRefInView(containerRef);

  const { article } = usePage();

  const shouldAutoExpandFirstAccordion = useMemo(
    () => featureFlags[AUTO_EXPAND_AB_TEST],
    [featureFlags],
  );

  const TrackingContainer: React.FC<{ children: React.ReactNode }> = useMemo(
    () =>
      withImpressionTracking(Base, () => {
        trackModuleImpression(moduleId, shouldAutoExpandFirstAccordion);
      }),
    [moduleId, shouldAutoExpandFirstAccordion],
  );

  // Make sure queries with the symptom interview always appear first
  const sortedQueries = useMemo(
    () =>
      [...module.queries]?.sort((a) => {
        if (a.miniInterview === 'symptoms') {
          return -1;
        }
        return 0;
      }),
    [module.queries],
  );

  const { displaySearchBar } = module;

  /**
   * Display these modules as an EmbeddedInterview module if the feature flag is on.
   * If this ends up being permanent behavior we will probably want to change these
   * modules in Sanity to directly render the EmbeddedInterview instead of the QueryModule.
   */
  if (
    featureFlags['growth-public-site-polaris-style-embedded-mini-interviews']
  ) {
    return (
      <ScrollableAnchorWrapper section={module.sideNav} disableAds>
        <EmbeddedInterview
          module={{
            id: module.id,
            type: 'embeddedInterview',
            interviews: module.queries
              .map((query, i) => ({
                _key: i.toString(),
                question: query.question,
                interview: query.miniInterview as MiniInterviewType,
              }))
              .filter((i) => Boolean(i.interview)),
          }}
          showSearchBar={displaySearchBar}
        />
      </ScrollableAnchorWrapper>
    );
  }

  if (
    !featureFlags['growth-public-site-sx-query-module-enabled'] ||
    (!module.queries.length && !displaySearchBar)
  ) {
    return null;
  }

  return (
    <ScrollableAnchorWrapper section={module.sideNav}>
      <TrackingContainer>
        <MobileDivider top />
        <Base margin={['2rem 0', '2.5rem 0 3rem']} ref={containerRef}>
          <ArticlePortableText>
            {module.queries.length ? (
              <h2>{module.title}</h2>
            ) : (
              <>
                <StyledH2>
                  {Language.t('QueryModule.askAQuestion')}
                  {article?.conditionName && (
                    <>
                      {' '}
                      {Language.t('QueryModule.about')}{' '}
                      {formatTitleCasing({
                        title: article?.conditionName,
                        casing: Cases.LowerCase,
                      })}
                    </>
                  )}
                </StyledH2>
                <Paragraph>{Language.t('QueryModule.typeAQuestion')}</Paragraph>
              </>
            )}
          </ArticlePortableText>
          {sortedQueries.map((query, i) => (
            <Accordion
              key={query.question}
              index={i}
              moduleId={moduleId}
              query={query}
              defaultExpanded={shouldAutoExpandFirstAccordion && i === 0}
              isInViewport={isInViewport}
            />
          ))}
          {displaySearchBar && (
            <Base marginTop={['0.5rem', '0.5rem', '1rem']}>
              <BuoyGPTSearch
                moduleId={moduleId}
                placeholder={
                  !module.queries.length
                    ? Language.t('QueryModule.askAQuestion')
                    : undefined
                }
              />
            </Base>
          )}
        </Base>
        <MobileDivider />
      </TrackingContainer>
    </ScrollableAnchorWrapper>
  );
};

export default QueryModule;
