import styled from 'styled-components';
import { Base, Theme } from 'styled';

type TabProps = {
  isActive: boolean;
};

type TitleContainerProps = {
  moduleHasButton: boolean;
  buttonInlineWithTitle: boolean | undefined;
};

type ContentProps = {
  moduleHasButton: boolean;
  buttonInlineWithTitle: boolean | undefined;
};

export const Container = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display'].includes(prop) && defaultValidatorFn(prop),
})`
  padding-top: 3rem;
  padding-bottom: 3rem;

  ${Theme.mediaQueries.md} {
    padding-top: 6rem;
    padding-bottom: 3.75rem;
  }
`;

export const Button = styled(Base)`
  display: inline-flex;
  margin-top: 1rem;
`;

export const TabsContainer = styled(Base)`
  display: flex;
  flex-wrap: wrap;
`;

export const TabContainer = styled(Base)``;

export const Tab = styled(Base)<TabProps>`
  ${({ theme }) => theme.typography.variants.primary.sm};
  color: ${({ isActive, theme }) =>
    isActive ? 'initial' : theme.palette.gray[50]};
`;

export const Content = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display'].includes(prop) && defaultValidatorFn(prop),
})<ContentProps>`
  ${({ theme }) => theme.typography.variants.primary.sm};

  margin-right: ${({ moduleHasButton, buttonInlineWithTitle }) =>
    moduleHasButton && !buttonInlineWithTitle ? '1rem' : 0};

  ${Theme.mediaQueries.md} {
    max-width: 37.5rem;
  }
`;

export const ContentContainer = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display'].includes(prop) && defaultValidatorFn(prop),
})``;

export const TopSection = styled(Base).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['display'].includes(prop) && defaultValidatorFn(prop),
})``;

export const TitleContainer = styled(Base)<TitleContainerProps>`
  h2 {
    ${({ theme }) => theme.typography.variants.primary.lg};

    ${Theme.mediaQueries.md} {
      ${({ theme }) => theme.typography.variants.primary.xl};
    }
  }

  margin-right: ${({ moduleHasButton, buttonInlineWithTitle }) =>
    moduleHasButton && buttonInlineWithTitle ? '1rem' : 0};
`;

export const Description = styled.p`
  ${({ theme }) => theme.typography.variants.primary.sm};
`;
