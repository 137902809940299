import React, { FC } from 'react';

import cx from 'classnames';

import { LinkOrButton } from 'styled';

import {
  EmphasizeModuleColorScheme,
  EmphasizeModuleColor,
} from 'constants/ColorScheme';
import useLanguage from 'hooks/useLanguage';

import { EmphasisLinkCardModule as IEmphasisLinkCardModule } from 'types';
import { useTheme } from 'styled-components';

const defaultProps = {
  hasCallout: false,
};

type Props = {
  module: IEmphasisLinkCardModule;
  hasCallout?: boolean;
  applyArticleBodyWidthOffset?: boolean;
};

const EmphasisLinkCardModule: FC<Props> = ({
  module,
  hasCallout,
  applyArticleBodyWidthOffset,
}) => {
  const Language = useLanguage();
  const { palette } = useTheme();
  const { content, contentTitleAlignment, moduleOptions } = module;
  const colorScheme =
    EmphasizeModuleColorScheme[
      module.moduleOptions.color as EmphasizeModuleColor
    ];
  const buttonVariant = content.button.variant;

  return (
    <div
      className={cx(
        'EmphasisLinkCardModule w100 px1_5 md:px9 py6 flex flex-col',
        {
          'md:items-center': !hasCallout,
          'border-top-gray-lighter': moduleOptions.showBorderTop,
          'border-bottom-gray-lighter': moduleOptions.showBorderBottom,
          'module-content-padding-with-callout': hasCallout,
          'article-inner-text-full-width': applyArticleBodyWidthOffset,
        }
      )}
      style={{
        backgroundColor: colorScheme.background,
      }}
    >
      <div
        className={cx(
          'EmphasisLinkCardModule__container flex flex-col items-start w100',
          {
            'EmphasisLinkCardModule__container--with-callout': hasCallout,
            'color-gray-100': colorScheme.text === palette.text.primary,
            'color-white': colorScheme.text === palette.common.white,
          }
        )}
      >
        <p
          className={cx('EmphasisLinkCardModule__title w100 mb2', {
            'text-xl': hasCallout,
            'text-lg': !hasCallout,
            'text-center': contentTitleAlignment === 'center',
          })}
        >
          {content.title}
        </p>
        {content.description && (
          <div className="mb1_5">
            <span
              className={hasCallout ? 'text-secondary-md' : 'text-secondary-sm'}
            >
              {content.description}
            </span>
          </div>
        )}
        {content.button.label && content.button.url && (
          <LinkOrButton
            ariaLabel={Language.t('Global.pageButtonAriaLabel', {
              title: content.button.url,
            })}
            className="inline-flex"
            variant={
              buttonVariant === 'primary'
                ? buttonVariant
                : (`hover-${buttonVariant}` as
                    | 'hover-underline-white'
                    | 'hover-underline-black')
            }
            label={content.button.label}
            to={content.button.url}
            wrap={true}
          />
        )}
      </div>
    </div>
  );
};

EmphasisLinkCardModule.defaultProps = defaultProps;

export default EmphasisLinkCardModule;
