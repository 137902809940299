import theme from '@buoyhealth/common.buoy-theme';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import React, { useRef } from 'react';
import {
  StyledList,
  StyledListItem,
  StyledButton,
} from '../../modules/SupplierCtaModule/SupplierCtaModule.style';
import { Slides } from '../SupplierPromoModal.style';
import { ISupplierCtaModule } from 'styled/components/modules/SupplierCtaModule/types';
import { ButtonProps } from '@buoyhealth/common.buoy-button';
import { trackModalFinalLinkClicked, trackNextPageClicked } from '../analytics';
import { ICONS } from 'styled/components/modules/SupplierCtaModule/constants';

interface IModalContentProps {
  config: ISupplierCtaModule['modalConfig'];
  page: number;
  goToNextPage: () => void;
  buttonProps?: Partial<ButtonProps>;
  impressionId: string;
  triggerIFrameOpen?: () => void;
}

const ModalContent = ({
  buttonProps,
  config,
  page,
  goToNextPage,
  impressionId,
  triggerIFrameOpen,
}: IModalContentProps) => {
  const pageConfigs = config.pages || [];
  const currentPage = pageConfigs[page];
  const { ctaButton } = currentPage;

  const containerRef = useRef<HTMLDivElement>(null);

  const ctaButtonLink = !ctaButton.isIFrame && ctaButton.url;

  const onCtaButtonClick = () => {
    if (ctaButton.url) {
      trackModalFinalLinkClicked(impressionId, ctaButton.url);
    }

    if (ctaButton.url && ctaButton.isIFrame) {
      triggerIFrameOpen?.();
    } else if (!ctaButton.url) {
      trackNextPageClicked(impressionId);
      goToNextPage();
    }
  };

  return (
    <Box ref={containerRef} sx={{ overflowX: 'hidden' }} p={1}>
      <Typography
        sx={{
          color: 'text.primary',
          textTransform: 'uppercase',
          marginBottom: '4px',
        }}
        fontSize="0.75rem"
        letterSpacing="2px"
      >
        {config.headerIntro}
      </Typography>
      <Slides>
        {pageConfigs.map((pageConfig, i) => (
          <Slide
            container={containerRef?.current}
            direction={i === page ? 'left' : 'right'}
            in={i === page}
            key={pageConfig.header}
            appear={false}
          >
            <Box>
              <Typography variant="h5" sx={{ marginBottom: 2.5 }}>
                {pageConfig.header}
              </Typography>
              <StyledList>
                {pageConfig.bulletPoints.map((bp, bpIndex) => {
                  const Icon = ICONS.find(
                    (icon) => icon.name === bp.icon
                  )?.component;
                  return (
                    <StyledListItem key={bp.text}>
                      <span>
                        {Icon && (
                          <Icon
                            color={theme.palette.orange[40]}
                            backgroundColor={theme.palette.gray[0]}
                          >
                            {bpIndex + 1}
                          </Icon>
                        )}
                      </span>
                      <span>{bp.text}</span>
                    </StyledListItem>
                  );
                })}
              </StyledList>
            </Box>
          </Slide>
        ))}
      </Slides>
      <Box mt="1rem">
        <StyledButton
          $spaceBetween
          href={ctaButtonLink || undefined}
          target={ctaButtonLink ? '_blank' : undefined}
          showFocusRing
          touchRippleColor={theme.palette.orange[30]}
          withBuoyTheme
          onClick={onCtaButtonClick}
          sx={{ minWidth: '50%' }}
          {...buttonProps}
        >
          <span>{ctaButton.label}</span>
          <span>→</span>
        </StyledButton>
      </Box>
    </Box>
  );
};

export default ModalContent;
