import React, { FC } from 'react';
import { Svg } from '../base';

type Props = {
  backgroundColor?: string;
  className?: string;
  color?: string;
};

const PillBottleIcon: FC<Props> = ({
  className = '',
  color = '#E29727',
  backgroundColor = '#F7F7F7',
}) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <ellipse cx="11.6424" cy="12" rx="11.6424" ry="12" fill={backgroundColor} />
    <rect
      x="7.75"
      y="8.75"
      width="8.19952"
      height="9.5"
      rx="1.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <rect
      x="10.6631"
      y="11.75"
      width="5.28626"
      height="3.5"
      stroke={color}
      strokeWidth="1.5"
    />
    <rect
      x="9.44336"
      y="6.5"
      width="4.8186"
      height="1"
      rx="0.5"
      stroke={color}
    />
  </Svg>
);

export default PillBottleIcon;
