import React, { useCallback, useState } from 'react';

import { LinkOrButton, Button } from 'styled';
import { ModuleHeader } from 'components/modules';
import {
  Container,
  LinksContainer,
  LinkContainer,
  LinkTextContainer,
  LinkText,
  ShowMoreButtonContainer,
} from './style';

import {
  GeneralModuleColorScheme,
  GeneralModuleColor,
} from 'constants/ColorScheme';

import useLanguage from 'hooks/useLanguage';

import { LinksListModule as ILinksListModule } from 'types';

interface Props {
  module: ILinksListModule;
  applyArticleBodyWidthOffset?: boolean;
}

interface State {
  showMore: boolean;
}

const MAX_LIST_COUNT = 7;

function LinksListModule(props: Props) {
  const Language = useLanguage();
  const [state, setState] = useState<State>({
    showMore: false,
  });

  const handleSeeMoreClick = useCallback(() => {
    setState((s) => ({
      ...s,
      showMore: !s.showMore,
    }));
  }, []);

  const { module, applyArticleBodyWidthOffset } = props;
  const { showMore } = state;
  const { anchor, moduleHeader, contents, moduleOptions } = module;
  const headerTitle = moduleHeader.title;
  const headerDescription = moduleHeader.description;
  const colorScheme =
    GeneralModuleColorScheme[module.moduleOptions.color as GeneralModuleColor];
  const renderModuleHeader = !!headerTitle || !!headerDescription;

  return (
    <Container
      id={anchor ? anchor : undefined}
      data-testid="links-list-module"
      applyArticleBodyWidthOffset={applyArticleBodyWidthOffset}
      colorScheme={colorScheme}
      moduleOptions={moduleOptions}
      renderModuleHeader={renderModuleHeader}
      pb={6}
    >
      {renderModuleHeader && (
        <ModuleHeader title={headerTitle} description={headerDescription} />
      )}
      <LinksContainer>
        {(contents.length > MAX_LIST_COUNT && !showMore
          ? contents.slice(0, MAX_LIST_COUNT)
          : contents
        ).map((item) => {
          const linkIsAnchor = item.url.startsWith('#');

          return (
            <LinkContainer
              key={item.id}
              position="relative"
              display="flex"
              justifyContent="space-between"
              alignItems={['flex-end', 'flex-end', 'center']}
              py={1.75}
            >
              <LinkTextContainer mr={[0, 0, 0, 4]}>
                <LinkOrButton
                  ariaLabel={item.title}
                  to={item.url}
                  wrap={true}
                  variant="hover-underline-black"
                  label={item.title}
                  openInCurrentTab={linkIsAnchor}
                />
                {!!item.description && (
                  <LinkText as="p" mt={[1, 1, 1, 0.5]}>
                    {item.description}
                  </LinkText>
                )}
              </LinkTextContainer>
              <LinkOrButton
                ariaLabel={item.title}
                to={item.url}
                wrap={true}
                variant="hover-underline-black"
                label={item.buttonLabel}
                openInCurrentTab={linkIsAnchor}
              />
            </LinkContainer>
          );
        })}
      </LinksContainer>
      {contents.length > MAX_LIST_COUNT && (
        <ShowMoreButtonContainer mt={1.75}>
          <Button
            ariaLabel={
              showMore
                ? Language.t('Global.seeLess')
                : Language.t('Global.seeMore')
            }
            label={
              showMore
                ? Language.t('Global.seeLess')
                : Language.t('Global.seeMore')
            }
            onClick={handleSeeMoreClick}
            variant="underline-black"
          />
        </ShowMoreButtonContainer>
      )}
    </Container>
  );
}

export default LinksListModule;
