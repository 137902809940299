import Box from '@mui/material/Box';
import React from 'react';
import ImageHeader from './ImageHeader';
import ModalContent from './ModalContent';
import { ISupplierPromoModalProps } from '../index';
import SwipeableMobileDrawer from 'styled/components/SwipeableMobileDrawer';
import styled from 'styled-components';

export interface IMobileSupplierCtaModalProps extends ISupplierPromoModalProps {
  paginate: (diff: -1 | 1) => void;
  page: number;
  impressionId: string;
  triggerIFrameOpen: () => void;
}

const HeaderContainer = styled.div`
  svg {
    max-height: 25vh;
  }
`;

const MobileSupplierCtaModal = ({
  config,
  isOpen,
  onClose,
  onOpen,
  paginate,
  page,
  impressionId,
  triggerIFrameOpen,
}: IMobileSupplierCtaModalProps) => {
  const goToPreviousPage = () => paginate(-1);
  const goToNextPage = () => paginate(1);

  return (
    <SwipeableMobileDrawer
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      showBackButton={page !== 0}
      onBackClick={goToPreviousPage}
      HeaderComponent={
        <HeaderContainer>
          <ImageHeader config={config} page={page} />
        </HeaderContainer>
      }
    >
      <Box padding="1.5rem 1rem" paddingBottom={6}>
        <ModalContent
          config={config}
          page={page}
          goToNextPage={goToNextPage}
          impressionId={impressionId}
          buttonProps={{
            fullWidth: true,
          }}
          triggerIFrameOpen={triggerIFrameOpen}
        />
      </Box>
    </SwipeableMobileDrawer>
  );
};

export default MobileSupplierCtaModal;
