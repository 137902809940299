import styled from 'styled-components';
import { Base, Theme } from 'styled';

type ListItemsContainerProps = {
  moduleIntersectsWithViewport: boolean;
};

export const Container = styled(Base)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const TextContainer = styled(Base)`
  ${({ theme }) => theme.global.managedMarketplacePageContentMaxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VideoContainer = styled(Base)`
  width: 100%;
  height: 100%;
`;

export const TitleContainer = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary['md-lg']};
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;

  ${Theme.mediaQueries.sm} {
    ${({ theme }) => theme.typography.variants.primary.xl};
    padding: 7.5rem 5rem 1.5rem;
  }
`;

export const ListItemsContainer = styled(Base)<ListItemsContainerProps>`
  padding: 1.25rem 2.5rem;
  width: 100%;
  opacity: 0;
  ${({ moduleIntersectsWithViewport, theme }) =>
    moduleIntersectsWithViewport && theme.animations.fadeInUpShortDistance};
  animation-delay: 600ms;

  ${Theme.mediaQueries.sm} {
    padding-top: 3rem;
    padding-bottom: 8.75rem;
  }
`;

export const ListItemsInnerContainer = styled(Base)`
  display: flex;
  flex-direction: column;

  ${Theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
`;

export const IconAndText = styled(Base)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1.25rem 0;

  ${Theme.mediaQueries.sm} {
    margin: 1.25rem;
  }
`;

export const IconText = styled(Base)`
  ${({ theme }) => theme.typography.variants.primary.md};
  margin-left: 0.5rem;

  ${Theme.mediaQueries.sm} {
    width: 8.1875rem;
  }

  ${Theme.mediaQueries.md} {
    width: 12.270625rem;
    max-width: unset;
  }

  ${Theme.mediaQueries.lg} {
    width: 17.8rem;
  }
`;
