import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '@buoyhealth/common.buoy-theme';

import { Svg } from 'styled';
import { Color } from 'styled/theme/colors';

type Props = {
  className?: string;
  color?: Color;
  isReverse?: boolean;
};

const AccordionArrowThick: FC<Props> = ({
  className = '',
  color = theme.palette.blue[80],
}) => (
  <Svg
    width="100%"
    height="100%"
    viewBox="0 0 19 13"
    fill="none"
    className={className}
  >
    <desc>Arrow Icon.</desc>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.707107 4.03553C0.316583 3.64501 0.316583 3.01184 0.707107 2.62132L2.12132 1.20711C2.51184 0.816582 3.14501 0.816582 3.53553 1.20711L9.41421 7.08579L15.2929 1.20711C15.6834 0.816583 16.3166 0.816583 16.7071 1.20711L18.1213 2.62132C18.5118 3.01184 18.5118 3.64501 18.1213 4.03553L10.1213 12.0355C9.7308 12.4261 9.09763 12.4261 8.70711 12.0355L0.707107 4.03553Z"
      fill={color}
    />
  </Svg>
);

export default styled(AccordionArrowThick)`
  ${({ theme }) => theme.transitions['transition-short']};
  transform: ${({ isReverse }) => (isReverse ? 'scaleY(-1)' : 'initial')};
`;
